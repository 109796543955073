import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    ExpandMore,
} from '@mui/icons-material';
import BulletinBoardContextLevel from '@contexts/bulletin-board';
import { AddLinkMenu } from '@components/menus';
import localStyles from './text-context-menu.module.scss';

/**
 * Replace this with a comment describing the component.
 */
function TextContextMenu({
    anchorEl,
    onClose,
    onAddLink,
    onRemoveLink,
}) {

    // #region Context

    /**
     * Use the bulletin board currentLink context value.
     */
    const bulletinBoardCurrentLink = BulletinBoardContextLevel.use.currentLink.value();

    // #endregion

    // #region State

    /**
     * Flag indicating whether the link submenu is visible or not.
     */
    const [
        linkSubmenuShown,
        setLinkSubmenuShown,
    ] = useState(false);

    // #endregion

    // #region Effects

    /**
     * Whenever the `currentLink` context value changes, update the link submenu visibility.
     */
    useEffect(() => {
        if (bulletinBoardCurrentLink) {
            setLinkSubmenuShown(true);
        }
    }, [ bulletinBoardCurrentLink ]);

    // #endregion

    // #region Functions

    /**
     * Factory for generating `onClose` functions for submenus.
     */
    const submenuOnCloseFactory = (setFn) => {
        return () => {
            setFn(false);
            onClose();
        };
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.wrapper}>
            {/* Secondary Menus */}
            <AddLinkMenu
                anchorEl={linkSubmenuShown ? anchorEl : null}
                onAddLink={onAddLink}
                onClose={submenuOnCloseFactory(setLinkSubmenuShown)}
                onRemoveLink={onRemoveLink}
            />
            {/* Main Menu */}
            <div
                className={`
                    ${ localStyles.textContextMenu }
                    ${
        anchorEl
            ? `opacity100 ${ bulletinBoardCurrentLink ? localStyles.withoutDelay : localStyles.withDelay }`
            : `opacity0 ${ localStyles.withoutDelay }`
        }
                `}
            >
                <button
                    type='button'
                    onClick={() => { return setLinkSubmenuShown(true); }}
                >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link/>
                    <span>
                        Link
                    </span>
                    <ExpandMore className={localStyles.expandIcon}/>
                </button>
            </div>
        </div>
    );

    // #endregion
}

TextContextMenu.propTypes = {
    /**
     * The element to anchor the text context menu to; if this isn't specified,
     * the menu will be hidden.
     */
    anchorEl:     PropTypes.object,
    /**
     * Callback function for when the "add link" button is clicked; should take the link
     * value as the only param.
     */
    onAddLink:    PropTypes.func.isRequired,
    /**
     * Callback function for when the menu should be closed.
     */
    onClose:      PropTypes.func.isRequired,
    /**
     * Callback function for when the "remove link" button is clicked.
     */
    onRemoveLink: PropTypes.func.isRequired,
};

TextContextMenu.defaultProps = {
    anchorEl: undefined,
};

export default TextContextMenu;
