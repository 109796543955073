import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    Dialog,
    Button,
    IconButton,
    StickyTooltip,
} from '@psionic/ui';
import {
    Close,
    SwapHoriz,
} from '@mui/icons-material';
import { UserInfo } from '@components/details';
import { FancyIconButton } from '@components/buttons';
import localStyles from './transfer-team-ownership-dialog.module.scss';

/**
 * Dialog for transferring team ownership.
 */
function TransferTeamOwnershipDialog({
    team,
    members,
    associations,
    onPromote,
    open,
    setOpen,
}) {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Track whether the confirm dialog is open or not.
     */
    const [
        confirmDialogOpen,
        setConfirmDialogOpen,
    ] = useState(false);

    /**
     * Track the profile of the member currently selected to transfer ownership to.
     */
    const [
        selectedMember,
        setSelectedMember,
    ] = useState(null);

    // #endregion

    // #region Functions

    /**
     * Handler for when a team member is selected.
     */
    const onTeamMemberSelected = (member) => {
        setSelectedMember(member);
        setConfirmDialogOpen(true);
        setOpen(false);
    };

    // #endregion

    // #region Render Functions

    /**
     * All of the team member list items the user can promote.
     */
    const membersListItems = useMemo(() => {
        const elements = [];

        for (const member of members) {
            // If the member is the signed-in user, don't display them in the list
            if (member?.id === profile?.id) {
                continue;
            }

            // Otherwise, add the user to the list
            elements.push(
                <li key={member.id}>
                    <div>
                        <FancyIconButton
                            className={localStyles.swapButton}
                            Icon={SwapHoriz}
                            tooltip='Transfer Team Ownership'
                            onClick={() => {
                                return onTeamMemberSelected(member);
                            }}
                        />
                        <UserInfo profile={member}/>
                    </div>
                </li>
            );
        }

        return elements;
    }, [
        members,
        associations,
    ]);

    /**
     * Empty state.
     */
    const emptyState = () => {
        return (
            <p className={localStyles.emptyState}>
                There are no team members that you can transfer ownership of this team to.
                <br/>
                <br/>
                A user must have accepted the invite to the team in order for them to be a viable candidate to
                transfer ownership to.
            </p>
        );
    };

    /**
     * Main render.
     */
    return (
        <>
            {/* CONFIRM DIALOG */}
            <Dialog
                className='dialog withIcon'
                isOpen={confirmDialogOpen}
                setIsOpen={setConfirmDialogOpen}
            >
                <div className='leftSide'>
                    <SwapHoriz className='primaryIcon'/>
                </div>
                <div className='rightSide'>
                    <h1>
                        Confirm Team Owner Transfer
                    </h1>
                    <p>
                        Are you sure you want to transfer ownership of
                        {' '}
                        {team.name}
                        {' '}
                        to
                        {' '}
                        {selectedMember?.displayName || selectedMember?.email || 'this team member'}
                        ?
                    </p>
                    <div className='actionSection'>
                        <Button
                            variant='text'
                            darkMode
                            onClick={() => {
                                return setConfirmDialogOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            darkMode
                            onClick={async() => {
                                await onPromote(selectedMember);
                                setConfirmDialogOpen(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Dialog>
            {/* MAIN DIALOG */}
            <Dialog
                className='dialog'
                isOpen={open}
                setIsOpen={setOpen}
            >
                <div className={localStyles.transferTeamOwnershipDialog}>
                    <header>
                        <h5>
                            Transfer Team Ownership
                        </h5>
                        <StickyTooltip
                            className={localStyles.closeDialogButton}
                            content='Close Transfer Team Ownership Dialog'
                        >
                            <IconButton
                                color='reject'
                                SvgIcon={Close}
                                onClick={() => {
                                    return setOpen(false);
                                }}
                            />
                        </StickyTooltip>
                    </header>
                    <section className={localStyles.dialogContent}>
                        {
                            membersListItems.length > 0
                                ? (
                                    <ul>
                                        {membersListItems}
                                    </ul>
                                )
                                : emptyState()
                        }
                    </section>
                </div>
            </Dialog>
        </>
    );

    // #endregion
}

TransferTeamOwnershipDialog.propTypes = {
    /**
     * Array of associations for the team.
     */
    associations: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Array of team member profiles for the team.
     */
    members:      PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Callback for when a user is selected to be promoted.
     */
    onPromote:    PropTypes.func.isRequired,
    /**
     * Flag indicating whether the dialog is open or closed.
     */
    open:         PropTypes.bool.isRequired,
    /**
     * Callback for setting the open state of the dialog.
     */
    setOpen:      PropTypes.func.isRequired,
    /**
     * The current team's info.
     */
    team:         PropTypes.object.isRequired,
};

TransferTeamOwnershipDialog.defaultProps = {

};

export default TransferTeamOwnershipDialog;
