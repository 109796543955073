import map from 'lodash/map';
import { createFluxState, createFluxCache } from '@psionic/flux';
import TeamModel from '@models/team';

export const userAssociations_FluxState = createFluxState({
    id:    'userAssociations',
    value: null,
});

export const userInvites_FluxState = createFluxState({
    id:    'userInvites',
    value: null,
});

export const userTeams_FluxCache = createFluxCache({
    id:    'userTeams',
    fetch: () => {
        const userAssociations = userAssociations_FluxState.get();

        return Promise.all(
            map(
                userAssociations,
                (association) => TeamModel.getByID(association.teamID)
            )
        );
    },
    dependsOn:        [ userAssociations_FluxState ],
    autofetchOnStale: true,
});

export const userTeamInvitations_FluxCache = createFluxCache({
    id:    'userTeamInvitations',
    fetch: () => {
        const userInvites = userInvites_FluxState.get();

        return Promise.all(
            map(
                userInvites,
                (association) => TeamModel.getByID(association.teamID)
            )
        );
    },
    dependsOn:        [ userInvites_FluxState ],
    autofetchOnStale: true,
});
