import { updatePassword } from 'firebase/auth';
import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';

/**
 * Updates the user's password in Firebase Auth.
 *
 * @param {Firebase.User} user The user to update the password for
 * @param {string} newPassword The new password to set for the user
 */
export default async function(user, newPassword) {
    try {
        await updatePassword(
            user,
            newPassword
        );
    } catch (err) {
        switch (err?.code) {
            case 'auth/requires-recent-login':
                throw ERRORS.factories.REQUIRES_RECENT_LOGIN();
            default:
                throw ERRORS.factories.UNDEFINED_ERROR();
        }
    }
}

// #region Categorized Errors

const ERRORS = new CategorizedErrorFactory({
    UNDEFINED_ERROR: {
        message: 'We encountered an issue while attempting to update your email!',
    },
    REQUIRES_RECENT_LOGIN: {
        message: 'This operation requires you to have a recent login.',
    },
});

// #endregion
