import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    IconButton,
    Form,
    TextField,
    StickyTooltip,
    Dialog,
    Button,
} from '@psionic/ui';
import {
    Edit,
    Check,
    Close,
    Delete,
    KeyboardArrowRight,
} from '@mui/icons-material';
import localStyles from './agenda-control.module.scss';

/**
 * Control for an agenda.
 */
function AgendaControl({
    agenda,
    onSave,
    onDelete,
    showControls,
}) {

    // #region Constants

    // #endregion

    // #region State

    /**
     * Track whether the control is in edit mode.
     */
    const [
        isEditing,
        setIsEditing,
    ] = useState(false);

    const [
        confirmDeleteDialogOpen,
        setConfirmDeleteDialogOpen,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    /**
     * Cancel editing the agenda.
     */
    const cancelEditing = () => {
        setIsEditing(false);
    };

    const onSaveAgenda = async(formData) => {
        await onSave({
            name: formData.name.value,
        });
        setIsEditing(false);
    };

    // #endregion

    // #region Render Functions

    const renderEditModeActions = () => {
        return (
            <>
                <StickyTooltip content='Save'>
                    <IconButton
                        color='approve'
                        SvgIcon={Check}
                        type='submit'
                        onClick={() => {}}
                    />
                </StickyTooltip>
                <StickyTooltip content='Cancel'>
                    <IconButton
                        color='reject'
                        SvgIcon={Close}
                        disabledOnFormSubmitting
                        onClick={cancelEditing}
                    />
                </StickyTooltip>
            </>
        );
    };

    const renderViewModeActions = () => {
        return (
            <>
                <StickyTooltip content='Edit'>
                    <IconButton
                        SvgIcon={Edit}
                        onClick={() => setIsEditing(true)}
                    />
                </StickyTooltip>
                <StickyTooltip content='Delete'>
                    <IconButton
                        color='reject'
                        SvgIcon={Delete}
                        onClick={() => setConfirmDeleteDialogOpen(true)}
                    />
                </StickyTooltip>
            </>
        );
    };

    const renderConfirmDeleteDialog = () => {
        return (
            <Dialog
                className='dialog withIcon'
                isOpen={confirmDeleteDialogOpen}
                setIsOpen={setConfirmDeleteDialogOpen}
            >
                <div className='leftSide'>
                    <Delete className='redIcon'/>
                </div>
                <div className='rightSide'>
                    <h1>
                        Confirm Delete Agenda
                    </h1>
                    <p>
                        Are you sure you want to delete agenda, "
                        {agenda.name}
                        "? This action cannot be undone.
                    </p>
                    <div className='actionSection'>
                        <Button
                            variant='text'
                            darkMode
                            onClick={() => {
                                return setConfirmDeleteDialogOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            darkMode
                            onClick={async() => {
                                await onDelete();
                                setConfirmDeleteDialogOpen(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    };

    /**
     * Main render.
     */
    return (
        <>
            {renderConfirmDeleteDialog()}
            <div className={localStyles.agendaControl}>
                {
                    isEditing
                        ? (
                            <Form
                                className={localStyles.form}
                                onSubmit={onSaveAgenda}
                            >
                                <TextField
                                    className={localStyles.textField}
                                    fieldKey='name'
                                    initialValue={agenda.name}
                                    label='Agenda Name'
                                    darkMode
                                    required
                                />
                                {
                                    showControls
                                        ? (
                                            <section className={localStyles.editActions}>
                                                { renderEditModeActions() }
                                            </section>
                                        )
                                        : null
                                }
                            </Form>
                        )
                        : (
                            <>
                                <Link
                                    className={localStyles.link}
                                    to={agenda.id}
                                >
                                    <p
                                        className={localStyles.agendaName}
                                    >
                                        { agenda.name }
                                    </p>
                                    <KeyboardArrowRight
                                        className={localStyles.arrow}
                                    />
                                </Link>
                                {
                                    showControls
                                        ? (
                                            <section className={localStyles.viewActions}>
                                                {renderViewModeActions() }
                                            </section>
                                        )
                                        : null
                                }
                            </>
                        )
                }
            </div>
        </>
    );

    // #endregion
}

AgendaControl.propTypes = {
    /**
     * The agenda to control.
     */
    agenda:       PropTypes.object.isRequired,
    /**
     * The callback for when the agenda is deleted.
     */
    onDelete:     PropTypes.func.isRequired,
    /**
     * The callback for when the agenda is saved.
     */
    onSave:       PropTypes.func.isRequired,
    /**
     * Flag indicating whether to show the controls.
     */
    showControls: PropTypes.bool,
};

AgendaControl.defaultProps = {
    showControls: false,
};

export default AgendaControl;
