import { useState, useMemo, useEffect } from 'react';
import find from 'lodash/find';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, StickyTooltip, Snackbar } from '@psionic/ui';
import {
    ArrowBack,
    Warning,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import { CORE } from '@utils/constants';
import { getTodaysAgendaUTCDate } from '@utils/time-utils';
import { SnackbarService } from '@services/snackbar';
import TodaysMinutes from '../components/todays-minutes/todays-minutes';
import TeamsMinutes from '../components/teams-minutes/teams-minutes';
import ExpiredMinutes from '../components/expired-minutes/expired-minutes';
import localStyles from './agenda-items-list-page.module.scss';

/**
 * Agenda items list page.
 */
function AgendaItemsListPage() {
    // #region Misc Hooks

    const { agendaID } = useParams();

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const team = TeamContextLevel.use.currentTeam.value();

    const teamAgendas = TeamContextLevel.use.teamAgendas.value();

    // #endregion

    // #region State

    /**
     * Track which tab we're on.
     */
    const [
        currentTab,
        setCurrentTab,
    ] = useState(TABS.TODAYS_MINUTES);

    // #endregion

    // #region Memoized Values

    const todaysDate = useMemo(() => {
        return getTodaysAgendaUTCDate(team?.timezone);
    }, []);

    const agenda = useMemo(() => {
        return find(
            teamAgendas,
            [
                'id',
                agendaID,
            ]
        );
    }, [
        teamAgendas,
        agendaID,
    ]);

    // #endregion

    // #region Effects

    /**
     * If the agenda no longer exists, go back to the agenda list.
     */
    useEffect(() => {
        if (!agenda) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => (
                    <Snackbar
                        color='warning'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Warning}
                        text='The agenda you were viewing no longer exists.'
                    />
                ),
                CORE.SNACKBAR_DURATION
            );
            navigate('..');
        }
    }, [ agenda ]);

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Memoized selected tab render.
     */
    const selectedTab = useMemo(() => {
        switch (currentTab) {
            case TABS.TODAYS_MINUTES:
                return (
                    <TodaysMinutes
                        agenda={agenda}
                        todaysDate={todaysDate}
                    />
                );
            case TABS.ALL_MINUTES:
                return (
                    <TeamsMinutes
                        agenda={agenda}
                        todaysDate={todaysDate}
                    />
                );
            case TABS.EXPIRED_MINUTES:
                return (
                    <ExpiredMinutes
                        agenda={agenda}
                        todaysDate={todaysDate}
                    />
                );
            default:
                return null;
        }
    }, [
        currentTab,
        todaysDate,
    ]);

    /**
     * Main render.
     */
    return (
        <div className='workspacePage'>
            <header className={localStyles.header}>
                <StickyTooltip content='Back to Agenda List'>
                    <IconButton
                        color='white'
                        SvgIcon={ArrowBack}
                        onClick={() => navigate('..')}
                    />
                </StickyTooltip>
                <h1>
                    {agenda?.name}
                    {' '}
                    Agenda
                </h1>
            </header>
            <section
                className={[
                    'tabs',
                    localStyles.tabs,
                ].join(' ')}
            >
                <button
                    className='tab'
                    data-selected={currentTab === TABS.TODAYS_MINUTES}
                    type='button'
                    onClick={() => setCurrentTab(TABS.TODAYS_MINUTES)}
                >
                    <p>
                        Today's Items
                    </p>
                </button>
                <button
                    className='tab'
                    data-selected={currentTab === TABS.ALL_MINUTES}
                    type='button'
                    onClick={() => setCurrentTab(TABS.ALL_MINUTES)}
                >
                    <p>
                        All Items
                    </p>
                </button>
                <button
                    className='tab'
                    data-selected={currentTab === TABS.EXPIRED_MINUTES}
                    type='button'
                    onClick={() => setCurrentTab(TABS.EXPIRED_MINUTES)}
                >
                    <p>
                        Expired Items
                    </p>
                </button>
            </section>
            <section>
                {selectedTab}
            </section>
        </div>
    );

    // #endregion
}

export default AgendaItemsListPage;

// #region Constants

/**
 * Reference enum of all of the different tabs the user can be on for this page.
 */
const TABS = {
    TODAYS_MINUTES:  'TODAYS_MINUTES',
    ALL_MINUTES:     'ALL_MINUTES',
    EXPIRED_MINUTES: 'EXPIRED_MINUTES',
};

// #endregion
