import { useEffect } from 'react';
import {
    Outlet,
    useParams,
} from 'react-router-dom';
import { useUserContext } from '@unifire-js/firebase/auth';
import { useRedirectOnCondition } from '@unifire-js/hooks';
import {
    Snackbar,
} from '@psionic/ui';
import filter from 'lodash/filter';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import {
    where,
} from 'firebase/firestore';
import {
    Warning,
} from '@mui/icons-material';
import { SnackbarService } from '@services/snackbar';
import Profile from '@models/profile';
import CheckInSurvey from '@models/check-in-survey';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import RevieweeContextLevel from '@contexts/reviewee';
import { CORE } from '@utils/constants';
import { LoadingPage } from '@components/loading';
// eslint-disable-next-line no-unused-vars
import localStyles from './wrapper.module.scss';

function RevieweePageWrapper() {

    // #region Misc Hooks

    const { revieweeID } = useParams();

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const reviewee = RevieweeContextLevel.use.reviewee.value();

    const revieweeAPI = RevieweeContextLevel.use.reviewee.api();

    const currentSurvey = RevieweeContextLevel.use.currentSurvey.value();

    const currentSurveyAPI = RevieweeContextLevel.use.currentSurvey.api();

    const team = TeamContextLevel.use.currentTeam.value();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    const teamReviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    const checkInServiceInfo = TeamCheckInContextLevel.use.checkInServiceInfo.value();

    const reviewers = RevieweeContextLevel.use.reviewers.value();

    const reviewersAPI = RevieweeContextLevel.use.reviewers.api();

    const revieweeGroups = RevieweeContextLevel.use.revieweeGroups.value();

    const revieweeGroupsAPI = RevieweeContextLevel.use.revieweeGroups.api();

    // #endregion

    // #region Effects

    /**
     * Redirect to the review-check-ins page if the user is not authorized to view the reviewee's profile.
     */
    const accessChecked = useRedirectOnCondition(
        `/team/${ team.id }/premium/check-in/review`,
        () => {
            if (
                reviewers === null
                || !find(reviewers, (reviewer) => reviewer.id === profile.id)
            ) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text="You do not have permission to view this reviewee's profile!"
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        },
        [
            profile,
            reviewers,
        ],
        () => { return !profile || reviewers === undefined; }
    );

    /**
     * Keep the reviewee's groups value synchronized.
     */
    useEffect(() => {
        revieweeGroupsAPI.set(
            filter(
                teamGroups,
                (group) => find(group.members, (member) => member.value === revieweeID)
            )
        );
    }, [
        teamGroups,
        revieweeID,
    ]);

    /**
     * Keep the reviewers value synchronized.
     */
    useEffect(() => {
        if (!revieweeGroups) {
            return;
        }

        const reviewerAssociationsForGroups = filter(
            teamReviewerAssociations,
            (association) => find(revieweeGroups, (group) => group.id === association.groupID)
                && association.reviewerID !== revieweeID
        );

        const uniqReviewerAssociationsByReviewerID = uniqBy(
            reviewerAssociationsForGroups,
            'reviewerID'
        );

        reviewersAPI.set(
            map(
                uniqReviewerAssociationsByReviewerID,
                (association) => teamMembers[ association.reviewerID ]
            )
        );
    }, [
        revieweeGroups,
        teamReviewerAssociations,
        teamMembers,
    ]);

    /**
     * Track the reviewee's profile.
     */
    useEffect(() => {
        const listenerName = 'reviewee-tracker';

        if (revieweeID) {
            Profile.addListenerByID(
                listenerName,
                revieweeID,
                revieweeAPI.set
            );

            return () => Profile.removeListener(listenerName);
        }
    }, [ revieweeID ]);

    /**
     * Track the reviewee's current survey.
     */
    useEffect(() => {
        const listenerName = 'reviewee-current-survey-tracker';

        if (revieweeID && checkInServiceInfo && team) {
            CheckInSurvey.addListenerByQueryInInstance(
                listenerName,
                `teams/${ team?.id }/checkInSurveys`,
                [
                    where('userID', '==', revieweeID),
                    where('surveyBatchID', '==', checkInServiceInfo?.latestBatchID),
                ],
                (results) => {
                    if (results?.length > 0) {
                        currentSurveyAPI.set(results[ 0 ]);
                    } else {
                        currentSurveyAPI.set(null);
                    }
                }
            );

            return () => CheckInSurvey.removeListener(listenerName);
        }
    }, [
        revieweeID,
        checkInServiceInfo,
        team,
    ]);

    // #endregion

    // #region Render Functions

    if (
        reviewee === undefined
        || currentSurvey === undefined
        || !revieweeGroups
        || !reviewers
        || !accessChecked
    ) {
        return <LoadingPage/>;
    }

    return <Outlet/>;

    // #endregion
}

/**
 * Wrapper to provide the reviewee page context level provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ProviderWrapper() {
    return (
        <RevieweeContextLevel.Provider>
            <RevieweePageWrapper/>
        </RevieweeContextLevel.Provider>
    );
}

export default ProviderWrapper;
