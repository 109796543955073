import { useEffect } from 'react';
import {
    Outlet,
    useParams,
} from 'react-router-dom';
import {
    Error,
    Warning,
} from '@mui/icons-material';
import {
    where,
} from 'firebase/firestore';
import { Snackbar } from '@psionic/ui';
import { useUserContext } from '@unifire-js/firebase/auth';
import { useRedirectOnCondition } from '@unifire-js/hooks';
import {
    useTrackLiveDataByQueryInContext,
} from '@hooks/context';
import TeamContextLevel from '@contexts/team';
import MyCheckInsContextLevel from '@contexts/my-check-ins';
import FillOutSurveyContextLevel from '@contexts/fill-out-survey';
import { SnackbarService } from '@services/snackbar';
import PrioritiesModel from '@models/priorities';
import CheckInSurveyModel from '@models/check-in-survey';
import CheckInSurveyQuestionModel from '@models/check-in-survey-question';
import { CORE } from '@utils/constants';
import { LoadingPage } from '@components/loading';
import localStyles from './wrapper.module.scss';

function FillOutSurveyWrapper() {
    // #region Misc Hooks

    const { surveyID, teamID } = useParams();

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const team = TeamContextLevel.use.currentTeam.value();

    const survey = FillOutSurveyContextLevel.use.survey.value();

    const surveyAPI = FillOutSurveyContextLevel.use.survey.api();

    const surveyQuestionsAPI = FillOutSurveyContextLevel.use.surveyQuestions.api();

    useTrackLiveDataByQueryInContext(
        'MY_PRIORITIES_TRACKER_PAST_SURVEY',
        MyCheckInsContextLevel.use.priorities,
        PrioritiesModel,
        [
            where('teamID', '==', team.id),
            where('userID', '==', profile?.id),
            where('completed', '==', false),
        ]
    );

    // #endregion

    // #region Effects

    /**
     * Redirect to the my-check-ins page if the survey requested is not owned by the user.
     */
    const ownershipChecked = useRedirectOnCondition(
        `team/${ teamID }/premium/check-in/my-check-ins`,
        () => {
            if (
                survey === null
                || survey.userID !== profile.id
            ) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You do not have permission to view this survey!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        },
        [
            profile,
            survey,
        ],
        () => { return !profile || survey === undefined; }
    );

    /**
     * Track the survey.
     */
    CheckInSurveyModel.useListenerByPath(
        'fill-out-survey-tracker',
        `teams/${ teamID }/checkInSurveys/${ surveyID }`,
        surveyAPI.set
    );

    /**
     * Keep the survey's questions loaded into context.
     */
    useEffect(() => {
        if (survey) {
            CheckInSurveyQuestionModel.addListenerByQueryInInstance(
                'survey-questions-tracker',
                `teams/${ team.id }/checkInSurveys/${ survey.id }/checkInSurveyQuestions`,
                [],
                (questions) => {
                    surveyQuestionsAPI.set(questions);
                }
            );
        }

        // In the cleanup function, remove the created listeners
        return () => {
            try {
                CheckInSurveyQuestionModel.removeListener('survey-questions-tracker');
            } catch {
                // Do nothing
            }
        };
    }, [ survey ]);

    // #endregion

    // #region Render Functions

    if (
        survey === undefined
        || !ownershipChecked
    ) {
        return <LoadingPage displayRefreshTip={false}/>;
    }

    if (!survey) {
        return (
            <div className={localStyles.error}>
                <Error/>
                <p>
                    Survey not found. You likely clicked an invalid link.
                </p>
            </div>
        );
    }

    return <Outlet/>;

    // #endregion
}

/**
 * Wrapper to provide the view survey context level provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ProviderWrapper() {
    return (
        <FillOutSurveyContextLevel.Provider>
            <FillOutSurveyWrapper/>
        </FillOutSurveyContextLevel.Provider>
    );
}

export default ProviderWrapper;
