import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '@services/firebase';
import ProfileModel from '@models/profile';

/**
 * Uploads the given file to Firebase storage, and links the download URL to the user's profile.
 */
export default async function uploadProfilePicture(profile, file) {
    const storageRef = ref(storage, `profile-avatars/${profile.id}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    await ProfileModel.writeToID(
        profile.id,
        { avatarURL: downloadURL },
        { mergeWithExistingValues: true },
    );
}
