import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import filter from 'lodash/filter';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import { useUserContext } from '@unifire-js/firebase/auth';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import MyCheckInsContextLevel from '@contexts/my-check-ins';
import { LoadingPage } from '@components/loading';
// eslint-disable-next-line no-unused-vars
import localStyles from './wrapper.module.scss';

function MyCheckInsWrapper() {

    // #region Context

    const { profile } = useUserContext();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    const teamReviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    const reviewers = MyCheckInsContextLevel.use.reviewers.value();

    const reviewersAPI = MyCheckInsContextLevel.use.reviewers.api();

    const myGroups = MyCheckInsContextLevel.use.myGroups.value();

    const myGroupsAPI = MyCheckInsContextLevel.use.myGroups.api();

    // #endregion

    // #region Effects

    /**
     * Keep the my groups value synchronized.
     */
    useEffect(() => {
        myGroupsAPI.set(
            filter(
                teamGroups,
                (group) => find(group.members, (member) => member.value === profile?.id)
            )
        );
    }, [
        teamGroups,
        profile,
    ]);

    /**
     * Keep the reviewers value synchronized.
     */
    useEffect(() => {
        if (!myGroups) {
            return;
        }

        const reviewerAssociationsForMyGroups = filter(
            teamReviewerAssociations,
            (association) => {
                return find(myGroups, (group) => group.id === association.groupID)
                    && association.reviewerID !== profile?.id;
            }
        );

        const uniqReviewerAssociationsByReviewerID = uniqBy(
            reviewerAssociationsForMyGroups,
            'reviewerID'
        );

        reviewersAPI.set(
            map(
                uniqReviewerAssociationsByReviewerID,
                (association) => teamMembers[ association.reviewerID ]
            )
        );
    }, [
        myGroups,
        teamReviewerAssociations,
        teamMembers,
        profile,
    ]);

    // #endregion

    /**
     * If the data hasn't loaded yet, return a loading page.
     */
    if (
        !myGroups
        || !reviewers
    ) {
        return <LoadingPage displayRefreshTip={false}/>;
    }

    /**
     * Main render.
     */
    return <Outlet/>;
}

/**
 * Wrapper to provide the "My Check Ins" context level Provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ProviderWrapper() {
    return (
        <MyCheckInsContextLevel.Provider>
            <MyCheckInsWrapper/>
        </MyCheckInsContextLevel.Provider>
    );
}

export default ProviderWrapper;
