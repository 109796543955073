import { useMemo } from 'react';

// #region Hook

/**
 * Use a memoized formatted reactions map ready to be used in the reusable components
 * that have been created for displaying reactions.
 *
 * @param {Object[]} reactions The reactions to format (will be used as a dependency for the memoization)
 * @returns {Object} The memoized formatted reactions map
 */
export default function(reactions) {
    return useMemo(() => {
        if (!reactions) {
            return {};
        }

        const reactionMap = {};

        for (const reaction of reactions) {
            reactionMap[ reaction.emojiID ] = {
                id:     reaction.emojiID,
                users:  reaction.users,
                native: reaction.native,
            };
        }

        return reactionMap;
    }, [ reactions ]);
}

// #endregion

// #region Helper Functions

// #endregion
