import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import { SettingsOutlined } from '@mui/icons-material';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import localStyles from './bidding-room-layout.module.scss';

/**
 * Page layout for any bidding room pages.
 */
function BiddingRoomLayout({
    title,
    children,
    actionsChildren,
    hasAdminPermissions,
}) {
    // #region Hooks

    /**
     * Use the "Settings Open" context API.
     */
    const settingsOpenContextAPI = BiddingRoomContextLevel.use.settingsOpen.api();

    // #endregion

    // #region Functions

    /**
     * Handler for when the settings button is clicked.
     */
    const onSettingsButtonClicked = () => {
        settingsOpenContextAPI.set(true);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.biddingRoomLayout}>
            <header>
                <h5>
                    {title}
                </h5>
                {
                    hasAdminPermissions
                        ? (
                            <StickyTooltip content='Open Bidding Room Settings'>
                                <IconButton onClick={onSettingsButtonClicked}>
                                    <SettingsOutlined/>
                                </IconButton>
                            </StickyTooltip>
                        )
                        : null
                }
            </header>
            <section className={localStyles.content}>
                {children}
            </section>
            <section className={localStyles.actions}>
                {actionsChildren}
            </section>
        </div>
    );

    // #endregion
}

BiddingRoomLayout.propTypes = {
    /**
     * Any React fragments to render within the actions section of the page.
     */
    actionsChildren: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    /**
     * Any React fragments to render within the main content of the page.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    /**
     * Flag indicating whether the user has admin permissions for the team.
     */
    hasAdminPermissions: PropTypes.bool,
    /**
     * The title to use for the header of the page.
     */
    title:               PropTypes.string.isRequired,
};

BiddingRoomLayout.defaultProps = {
    hasAdminPermissions: false,
};

export default BiddingRoomLayout;
