import {
    ref,
    deleteObject,
} from 'firebase/storage';
import { storage } from '@services/firebase';

/**
 * Deletes the group's avatar from Firebase storage. Does not update the group's avatar URL, as it
 * is assumed that the group is being deleted.
 */
export default async function deleteGroupAvatar(teamID, groupID) {
    const storageRef = ref(storage, `group-avatars/${teamID}/${groupID}`);
    await deleteObject(storageRef);
}
