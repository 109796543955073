import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import differenceBy from 'lodash/differenceBy';
import {
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    useTrackLiveDataByPathInContext,
    useTrackLiveSubmodelDataByChangingQueryInContext,
    useTrackLiveSubmodelDataByQueryInContext,
} from '@hooks/context';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import TeamContextLevel from '@contexts/team';
import CheckInServiceInfoModel from '@models/check-in-service-info';
import CheckInSurveyBatchModel from '@models/check-in-survey-batch';
import CheckInSurveyModel from '@models/check-in-survey';
import ActivityLogModel from '@models/activity-log';
import CheckInReviewerAssociationModel from '@models/check-in-reviewer-association';
import { LoadingPage } from '@components/loading';

// #region External Helper Functions

function getRevieweeActivityLogListenerName(revieweeID) {
    return `reviewee-activity-log-listener-${ revieweeID }`;
}

// #endregion

function CheckInWrapper() {

    // #region Refs

    const surveyActivityListenersRef = useRef({});

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const team = TeamContextLevel.use.currentTeam.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    const myReviewAssociations = TeamCheckInContextLevel.use.myReviewAssociations.value();

    const myReviewAssociationsAPI = TeamCheckInContextLevel.use.myReviewAssociations.api();

    const currentSurveyBatch = TeamCheckInContextLevel.use.currentSurveyBatch.value();

    const currentSurveyBatchAPI = TeamCheckInContextLevel.use.currentSurveyBatch.api();

    const [
        // eslint-disable-next-line no-unused-vars
        checkInServiceInfo,
        checkInServiceInfoFetched,
    ] = useTrackLiveDataByPathInContext(
        'TEAM_CHECK_IN_SERVICE_INFO_TRACKER',
        TeamCheckInContextLevel.use.checkInServiceInfo,
        CheckInServiceInfoModel,
        `teams/${ team.id }/checkInServiceInfo/default`
    );

    const [
        // eslint-disable-next-line no-unused-vars
        myCurrentCheckInSurvey,
        myCurrentCheckInSurveyFetched,
        // eslint-disable-next-line no-unused-vars
        myCurrentCheckInSurveyUpToDate,
    ] = useTrackLiveSubmodelDataByChangingQueryInContext(
        'TEAM_CHECK_IN_MY_CURRENT_CHECK_IN_TRACKER',
        TeamCheckInContextLevel.use.myCurrentCheckIn,
        CheckInSurveyModel,
        `teams/${ team.id }/checkInSurveys`,
        [
            where('surveyBatchID', '==', checkInServiceInfo?.latestBatchID),
            where('userID', '==', profile?.id),
        ],
        [ checkInServiceInfo ],
        () => {
            return !checkInServiceInfo?.latestBatchID;
        },
        (data) => {
            return data?.length > 0 ? data[ 0 ] : null;
        }
    );

    const [
        // eslint-disable-next-line no-unused-vars
        teamReviewerAssociations,
        teamReviewerAssociationsFetched,
    ] = useTrackLiveSubmodelDataByQueryInContext(
        'TEAM_CHECK_IN_REVIEWER_ASSOCIATIONS_TRACKER',
        TeamCheckInContextLevel.use.checkInReviewerAssociations,
        CheckInReviewerAssociationModel,
        `teams/${ team.id }/checkInReviewerAssociations`,
        []
    );

    const currentSurveysByTeamMemberID = TeamCheckInContextLevel.use.currentSurveysByTeamMemberID.value();

    const currentSurveysByTeamMemberIDAPI = TeamCheckInContextLevel.use.currentSurveysByTeamMemberID.api();

    const latestActivityLogBySurveyID = TeamCheckInContextLevel.use.latestActivityLogBySurveyID.value();

    const latestActivityLogBySurveyIDAPI = TeamCheckInContextLevel.use.latestActivityLogBySurveyID.api();

    // #endregion

    // #region Effects

    /**
     * Whenever the team's reviewer associations update, keep the current user's reviewer associations up to date.
     */
    useEffect(() => {
        if (!teamReviewerAssociations || !profile) {
            return;
        }

        myReviewAssociationsAPI.set(
            filter(
                teamReviewerAssociations,
                (association) => association.reviewerID === profile.id
            )
        );
    }, [
        teamReviewerAssociations,
        profile,
    ]);

    /**
     * Whenever the check-in service info changes, create a listener to track all of the team's current surveys.
     */
    useEffect(() => {
        if (checkInServiceInfo?.latestBatchID) {
            CheckInSurveyModel.addListenerByQueryInInstance(
                'all-team-members-current-surveys-tracker',
                `teams/${ team.id }/checkInSurveys`,
                [ where('surveyBatchID', '==', checkInServiceInfo.latestBatchID) ],
                (newCurrentSurveys) => {
                    currentSurveysByTeamMemberIDAPI.set(
                        reduce(
                            newCurrentSurveys,
                            (acc, survey) => {
                                acc[ survey.userID ] = survey;

                                return acc;
                            },
                            {}
                        )
                    );
                }
            );

            return () => CheckInSurveyModel.removeListener('all-team-members-current-surveys-tracker');
        } else if (
            (checkInServiceInfo && !checkInServiceInfo.latestBatchID)
            || !checkInServiceInfo
        ) {
            currentSurveysByTeamMemberIDAPI.set({});
        }
    }, [ checkInServiceInfo ]);

    /**
     * When the check-in service info changes, create a listener to track the latest survey batch.
     */
    useEffect(() => {
        // If the check-in service info is not loaded yet, do nothing
        if (!checkInServiceInfoFetched) {
            return;
        }

        const latestBatchID = checkInServiceInfo?.latestBatchID;

        // If the latest batch ID is not set, set the current survey batch to null
        if (!latestBatchID) {
            currentSurveyBatchAPI.set(null);
        }

        // Create the listener
        CheckInSurveyBatchModel.addListenerByPath(
            `latest-survey-batch-tracker-${ latestBatchID }`,
            `teams/${ team.id }/checkInSurveyBatches/${ latestBatchID }`,
            (newBatch) => {
                currentSurveyBatchAPI.set(newBatch);
            }
        );

        // Remove the listener when the component unmounts
        return () => CheckInSurveyBatchModel.removeListener(`latest-survey-batch-tracker-${ latestBatchID }`);
    }, [
        checkInServiceInfo,
        checkInServiceInfoFetched,
    ]);

    /**
     * Whenever the current surveys change, create listeners to track
     * the activity logs and user viewed timestamps for each assigned team member.
     */
    useEffect(() => {
        // If the current surveys have not been fetched yet, do nothing.
        if (currentSurveysByTeamMemberID === undefined) {
            return;
        }

        // For each team member, create new listeners, if needed
        for (const teamMember of Object.values(teamMembers)) {
            const currentListeners = surveyActivityListenersRef.current[ teamMember.id ];
            const membersCurrentSurvey = currentSurveysByTeamMemberID[ teamMember.id ];

            if (currentListeners?.surveyID === membersCurrentSurvey?.id) {
                continue;
            }

            // Remove the old listeners, if they exist
            if (currentListeners) {
                ActivityLogModel.removeListener(getRevieweeActivityLogListenerName(teamMember.id));
                surveyActivityListenersRef.current[ teamMember.id ] = null;
            }

            if (membersCurrentSurvey?.id) {
                // Create the new listeners
                ActivityLogModel.addListenerByQueryInInstance(
                    getRevieweeActivityLogListenerName(teamMember.id),
                    `teams/${ team.id }/checkInSurveys/${ membersCurrentSurvey?.id }/activityLogs`,
                    [
                        orderBy('timestamp', 'desc'),
                        limit(1),
                    ],
                    (latestActivityLog) => {
                        latestActivityLogBySurveyIDAPI.set((prev) => ({
                            ...prev,
                            [ membersCurrentSurvey.id ]: latestActivityLog[ 0 ],
                        }));
                    }
                );

                // Update the listeners ref to indicate that this team member has listeners instantiated
                // for their current survey
                surveyActivityListenersRef.current[ teamMember.id ] = {
                    surveyID: membersCurrentSurvey?.id,
                };
            }
        }

        // Remove any listeners that are no longer needed
        const teamMembersNoLongerExisting = differenceBy(
            Object.keys(surveyActivityListenersRef.current),
            Object.keys(teamMembers)
        );

        for (const teamMemberID of teamMembersNoLongerExisting) {
            ActivityLogModel.removeListener(getRevieweeActivityLogListenerName(teamMemberID));

            delete surveyActivityListenersRef.current[ teamMemberID ];
        }

        // If the latestActivityLogBySurveyID structure has not been produced yet, set it to an empty object
        if (latestActivityLogBySurveyID === undefined) {
            latestActivityLogBySurveyIDAPI.set({});
        }
    }, [
        teamMembers,
        currentSurveysByTeamMemberID,
    ]);

    /**
     * When the component unmounts, remove all of the listeners that were created for the survey activity.
     */
    useEffect(() => {
        return () => {
            for (const teamMemberID of Object.keys(surveyActivityListenersRef.current)) {
                ActivityLogModel.removeListener(getRevieweeActivityLogListenerName(teamMemberID));
            }
        };
    }, []);

    // #endregion

    // #region Render Functions

    if (
        !checkInServiceInfoFetched
        || !myCurrentCheckInSurveyFetched
        || !teamReviewerAssociationsFetched
        || !currentSurveysByTeamMemberID
        || !latestActivityLogBySurveyID
        || !myReviewAssociations
        || currentSurveyBatch === undefined
    ) {
        return <LoadingPage/>;
    }

    return <Outlet/>;

    // #endregion
}

/**
 * Wrapper to provide the team check-in context level Provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ProviderWrapper() {
    return (
        <TeamCheckInContextLevel.Provider>
            <CheckInWrapper/>
        </TeamCheckInContextLevel.Provider>
    );
}

export default ProviderWrapper;
