import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const CheckInSurveyModel = new Submodel({
    collectionName:  'checkInSurveys',
    parent:          TeamModel,
    collectionProps: [
        'userID',
        'startDate',
        'endDate',
        'dateSubmitted',
        'surveyBatchID',
        'heartbeatValue',
        'heartbeatAdditionalComment',
        'teamID',
        'reminderSent',
    ],
    propDefaults: {},
});

export default CheckInSurveyModel;
