/* eslint-disable no-magic-numbers */
/* eslint-disable no-bitwise */
/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Popover,
    IconButton,
} from '@mui/material';
import {
    StickyTooltip,
    usePseudoSelectors,
} from '@psionic/ui';
import {
    Face,
    Email,
    Add,
    Check,
    PersonOff,
} from '@mui/icons-material';
import Crown from '@assets/images/crown.svg';
import localStyles from './user-avatar.module.scss';

/**
 * A user avatar with the user's profile picture.
 */
function UserAvatar({
    userName,
    userEmail,
    imageURL,
    size,
    status,
    hasTooltip,
    hasPopover,
}) {
    // #region Hooks

    const [
        badgePsuedoSelectorProps,
        badgePseudoSelectorStates,
    ] = usePseudoSelectors();

    /**
     * Track whether the popover is open in state.
     */
    const [
        popoverOpen,
        setPopoverOpen,
    ] = useState(false);

    /**
     * Track a ref of the popover's anchor el.
     */
    const popoverAnchorElRef = useRef();

    /**
     * Memoized color based off of the user's name.
     */
    const avatarColor = useMemo(() => {
        if (!userName) {
            return null;
        }

        const trimmedUserName = userName.trim();

        let hash = 0;
        // eslint-disable-next-line id-length
        let i;

        for (i = 0; i < trimmedUserName.length; i += 1) {
            hash = trimmedUserName.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${ value.toString(16) }`.slice(-2);
        }

        return color;
    }, [ userName ]);

    /**
     * Memoized props to pass to the Avatar.
     */
    const avatarProps = useMemo(() => {
        const noInitialsProps = {
            sx: {
                bgcolor: '#F3F6F9',
            },
            children: <Face className={localStyles.noProfileInfoIcon}/>,
        };

        const trimmedUserName = userName ? userName.trim() : null;

        if (!trimmedUserName) {
            return noInitialsProps;
        }

        const splitUserName = trimmedUserName.split(' ');
        const firstInitial = splitUserName[ 0 ][ 0 ].toUpperCase();
        let secondInitial = splitUserName.length > 1 ? splitUserName[ 1 ][ 0 ] : '';
        secondInitial = secondInitial === undefined ? '' : secondInitial.toUpperCase();

        return {
            sx: {
                bgcolor: avatarColor,
            },
            children: `${ firstInitial }${ secondInitial }`,
        };
    }, [
        userName,
        avatarColor,
    ]);

    // #endregion

    // #region Functions

    /**
     * Handler for when the avatar is clicked.
     */
    const handleOnClick = (event) => {
        event.stopPropagation();

        if (hasPopover) {
            setPopoverOpen((prevState) => { return !prevState; });
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Render the given status for the user.
     */
    const statusBadge = () => {
        if (!status) {
            return null;
        }

        // eslint-disable-next-line react/prop-types, react/no-multi-comp
        function BadgeWrapper({ title, children }) {
            return (
                <StickyTooltip content={title}>
                    <div
                        className={localStyles.badge}
                        data-status={status}
                        {...badgePsuedoSelectorProps}
                    >
                        {children}
                    </div>
                </StickyTooltip>
            );
        }

        switch (status) {
            case 'joined':
                return (
                    <BadgeWrapper title='Joined'>
                        <Check/>
                    </BadgeWrapper>
                );
            case 'invited':
                return (
                    <BadgeWrapper title='Invited'>
                        <Email/>
                    </BadgeWrapper>
                );
            case 'pendingInvite':
                return (
                    <BadgeWrapper title='Will be invited upon saving changes'>
                        <Add/>
                    </BadgeWrapper>
                );
            case 'active':
                return <BadgeWrapper title='Active'/>;
            case 'voted':
                return (
                    <BadgeWrapper title='Voted'>
                        <Check/>
                    </BadgeWrapper>
                );
            case 'owner':
                return (
                    <BadgeWrapper title='Team Owner'>
                        <Crown/>
                    </BadgeWrapper>
                );
            case 'afk':
                return (
                    <BadgeWrapper title='Away From Keyboard'>
                        <PersonOff/>
                    </BadgeWrapper>
                );
            default:
                return null;
        }
    };

    /**
     * Popover for when the user avatar is clicked.
     */
    const popover = () => { return (
        <Popover
            anchorEl={popoverAnchorElRef.current}
            open={popoverOpen}
            anchorOrigin={{
                vertical:   'center',
                horizontal: 'center',
            }}
            PaperProps={{
                className: localStyles.popoverPaper,
            }}
            transformOrigin={{
                vertical:   'center',
                horizontal: 'center',
            }}
            onClose={handleOnClick}
        >
            <div className={localStyles.popoverCard}>
                {
                    imageURL
                        ? (
                            <div className={localStyles.imgContainer}>
                                <img
                                    alt={`${ userName || userEmail } avatar`}
                                    src={imageURL}
                                />
                            </div>
                        )
                        : (
                            <div className={localStyles.defaultImage}>
                                <Face/>
                            </div>
                        )
                }
                <div className={localStyles.userInfo}>
                    <h6>
                        {userName || userEmail}
                    </h6>
                </div>
            </div>
        </Popover>
    ); };

    /**
     * Render the avatar itself.
     */
    const renderAvatar = () => { return (
        <>
            <Avatar
                className={localStyles.userAvatar}
                data-size={size}
                src={imageURL}
                {...avatarProps}
            />
            {statusBadge()}
        </>
    ); };

    /**
     * Wrap the given children in a StickyTooltip if the `hasTooltip` prop is true.
     */
    const wrapInTooltipIfApplicable = (children) => {
        if (hasTooltip && !badgePseudoSelectorStates?.isHovered) {
            return (
                <StickyTooltip content={userName || userEmail}>
                    {children}
                </StickyTooltip>
            );
        }

        return children;
    };

    /**
     * Wrap the given children in an IconButton if the `hasPopover` prop is true.
     */
    const wrapInButtonIfApplicable = (children) => {
        if (hasPopover) {
            return (
                <IconButton
                    ref={popoverAnchorElRef}
                    className={localStyles.userAvatarWrapper}
                    onClick={handleOnClick}
                >
                    {children}
                </IconButton>
            );
        }

        return children;
    };

    /**
     * If no user name or email is provided, render nothing.
     */
    if (!userName && !userEmail) {
        return null;
    }

    /**
     * Main render.
     */
    return (
        <>
            {hasPopover ? popover() : null}
            {
                wrapInTooltipIfApplicable(
                    wrapInButtonIfApplicable(
                        renderAvatar()
                    )
                )
            }
        </>
    );

    // #endregion
}

UserAvatar.propTypes = {
    /**
     * Flag indicating whether the avatar should have a popover.
     */
    hasPopover: PropTypes.bool,
    /**
     * Flag indicating whether the avatar should have a tooltip.
     */
    hasTooltip: PropTypes.bool,
    /**
     * The user's profile picture URL.
     */
    imageURL:   PropTypes.string,
    /**
     * The size of the avatar.
     */
    size:       PropTypes.oneOf([
        'extra-extra-small',
        'extra-small',
        'small',
        'medium',
        'large',
    ]),
    /**
     * Optionally specify a status to represent on the user avatar with a badge.
     */
    status: PropTypes.oneOf([
        'joined',
        'invited',
        'pendingInvite',
        'active',
        'voted',
        'owner',
        'afk',
    ]),
    /**
     * The user's email.
     */
    userEmail: PropTypes.string,
    /**
     * The user's name.
     */
    userName:  PropTypes.string,
};

UserAvatar.defaultProps = {
    hasPopover: true,
    hasTooltip: true,
    imageURL:   undefined,
    size:       'small',
    status:     undefined,
    userEmail:  undefined,
    userName:   undefined,
};

export default UserAvatar;
