import { Submodel } from '@unifire-js/firebase/firestore';
import CheckInSurveyModel from '@models/check-in-survey';

const ActivityLogModel = new Submodel({
    collectionName:  'activityLogs',
    parent:          CheckInSurveyModel,
    collectionProps: [
        'type',
        'userID',
        'timestamp',
        'acknowledgedBy',
    ],
    propDefaults: {},
});

export default ActivityLogModel;
