import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    IconButton,
    ControlledSwitch,
    CircularSpinner,
} from '@psionic/ui';
import {
    ArrowBack,
    Warning,
} from '@mui/icons-material';
import {
    useTrackLiveSubmodelDataByQueryInContext,
    useTrackLiveDataByPathInContext,
} from '@hooks/context';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import CheckInQuestionModel from '@models/check-in-question';
import CheckInSurveyConfigurationModel from '@models/check-in-survey-configuration';
import {
    disableCheckInService,
    enableCheckInService,
} from '@services/check-in';
import { LoadingPage } from '@components/loading';
import GlobalSettingsTab from './components/global-settings-tab/global-settings';
import QuestionsTab from './components/questions-tab/questions-tab';
import ReviewerAssignmentTab from './components/reviewer-assignment-tab/reviewer-assignment';
import DashboardTab from './components/dashboard/dashboard';
import localStyles from './manage-check-in-service.module.scss';

function ManageCheckInService() {

    // #region Constants

    const TABS = {
        QUESTIONS:           'QUESTIONS',
        GLOBAL_SETTINGS:     'GLOBAL_SETTINGS',
        REVIEWER_ASSIGNMENT: 'REVIEWER_ASSIGNMENT',
        DASHBOARD:           'DASHBOARD',
    };

    // #endregion

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const currentTeam = TeamContextLevel.use.currentTeam.value();

    const checkInServiceInfo = TeamCheckInContextLevel.use.checkInServiceInfo.value();

    const checkInReviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    const [
        // eslint-disable-next-line no-unused-vars
        checkInQuestions,
        initialCheckInQuestionsFetched,
    ] = useTrackLiveSubmodelDataByQueryInContext(
        'TEAM_CHECK_IN_QUESTIONS_TRACKER',
        TeamCheckInContextLevel.use.checkInQuestions,
        CheckInQuestionModel,
        `teams/${ currentTeam?.id }/checkInQuestions`,
        []
    );

    const [
        // eslint-disable-next-line no-unused-vars
        checkInSurveyConfiguration,
        initialCheckInSurveyConfigurationFetched,
    ] = useTrackLiveDataByPathInContext(
        'TEAM_CHECK_IN_SURVEY_CONFIGURATION_TRACKER',
        TeamCheckInContextLevel.use.checkInSurveyConfiguration,
        CheckInSurveyConfigurationModel,
        `teams/${ currentTeam?.id }/checkInSurveyConfigurations/default`
    );

    // #endregion

    // #region State

    const [
        selectedTab,
        setSelectedTab,
    ] = useState(TABS.DASHBOARD);

    const [
        checkInServiceEnabled,
        setCheckInServiceEnabled,
    ] = useState(Boolean(checkInServiceInfo?.enabled));

    const [
        updatingCheckInServiceEnabled,
        setUpdatingCheckInServiceEnabled,
    ] = useState(false);

    // #endregion

    // #region Effects

    /**
     * Keep the `checkInServiceEnabled` state in sync with the database.
     */
    useEffect(() => {
        setCheckInServiceEnabled(Boolean(checkInServiceInfo?.enabled));
    }, [ checkInServiceInfo ]);

    // #endregion

    // #region Memoized Values

    const checkInSwitchDisabled = useMemo(() => {
        return (
            checkInReviewerAssociations?.length <= 0
            || checkInQuestions?.length <= 0
        )
            && !checkInServiceEnabled;
    }, [
        checkInReviewerAssociations,
        checkInServiceEnabled,
        checkInQuestions,
    ]);

    const disabledSwitchMessage = useMemo(() => {
        if (checkInQuestions?.length <= 0) {
            return 'Set up some questions first!';
        }

        if (checkInReviewerAssociations?.length <= 0) {
            return 'Set up some reviewer assignments first!';
        }

        return '';
    }, [
        checkInReviewerAssociations,
        checkInServiceEnabled,
        checkInQuestions,
    ]);

    // #endregion

    // #region Functions

    const updateCheckInServiceEnabled = async(event) => {
        if (updatingCheckInServiceEnabled) {
            return;
        }

        const newCheckInServiceEnabled = event.target.checked;

        setCheckInServiceEnabled(newCheckInServiceEnabled);
        setUpdatingCheckInServiceEnabled(true);

        if (newCheckInServiceEnabled) {
            await enableCheckInService(currentTeam?.id);
        } else {
            await disableCheckInService(currentTeam?.id);
        }

        setUpdatingCheckInServiceEnabled(false);
    };

    // #endregion

    // #region Render Functions

    const renderSelectedTabContent = () => {
        switch (selectedTab) {
            case TABS.QUESTIONS:
                return <QuestionsTab/>;
            case TABS.GLOBAL_SETTINGS:
                return <GlobalSettingsTab/>;
            case TABS.REVIEWER_ASSIGNMENT:
                return <ReviewerAssignmentTab/>;
            case TABS.DASHBOARD:
                return <DashboardTab/>;
            default:
                return null;
        }
    };

    if (
        !initialCheckInQuestionsFetched
        || !initialCheckInSurveyConfigurationFetched
    ) {
        return <LoadingPage/>;
    }

    return (
        <div className='workspacePage'>
            <header className={localStyles.header}>
                <IconButton
                    color='white'
                    SvgIcon={ArrowBack}
                    onClick={() => navigate('../')}
                />
                <h1>
                    Manage Check-In Service
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                <div className={localStyles.enableSwitch}>
                    <ControlledSwitch
                        checked={checkInServiceEnabled}
                        color='primary'
                        disabled={checkInSwitchDisabled}
                        height={30}
                        id='checkInServiceEnabledSwitch'
                        label={checkInServiceEnabled ? 'Check-in Service Enabled' : 'Check-in Service Disabled'}
                        width={50}
                        darkMode
                        onChange={updateCheckInServiceEnabled}
                    />
                    {
                        updatingCheckInServiceEnabled
                            ? (
                                <div className={localStyles.saving}>
                                    <CircularSpinner size={16}/>
                                    <p>Saving...</p>
                                </div>
                            )
                            : null
                    }
                    {
                        checkInSwitchDisabled
                            ? (
                                <div className={localStyles.disabledWarning}>
                                    <Warning/>
                                    {disabledSwitchMessage}
                                </div>
                            )
                            : null
                    }
                </div>
                <div className={localStyles.mainContent}>
                    <div className='tabs'>
                        <button
                            className='tab'
                            data-selected={selectedTab === TABS.DASHBOARD}
                            type='button'
                            onClick={() => setSelectedTab(TABS.DASHBOARD)}
                        >
                            <p>
                                Dashboard
                            </p>
                        </button>
                        <button
                            className='tab'
                            data-selected={selectedTab === TABS.QUESTIONS}
                            type='button'
                            onClick={() => setSelectedTab(TABS.QUESTIONS)}
                        >
                            <p>
                                Questions
                            </p>
                        </button>
                        <button
                            className='tab'
                            data-selected={selectedTab === TABS.GLOBAL_SETTINGS}
                            type='button'
                            onClick={() => setSelectedTab(TABS.GLOBAL_SETTINGS)}
                        >
                            <p>
                                Global Settings
                            </p>
                        </button>
                        <button
                            className='tab'
                            data-selected={selectedTab === TABS.REVIEWER_ASSIGNMENT}
                            type='button'
                            onClick={() => setSelectedTab(TABS.REVIEWER_ASSIGNMENT)}
                        >
                            <p>
                                Reviewer Assignment
                            </p>
                        </button>
                    </div>
                    <div className={localStyles.tabContent}>
                        {renderSelectedTabContent()}
                    </div>
                </div>
            </section>
        </div>
    );

    // #endregion
}

export default ManageCheckInService;
