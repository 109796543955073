import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
    Popover,
} from '@mui/material';
import {
    Button,
} from '@psionic/ui';
import {
    TeamAvatar,
} from '@components/avatars';
import localStyles from './workspace-select-menu.module.scss';

/**
 * Menu for selecting a workspace (team).
 */
function WorkspaceSelectMenu({
    teams,
    onTeamSelected,
    anchorEl,
    setAnchorEl,
}) {
    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    const renderEmptyState = () => {
        return (
            <p className={localStyles.emptyStateText}>
                You aren't a part of any teams yet. Create a new team or ask your team admin to invite you.
            </p>
        );
    };

    const renderWorkspaceList = () => {
        const workspaces = [];

        for (const team of teams) {
            workspaces.push(
                <li key={team.id}>
                    <button
                        type='button'
                        onClick={() => {
                            return onTeamSelected(team.id);
                        }}
                    >
                        <TeamAvatar
                            imageURL={team.avatarURL}
                            size='extra-extra-small'
                            teamName={team.name}
                        />
                        <p>
                            {team.name}
                        </p>
                    </button>
                </li>
            );
        }

        return (
            <ul>
                {workspaces}
            </ul>
        );
    };

    /**
     * Main render.
     */
    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical:   'bottom',
                horizontal: 'center',
            }}
            PaperProps={{
                className: localStyles.workspaceSelectMenu,
            }}
            transformOrigin={{
                vertical:   'top',
                horizontal: 'center',
            }}
            disableAutoFocus
            disableEnforceFocus
            onClose={() => {
                return setAnchorEl(null);
            }}
        >
            <h1>
                Your Teams
            </h1>
            {
                teams?.length > 0
                    ? renderWorkspaceList()
                    : renderEmptyState()
            }
            <Button
                className={localStyles.createTeamButton}
                variant='contained'
                width='100%'
                darkMode
                onClick={() => {
                    return navigate('/user/create-team');
                }}
            >
                Create a New Team
            </Button>
        </Popover>
    );

    // #endregion
}

WorkspaceSelectMenu.propTypes = {
    /**
     * The anchor element for the popover. If this is set, the popover will be open, and vice versa.
     */
    anchorEl:       PropTypes.object,
    /**
     * The function to call when a team is selected. Should take in the ID of the selected
     * team as the only argument.
     */
    onTeamSelected: PropTypes.func.isRequired,
    /**
     * The function to call to set the anchor element for the popover.
     */
    setAnchorEl:    PropTypes.func.isRequired,
    /**
     * The teams that can be selected from this menu.
     */
    teams:          PropTypes.arrayOf(PropTypes.object).isRequired,
};

WorkspaceSelectMenu.defaultProps = {
    anchorEl: null,
};

export default WorkspaceSelectMenu;
