import { useNavigate } from 'react-router-dom';
import { Button } from '@psionic/ui';
import {
    Footer,
} from '@components/core';
import localStyles from './page-not-found.module.scss';

/**
 * Page that serves as the 404 error page for the site.
 */
function PageNotFound() {
    // #region Hooks

    /**
     * Use react-router-dom's `navigate` function.
     */
    const navigate = useNavigate();

    // #endregion

    // #region Functions

    /**
     * Function to take the user back to the home page.
     */
    const navigateHome = () => {
        navigate('/home');
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <main>
            <div
                className={[
                    'nonWorkspacePage',
                    localStyles.pageNotFound,
                ].join(' ')}
            >
                <header>
                    <h1>
                        Oops!
                    </h1>
                </header>
                <section className='pageContent'>
                    <p>
                        Sorry, this page is off on vacation, or never existed to begin with!
                        <br/>
                        <br/>
                        We'd love to take you back to the home page, for now. What do you say?
                    </p>
                </section>
                <Button
                    variant='contained'
                    width={300}
                    onClick={navigateHome}
                >
                    Go Back Home
                </Button>
            </div>
        </main>
    );

    // #endregion
}

PageNotFound.propTypes = {

};

PageNotFound.defaultProps = {

};

export default PageNotFound;
