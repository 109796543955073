import { httpsCallable } from 'firebase/functions';
import { functions } from '@services/firebase';

/**
 * Useful unwrapping function factory.
 */
const httpsCallableFactory = (fnName) => {
    return async(argObj) => {
        const result = await httpsCallable(functions, fnName)(argObj);

        return result?.data || null;
    };
};

/**
 * Default export of all unwrapped callable https functions.
 */
export default {
    createStripeCustomer:              httpsCallableFactory('createStripeCustomer'),
    createCheckoutSession:             httpsCallableFactory('createCheckoutSession'),
    getAllAvailableSubscriptionPrices: httpsCallableFactory('getAllAvailableSubscriptionPrices'),
    cancelSubscription:                httpsCallableFactory('cancelSubscription'),
    undoSubscriptionCancellation:      httpsCallableFactory('undoSubscriptionCancellation'),
    deleteCustomerSubscriptions:       httpsCallableFactory('deleteCustomerSubscriptions'),
    generateThoughtsOnSurvey:          httpsCallableFactory('generateThoughtsOnSurvey'),
    disbandTeam:                       httpsCallableFactory('disbandTeam'),
};
