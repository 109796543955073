import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const CheckInSurveyBatchModel = new Submodel({
    collectionName:  'checkInSurveyBatches',
    parent:          TeamModel,
    collectionProps: [
        'startDate',
        'endDate',
    ],
});

export default CheckInSurveyBatchModel;
