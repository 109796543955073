import { useEffect, useRef } from 'react';

// #region Hook

/**
 * Sets up an interval which calls the specified callback.
 *
 * @param {function} callback The callback to call on the interval
 * @param {number} delay The delay between calls to the callback
 */
export default function(callback, delay) {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes
    useEffect(() => {
        savedCallback.current = callback;
    }, [ callback ]);

    // Set up the interval
    useEffect(() => {
        // Don't schedule if no delay is specified
        if (!delay && delay !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        return () => clearInterval(id);
    }, [ delay ]);
}

// #endregion

// #region Helper Functions

// #endregion
