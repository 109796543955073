import { Submodel } from '@unifire-js/firebase/firestore';
import CheckInSurveyModel from '@models/check-in-survey';

const CheckInSurveyQuestionModel = new Submodel({
    collectionName:  'checkInSurveyQuestions',
    parent:          CheckInSurveyModel,
    collectionProps: [
        'question',
        'isOptional',
        'isAlwaysAsked',
        'surveyID',
        'teamID',
        'groupID',
    ],
    defaultProps: {
        isOptional: false,
    },
});

export default CheckInSurveyQuestionModel;
