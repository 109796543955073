import PropTypes from 'prop-types';
import { Popper } from '@mui/material';
import { Lightbulb, Close } from '@mui/icons-material';
import { IconButton } from '@psionic/ui';
import localStyles from './tutorial-prompt.module.scss';

/**
 * A tutorial prompt popover element that displays a title and content.
 */
function TutorialPrompt({
    anchorEl,
    isOpen,
    onClose,
    title,
    content,
}) {

    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Popper
            anchorEl={anchorEl}
            className={localStyles.tutorialPrompt}
            open={isOpen}
        >
            <div className={localStyles.header}>
                <div className={localStyles.left}>
                    <Lightbulb/>
                    <h2>
                        {title}
                    </h2>
                </div>
                <div className={localStyles.right}>
                    <IconButton
                        color='white'
                        size={24}
                        SvgIcon={Close}
                        onClick={onClose}
                    />
                </div>
            </div>
            <div className={localStyles.content}>
                {content}
            </div>
        </Popper>
    );

    // #endregion
}

TutorialPrompt.propTypes = {
    /**
     * The anchoring element for the tutorial prompt.
     */
    anchorEl: PropTypes.any.isRequired,
    /**
     * Whether the tutorial prompt is open.
     */
    isOpen:   PropTypes.bool,
    /**
     * The function to call to close the tutorial prompt.
     */
    onClose:  PropTypes.func.isRequired,
    /**
     * The title of the tutorial prompt.
     */
    title:    PropTypes.string.isRequired,
    /**
     * The content of the tutorial prompt.
     */
    content:  PropTypes.node.isRequired,
};

TutorialPrompt.defaultProps = {
    isOpen: false,
};

export default TutorialPrompt;
