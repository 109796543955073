import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const FeatureConfigModel = new Submodel({
    collectionName:  'featureConfigs',
    parent:          TeamModel,
    collectionProps: [ 'enabled' ],
    propDefaults:    {
        enabled: false,
    },
});

export default FeatureConfigModel;
