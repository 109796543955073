import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useTrackFunction } from '@unifire-js/hooks';
import {
    IconButton,
    StickyTooltip,
} from '@psionic/ui';
import {
    Check,
    Close,
} from '@mui/icons-material';
import {
    TeammateSelectInput,
    FilledTextField,
} from '@components/inputs';
import localStyles from './action-item-input.module.scss';

/**
 * Input for updating an action item.
 */
function ActionItemInput({
    actionItem,
    teammates,
    groups,
    onSave,
    onCancel,
}) {
    // #region State

    /**
     * Store a local copy of the action item to edit.
     */
    const [
        localActionItem,
        setLocalActionItem,
    ] = useState(actionItem);

    // #endregion

    // #region Effects

    // #endregion

    // #region Tracked Functions

    /**
     * Tracked save function.
     */
    const [
        saving,
        save,
    ] = useTrackFunction(() => {
        return onSave(localActionItem);
    });

    /**
     * Tracked cancel function.
     */
    const [
        canceling,
        cancel,
    ] = useTrackFunction(onCancel);

    // #endregion

    // #region Memoized Values

    /**
     * Memoized flag indicating whether a tracked function is running.
     */
    const actionRunning = useMemo(() => {
        return saving || canceling;
    }, [
        saving,
        canceling,
    ]);

    /**
     * Memoized error flag.
     */
    const error = useMemo(
        () => {
            return Object.values(localActionItem?.requires || {}).length < 1
            || !localActionItem.message;
        },
        [ localActionItem ]
    );

    // #endregion

    // #region Functions

    /**
     * Callback for when the action item's `requires` prop changes.
     */
    const onUpdateRequires = (newRequires) => {
        setLocalActionItem((prevState) => {
            return {
                ...prevState,
                requires: map(newRequires, (requirement) => {
                    return { type: requirement.type, value: requirement.value };
                }),
            };
        });
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.actionItemInput}>
            <section className={localStyles.inputs}>
                <TeammateSelectInput
                    groups={groups}
                    includeGroups={false}
                    label='Requires'
                    setValue={onUpdateRequires}
                    teammates={teammates}
                    value={localActionItem.requires}
                    isMulti
                />
                <FilledTextField
                    label='Message'
                    value={localActionItem.message}
                    setValue={(value) => {
                        return setLocalActionItem((prevState) => {
                            return {
                                ...prevState,
                                message: value,
                            };
                        });
                    }}
                />
            </section>
            <section className={localStyles.actions}>
                <StickyTooltip content='Save Changes'>
                    <IconButton
                        className='acceptButton'
                        color='approve'
                        disabled={actionRunning || error}
                        SvgIcon={Check}
                        onClick={save}
                    />
                </StickyTooltip>
                <StickyTooltip content='Cancel Changes'>
                    <IconButton
                        className='declineButton'
                        color='reject'
                        disabled={actionRunning}
                        SvgIcon={Close}
                        onClick={cancel}
                    />
                </StickyTooltip>
            </section>
        </div>
    );

    // #endregion
}

ActionItemInput.propTypes = {
    /**
     * The action item to represent in the input.
     */
    actionItem: PropTypes.object.isRequired,
    /**
     * The array of groups that the action item can reference.
     */
    groups:     PropTypes.arrayOf(PropTypes.object),
    /**
     * Callback for when the "cancel" button is clicked.
     */
    onCancel:   PropTypes.func.isRequired,
    /**
     * Callback for when the "save" button is clicked.
     */
    onSave:     PropTypes.func.isRequired,
    /**
     * The array of teammates that could be associated with the action item.
     */
    teammates:  PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActionItemInput.defaultProps = {
    groups: [],
};

export default ActionItemInput;
