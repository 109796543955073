import { useEffect, useMemo, useState } from 'react';
import {
    map,
    find,
} from 'lodash';
import {
    collectionGroup,
    query,
    where,
    getDocs,
    getDoc,
} from 'firebase/firestore';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    Info,
} from '@mui/icons-material';
import { firestore } from '@services/firebase';
import TeamContextLevel from '@contexts/team';
import ActionItemModel from '@models/action-item';
import { MinuteAccordion } from '@components/accordions';
import localStyles from './to-do.module.scss';

/**
 * View for a user's personal to-do list.
 */
function ToDo() {

    // #region Context

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the team members context value.
     */
    const teamMembers = TeamContextLevel.use.teamMembers.value();

    /**
     * Use the team groups context value.
     */
    const teamGroups = TeamContextLevel.use.teamGroups.value();

    // #endregion

    // #region State

    /**
     * Track the "To Do" info objects in state.
     */
    const [
        toDoInfos,
        setToDoInfos,
    ] = useState(null);

    /**
     * Track an update flip variable to denote when the to do list items should be re-rendered.
     */
    const [
        updateFlipper,
        setUpdateFlipper,
    ] = useState(false);

    // #endregion

    // #region Effects

    /**
     * Get the user's action items, once.
     */
    useEffect(() => {
        const fetchData = async() => {
            const actionItemsCollectionGroup = collectionGroup(firestore, 'actionItems');
            const usersActionItemsQuery = query(
                actionItemsCollectionGroup,
                where('requires', 'array-contains', { type: 'TEAMMATE', value: profile.id }),
                where('complete', '==', false),
                where('teamID', '==', currentTeam.id)
            );
            const querySnapshot = await getDocs(usersActionItemsQuery);
            const actionItemInfos = [];
            await Promise.all(map(querySnapshot.docs, async(doc) => {
                // Create an `actionItemInfo` object to track both the action item and its parent minute
                const actionItemInfo = {};

                // Fetch the action item's parent minute info
                const parentMinute = await getDoc(doc.ref.parent.parent);

                // Put the necessary data into the `actionItemInfo` object
                actionItemInfo.actionItem = {
                    id: doc.id,
                    ...doc.data(),
                };
                actionItemInfo.minute = {
                    id: parentMinute.id,
                    ...parentMinute.data(),
                };

                // Add the object to the `actionItemInfos` array
                actionItemInfos.push(actionItemInfo);
            }));

            // Once the array is built, then we can set the state
            setToDoInfos(actionItemInfos);
        };

        fetchData();
    }, []);

    // #endregion

    // #region Functions

    /**
     * Callback for when an action item's `complete` flag is toggled.
     */
    const onUpdateCompleteActionItemFlag = (minute, complete, actionItemID) => {
        // Update the data in the database
        ActionItemModel.writeToPath(
            `agendas/${ minute.agendaID }/days/${ minute.dateToReview }/minutes/${ minute.id }/actionItems/${ actionItemID }`,
            { complete },
            { mergeWithExistingValues: true }
        );

        // Update the local state
        const relevantToDoInfo = find(toDoInfos, (toDoInfo) => {
            return toDoInfo.actionItem.id === actionItemID;
        });
        relevantToDoInfo.actionItem.complete = complete;

        // Set the flipper variable
        setUpdateFlipper((prevState) => {
            return !prevState;
        });
    };

    // #endregion

    // #region Render Functions

    /**
     * Memoized "To Do" list items.
     */
    const toDoItems = useMemo(() => {
        // If the necessary data has not loaded yet, return a skeleton
        if (!toDoInfos) {
            return null;
        }

        const listItems = [];

        for (const toDoInfo of toDoInfos) {
            listItems.push(
                <li key={toDoInfo.actionItem.id}>
                    <MinuteAccordion
                        actionItems={[ toDoInfo.actionItem ]}
                        currentTeam={currentTeam}
                        groups={teamGroups}
                        highlightOnReviewed={false}
                        minute={toDoInfo.minute}
                        showActionItemEditButton={false}
                        showAddActionItemButton={false}
                        showMinuteEditButton={false}
                        teammates={Object.values(teamMembers || {})}
                        onActionItemCompleteUpdate={(actionItemID, completed) => {
                            return onUpdateCompleteActionItemFlag(toDoInfo.minute, completed, toDoInfo.actionItem.id);
                        }}
                    />
                </li>
            );
        }

        if (listItems.length <= 0) {
            listItems.push(
                <li key='empty-state-text'>
                    <p className={localStyles.emptyStateText}>
                        You have no personal "To Do" items!
                    </p>
                </li>
            );
        }

        return listItems;
    }, [
        toDoInfos,
        updateFlipper,
    ]);

    /**
     * Main render.
     */
    return (
        <div
            className={[
                'workspacePage',
                localStyles.toDoPage,
            ].join(' ')}
        >
            <header>
                <h1>
                    Personal To Do List
                </h1>
                <section className={localStyles.tipCard}>
                    <Info/>
                    <p>
                        "To Do" items come from unresolved agenda action items you are assigned to.
                    </p>
                </section>
            </header>
            <ul>
                {toDoItems}
            </ul>
        </div>
    );

    // #endregion
}

export default ToDo;
