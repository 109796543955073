import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
    ControlledTextField,
    IconButton,
} from '@psionic/ui';
import { Popover } from '@mui/material';
import { EmojiEmotions } from '@mui/icons-material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { HEARTBEAT_COLORS } from '@utils/constants';
import {
    HeartbeatRadioButton,
} from '@components/check-in';
import localStyles from './heartbeat-input.module.scss';

/**
 * An input for the heartbeat.
 */
const HeartbeatInput = forwardRef(({
    selectedValue,
    onChange,
    additionalComment,
    onAdditionalCommentChange,
    showErrors,
}, ref) => {
    // #region Constants

    // #endregion

    // #region State

    const [
        emojiPickerAnchor,
        setEmojiPickerAnchor,
    ] = useState(null);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    const onEmojiSelect = (event) => {
        const emoji = event.native;
        const newValue = (() => {
            const existingContent = { ...additionalComment };

            if (!existingContent || isEmpty(existingContent)) {
                return {
                    type:    'doc',
                    content: [ {
                        type:    'paragraph',
                        content: [ {
                            type: 'text',
                            text: emoji,
                        } ],
                    } ],
                };
            }

            const lastContentEntry = existingContent.content[ existingContent.content.length - 1 ];

            if (!lastContentEntry.content) {
                lastContentEntry.content = [ { type: 'text', text: '' } ];
            }

            lastContentEntry.content[ 0 ].text += emoji;

            return existingContent;
        })();

        onAdditionalCommentChange(newValue);
        ref.current.setContent(newValue);
        setEmojiPickerAnchor(null);
    };

    // #endregion

    // #region Render Functions

    const renderInputs = () => {
        const inputs = [];

        // eslint-disable-next-line no-magic-numbers
        for (let inputIndex = 1; inputIndex <= 5; inputIndex++) {
            inputs.push(
                <li key={`heartbeat-radio-button-${ inputIndex }`}>
                    <HeartbeatRadioButton
                        selected={selectedValue === inputIndex}
                        value={inputIndex}
                        onChange={onChange}
                    />
                </li>
            );
        }

        return inputs;
    };

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.heartbeatInput}
            style={{
                borderColor: selectedValue ? HEARTBEAT_COLORS[ selectedValue ] : 'rgba(255, 255, 255, 0.25)',
            }}
        >
            <h1>
                How have you been feeling at work since your last check-in?
            </h1>
            {
                showErrors && !selectedValue
                    ? (
                        <p className={localStyles.errorMessage}>
                            A selection is required
                        </p>
                    )
                    : null
            }
            <div className={localStyles.inputSection}>
                <p className={localStyles.leftText}>
                    Awful
                </p>
                <ul className={localStyles.radioGroup}>
                    {renderInputs()}
                </ul>
                <p className={localStyles.rightText}>
                    Amazing!
                </p>
            </div>
            <div className={localStyles.additionalCommentSection}>
                <ControlledTextField
                    ref={ref}
                    id='additional-comment'
                    label='Additional Comments'
                    value={additionalComment}
                    darkMode
                    multiline
                    onChange={onAdditionalCommentChange}
                />
                <IconButton
                    color='lowEmphasis'
                    size={30}
                    SvgIcon={EmojiEmotions}
                    onClick={(event) => setEmojiPickerAnchor(event.currentTarget)}
                />
            </div>
            <Popover
                anchorEl={emojiPickerAnchor}
                open={Boolean(emojiPickerAnchor)}
                anchorOrigin={{
                    vertical:   'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    className: localStyles.popover,
                }}
                onClose={() => setEmojiPickerAnchor(null)}
            >
                <div className='emojiPickerWrapper'>
                    <Picker
                        data={data}
                        onEmojiSelect={onEmojiSelect}
                    />
                </div>
            </Popover>
        </div>
    );

    // #endregion
});

HeartbeatInput.propTypes = {
    /**
     * The additional comment.
     */
    additionalComment: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    /**
     * The function to call when the selected value changes.
     */
    onChange:                  PropTypes.func.isRequired,
    /**
     * The selected heartbeat value.
     */
    selectedValue:             PropTypes.number,
    /**
     * The function to call when the additional comment changes.
     */
    onAdditionalCommentChange: PropTypes.func.isRequired,
    /**
     * Flag indicating whether errors should be shown.
     */
    showErrors:                PropTypes.bool,
};

HeartbeatInput.defaultProps = {
    additionalComment: '',
    selectedValue:     null,
    showErrors:        false,
};

export default HeartbeatInput;
