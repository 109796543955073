import { useEffect, useState } from 'react';
import {
    Lightbulb,
    Close,
} from '@mui/icons-material';
import {
    IconButton,
} from '@psionic/ui';
import { useUserContext } from '@unifire-js/firebase/auth';
import ReleaseNotesService from '@services/release-notes/release-notes-service';
import { UpperToast } from '@components/toasts';
import localStyles from './release-notes-module.module.scss';

function ReleaseNotesModule() {
    const [
        releaseNotes,
        setReleaseNotes,
    ] = useState(null);

    const [
        manuallyClosed,
        setManuallyClosed,
    ] = useState(false);

    const { profile } = useUserContext();

    useEffect(() => {
        async function performAction() {
            if (!profile) {
                return;
            }

            if (ReleaseNotesService.hasUserSeenReleaseNotes(profile)) {
                setManuallyClosed(true);

                return;
            }

            setReleaseNotes(await ReleaseNotesService.getReleaseNotes(ReleaseNotesService.getCurrentVersion()));
        }

        performAction();
    }, [ profile ]);

    return (
        <UpperToast isOpen={Boolean(releaseNotes) && !manuallyClosed}>
            <div className={localStyles.toastContent}>
                <Lightbulb/>
                <a href={`/home/release-notes?version=${ ReleaseNotesService.getCurrentVersion() }`}>
                    {releaseNotes?.title || 'Placeholder'}
                </a>
                <IconButton
                    className={localStyles.closeButton}
                    color='red'
                    SvgIcon={Close}
                    onClick={() => {
                        ReleaseNotesService.markVersionAsDismissedByUser(profile);
                        setManuallyClosed(true);
                    }}
                />
            </div>
        </UpperToast>
    );
}

export default ReleaseNotesModule;
