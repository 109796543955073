import { Model } from '@unifire-js/firebase/firestore';

const AILogModel = new Model({
    collectionName:  'aiLogs',
    collectionProps: [
        'associatedData',
        'assistedUserID',
        'content',
        'dateCreated',
    ],
});

export default AILogModel;
