import map from 'lodash/map';
import { RealtimeDatabaseInterface } from '@unifire-js/firebase/realtime-database';
import BiddingRoomConfigModel from '@models/bidding-room-config';

/**
 * Reference enum of the possible bidding room phases.
 */
export const PHASES = {
    LOBBY: 'LOBBY',
    VOTING: 'VOTING',
    RESULTS: 'RESULTS',
};

/**
 * Submits the user's vote for the team's bidding room.
 */
export async function submitVote(profileID, teamID, cardIndex) {
    await Promise.all([
        RealtimeDatabaseInterface.writeToPath(
            `${teamID}/biddingRoom/privateInfo/userVotes/${profileID}`,
            cardIndex,
        ),
        RealtimeDatabaseInterface.writeToPath(
            `${teamID}/biddingRoom/publicInfo/users/${profileID}/voted`,
            true
        ),
    ]);
}

/**
 * Removes a user's vote from the team's bidding room.
 */
export async function removeVote(profileID, teamID) {
    await Promise.all([
        RealtimeDatabaseInterface.writeToPath(
            `${teamID}/biddingRoom/publicInfo/users/${profileID}/voted`,
            false
        ),
        RealtimeDatabaseInterface.deleteAtPath(`${teamID}/biddingRoom/privateInfo/userVotes/${profileID}`),
    ]);
}

/**
 * Sets the user's observer mode flag to the given value (true or false).
 */
export async function setObserverMode(profileID, teamID, value) {
    await Promise.all([
        removeVote(profileID, teamID),
        RealtimeDatabaseInterface.writeToPath(
            `${teamID}/biddingRoom/publicInfo/users/${profileID}/observerMode`,
            value,
        ),
    ]);
}

/**
 * Kicks the given user from the team's bidding room and removes their vote from the room.
 * The user will be able to join back by refreshing their page.
 */
export async function kickUser(profileID, teamID) {
    await Promise.all([
        RealtimeDatabaseInterface.writeToPath(
            `${teamID}/biddingRoom/publicInfo/users/${profileID}/kicked`,
            true
        ),
        removeVote(profileID, teamID),
    ]);
}

/**
 * Starts a new bidding round for the team's bidding room.
 */
export async function startNextBiddingRound(teamID, publicInfoUsers) {
    // Fetch the latest bidding room config deck from Firestore
    const latestBiddingRoomConfig = await BiddingRoomConfigModel.getByID(teamID);
    // Clear all user votes first
    await Promise.all(map(publicInfoUsers, (user) => {
        return RealtimeDatabaseInterface.writeToPath(
            `${teamID}/biddingRoom/publicInfo/users/${user.profile.id}/voted`,
            false,
        );
    }));
    await RealtimeDatabaseInterface.writeToPath(
        `${teamID}/biddingRoom/publicInfo/deck`,
        latestBiddingRoomConfig.deck
    );
    await RealtimeDatabaseInterface.writeToPath(
        `${teamID}/biddingRoom/publicInfo/phase`,
        PHASES.VOTING,
    );
}

/**
 * Get the team's bidding room private info.
 */
export function getPrivateInfo(teamID) {
    return RealtimeDatabaseInterface.getByPath(`${teamID}/biddingRoom/privateInfo`); 
}

/**
 * Get an object with info one user voting status.
 */
export function getUserVotingInfo(users) {
    const info = {
        allUsersVoted: true,
        atLeastOneUserVoted: false,
    };
    for (const user of Object.values(users || {})) {
        if (!user.voted && !user.observerMode && !user.kicked) {
            info.allUsersVoted = false;
        }
        if (!user.observerMode && !user.kicked) {
            info.atLeastOneUserVoted = true;
        }
    }
    return info;
}