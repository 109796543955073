/* eslint-disable no-unused-vars */
import { setUnifireFirebaseApp } from '@unifire-js/firebase';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from '@firebase/firestore';
import { FIREBASE_CONFIG } from '@root/app-settings';

const app = initializeApp(FIREBASE_CONFIG);
setUnifireFirebaseApp(app);

export const auth = getAuth();

export const database = getDatabase();

export const functions = getFunctions();

export const storage = getStorage();

export const firestore = getFirestore();

// ConnectFirestoreEmulator(firestore, 'localhost', 8080);
// connectAuthEmulator(auth, "http://localhost:9099");
// connectDatabaseEmulator(database, "localhost", 9000);
// connectStorageEmulator(storage, "localhost", 9199);
// connectFunctionsEmulator(functions, "localhost", 5001);
