import { useEffect } from 'react';
import { useFetchedState } from '@hooks/async';

/**
 * An alternative to `useMemo` for values that require some asynchronous processing to fetch the value
 * whenever it needs to be memoized.
 */
export default function(fn, dependencies) {
    // Track the value in state
    const [value, valueFetched, setValue] = useFetchedState();
    
    // Use `useEffect` as a way to memoize the async value
    useEffect(() => {
        const internalFn = async () => {
            const result = await fn();
            setValue(result);
        }
        
        internalFn();
    }, dependencies);

    // Return the hook API
    return [value, valueFetched];
}
