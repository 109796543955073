import { useEffect } from 'react';

// #region Hook

/**
 * Tracks the specified live Firestore data in the specified context.
 *
 * @param {string} listenerID The ID to use for the listener; should be unique
 * @param {Object} contextUsageObj The context usage object to use to track the live data;
 * should come from the `SomeContextLevel.use.contextCategory`-style variables
 * @param {Object} submodel The Firestore submodel to track the live data of
 * @param {string} path The path of the document to track the live data of
 * @param {function} [postProcessingFn] Optional function to apply to the live data any time
 * it updates before it is set in the context and returned by this hook
 * @returns {[Object, boolean]} The live data and the initial data fetched flag
 */
export default function(listenerID, contextUsageObj, submodel, path, postProcessingFn) {
    // Use the context API
    const api = contextUsageObj.api();

    // Track the live data in state
    let [
        liveData,
        // eslint-disable-next-line prefer-const
        initialLiveDataFetched,
    ] = submodel.useLiveDataByPath(
        listenerID,
        path
    );

    // Apply the post-processing function, if it exists
    if (postProcessingFn) {
        liveData = postProcessingFn(liveData, initialLiveDataFetched);
    }

    // Whenever the live data changes, update the context
    useEffect(() => {
        api.set(liveData);
    }, [ liveData ]);

    // Return the live data and the initial fetch flag, in case it is needed by the caller
    return [
        liveData,
        initialLiveDataFetched,
    ];
}

// #endregion

// #region Helper Functions

// #endregion
