import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { where } from 'firebase/firestore';
import {
    Snackbar,
} from '@psionic/ui';
import {
    Warning,
} from '@mui/icons-material';
import {
    useUserContext,
    useRedirectOnAuthCondition,
} from '@unifire-js/firebase/auth';
import {
    userAssociations_FluxState,
    userInvites_FluxState,
} from '@data/user';
import AssociationModel from '@models/association';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import {
    LandingPageHeader,
} from '@components/core';
import { LoadingPage } from '@components/loading';

/**
 * Wrapper for any user routes.
 */
function UserWrapper() {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { user, profile } = useUserContext();

    /**
     * Use redirect if the user is not signed in or verified.
     */
    const authChecked = useRedirectOnAuthCondition(
        '/home/login',
        (userContext) => {
            if (!userContext.user) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You must be signed in to access this page.'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }
        }
    );

    // #endregion

    // #region Effects

    useEffect(() => {
        if (!profile) {
            userAssociations_FluxState.set(null);
            userInvites_FluxState.set(null);
        }
    }, [ profile ]);

    // #endregion

    // #region Render Functions

    /**
     * Display a loading state if the authorization has not been checked yet or if the profile is not loaded.
     */
    if (!authChecked || !profile) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <>
            <PopulateAssociationsContext profile={profile}/>
            <LandingPageHeader
                profile={profile}
                user={user}
                displaysWorkspaceAndNotifications
            />
            <main>
                <Outlet/>
            </main>
        </>
    );

    // #endregion
}

// eslint-disable-next-line react/prop-types, react/no-multi-comp
function PopulateAssociationsContext({ profile }) {
    const [
        associations,
        // eslint-disable-next-line no-unused-vars
        initialFetchDone,
    ] = AssociationModel.useLiveDataByQuery(
        'UserAssociationsListener',
        // eslint-disable-next-line array-bracket-newline
        [
            // eslint-disable-next-line react/prop-types
            where('userID', '==', profile?.id),
        // eslint-disable-next-line array-bracket-newline
        ]
    );

    useEffect(() => {
        userAssociations_FluxState.set(associations);
    }, [ associations ]);

    return null;
}

export default UserWrapper;
