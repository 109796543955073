import { useMemo } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { DateTime } from 'luxon';
import {
    Close,
    Check,
} from '@mui/icons-material';
import {
    StickyTooltip,
    Button,
} from '@psionic/ui';
import { sendSurveyToUser } from '@services/check-in';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import TeamContextLevel from '@contexts/team';
import { UserAvatar } from '@components/avatars';
import localStyles from './dashboard.module.scss';

function Dashboard() {

    // #region Constants

    const FIT_CRITERIA_BUT_NO_SURVEY_MESSAGE = 'User only fit the criteria for a survey to be sent out after cycle began.';

    // #endregion

    // #region Context

    const currentSurveysByTeamMemberID = TeamCheckInContextLevel.use.currentSurveysByTeamMemberID.value();

    const checkInServiceInfo = TeamCheckInContextLevel.use.checkInServiceInfo.value();

    const team = TeamContextLevel.use.currentTeam.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const checkInReviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    const checkInQuestions = TeamCheckInContextLevel.use.checkInQuestions.value();

    const currentSurveyBatch = TeamCheckInContextLevel.use.currentSurveyBatch.value();

    const checkInSurveyConfiguration = TeamCheckInContextLevel.use.checkInSurveyConfiguration.value();

    // #endregion

    // #region Memoized Values

    const numSurveysSentOut = useMemo(() => {
        return Object.values(currentSurveysByTeamMemberID)?.length || 0;
    }, [ currentSurveysByTeamMemberID ]);

    const numSurveysSubmitted = useMemo(() => {
        return Object.values(currentSurveysByTeamMemberID).reduce(
            (acc, survey) => {
                if (survey.dateSubmitted) {
                    return acc + 1;
                }

                return acc;
            },
            0
        );
    }, [ currentSurveysByTeamMemberID ]);

    const numMembersWithoutSurveys = useMemo(() => {
        return Object.values(teamMembers).length - numSurveysSentOut;
    }, [
        numSurveysSentOut,
        teamMembers,
    ]);

    const notesByUser = useMemo(() => {
        const notesByMemberID = {};

        for (const member of Object.values(teamMembers)) {
            // If the member has a current survey, then they don't need additional notes
            if (currentSurveysByTeamMemberID[ member.id ]) {
                continue;
            }

            // If the member is not part of a group with a reviewer, report that as a note
            const memberGroups = filter(
                teamGroups,
                (group) => find(
                    group.members,
                    (groupMember) => groupMember.value === member.id
                )
            );

            const memberGroupsWithReviewers = filter(
                memberGroups,
                (group) => find(
                    checkInReviewerAssociations,
                    (association) => association.groupID === group.id && association.reviewerID !== member.id
                ) && !find(
                    checkInReviewerAssociations,
                    (association) => association.groupID === group.id && association.reviewerID === member.id
                )
            );

            if (memberGroupsWithReviewers.length <= 0) {
                notesByMemberID[ member.id ] = 'Not a part of any groups with an assigned reviewer (where the user is not a reviewer for the group as well).';
                continue;
            }

            // If the member is not part of any groups with at least one question set, report that as a note
            const allGroupHasQuestions = Boolean(
                find(
                    checkInQuestions,
                    (question) => question.groupToAsk === 'ALL'
                )
            );

            const memberGroupsWithQuestions = filter(
                memberGroupsWithReviewers,
                (group) => find(
                    checkInQuestions,
                    (question) => question.groupToAsk === group.id
                )
            );

            if (memberGroupsWithQuestions.length <= 0 && !allGroupHasQuestions) {
                notesByMemberID[ member.id ] = 'Not a part of any reviewed groups with at least one question set.';
                continue;
            }

            // If we made it here, the user likely joined late or only recently fit the criteria for a survey to
            // be sent out
            notesByMemberID[ member.id ] = 'User only fit the criteria for a survey to be sent out after cycle began.';
        }

        return notesByMemberID;
    }, [
        teamMembers,
        currentSurveysByTeamMemberID,
    ]);

    const formattedStartDate = useMemo(() => {
        return currentSurveyBatch?.startDate
            ? DateTime.fromJSDate(currentSurveyBatch.startDate).toFormat('MMM dd')
            : '-';
    }, [ currentSurveyBatch ]);

    const formattedEndDate = useMemo(() => {
        return currentSurveyBatch?.endDate
            ? DateTime.fromJSDate(currentSurveyBatch.endDate).toFormat('MMM dd')
            : '-';
    }, [ currentSurveyBatch ]);

    // #endregion

    // #region Functions

    const onSendSurveyClicked = async(teamMember) => {
        await sendSurveyToUser(
            teamMember.id,
            team.id,
            currentSurveyBatch,
            checkInSurveyConfiguration,
            checkInReviewerAssociations,
            teamGroups,
            checkInQuestions
        );
    };

    // #endregion

    // #region Render Functions

    const renderTeammates = () => {
        const teammateRenders = [];

        for (const teamMember of Object.values(teamMembers)) {
            teammateRenders.push(
                <tr
                    key={teamMember.id}
                    className={localStyles.dataRow}
                >
                    <td>
                        <UserAvatar
                            imageURL={teamMember.avatarURL}
                            size='small'
                            userEmail={teamMember.email}
                            userName={teamMember.displayName}
                        />
                    </td>
                    <td>
                        {
                            currentSurveysByTeamMemberID[ teamMember.id ]
                                ? (
                                    <StickyTooltip content='Survey Sent'>
                                        <Check className={localStyles.sentIcon}/>
                                    </StickyTooltip>
                                )
                                : (
                                    <StickyTooltip content='Survey Not Sent'>
                                        <Close className={localStyles.notSentIcon}/>
                                    </StickyTooltip>
                                )
                        }
                    </td>
                    <td>
                        {
                            currentSurveysByTeamMemberID[ teamMember.id ]?.dateSubmitted
                                ? (
                                    <StickyTooltip content='Survey Submitted'>
                                        <Check className={localStyles.sentIcon}/>
                                    </StickyTooltip>
                                )
                                : (
                                    <StickyTooltip content='Survey Not Submitted'>
                                        <Close className={localStyles.notSentIcon}/>
                                    </StickyTooltip>
                                )
                        }
                    </td>
                    <td>
                        { notesByUser[ teamMember.id ] || '-' }
                    </td>
                    <td>
                        {
                            notesByUser[ teamMember.id ] === FIT_CRITERIA_BUT_NO_SURVEY_MESSAGE
                                ? (
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        darkMode
                                        onClick={() => onSendSurveyClicked(teamMember)}
                                    >
                                        Send Survey
                                    </Button>
                                )
                                : '-'
                        }
                    </td>
                </tr>
            );
        }

        return teammateRenders;
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.dashboard}>
            {
                checkInServiceInfo?.enabled
                    ? checkInServiceInfo?.latestBatchID
                        ? (
                            <>
                                <div className={localStyles.cycleDates}>
                                    <div className={localStyles.dateCard}>
                                        <p>
                                            {formattedStartDate}
                                        </p>
                                        <h3>
                                            Start Date
                                        </h3>
                                        <h4>
                                            (current cycle)
                                        </h4>
                                    </div>
                                    <div className={localStyles.dateCard}>
                                        <p>
                                            {formattedEndDate}
                                        </p>
                                        <h3>
                                            End Date
                                        </h3>
                                        <h4>
                                            (current cycle)
                                        </h4>
                                    </div>
                                </div>
                                <div className={localStyles.metrics}>
                                    <div className={localStyles.metric}>
                                        <p>
                                            {numSurveysSentOut}
                                        </p>
                                        <h3>
                                            Surveys Sent Out
                                        </h3>
                                        <h4>
                                            (current cycle)
                                        </h4>
                                    </div>
                                    <div className={localStyles.metric}>
                                        <p>
                                            {numSurveysSubmitted}
                                        </p>
                                        <h3>
                                            Surveys Completed
                                        </h3>
                                        <h4>
                                            (current cycle)
                                        </h4>
                                    </div>
                                    <div className={localStyles.metric}>
                                        <p>
                                            {numMembersWithoutSurveys}
                                        </p>
                                        <h3>
                                            Teammates Without Survey
                                        </h3>
                                        <h4>
                                            (current cycle)
                                        </h4>
                                    </div>
                                </div>
                                <div className={localStyles.usersTable}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    User
                                                </th>
                                                <th>
                                                    Survey Sent
                                                </th>
                                                <th>
                                                    Survey Submitted
                                                </th>
                                                <th>
                                                    Notes
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderTeammates()}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )
                        : (
                            <p className={localStyles.emptyState}>
                                Please wait... surveys will be sent out in less than a minute.
                            </p>
                        )
                    : (
                        <p className={localStyles.emptyState}>
                            Check-in service is not enabled for this team.
                        </p>
                    )
            }
        </div>
    );

    // #endregion
}

export default Dashboard;
