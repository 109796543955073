import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode:    'dark',
        primary: {
            main:         '#009ad7 !important',
            contrastText: '#fff',
        },
        secondary: {
            main:         '#003348',
            contrastText: '#fff',
        },
        subtle: {
            main:        '#ffffff50',
            contastText: '#000',
        },
        default: {
            main:        '#003348',
            contastText: '#fff',
        },
        reject: {
            main:         '#ff495c',
            contrastText: '#fff',
        },
        rejectDull: {
            main:         '#cc5a65',
            contrastText: '#fff',
        },
        warning: {
            main:         '#ffc652',
            contrastText: '#fff',
        },
        warningDull: {
            main:         '#b37a08',
            contrastText: '#fff',
        },
        inactive: {
            main:         '#8b8c8f',
            contrastText: '#fff',
        },
    },
});

export default theme;
