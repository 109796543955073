import {
    useState,
} from 'react';
import PropTypes from 'prop-types';
import {
    useMediaQuery,
    Tab,
    Tabs,
} from '@mui/material';
import {
    Form,
    TextField,
    Button,
} from '@psionic/ui';
import LogoWhite from '@assets/images/logo-white.svg';
import { CORE } from '@utils/constants';
import localStyles from './login-card.module.scss';

/**
 * A UI card for logging in or signing up.
 */
function LoginCard({
    loginFn,
    signUpFn,
    forgotPasswordFn,
    className,
    classNameMobile,
}) {
    // #region Hooks

    /**
     * Tracks the currently selected tab.
     */
    const [
        currentTab,
        setCurrentTab,
    ] = useState(TABS.LOGIN);

    /**
     * Set up media query to flip between desktop and mobile rendering.
     */
    const isMobileView = useMediaQuery('(max-width: 1200px)');

    // #endregion

    // #region Methods

    /**
     * Handles the Tabs `onChange` event.
     */
    const onTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    // #endregion

    // #region Render Functions

    /**
     * Tab Panel render.
     */
    const renderTabPanel = () => {
        switch (currentTab) {
            case TABS.LOGIN:
                return renderLoginPanel();
            case TABS.SIGN_UP:
                return renderSignUpPanel();
            default:
                return null;
        }
    };

    /**
     * Login panel render.
     */
    const renderLoginPanel = () => { return (
        <Form
            className={localStyles.form}
            onSubmit={loginFn}
        >
            <div className={localStyles.formFields}>
                <TextField
                    key='login-email'
                    fieldKey='email'
                    label='Email'
                    type='email'
                    darkMode
                    required
                />
                <TextField
                    key='login-password'
                    fieldKey='password'
                    label='Password'
                    type='password'
                    darkMode
                    required
                />
            </div>
            <div className={localStyles.actionButtons}>
                <Button
                    className={isMobileView ? localStyles.formButtonMobile : localStyles.formButton}
                    type='submit'
                    variant='contained'
                    width='100%'
                    darkMode
                >
                    Login
                </Button>
                <Button
                    className={localStyles.forgotPasswordButton}
                    variant='text'
                    darkMode
                    disabledOnFormSubmitting
                    onClick={forgotPasswordFn}
                >
                    Forgot Password?
                </Button>
            </div>
        </Form>
    ); };

    /**
     * Sign up panel render.
     */
    const renderSignUpPanel = () => { return (
        <Form
            className={localStyles.form}
            onSubmit={signUpFn}
        >
            <div className={localStyles.formFields}>
                <TextField
                    key='signup-email'
                    fieldKey='email'
                    label='Email'
                    type='email'
                    darkMode
                    required
                />
                <TextField
                    key='signup-password'
                    fieldKey='password'
                    label='Password'
                    type='password'
                    validator={(value) => {
                        if (value.length < CORE.MIN_PASSWORD_LENGTH) {
                            return `Password must be at least ${ CORE.MIN_PASSWORD_LENGTH } characters`;
                        }

                        return null;
                    }}
                    darkMode
                    required
                />
                <TextField
                    key='signup-confirm-password'
                    fieldKey='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    validator={(value, formData) => {
                        if (value !== formData.password.value) {
                            return 'Passwords do not match';
                        }

                        return null;
                    }}
                    darkMode
                    required
                />
            </div>
            <Button
                className={isMobileView ? localStyles.formButtonMobile : localStyles.formButton}
                type='submit'
                variant='contained'
                width='100%'
                darkMode
            >
                Sign Up
            </Button>
        </Form>
    ); };

    /**
     * Desktop render.
     */
    const renderDesktopView = () => { return (
        <div
            className={`
                    ${ localStyles.card }
                    ${ className }
                `}
        >
            <div className={localStyles.leftPane}>
                <LogoWhite className={localStyles.logo}/>
                <h4
                    className={`
                            whiteText
                            ${ localStyles.appName }
                        `}
                >
                    Eager Lead
                </h4>
            </div>
            <div className={localStyles.rightPane}>
                <Tabs
                    className={localStyles.tabs}
                    value={currentTab}
                    onChange={onTabChange}
                >
                    <Tab
                        className={localStyles.tab}
                        label='Login'
                        value={TABS.LOGIN}
                    />
                    <Tab
                        className={localStyles.tab}
                        label='Sign Up'
                        value={TABS.SIGN_UP}
                    />
                </Tabs>
                {renderTabPanel()}
            </div>
        </div>
    ); };

    /**
     * Mobile render.
     */
    const renderMobileView = () => { return (
        <div
            className={`
                    ${ localStyles.mobileModal }
                    ${ classNameMobile }
                `}
        >
            <div className={localStyles.rightPaneMobile}>
                <LogoWhite className={localStyles.logoMobile}/>
                <Tabs
                    className={localStyles.tabsMobile}
                    value={currentTab}
                    onChange={onTabChange}
                >
                    <Tab
                        className={localStyles.tab}
                        label='Login'
                        value={TABS.LOGIN}
                    />
                    <Tab
                        className={localStyles.tab}
                        label='Sign Up'
                        value={TABS.SIGN_UP}
                    />
                </Tabs>
                {renderTabPanel()}
            </div>
        </div>
    ); };

    /**
     * Main render.
     */
    if (isMobileView) {
        return renderMobileView();
    }

    return renderDesktopView();

    // #endregion
}

LoginCard.propTypes = {
    /**
     * Optional CSS override for the desktop view wrapper.
     */
    className:        PropTypes.string,
    /**
     * Optional CSS override for the mobile view wrapper.
     */
    classNameMobile:  PropTypes.string,
    /**
     * Callback for when the "Forgot Password" button is pressed.
     */
    forgotPasswordFn: PropTypes.func.isRequired,
    /**
     * Callback for when the login button is pressed. If it returns a
     * promise, the login and sign up buttons will be disabled while it
     * is running.
     */
    loginFn:          PropTypes.func.isRequired,
    /**
     * Callback for when the sign up button is pressed. If it returns a
     * promise, the login and sign up buttons will be disabled while it
     * is running.
     */
    signUpFn:         PropTypes.func.isRequired,
};

export default LoginCard;

/**
 * Reference enum for the different tab panel values.
 */
const TABS = {
    LOGIN:   'LOGIN',
    SIGN_UP: 'SIGN_UP',
};
