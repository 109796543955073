import { updateEmail } from 'firebase/auth';
import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';
import ProfileModel from '@models/profile';

/**
 * Updates the user's email in Firebase Auth and on their profile in Firestore.
 *
 * @param {Firebase.User} user The user to update the email for
 * @param {string} newEmail The new email to set for the user
 */
export default async function(user, newEmail) {
    try {
        const sanitizedNewEmail = newEmail.trim().toLowerCase();

        await updateEmail(
            user,
            sanitizedNewEmail
        );
        await ProfileModel.writeToID(
            user.uid,
            { email: sanitizedNewEmail },
            { mergeWithExistingValues: true }
        );
    } catch (err) {
        switch (err?.code) {
            case 'auth/requires-recent-login':
                throw ERRORS.factories.REQUIRES_RECENT_LOGIN();
            default:
                throw ERRORS.factories.UNDEFINED_ERROR();
        }
    }
}

// #region Categorized Errors

const ERRORS = new CategorizedErrorFactory({
    UNDEFINED_ERROR: {
        message: 'We encountered an issue while attempting to update your email!',
    },
    REQUIRES_RECENT_LOGIN: {
        message: 'This operation requires you to have a recent login.',
    },
});

// #endregion
