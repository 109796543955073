import {
    useState, useRef, useMemo, useEffect,
} from 'react';
import {
    ArrowBack,
    Check,
    Error,
} from '@mui/icons-material';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useUserContext } from '@unifire-js/firebase/auth';
import { DateTime } from 'luxon';
import {
    IconButton,
    Button,
    Snackbar,
    CircularSpinner,
    ControlledTextField,
} from '@psionic/ui';
import { SnackbarService } from '@services/snackbar';
import { submitSurvey } from '@services/check-in';
import TeamContextLevel from '@contexts/team';
import MyCheckInsContextLevel from '@contexts/my-check-ins';
import FillOutSurveyContextLevel from '@contexts/fill-out-survey';
import {
    ReviewersInfo,
    HeartbeatInput,
    QuestionInput,
} from '@components/check-in';
import ConnectedPastPriority from '../components/subcomponents/connected-past-priority';
import localStyles from './fill-out-survey.module.scss';

function FillOutSurvey() {

    // #region Misc Hooks

    const navigate = useNavigate();

    const { teamID } = useParams();

    // #endregion

    // #region Refs

    const responsesRef = useRef([]);

    const heartbeatAdditionalCommentRef = useRef(undefined);

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const survey = FillOutSurveyContextLevel.use.survey.value();

    const questions = FillOutSurveyContextLevel.use.surveyQuestions.value();

    const reviewers = MyCheckInsContextLevel.use.reviewers.value();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const existingPriorities = MyCheckInsContextLevel.use.priorities.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    // #endregion

    // #region Memoized Values

    const pastPriorities = useMemo(() => {
        if (existingPriorities) {
            return filter(
                existingPriorities,
                (priority) => priority.dateCreated < survey.startDate
            );
        }

        return undefined;
    }, [ existingPriorities ]);

    // #endregion

    // #region State

    const [
        editedSurvey,
        setEditedSurvey,
    ] = useState(survey);

    const [
        newPriorities,
        setNewPriorities,
    ] = useState([ '' ]);

    const [
        showErrors,
        setShowErrors,
    ] = useState(false);

    const [
        completedPriorities,
        setCompletedPriorities,
    ] = useState([]);

    const [
        heartbeatAdditionalCommentLoaded,
        setHeartbeatAdditionalCommentLoaded,
    ] = useState(false);

    // #endregion

    // #region Effects

    /**
     * Whenever the new priorities update, if the last priority in the array is not empty (or if no priority yet
     * exists), add a new empty priority to the end of the array.
     */
    useEffect(() => {
        if (newPriorities === undefined) {
            return;
        }

        if (newPriorities?.length <= 0) {
            setNewPriorities([ '' ]);
        }

        if (newPriorities[ newPriorities.length - 1 ] !== '') {
            setNewPriorities((prev) => [
                ...prev,
                '',
            ]);
        }
    }, [ newPriorities ]);

    /**
     * Set the content of the additional comment ref whenever appropriate.
     */
    useEffect(() => {
        if (
            !heartbeatAdditionalCommentLoaded
            && heartbeatAdditionalCommentRef.current
            && editedSurvey?.heartbeatAdditionalComment
        ) {
            heartbeatAdditionalCommentRef.current.setContent(editedSurvey.heartbeatAdditionalComment);
            setHeartbeatAdditionalCommentLoaded(true);
        }
    }, [ editedSurvey ]);

    // #endregion

    // #region Functions

    const updateNewPriority = (index, newValue) => {
        setNewPriorities((prev) => [
            ...prev.slice(0, index),
            newValue,
            ...prev.slice(index + 1),
        ]);
    };

    const removeInputIfEmptyAndNotLast = (index) => {
        if (index === newPriorities.length - 1) {
            return;
        }

        if (!newPriorities[ index ]) {
            setNewPriorities((prev) => [
                ...prev.slice(0, index),
                ...prev.slice(index + 1),
            ]);
        }
    };

    const onPriorityCompleteStateUpdated = (priorityID, checked) => {
        if (checked) {
            setCompletedPriorities((prev) => [
                ...prev,
                priorityID,
            ]);
        } else {
            setCompletedPriorities((prev) => filter(prev, (id) => id !== priorityID));
        }
    };

    const updateFieldInSurvey = (fieldKey, newValue) => {
        setEditedSurvey((prev) => ({
            ...prev,
            [ fieldKey ]: newValue,
        }));
    };

    const surveyIsValid = () => {
        // Validate that a heartbeat value has been selected
        if (!editedSurvey?.heartbeatValue) {
            return false;
        }

        // Validate that for each non-optional question, at least one response has been provided
        for (const question of questions) {
            const filledInResponsesToQuestion = filter(
                responsesRef.current,
                (response) => response.surveyQuestionID === question.id && response.content
            );

            if (!question.isOptional && filledInResponsesToQuestion.length <= 0) {
                return false;
            }
        }

        // Validate that at least one new priority was created
        if (!newPriorities || newPriorities?.length <= 0) {
            return false;
        }

        return true;
    };

    const onSubmitClicked = async() => {
        // If the survey is valid, submit the survey
        if (surveyIsValid()) {
            await submitSurvey(
                profile.id,
                editedSurvey,
                completedPriorities,
                newPriorities,
                responsesRef.current
            );

            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => {
                    return (
                        <Snackbar
                            color='approve'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Check}
                            text='Survey Submitted!'
                        />
                    );
                }
            );

            navigate('../');
        }

        // Otherwise, show an error snackbar
        else {
            setShowErrors(true);

            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => {
                    return (
                        <Snackbar
                            color='reject'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Error}
                            text='Please fill out all required fields.'
                        />
                    );
                }
            );
        }
    };

    // #endregion

    // #region Render Functions

    const renderNoSurvey = () => {
        return (
            <p className={localStyles.emptyState}>
                No survey was found with the given ID. You may have navigated to an invalid link.
                <br/>
                Please click the back button and select a survey from the list of your surveys.
            </p>
        );
    };

    const renderTitle = () => {
        const startDate = DateTime.fromJSDate(survey.startDate).toFormat('MMM d, yyyy');
        const endDate = DateTime.fromJSDate(survey.endDate).toFormat('MMM d, yyyy');

        return `Your Survey for ${ startDate } - ${ endDate }`;
    };

    const renderPastPriorities = () => {
        if (!pastPriorities) {
            return <CircularSpinner/>;
        }

        if (pastPriorities?.length <= 0) {
            return (
                <p className={localStyles.emptyState}>
                    You don't have any past priorities yet. Priorities that you create on surveys
                    which have not been completed yet will show up here.
                </p>
            );
        }

        const renders = [];

        for (const priority of pastPriorities) {
            renders.push(
                <li key={`priority-${ priority.id }-input`}>
                    <ConnectedPastPriority
                        profile={profile}
                        reviewers={reviewers}
                        setChecked={(newValue) => onPriorityCompleteStateUpdated(priority.id, newValue)}
                        surveyID={survey.id}
                        surveyOwner={profile}
                        teamID={teamID.id}
                        teamMembers={teamMembers}
                        priority={{
                            ...priority,
                            completed: (completedPriorities || []).includes(priority.id),
                        }}
                    />
                </li>
            );
        }

        return renders;
    };

    const renderCurrentPriorityInputs = () => {
        if (!newPriorities) {
            return <CircularSpinner/>;
        }

        const nonEmptyPriorities = filter(
            newPriorities || [],
            (priority) => Boolean(priority?.content)
        );

        const hasError = showErrors && nonEmptyPriorities?.length <= 0;

        const renders = [];

        for (const [
            newPriorityIndex,
            newPriority,
        ] of newPriorities.entries()) {
            renders.push(
                <li key={`new-priority-${ newPriorityIndex }-input`}>
                    <div className={localStyles.bulletPoint}/>
                    <ControlledTextField
                        color='primary'
                        hasError={hasError}
                        label={newPriority ? '' : 'New Priority...'}
                        value={newPriority}
                        darkMode
                        multiline
                        onBlur={() => removeInputIfEmptyAndNotLast(newPriorityIndex)}
                        onChange={(newValue) => updateNewPriority(newPriorityIndex, newValue)}
                    />
                </li>
            );
        }

        return (
            <>
                {
                    hasError
                        ? (
                            <p className={localStyles.requiresOnePriorityError}>
                                You must create at least one priority.
                            </p>
                        )
                        : null
                }
                <ul className={localStyles.currentPriorities}>
                    {renders}
                </ul>
            </>
        );
    };

    const renderQuestions = () => {
        if (!questions || responsesRef.current === undefined) {
            return <CircularSpinner/>;
        }

        const renders = [];

        const sortedQuestions = sortBy(
            questions,
            [
                (item) => (item.groupID === 'ALL' ? 0 : 1),
                'groupID',
                'isOptional',
            ]
        );

        for (const question of sortedQuestions) {
            renders.push(
                <li key={`question-${ question.id }-input`}>
                    <QuestionInput
                        id={`question-${ question.id }-input`}
                        isOptional={question.isOptional}
                        question={question}
                        responsesRef={responsesRef}
                        showErrors={showErrors}
                        groupAsked={
                            question.groupID === 'ALL'
                                ? { id: 'ALL' }
                                : teamGroups.find((group) => group.id === question.groupID)
                        }
                        onChange={() => {}}
                    />
                </li>
            );
        }

        return renders;
    };

    const renderSurvey = () => {
        return (
            <div className={localStyles.survey}>
                <ReviewersInfo reviewers={reviewers}/>
                <section className={localStyles.form}>
                    <div className={localStyles.heartbeat}>
                        <HeartbeatInput
                            ref={heartbeatAdditionalCommentRef}
                            additionalComment={editedSurvey?.heartbeatAdditionalComment}
                            selectedValue={editedSurvey?.heartbeatValue}
                            showErrors={showErrors}
                            onAdditionalCommentChange={(value) => updateFieldInSurvey('heartbeatAdditionalComment', value)}
                            onChange={(value) => updateFieldInSurvey('heartbeatValue', value)}
                        />
                    </div>
                    <div className={localStyles.priorities}>
                        <header>
                            <h3>
                                Priorities
                            </h3>
                        </header>
                        <div className={localStyles.pastPriorities}>
                            <h4>
                                Mark priorities from your past Check-in as complete
                            </h4>
                            <ul className={localStyles.pastPrioritiesList}>
                                {renderPastPriorities()}
                            </ul>
                        </div>
                        <div className={localStyles.newPriorities}>
                            <h4>
                                What do you intend to accomplish between now and your next Check-in?
                            </h4>
                            {renderCurrentPriorityInputs()}
                        </div>
                    </div>
                    <div className={localStyles.questions}>
                        <header>
                            <h3>
                                Questions
                            </h3>
                        </header>
                        <ul>
                            {renderQuestions()}
                        </ul>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div
            className={[
                'workspacePage',
                localStyles.workspacePage,
            ].join(' ')}
        >
            <header className={localStyles.header}>
                <IconButton
                    color='white'
                    SvgIcon={ArrowBack}
                    onClick={() => navigate('../')}
                />
                <h1>
                    { survey ? renderTitle() : 'Invalid Survey' }
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                {survey ? renderSurvey() : renderNoSurvey()}
                <section className={localStyles.actions}>
                    <Button
                        color='primary'
                        variant='contained'
                        darkMode
                        onClick={onSubmitClicked}
                    >
                        Submit Survey
                    </Button>
                </section>
            </section>
        </div>
    );

    // #endregion
}

export default FillOutSurvey;
