import { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { Autobatcher } from '@unifire-js/firebase/firestore';
import {
    Logout,
    Settings,
    Apps,
    NotificationsActive,
    NotificationsNone,
    Check,
} from '@mui/icons-material';
import {
    Link,
    useNavigate,
} from 'react-router-dom';
import {
    CircularSpinner,
    IconButton,
    Snackbar,
} from '@psionic/ui';
import {
    useStateReader,
    useCacheReader,
} from '@psionic/flux-react';
import LogoWhite from '@assets/images/logo-white.svg';
import {
    signOutUser,
} from '@services/user';
import { SnackbarService } from '@services/snackbar';
import AssociationModel, { ROLES } from '@models/association';
import InviteModel from '@models/invite';
import {
    userInvites_FluxState,
    userTeams_FluxCache,
    userTeamInvitations_FluxCache,
} from '@data/user';
import { CORE } from '@utils/constants';
import {
    UserAvatar,
} from '@components/avatars';
import {
    ActionMenu,
    WorkspaceSelectMenu,
    InvitationsMenu,
} from '@components/menus';
import { TutorialPrompt } from '@components/core';
import localStyles from './landing-page-header.module.scss';

/**
 * Header for the landing page.
 */
function LandingPageHeader({
    user,
    profile,
    displaysWorkspaceAndNotifications,
}) {
    // #region Misc Hooks

    const navigate = useNavigate();

    const userInvites = useStateReader(userInvites_FluxState);

    const [
        userTeams,
        // eslint-disable-next-line no-unused-vars
        userTeamsIsStale,
        userTeamsIsLoading,
    ] = useCacheReader(userTeams_FluxCache);

    const [
        userTeamInvitations,
        // eslint-disable-next-line no-unused-vars
        userTeamInvitationsIsStale,
        userTeamInvitationsIsLoading,
    ] = useCacheReader(userTeamInvitations_FluxCache);

    // #endregion

    // #region Refs

    const invitesButtonRef = useRef();

    // #endregion

    // #region State

    const [
        actionMenuAnchorEl,
        setActionMenuAnchorEl,
    ] = useState(null);

    const [
        workspaceSelectAnchorRef,
        setWorkspaceSelectAnchorRef,
    ] = useState(null);

    const [
        notificationMenuAnchorEl,
        setNotificationMenuAnchorEl,
    ] = useState(null);

    const [
        userHasClosedInvitesTutorial,
        setUserHasClosedInvitesTutorial,
    ] = useState(false);

    // #endregion

    // #region Memoized Values

    const displayUserInvitesNotification = useMemo(() => {
        return !userHasClosedInvitesTutorial
            && !userTeamsIsLoading
            && userTeams?.length <= 0
            && userInvites?.length > 0;
    }, [
        userInvites,
        userTeams,
        userTeamsIsLoading,
        userHasClosedInvitesTutorial,
    ]);

    // #endregion

    // #region Functions

    const closeActionMenu = () => {
        setActionMenuAnchorEl(null);
    };

    const onTeamSelected = (teamID) => {
        navigate(`/team/${ teamID }`);
    };

    const onInvitationAccepted = async(team) => {
        const autobatcher = new Autobatcher();

        // Get the corresponding existing invite
        const existingInvite = find(userInvites, [
            'teamID',
            team.id,
        ]);

        // If the existing invite exists, then delete the invite and create the association
        if (existingInvite) {
            InviteModel.deleteByID(existingInvite.id, { autobatcher });
            AssociationModel.writeToNewDoc(
                {
                    teamID: team.id,
                    userID: profile.id,
                    role:   ROLES.MEMBER,
                }
            );
        }

        await autobatcher.allBatchesFinalized();

        // Display a snackbar with a success message
        SnackbarService.addSnackbar(
            ({ removeSnackbar }) => {
                return (
                    <Snackbar
                        color='approve'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Check}
                        text={`Successfully joined ${ team.name }`}
                    />
                );
            },
            CORE.SNACKBAR_DURATION
        );
    };

    const onInvitationDeclined = async(team) => {
        // Get the corresponding existing invite
        const existingInvite = find(userInvites, [
            'teamID',
            team.id,
        ]);

        // If the existing invite exists, then delete the invite
        if (existingInvite) {
            await InviteModel.deleteByID(existingInvite.id);
        }
    };

    // #endregion

    // #region Render Functions

    const renderLoading = () => {
        return (
            <CircularSpinner
                color='white'
                size={24}
                hideBackground
            />
        );
    };

    const renderNotificationsButton = () => {
        if (!profile) {
            return null;
        }

        if (userTeamInvitationsIsLoading || !userTeamInvitations) {
            return renderLoading();
        }

        return (
            <>
                {/* Menu */}
                <InvitationsMenu
                    anchorEl={notificationMenuAnchorEl}
                    invitations={userTeamInvitations}
                    setAnchorEl={setNotificationMenuAnchorEl}
                    onInvitationAccepted={onInvitationAccepted}
                    onInvitationDeclined={onInvitationDeclined}
                />
                {/* Open Menu Button */}
                <IconButton
                    ref={invitesButtonRef}
                    color={userTeamInvitations?.length > 0 ? 'notification' : 'white'}
                    SvgIcon={userTeamInvitations?.length > 0 ? NotificationsActive : NotificationsNone}
                    onClick={(event) => {
                        return setNotificationMenuAnchorEl(event.currentTarget);
                    }}
                />
                {/* Tutorial Prompt */}
                <TutorialPrompt
                    anchorEl={invitesButtonRef.current}
                    isOpen={displayUserInvitesNotification}
                    title='You have a team invitation!'
                    content={(
                        <p>
                            You've been invited to join a team. You can see your invitations by clicking
                            the yellow notification bell, above.
                        </p>
                    )}
                    onClose={() => setUserHasClosedInvitesTutorial(true)}
                />
            </>
        );
    };

    const renderWorkspaceButton = () => {
        if (!profile) {
            return null;
        }

        if (userTeamsIsLoading || !userTeams) {
            return renderLoading();
        }

        if (userTeams) {
            return (
                <>
                    {/* Menu */}
                    <WorkspaceSelectMenu
                        anchorEl={workspaceSelectAnchorRef}
                        setAnchorEl={setWorkspaceSelectAnchorRef}
                        teams={userTeams}
                        onTeamSelected={onTeamSelected}
                    />
                    {/* Open Menu Button */}
                    <IconButton
                        color='white'
                        SvgIcon={Apps}
                        onClick={(event) => {
                            return setWorkspaceSelectAnchorRef(event.currentTarget);
                        }}
                    />
                </>
            );
        }

        return null;
    };

    const renderAvatarButton = () => {
        return (
            <>
                {/* Action Menu */}
                <ActionMenu
                    anchorEl={actionMenuAnchorEl}
                    actions={[
                        {
                            label:   'Account Settings',
                            icon:    Settings,
                            onClick: () => {
                                return navigate('/user/account-settings');
                            },
                        },
                        {
                            label:   'Sign Out',
                            icon:    Logout,
                            onClick: signOutUser,
                            color:   'reject',
                        },
                    ]}
                    onClose={closeActionMenu}
                />
                {/* Main Button */}
                <button
                    className={localStyles.avatarButton}
                    type='button'
                    onClick={(event) => {
                        return setActionMenuAnchorEl(event.target);
                    }}
                >
                    <UserAvatar
                        hasPopover={false}
                        hasTooltip={false}
                        imageURL={profile?.avatarURL}
                        size='extra-extra-small'
                        userEmail={profile?.email}
                        userName={profile?.displayName}
                    />
                </button>
            </>
        );
    };

    const renderLoginButton = () => {
        return (
            <Link
                className={localStyles.loginLink}
                to='/home/login'
            >
                Login
            </Link>
        );
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.headerWrapper}>
            <div className={localStyles.header}>
                <div className={localStyles.left}>
                    <Link
                        className={localStyles.logo}
                        to='/home'
                    >
                        <LogoWhite/>
                        <h1>Eager Lead</h1>
                    </Link>
                </div>
                <div className={localStyles.right}>
                    {displaysWorkspaceAndNotifications ? renderWorkspaceButton() : null}
                    {displaysWorkspaceAndNotifications ? renderNotificationsButton() : null}
                    {
                        user
                            ? profile
                                ? renderAvatarButton()
                                : renderLoading()
                            : renderLoginButton()
                    }
                </div>
            </div>
        </div>
    );

    // #endregion
}

LandingPageHeader.propTypes = {
    /**
     * The profile of the currently signed-in user.
     */
    profile: PropTypes.shape({
        avatarURL:   PropTypes.string,
        displayName: PropTypes.string,
        email:       PropTypes.string,
        id:          PropTypes.string,
    }),
    /**
     * The currently signed-in user object.
     */
    user:                              PropTypes.object,
    /**
     * Whether or not the header displays the workspace and notifications buttons.
     */
    displaysWorkspaceAndNotifications: PropTypes.bool,
};

LandingPageHeader.defaultProps = {
    profile:                           null,
    displaysWorkspaceAndNotifications: false,
    user:                              undefined,
};

export default LandingPageHeader;
