import PropTypes from 'prop-types';
import {
    Popover,
} from '@mui/material';
import {
    Check,
    Close,
} from '@mui/icons-material';
import {
    IconButton,
    StickyTooltip,
} from '@psionic/ui';
import { TeamAvatar } from '@components/avatars';
import localStyles from './invitations-menu.module.scss';

/**
 * Menu to show a user their team invitations.
 */
function InvitationsMenu({
    invitations,
    onInvitationAccepted,
    onInvitationDeclined,
    anchorEl,
    setAnchorEl,
}) {
    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    const renderEmptyState = () => (
        <p className={localStyles.emptyStateText}>
            You have no team invitations.
        </p>
    );

    const renderInvitationList = () => {
        const invitationListItems = [];
        for (const team of invitations) {
            invitationListItems.push(
                <li key={team.id}>
                    <TeamAvatar
                        size="extra-extra-small"
                        teamName={team.name}
                        imageURL={team.avatarURL}
                    />
                    <p>
                        {team.name}
                    </p>
                    <StickyTooltip
                        content="Accept Invitation"
                    >
                        <IconButton
                            SvgIcon={Check}
                            color="approve"
                            onClick={() => onInvitationAccepted(team)}
                        />
                    </StickyTooltip>
                    <StickyTooltip
                        content="Decline Invitation"
                    >
                        <IconButton
                            SvgIcon={Close}
                            color="reject"
                            onClick={() => onInvitationDeclined(team)}
                        />
                    </StickyTooltip>
                </li>,
            );
        }

        return (
            <ul>
                {invitationListItems}
            </ul>
        );
    };

    /**
     * Main render.
     */
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical:   'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical:   'top',
                horizontal: 'center',
            }}
            PaperProps={{
                className: localStyles.invitationsMenu,
            }}
            disableAutoFocus
            disableEnforceFocus
        >
            <h1>
                Team Invitations
            </h1>
            {
                invitations?.length > 0
                    ? renderInvitationList()
                    : renderEmptyState()
            }
        </Popover>
    );

    // #endregion
}

InvitationsMenu.propTypes = {
    /**
     * The teams the user is being invited to.
     */
    invitations:          PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Function to call when the user accepts an invitation.
     */
    onInvitationAccepted: PropTypes.func.isRequired,
    /**
     * Function to call when the user declines an invitation.
     */
    onInvitationDeclined: PropTypes.func.isRequired,
    /**
     * The anchor element for the menu. If this is null, the menu will not be shown. If this is not null, the menu will be shown.
     */
    anchorEl:             PropTypes.object,
    /**
     * Function to call to set the anchor element for the menu.
     */
    setAnchorEl:          PropTypes.func.isRequired,
};

InvitationsMenu.defaultProps = {

};

export default InvitationsMenu;
