import { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionItemInput } from '@components/inputs';
import { ActionItemInfo } from '@components/details';

/**
 * Control for an action item. Handles both the info and input states, as well
 * as toggling between the two.
 */
function ActionItemControl({
    actionItem,
    teammates,
    groups,
    onCompleteUpdate,
    onDelete,
    onSave,
    onCancel,
    isNewActionItem,
}) {
    // #region Constants

    // #endregion

    // #region State

    /**
     * Track whether the action item is in edit mode or not.
     */
    const [
        inEditMode,
        setInEditMode,
    ] = useState(isNewActionItem);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    /**
     * Augmented onSave function that also sets the edit mode to false.
     */
    const augmentedOnSave = (newData) => {
        setInEditMode(false);
        onSave(newData);
    };

    /**
     * Augmented `onCancel` function that also sets the edit mode to false.
     */
    const augmentedOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
        setInEditMode(false);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return inEditMode
        ? (
            <ActionItemInput
                actionItem={actionItem}
                groups={groups}
                teammates={teammates}
                onCancel={augmentedOnCancel}
                onSave={augmentedOnSave}
            />
        )
        : (
            <ActionItemInfo
                actionItem={actionItem}
                groups={groups}
                teammates={teammates}
                onCompleteUpdate={onCompleteUpdate}
                onDelete={onDelete}
                onEdit={onSave ? () => setInEditMode(true) : null}
            />
        );

    // #endregion
}

ActionItemControl.propTypes = {
    /**
     * The action item to display and control.
     */
    actionItem:       PropTypes.object.isRequired,
    /**
     * The array of groups that can be related to the action item.
     */
    groups:           PropTypes.arrayOf(PropTypes.object),
    /**
     * Flag indicating whether this is a new action item.
     */
    isNewActionItem:  PropTypes.bool,
    /**
     * Callback for when the action item's modification should be canceled.
     * This will normally be handled automatically. In cases where additional
     * logic must take place, like if this is a new action item being created,
     * this may need to be specified.
     */
    onCancel:         PropTypes.func,
    /**
     * Callback for when the `completed` flag of the action item is updated.
     */
    onCompleteUpdate: PropTypes.func.isRequired,
    /**
     * Callback for when the action item should be deleted. If not provided,
     * the control to delete the action item will not be shown.
     */
    onDelete:         PropTypes.func,
    /**
     * Callback for when the action item should be saved. If not provided,
     * the control to edit the action item will not be shown.
     */
    onSave:           PropTypes.func,
    /**
     * The array of teammates that can be related to the action item.
     */
    teammates:        PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActionItemControl.defaultProps = {
    isNewActionItem: false,
    groups:          [],
    onCancel:        undefined,
    onDelete:        undefined,
    onSave:          undefined,
};

export default ActionItemControl;
