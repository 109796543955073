import {
    useRedirectOnAuthCondition,
    useUserContext,
} from '@unifire-js/firebase/auth';
import {
    Check,
} from '@mui/icons-material';
import { PuffLoader, Snackbar } from '@psionic/ui';
import { SnackbarService } from '@services/snackbar';
import { LoadingPage } from '@components/loading';
import localStyles from './awaiting-email-verification.module.scss';

/**
 * Page for users to wait while their email is being verified.
 */
function AwaitingEmailVerification() {
    // #region Hooks

    /**
     * Hook for automatically redirecting the user to the home page when they have verified their
     * email.
     */
    useRedirectOnAuthCondition(
        '/home',
        (userContext) => {
            if (userContext.user && userContext.profile?.emailVerified) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => (
                        <Snackbar
                            color='approve'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Check}
                            text='Your email has been verified!'
                        />
                    )
                );

                return true;
            }
        }
    );

    /**
     * Use the user's profile from user context.
     */
    const { profile } = useUserContext();

    // #endregion

    // #region Render Functions

    /**
     * If the user's profile does not have an email, return a loading page.
     */
    if (!profile?.email) {
        return <LoadingPage displayRefreshTip={false}/>;
    }

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pageWrapper}>
            <div className={localStyles.loadingIndicatorWrapper}>
                <PuffLoader size={100}/>
            </div>
            <h5 className='centerText'>
                You will receive an email at
                {' '}
                {profile.email}
                .
                <br/>
                Please click the link to verify.
                <br/>
                <br/>
                You will be redirected to the "Home" page once you are logged in.
            </h5>
        </div>
    );

    // #endregion
}

export default AwaitingEmailVerification;
