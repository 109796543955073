import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    sortBy,
} from 'lodash';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    Skeleton,
} from '@mui/material';
import { Button } from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import MinuteModel from '@models/minute';
import {
    addMinute,
    getDateValue,
    createDefaultMinuteForDay,
} from '@services/minutes';
import { PRIORITIES } from '@utils/constants';
import {
    convertAgendaUTCDateToTeamTimezone,
} from '@utils/time-utils';
import {
    DateListHeader,
    DateListSection,
} from '@components/lists/date-list';
import {
    MinuteInput,
} from '@components/inputs';
import {
    RequiredMembersInfo,
    PriorityIndicator,
} from '@components/details';
import MinuteControl from '../minute-control/minute-control';
import localStyles from './todays-minutes.module.scss';

/**
 * Component that displays today's minutes.
 */
function TodaysMinutes({
    todaysDate,
    agenda,
}) {
    // #region Context

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the team members context value.
     */
    const teamMembers = TeamContextLevel.use.teamMembers.value();

    /**
     * Use the team groups context value.
     */
    const teamGroups = TeamContextLevel.use.teamGroups.value();

    // #endregion

    // #region State

    /**
     * Track any loaded minute chunks in state.
     */
    const [
        minutes,
        setMinutes,
    ] = useState(null);

    /**
     * Track the minute in add mode in state.
     */
    const [
        minuteToAdd,
        setMinuteToAdd,
    ] = useState(null);

    // #endregion

    // #region Effects

    /**
     * Keep today's minutes up-to-date.
     */
    useEffect(() => {
        // Get today's unix timestamp
        const todaysDateValue = getDateValue(todaysDate);

        // Subscribe to the agenda's minutes for today
        MinuteModel.addListenerByQueryInInstance(
            'todays-minute-tracker',
            `agendas/${ agenda.id }/days/${ todaysDateValue }/minutes`,
            [],
            (newMinutes) => {
                setMinutes(
                    sortBy(
                        sortBy(
                            newMinutes,
                            (minute) => {
                                switch (minute.priority) {
                                    case PRIORITIES.LOW:
                                        // eslint-disable-next-line no-magic-numbers
                                        return 2;
                                    case PRIORITIES.MEDIUM:
                                        return 1;
                                    case PRIORITIES.HIGH:
                                        return 0;
                                    default:
                                        // eslint-disable-next-line no-magic-numbers
                                        return 3;
                                }
                            }
                        ),
                        [ 'reviewed' ]
                    )
                );
            }
        );

        // In the cleanup function, remove the created listener
        return () => {
            MinuteModel.removeListener('todays-minute-tracker');
        };
    }, []);

    // #endregion

    // #region Functions

    /**
     * Callback for when a new minute is saved.
     */
    const onSaveNewMinute = (data) => {
        setMinuteToAdd(null);
        addMinute(agenda.id, getDateValue(todaysDate), data);
    };

    /**
     * Callback to create the minute to add item.
     */
    const createMinuteToAdd = () => {
        setMinuteToAdd(createDefaultMinuteForDay(profile.id, agenda.id));
    };

    // #endregion

    // #region Render Functions

    /**
     * Memoized todays minutes list items.
     */
    const todaysMinutesItems = useMemo(() => {
        const listItems = [];

        // If the minutes haven't been loaded yet, return a skeleton
        if (!minutes) {
            listItems.push(<Skeleton
                key='loading-state'
                height={58}
                variant='rectangular'
            />);
        }

        // If the minutes have been loaded, but there were no results, return an empty state
        else if (minutes.length === 0 && !minuteToAdd) {
            listItems.push(
                <p
                    key='empty-state'
                    className={localStyles.emptyStateText}
                >
                    No agenda items for today!
                </p>
            );
        }

        // Otherwise, return the actual minutes
        else {
            for (const minute of minutes) {
                listItems.push(
                    <MinuteControl
                        key={minute.id}
                        agenda={agenda}
                        currentTeam={currentTeam}
                        day={getDateValue(todaysDate)}
                        groups={teamGroups}
                        minute={minute}
                        teammates={Object.values(teamMembers || {})}
                    />
                );
            }
        }

        return listItems;
    }, [
        minutes,
        minuteToAdd,
    ]);

    /**
     * Memoized minute to add item.
     */
    const minuteToAddItem = useMemo(() => {
        // If there is no minute to add, don't render anything
        if (!minuteToAdd) {
            return null;
        }

        // Otherwise, render the minute to add item
        return (
            <section className={localStyles.newMinuteSection}>
                <PriorityIndicator
                    priority={minuteToAdd?.priority}
                    onChangePriority={(newValue) => setMinuteToAdd((prev) => ({
                        ...prev,
                        priority: newValue,
                    }))}
                />
                <MinuteInput
                    groups={teamGroups}
                    minute={minuteToAdd}
                    teammates={Object.values(teamMembers || {})}
                    onCancel={() => setMinuteToAdd(null)}
                    onSave={(data) => onSaveNewMinute({ ...data, priority: minuteToAdd?.priority })}
                />
            </section>
        );
    }, [
        minuteToAdd,
        teamMembers,
    ]);

    /**
     * Main render.
     */
    return (
        <div className={localStyles.todaysMinutes}>
            <RequiredMembersInfo
                minutes={minutes}
                teamGroups={teamGroups}
                teammates={Object.values(teamMembers || {})}
            />
            <DateListHeader
                className={localStyles.dateHeader}
                date={convertAgendaUTCDateToTeamTimezone(todaysDate, currentTeam?.timezone)}
            />
            <DateListSection>
                {
                    minuteToAdd
                        ? minuteToAddItem
                        : (
                            <div>
                                <Button
                                    variant='outlined'
                                    onClick={createMinuteToAdd}
                                >
                                    Add Item for Today
                                </Button>
                            </div>
                        )
                }
                {todaysMinutesItems}
            </DateListSection>
        </div>
    );

    // #endregion
}

TodaysMinutes.propTypes = {
    /**
     * The agenda to display minutes for.
     */
    agenda:     PropTypes.object.isRequired,
    /**
     * Today's date in UTC time (from luxon).
     */
    todaysDate: PropTypes.object.isRequired,
};

export default TodaysMinutes;
