import { Model } from '@unifire-js/firebase/firestore';

const PrioritiesModel = new Model({
    collectionName:  'priorities',
    collectionProps: [
        'content',
        'userID',
        'teamID',
        'completed',
        'dateCreated',
        'surveyCompleted',
    ],
    propDefaults: {
        completed: false,
    },
});

export default PrioritiesModel;
