import { useState, useMemo, useRef } from 'react';
import {
    useUserContext,
} from '@unifire-js/firebase/auth';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';
import find from 'lodash/find';
import {
    Button,
    IconButton,
    TextField,
    Form,
    StickyTooltip,
    Snackbar,
} from '@psionic/ui';
import {
    Save,
    Add,
    Delete,
    Business,
    Error,
} from '@mui/icons-material';
import AssociationModel, { ROLES } from '@models/association';
import InviteModel from '@models/invite';
import BiddingRoomConfigModel from '@models/bidding-room-config';
import TeamModel from '@models/team';
import { uploadTeamLogo } from '@services/team';
import { SnackbarService } from '@services/snackbar';
import { validateEmail } from '@utils/validation';
import { CORE } from '@utils/constants';
import {
    UploadAvatarButton,
} from '@components/buttons';
import { UserAvatar } from '@components/avatars';
import { StepperHeader } from '@components/steppers';
import localStyles from './create-team-stepper.module.scss';

/**
 * View for the Create New Team stepper.
 */
function CreateTeamStepper() {
    // #region Constants

    /**
     * Reference enum of all of the steps.
     */
    const STEPS = [
        {
            title:    'Team Information',
            renderFn: () => { return renderCompanyInfoStep(); },
        },
        {
            title:    'Invite Team Members',
            renderFn: () => { return renderInviteTeamMembersStep(); },
        },
    ];

    // #endregion

    // #region Refs

    const newTeamMemberFormRef = useRef();

    // #endregion

    // #region Hooks

    /**
     * Get the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the navigate hook from react-router-dom.
     */
    const navigate = useNavigate();

    /**
     * Track the user's current step in state.
     */
    const [
        currentStep,
        setCurrentStep,
    ] = useState(1);

    /**
     * Track the team's logo file.
     */
    const [
        teamLogo,
        setTeamLogo,
    ] = useState(null);

    /**
     * Track each step's form data.
     */
    const [
        stepOneFormData,
        setStepOneFormData,
    ] = useState(null);
    const [
        stepTwoFormData,
        setStepTwoFormData,
    ] = useState(null);

    /**
     * Track invited team member's emails in state.
     */
    const [
        teamMemberEmails,
        setTeamMemberEmails,
    ] = useState([]);

    // #endregion

    // #region Functions

    /**
     * Handler for the "cancel" button being clicked.
     */
    const onCancel = () => {
        navigate('/home');
    };

    /**
     * Handler for the "next" button being clicked.
     */
    const onNext = () => {
        setCurrentStep((prevState) => {
            if (prevState + 1 <= STEPS.length) {
                return prevState + 1;
            }

            return prevState;
        });
    };

    /**
     * Handler for the "back" button being clicked.
     */
    const onBack = () => {
        setCurrentStep((prevState) => {
            if (prevState - 1 > 0) {
                return prevState - 1;
            }

            return prevState;
        });
    };

    /**
     * Handler for when the team member email is submitted.
     */
    const onTeamMemberEmailSubmitted = (formData) => {
        const email = formData.teamMemberEmail.value;
        setTeamMemberEmails((prevState) => { return [
            ...prevState,
            email,
        ]; });
        newTeamMemberFormRef.current.setFormData((prevState) => { return {
            ...prevState,
            teamMemberEmail: {
                ...prevState.teamMemberEmail,
                value: '',
            },
        }; });
    };

    /**
     * Handler for when the "Remove Team Member" button is clicked.
     */
    const onRemoveTeamMemberClicked = (email) => {
        setTeamMemberEmails((prevState) => {
            const index = prevState.indexOf(email);
            prevState.splice(index, 1);

            return [ ...prevState ];
        });
    };

    /**
     * Handler for when the "On Finish" button is clicked.
     */
    const createTeam = async() => {
        try {
            // First, we want to create the team document
            const teamDocRef = await TeamModel.writeToNewDoc({
                name: stepOneFormData.teamName.value,
            });

            // If a team logo was given, upload and link that logo to the team
            if (teamLogo) {
                await uploadTeamLogo(teamDocRef, teamLogo);
            }

            // Create the owner association for the user
            await AssociationModel.writeToNewDoc({
                teamID: teamDocRef.id,
                userID: profile.id,
                role:   ROLES.OWNER,
            });

            // Create the invites for all invited team members
            await Promise.all(map(teamMemberEmails, async(email) => {
                await InviteModel.writeToNewDoc({
                    teamID: teamDocRef.id,
                    email,
                });
            }));

            // Create the bidding room config for the team
            await BiddingRoomConfigModel.writeToID(
                teamDocRef.id,
                {},
                { mergeWithDefaultValues: true }
            );

            // Open a snackbar to say that the save was successful
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='approve'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Save}
                        text='New Team Created!'
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );

            // Take the user to their "My Teams" page
            navigate(`/team/${ teamDocRef.id }`);
        } catch (err) {
            // Open an error snackbar
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text='We were unable to create your new team!'
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Memoized team members.
     */
    const teamMembers = useMemo(() => {
        const members = [];
        teamMemberEmails.sort();

        for (const teamMemberEmail of teamMemberEmails) {
            members.push(
                <li key={`invited-user-${ teamMemberEmail }`}>
                    <UserAvatar
                        size='small'
                        userEmail={teamMemberEmail}
                    />
                    <p>
                        {teamMemberEmail}
                    </p>
                    <StickyTooltip content='Remove Team Member'>
                        <IconButton
                            SvgIcon={Delete}
                            onClick={() => { return onRemoveTeamMemberClicked(teamMemberEmail); }}
                        />
                    </StickyTooltip>
                </li>
            );
        }

        return members;
    }, [ teamMemberEmails ]);

    /**
     * Render the company info step.
     */
    const renderCompanyInfoStep = () => { return (
        <Form
            initialFormData={stepOneFormData}
            onChange={(newFormData) => { return setStepOneFormData(newFormData); }}
            onSubmit={onNext}
        >
            <section className={localStyles.companyInfoSection}>
                <UploadAvatarButton
                    FallbackIcon={Business}
                    imageURL={teamLogo ? URL.createObjectURL(teamLogo) : null}
                    size='large'
                    variant='rounded'
                    onImageChange={(image) => { return setTeamLogo(image); }}
                />
                <TextField
                    className={localStyles.textInput}
                    fieldKey='teamName'
                    label='Team Name'
                    darkMode
                    required
                />
            </section>
            <section className={localStyles.actions}>
                <Button
                    variant='text'
                    darkMode
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    darkMode
                >
                    Next
                </Button>
            </section>
        </Form>
    ); };

    /**
     * Render the invite team members step.
     */
    const renderInviteTeamMembersStep = () => { return (
        <div className={localStyles.inviteTeamMembersSection}>
            <div className={localStyles.upperSection}>
                <ul className={localStyles.teamMembers}>
                    <li>
                        <UserAvatar
                            imageURL={profile.avatarURL}
                            size='small'
                            userEmail={profile.email}
                            userName={profile.displayName}
                        />
                        <p>
                            {profile.displayName || profile.email}
                        </p>
                    </li>
                    {teamMembers}
                </ul>
                <div>
                    <Form
                        ref={newTeamMemberFormRef}
                        className={localStyles.addTeammateForm}
                        initialFormData={stepTwoFormData}
                        onChange={(newFormData) => { return setStepTwoFormData(newFormData); }}
                        onSubmit={onTeamMemberEmailSubmitted}
                    >
                        <TextField
                            className={localStyles.alignFormFieldWithButton}
                            fieldKey='teamMemberEmail'
                            label='Team Member Email'
                            validator={(value) => {
                                if (
                                    find(
                                        teamMemberEmails,
                                        (email) => { return email === value; }
                                    )
                                ) {
                                    return 'This user has already been invited!';
                                }

                                if (!validateEmail(value)) {
                                    return 'Please enter a valid email address!';
                                }
                            }}
                            darkMode
                            required
                        />
                        <StickyTooltip content='Add Teammate'>
                            <IconButton
                                SvgIcon={Add}
                                type='submit'
                            />
                        </StickyTooltip>
                    </Form>
                </div>
            </div>
            <section className={localStyles.actions}>
                <Button
                    variant='text'
                    darkMode
                    onClick={onBack}
                >
                    Back
                </Button>
                <Button
                    variant='contained'
                    darkMode
                    onClick={createTeam}
                >
                    Finish
                </Button>
            </section>
        </div>
    ); };

    /**
     * Main render.
     */
    return (
        <div className={`nonWorkspacePage ${ localStyles.createTeamPage }`}>
            <StepperHeader
                currentStep={currentStep}
                numSteps={STEPS.length}
                stepTitle={STEPS[ currentStep - 1 ].title}
            />
            <section className={localStyles.content}>
                {STEPS[ currentStep - 1 ].renderFn()}
            </section>
        </div>
    );

    // #endregion
}

export default CreateTeamStepper;
