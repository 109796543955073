import { useId } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ActionItemModel from '@models/action-item';
import {
    updateMinuteReviewed,
    deleteMinute,
    getDateValue,
    updateMinute,
    rescheduleMinute,
    updateMinutePriority,
} from '@services/minutes';
import {
    getValueForDateInTeamTimezone,
} from '@utils/time-utils';
import { MinuteAccordion } from '@components/accordions';
import localStyles from './minute-control.module.scss';

/**
 * Control for viewing and editing a minute.
 */
function MinuteControl({
    agenda,
    minute,
    teammates,
    groups,
    currentTeam,
    day,
    rescheduleLimitedToPresent,
}) {
    // #region Constants

    /**
     * String for the path to minute in the database.
     */
    const MINUTE_PATH = `agendas/${ agenda.id }/days/${ day }/minutes/${ minute.id }`;

    // #endregion

    // #region State

    /**
     * Create a unique ID for the subscription creation.
     */
    const subscriptionIDPrefix = useId();

    /**
     * Track the minute's action items.
     */
    const [
        actionItems,
        // eslint-disable-next-line no-unused-vars
        actionItemsFetched,
    ] = ActionItemModel.useLiveDataByQueryInInstance(
        `${ subscriptionIDPrefix }-${ minute.id }-action-items-subscription`,
        `${ MINUTE_PATH }/actionItems`,
        []
    );

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    /**
     * Callback to update the given minute.
     */
    const onUpdateMinute = (data) => {
        updateMinute(agenda.id, day, data);
    };

    /**
     * Callback to update the given minute's priority.
     */
    const onUpdateMinutePriority = (newPriority) => {
        updateMinutePriority(agenda.id, day, minute.id, newPriority);
    };

    /**
     * Callback to update the given action item.
     */
    const onUpdateActionItem = (data) => {
        ActionItemModel.writeToPath(
            `${ MINUTE_PATH }/actionItems/${ data.id }`,
            data
        );
    };

    /**
     * Callback for when an action item's `complete` flag is toggled.
     */
    const onUpdateCompleteActionItemFlag = (complete, actionItemID) => {
        ActionItemModel.writeToPath(
            `${ MINUTE_PATH }/actionItems/${ actionItemID }`,
            { complete },
            { mergeWithExistingValues: true }
        );
    };

    /**
     * Callback to save a new action item to the database.
     */
    const onSaveNewActionItem = (data) => {
        ActionItemModel.writeToNewDoc(
            `${ MINUTE_PATH }/actionItems`,
            data
        );
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.minuteControl}>
            <MinuteAccordion
                actionItems={actionItems}
                currentTeam={currentTeam}
                groups={groups}
                minute={minute}
                openByDefault={false}
                rescheduleLimitedToPresent={rescheduleLimitedToPresent}
                teammates={teammates}
                showMinuteEditButton
                onActionItemSave={onUpdateActionItem}
                onChangePriority={onUpdateMinutePriority}
                onNewActionItemSave={onSaveNewActionItem}
                onSave={onUpdateMinute}
                onActionItemCompleteUpdate={(actionItemID, completed) => {
                    return onUpdateCompleteActionItemFlag(completed, actionItemID);
                }}
                onActionItemDelete={(actionItemID) => {
                    return ActionItemModel.deleteByPath(`${ MINUTE_PATH }/actionItems/${ actionItemID }`);
                }}
                onDelete={() => {
                    return deleteMinute(agenda.id, day, minute.id);
                }}
                onReschedule={(newDate) => {
                    return rescheduleMinute(
                        agenda.id,
                        day,
                        minute.id,
                        getDateValue(getValueForDateInTeamTimezone(DateTime.fromISO(newDate), currentTeam?.timezone))
                    );
                }}
                onReviewUpdate={(reviewed) => {
                    return updateMinuteReviewed(agenda.id, day, minute.id, reviewed);
                }}
            />
        </div>
    );

    // #endregion
}

MinuteControl.propTypes = {
    agenda:                     PropTypes.object.isRequired,
    currentTeam:                PropTypes.object.isRequired,
    day:                        PropTypes.number.isRequired,
    groups:                     PropTypes.array.isRequired,
    minute:                     PropTypes.object.isRequired,
    rescheduleLimitedToPresent: PropTypes.bool,
    teammates:                  PropTypes.array.isRequired,
};

MinuteControl.defaultProps = {
    rescheduleLimitedToPresent: false,
};

export default MinuteControl;
