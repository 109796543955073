import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    map,
} from 'lodash';
import {
    collectionGroup,
    query,
    where,
    onSnapshot,
    orderBy,
    limit,
} from 'firebase/firestore';
import {
    Skeleton,
} from '@mui/material';
import {
    Info,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import { firestore } from '@services/firebase';
import { getDateValue } from '@services/minutes';
import { LazyLoadTrigger } from '@components/loading';
import MinuteControl from '../minute-control/minute-control';
import localStyles from './expired-minutes.module.scss';

/**
 * View for expired minutes (minutes that were not reviewed by their set date).
 */
function ExpiredMinutes({
    todaysDate,
    agenda,
}) {
    // #region Constants

    /**
     * The number of minutes in each chunk.
     */
    const minutesPerChunk = 10;

    // #endregion

    // #region Context

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the team members context value.
     */
    const teamMembers = TeamContextLevel.use.teamMembers.value();

    /**
     * Use the team groups context value.
     */
    const teamGroups = TeamContextLevel.use.teamGroups.value();

    // #endregion

    // #region State

    /**
     * Track the number of minutes to load.
     */
    const [
        numMinutesToLoad,
        setNumMinutesToLoad,
    ] = useState(minutesPerChunk);

    /**
     * Track any loaded minutes in state.
     */
    const [
        minutes,
        setMinutes,
    ] = useState(null);

    // #endregion

    // #region Effects

    /**
     * Keep the minutes up-to-date.
     */
    useEffect(() => {
        const minutesCollectionGroup = collectionGroup(firestore, 'minutes');
        const teamsExpiredMinutesQuery = query(
            minutesCollectionGroup,
            where('agendaID', '==', agenda.id),
            where('dateToReview', '<', getDateValue(todaysDate)),
            where('reviewed', '==', false),
            orderBy('dateToReview', 'desc'),
            limit(numMinutesToLoad)
        );
        const unsubscribe = onSnapshot(
            teamsExpiredMinutesQuery,
            (querySnapshot) => {
                if (querySnapshot.empty) {
                    setMinutes([]);
                } else {
                    setMinutes(map(querySnapshot.docs, (queryDocumentSnapshot) => ({
                        ...queryDocumentSnapshot.data(),
                        id:   queryDocumentSnapshot.id,
                        _ref: queryDocumentSnapshot.ref,
                    })));
                }
            }
        );

        // In the cleanup function, remove the created listener
        return () => {
            unsubscribe();
        };
    }, [ numMinutesToLoad ]);

    // #endregion

    // #region Functions

    /**
     * Callback for when the lazy load trigger is triggered.
     */
    const onLazyLoadTriggered = () => {
        if (minutes.length === numMinutesToLoad) {
            setNumMinutesToLoad((prevState) => prevState + minutesPerChunk);
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Memoized expired minute items.
     */
    const expiredMinuteItems = useMemo(() => {
        const listItems = [];

        if (!minutes) {
            listItems.push(
                <li key='loading-state'>
                    <Skeleton
                        height={58}
                        variant='rectangular'
                    />
                </li>
            );
        } else if (minutes.length <= 0) {
            listItems.push(
                <li key='empty-state'>
                    <p className={localStyles.emptyStateText}>
                        This agenda does not have any expired items!
                    </p>
                </li>
            );
        } else {
            for (const minute of minutes) {
                listItems.push(
                    <li key={minute}>
                        <MinuteControl
                            agenda={agenda}
                            currentTeam={currentTeam}
                            day={minute.dateToReview}
                            groups={teamGroups}
                            minute={minute}
                            teammates={Object.values(teamMembers || {})}
                            rescheduleLimitedToPresent
                        />
                    </li>
                );
            }
        }

        return listItems;
    }, [ minutes ]);

    /**
     * Main render.
     */
    return (
        <section className={localStyles.expiredMinutes}>
            <section className={localStyles.tipCard}>
                <Info/>
                <p>
                    Expired items are agenda items that have not been reviewed, but had a review date set in the past.
                </p>
            </section>
            <section className={localStyles.expiredMinutes}>
                <ul>
                    {expiredMinuteItems}
                </ul>
                <LazyLoadTrigger
                    action={onLazyLoadTriggered}
                    debounceTime={1000}
                    ignore={!minutes || (minutes.length < numMinutesToLoad)}
                />
            </section>
        </section>
    );

    // #endregion
}

ExpiredMinutes.propTypes = {
    /**
     * The agenda to display minutes for.
     */
    agenda:     PropTypes.object.isRequired,
    /**
     * Today's date in UTC time (from luxon).
     */
    todaysDate: PropTypes.object.isRequired,
};

export default ExpiredMinutes;
