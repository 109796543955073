import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTrackFunction } from '@unifire-js/hooks';
import {
    TextField,
} from '@mui/material';
import {
    StickyTooltip,
    IconButton,
} from '@psionic/ui';
import {
    Group,
    Check,
    Close,
} from '@mui/icons-material';
import { UploadAvatarButton } from '@components/buttons';
import { TeammateSelectInput } from '@components/inputs';
import localStyles from './group-input.module.scss';

/**
 * Input field for creating a new group.
 */
function GroupInput({
    group,
    teammates,
    onSave,
    onCancel,
}) {
    // #region State

    /**
     * Track a local copy of the group in state.
     */
    const [
        localGroup,
        setLocalGroup,
    ] = useState(group);

    /**
     * Track the image file for any new avatar that is uploaded.
     */
    const [
        newAvatar,
        setNewAvatar,
    ] = useState(null);

    // #endregion

    // #region Effects

    // #endregion

    // #region Tracked Functions

    /**
     * Tracked save function.
     */
    const [
        saving,
        save,
    ] = useTrackFunction(() => { return onSave(localGroup, newAvatar); });

    /**
     * Tracked cancel function.
     */
    const [
        canceling,
        cancel,
    ] = useTrackFunction(onCancel);

    // #endregion

    // #region Memoized Values

    /**
     * Memoized flag indicating whether a tracked function is running.
     */
    const actionRunning = useMemo(() => { return saving || canceling; }, [
        saving,
        canceling,
    ]);

    /**
     * Memoized error flag.
     */
    const error = useMemo(() => { return !localGroup.name; }, [ localGroup ]);

    // #endregion

    // #region Functions

    /**
     * Handler for when the group avatar changes.
     */
    const onImageChange = (newImage) => {
        setNewAvatar(newImage);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.groupInput}>
            <UploadAvatarButton
                FallbackIcon={Group}
                imageURL={newAvatar ? URL.createObjectURL(newAvatar) : localGroup.avatarURL}
                size='small'
                onImageChange={onImageChange}
            />
            <div className={localStyles.teammateSelectWrapper}>
                <TeammateSelectInput
                    includeGroups={false}
                    label='Members'
                    placeholder='Add Group Members'
                    teammates={teammates}
                    value={localGroup.members}
                    setValue={(value) => { return setLocalGroup((prevState) => { return {
                        ...prevState,
                        members: value,
                    }; }); }}
                    isMulti
                />
            </div>
            <TextField
                className={localStyles.nameInput}
                error={!localGroup.name}
                helperText={localGroup.name ? ' ' : 'Group name required'}
                label='Group Name'
                value={localGroup.name}
                variant='outlined'
                fullWidth
                required
                onChange={(event) => { return setLocalGroup((prevState) => { return {
                    ...prevState,
                    name: event.target.value,
                }; }); }}
            />
            <section className={localStyles.actions}>
                <StickyTooltip content='Save Changes'>
                    <IconButton
                        className='acceptButton'
                        color='approve'
                        disabled={actionRunning || error}
                        SvgIcon={Check}
                        onClick={save}
                    />
                </StickyTooltip>
                <StickyTooltip content='Cancel Changes'>
                    <IconButton
                        className='declineButton'
                        color='reject'
                        disabled={actionRunning}
                        SvgIcon={Close}
                        onClick={cancel}
                    />
                </StickyTooltip>
            </section>
        </div>
    );

    // #endregion
}

GroupInput.propTypes = {
    /**
     * The group object to represent in the input as the initial values to display.
     */
    group:     PropTypes.object.isRequired,
    /**
     * Callback for when the cancel button is pressed.
     */
    onCancel:  PropTypes.func.isRequired,
    /**
     * Callback for when the group is saved. Should accept the new `group` object and the new avatar file (if one was
     * uploaded) as the only 2 params.
     */
    onSave:    PropTypes.func.isRequired,
    /**
     * Array of teammates that can join the group.
     */
    teammates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

GroupInput.defaultProps = {

};

export default GroupInput;
