/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable prefer-destructuring */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    where,
    orderBy,
    limit,
    collection,
    doc,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import {
    CircularSpinner,
    IconButton,
} from '@psionic/ui';
import {
    ArrowUpward,
    ArrowDownward,
    TrendingFlat,
    ChatBubble,
} from '@mui/icons-material';
import StarterKit from '@tiptap/starter-kit';
import { EditorContent, useEditor } from '@tiptap/react';
import { firestore } from '@services/firebase';
import {
    postComment,
    markActivityAsSeen,
} from '@services/check-in';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import RevieweeContextLevel from '@contexts/reviewee';
import CheckInSurveyModel from '@models/check-in-survey';
import CheckInSurveyQuestionModel from '@models/check-in-survey-question';
import CommentModel from '@models/comment';
import ReactionModel from '@models/reaction';
import PrioritiesModel from '@models/priorities';
import AILogModel from '@models/ai-log';
import { useFormattedReactions } from '@hooks/reactions';
import { onReactionClickedFactory } from '@utils/reactions';
import {
    HEARTBEAT_COLORS,
} from '@utils/constants';
import FirebaseFunctions from '@utils/constants/firebase-functions';
import {
    HeartbeatPulse,
    ReviewersInfo,
} from '@components/check-in';
import {
    CommentInput,
    Reactions,
    AIThoughts,
} from '@components/core';
import ExistingCommentControl from '../../../my-check-ins/components/subcomponents/existing-comment-control';
import ConnectedPastPriority from '../../../my-check-ins/components/subcomponents/connected-past-priority';
import ConnectedQuestion from '../../../my-check-ins/components/subcomponents/connected-question';
import localStyles from './review-survey.module.scss';

// #region Magic Numbers

const FOUR_SURVEYS = 4;

// #endregion

function ReviewSurvey({
    survey,
    showHeader,
}) {

    // #region Misc Hooks

    const editor = useEditor({
        extensions: [ StarterKit ],
        editable:   false,
    });

    const { revieweeID } = useParams();

    // #endregion

    // #region Refs

    const aiRequestSent = useRef(false);

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const team = TeamContextLevel.use.currentTeam.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    const latestActivityLogBySurveyID = TeamCheckInContextLevel.use.latestActivityLogBySurveyID.value();

    const reviewers = RevieweeContextLevel.use.reviewers.value();

    const reviewee = RevieweeContextLevel.use.reviewee.value();

    // #endregion

    // #region State

    const [
        pastFourSurveys,
        setPastFourSurveys,
    ] = useState(undefined);

    const [
        surveyQuestions,
        setSurveyQuestions,
    ] = useState(undefined);

    const [
        incompletePriorities,
        setIncompletePriorities,
    ] = useState(undefined);

    const [
        prioritiesCreatedThisSurvey,
        setPrioritiesCreatedThisSurvey,
    ] = useState(undefined);

    const [
        prioritiesCompletedThisSurvey,
        setPrioritiesCompletedThisSurvey,
    ] = useState(undefined);

    const [
        heartbeatReactions,
        setHeartbeatReactions,
    ] = useState(null);

    const [
        heartbeatComments,
        setHeartbeatComments,
    ] = useState(null);

    const [
        heartbeatComment,
        setHeartbeatComment,
    ] = useState('');

    const [
        commentSectionOpen,
        setCommentSectionOpen,
    ] = useState(undefined);

    const [
        aiThoughts,
        setAIThoughts,
    ] = useState(undefined);

    // #endregion

    // #region Effects

    /**
     * Whenever the survey updates, fetch the AI log for the survey, if it exists. Otherwise,
     * generate one.
     */
    useEffect(() => {
        if (!survey || aiThoughts !== undefined || !team) {
            return;
        }

        // If the request was already sent, return early
        if (aiRequestSent.current) {
            return;
        }

        const getAILog = async() => {
            // Set the request sent variable to true
            aiRequestSent.current = true;

            // Fetch the AI log from Firestore
            let aiLog = await AILogModel.getByQuery([ where(
                'associatedData',
                '==',
                doc(collection(firestore, `teams/${ team.id }/checkInSurveys`), survey.id)
            ) ]);

            // If the AI log doesn't exist yet, generate one
            if (aiLog.length <= 0) {
                await FirebaseFunctions.generateThoughtsOnSurvey({
                    survey: {
                        id:                         survey.id,
                        teamID:                     survey.teamID,
                        heartbeatValue:             survey.heartbeatValue,
                        heartbeatAdditionalComment: survey.heartbeatAdditionalComment,
                        userID:                     survey.userID,
                        startDate:                  survey.startDate,
                        endDate:                    survey.endDate,
                    },
                    assistedUserID: profile.id,
                });

                aiLog = await AILogModel.getByQuery([ where(
                    'associatedData',
                    '==',
                    doc(collection(firestore, `teams/${ team.id }/checkInSurveys`), survey.id)
                ) ]);
            }

            // Set the AI log in state
            setAIThoughts(aiLog[ 0 ] || null);
        };

        getAILog();
    }, [
        survey,
        aiThoughts,
        team,
    ]);

    /**
     * Whenever the latest activity log updates, if the user viewed timestamp is older than it, write a new one.
     */
    useEffect(() => {
        if (!survey) {
            return;
        }

        const latestActivityLog = latestActivityLogBySurveyID[ survey.id ];

        if (
            latestActivityLog
            && latestActivityLog.userID !== profile.id
            && !(latestActivityLog?.acknowledgedBy || []).includes(profile.id)
        ) {
            markActivityAsSeen(
                profile.id,
                latestActivityLog,
                team.id,
                survey.id
            );
        }
    }, [ latestActivityLogBySurveyID ]);

    /**
     * Track the comments for the survey's heartbeat.
     */
    CommentModel.useListenerByQuery(
        'heartbeat-comments',
        [
            where(
                'associatedData',
                '==',
                doc(collection(firestore, `teams/${ team.id }/checkInSurveys`), survey.id)
            ),
            orderBy('dateCreated'),
        ],
        setHeartbeatComments
    );

    /**
     * Track the reactions for the survey's heartbeat.
     */
    ReactionModel.useListenerByQuery(
        'heartbeat-reactions',
        [ where(
            'associatedData',
            '==',
            doc(collection(firestore, `teams/${ team.id }/checkInSurveys`), survey.id)
        ) ],
        setHeartbeatReactions
    );

    /**
     * Track the incomplete priorities.
     */
    PrioritiesModel.useListenerByQuery(
        'reviewee-incomplete-priorities-tracker',
        [
            where('teamID', '==', team.id),
            where('userID', '==', revieweeID),
            where('completed', '==', false),
            where('dateCreated', '<', survey.startDate),
        ],
        setIncompletePriorities
    );

    /**
     * Track the priorities completed during this survey.
     */
    PrioritiesModel.useListenerByQuery(
        'reviewee-priorities-completed-during-survey-tracker',
        [
            where('teamID', '==', team.id),
            where('userID', '==', revieweeID),
            where('completed', '==', true),
            where('surveyCompleted', '==', survey.id),
            where('dateCreated', '<', survey.startDate),
        ],
        setPrioritiesCompletedThisSurvey
    );

    /**
     * Track the priorities that were created during this survey.
     */
    PrioritiesModel.useListenerByQuery(
        'reviewee-priorities-created-during-survey-tracker',
        [
            where('teamID', '==', team.id),
            where('userID', '==', revieweeID),
            where('dateCreated', '<=', survey.endDate),
            where('dateCreated', '>=', survey.startDate),
            orderBy('dateCreated', 'desc'),
            orderBy('completed', 'desc'),
        ],
        setPrioritiesCreatedThisSurvey
    );

    /**
     * Track the survey questions.
     */
    CheckInSurveyQuestionModel.useListenerByQueryInInstance(
        'reviewee-survey-questions-tracker',
        `teams/${ team.id }/checkInSurveys/${ survey.id }/checkInSurveyQuestions`,
        [],
        setSurveyQuestions
    );

    /**
     * Load the past 4 surveys.
     */
    useEffect(() => {
        if (!survey || pastFourSurveys !== undefined) {
            return;
        }

        CheckInSurveyModel.getByQueryInInstance(
            `teams/${ team.id }/checkInSurveys`,
            [
                where('startDate', '<', survey.startDate),
                orderBy('startDate', 'desc'),
                limit(FOUR_SURVEYS),
            ]
        ).then(setPastFourSurveys);
    }, [ survey ]);

    /**
     * Keep the additional pulse comment in sync.
     */
    useEffect(() => {
        if (editor?.commands && survey?.heartbeatAdditionalComment) {
            editor.commands.setContent(survey.heartbeatAdditionalComment);
        }
    }, [
        survey,
        editor,
    ]);

    /**
     * If there is at least 1 existing comment, open the comment section.
     */
    useEffect(() => {
        if (heartbeatComments?.length > 0 && commentSectionOpen === undefined) {
            setCommentSectionOpen(true);
        }
    }, [ heartbeatComments ]);

    // #endregion

    // #region Memoized Values

    const formattedHeartbeatReactions = useFormattedReactions(heartbeatReactions);

    // #endregion

    // #region Functions

    const postHeartbeatComment = async() => {
        const content = heartbeatComment;
        setHeartbeatComment('');

        await postComment(
            doc(collection(firestore, `teams/${ team.id }/checkInSurveys`), survey.id),
            reviewers,
            profile,
            content,
            team.id,
            survey.id,
            reviewee
        );
    };

    const onHeartbeatReactionClicked = onReactionClickedFactory(
        doc(collection(firestore, `teams/${ team.id }/checkInSurveys`), survey.id),
        profile
    );

    // #endregion

    // #region Render Functions

    const renderExistingHeartbeatComents = () => {
        const renders = [];

        for (const existingComment of heartbeatComments) {
            renders.push(
                <ExistingCommentControl
                    key={`${ existingComment.id }-${ new Date() }`}
                    comment={existingComment}
                    profile={profile}
                    surveyID={survey.id}
                    teamID={team.id}
                    teamMembers={teamMembers}
                />
            );
        }

        return renders;
    };

    const renderTitle = () => {
        const startDate = DateTime.fromJSDate(survey.startDate).toFormat('MMM d, yyyy');
        const endDate = DateTime.fromJSDate(survey.endDate).toFormat('MMM d, yyyy');

        return `Survey for ${ startDate } - ${ endDate }`;
    };

    const renderNoSurvey = () => {
        return (
            <p className={localStyles.emptyState}>
                No survey was found with the given ID. You may have navigated to an invalid link.
                <br/>
                Please click the back button and select a survey from the list of your surveys.
            </p>
        );
    };

    const renderPriorities = () => {
        const completePriorityRenders = [];

        for (const priority of prioritiesCompletedThisSurvey) {
            completePriorityRenders.push(
                <li key={`priority-${ priority.id }`}>
                    <ConnectedPastPriority
                        priority={priority}
                        profile={profile}
                        reviewers={reviewers}
                        surveyID={survey.id}
                        surveyOwner={reviewee}
                        teamID={team.id}
                        teamMembers={teamMembers}
                    />
                </li>
            );
        }

        const createdPriorityRenders = [];

        for (const priority of prioritiesCreatedThisSurvey) {
            createdPriorityRenders.push(
                <li key={`priority-${ priority.id }`}>
                    <ConnectedPastPriority
                        priority={priority}
                        profile={profile}
                        reviewers={reviewers}
                        surveyID={survey.id}
                        surveyOwner={reviewee}
                        teamID={team.id}
                        teamMembers={teamMembers}
                    />
                </li>
            );
        }

        const incompletePriorityRenders = [];

        for (const priority of incompletePriorities) {
            incompletePriorityRenders.push(
                <li key={`priority-${ priority.id }`}>
                    <ConnectedPastPriority
                        priority={priority}
                        profile={profile}
                        reviewers={reviewers}
                        surveyID={survey.id}
                        surveyOwner={reviewee}
                        teamID={team.id}
                        teamMembers={teamMembers}
                    />
                </li>
            );
        }

        return (
            <>
                <h3>
                    Completed This Cycle
                </h3>
                <ul className={localStyles.prioritiesList}>
                    {
                        completePriorityRenders.length > 0
                            ? completePriorityRenders
                            : (
                                <p className={localStyles.emptyState}>
                                    No priorities were completed this cycle.
                                </p>
                            )
                    }
                </ul>
                <h3>
                    Created This Cycle
                </h3>
                <ul className={localStyles.prioritiesList}>
                    {
                        createdPriorityRenders.length > 0
                            ? createdPriorityRenders
                            : (
                                <p className={localStyles.emptyState}>
                                    No priorities were created this cycle.
                                </p>
                            )
                    }
                </ul>
                <h3>
                    Incomplete
                </h3>
                <ul className={localStyles.prioritiesList}>
                    {
                        incompletePriorityRenders.length > 0
                            ? incompletePriorityRenders
                            : (
                                <p className={localStyles.emptyState}>
                                    No priorities remain incomplete from previous cycles.
                                </p>
                            )
                    }
                </ul>
            </>
        );
    };

    const renderQuestions = () => {
        const renders = [];

        for (const question of surveyQuestions) {
            renders.push(
                <li key={`question-${ question.id }`}>
                    <ConnectedQuestion
                        profile={profile}
                        question={question}
                        reviewers={reviewers}
                        surveyOwner={reviewee}
                        teamMembers={teamMembers}
                    />
                </li>
            );
        }

        return (
            <ul className={localStyles.questionsList}>
                {renders}
            </ul>
        );
    };

    const renderSurvey = () => {
        const dates = [
            ...pastFourSurveys.map((pastSurvey) => DateTime.fromJSDate(pastSurvey.endDate).toMillis()),
            DateTime.fromJSDate(survey.endDate).toMillis(),
        ];

        const ratings = [
            ...pastFourSurveys.map((pastSurvey) => pastSurvey.heartbeatValue),
            survey.heartbeatValue,
        ];

        if (dates.length === 1) {
            dates.push(DateTime.fromJSDate(survey.endDate).toMillis());
            ratings.push(survey.heartbeatValue);
        }

        // eslint-disable-next-line id-length
        const averageRating = ratings.reduce((a, b) => a + b, 0) / ratings.length;

        let averageRatingNote;

        if (survey.heartbeatValue < averageRating) {
            averageRatingNote = (
                <>
                    <ArrowDownward style={{ color: '#FF495C' }}/>
                    This is lower than your average answer.
                </>
            );
        } else if (survey.heartbeatValue === Number(averageRating.toFixed(1))) {
            averageRatingNote = (
                <>
                    <TrendingFlat style={{ color: 'white' }}/>
                    This is the same as your average answer.
                </>
            );
        } else {
            averageRatingNote = (
                <>
                    <ArrowUpward style={{ color: '#3DDC97' }}/>
                    This is higher than your average answer.
                </>
            );
        }

        let averageRatingColor = HEARTBEAT_COLORS[ 1 ];

        if (averageRating >= 4.5) {
            averageRatingColor = HEARTBEAT_COLORS[ 5 ];
        } else if (averageRating >= 3.5) {
            averageRatingColor = HEARTBEAT_COLORS[ 4 ];
        } else if (averageRating >= 2.5) {
            averageRatingColor = HEARTBEAT_COLORS[ 3 ];
        } else if (averageRating >= 1.5) {
            averageRatingColor = HEARTBEAT_COLORS[ 2 ];
        }

        return (
            <>
                <AIThoughts
                    aiLog={aiThoughts}
                    overUsageLimits={aiThoughts === null}
                />
                <div className={localStyles.survey}>
                    <div className={localStyles.pulse}>
                        <h2>
                            Pulse
                            <IconButton
                                color='lowEmphasis'
                                SvgIcon={ChatBubble}
                                onClick={() => setCommentSectionOpen((prev) => !prev)}
                            />
                        </h2>
                        <div className={localStyles.statistics}>
                            <h3>
                                Feeling
                                {' '}
                                {survey.heartbeatValue}
                                {' '}
                                out of 5
                            </h3>
                            <p>
                                {averageRatingNote}
                            </p>
                        </div>
                        <div className={localStyles.commentText}>
                            <EditorContent editor={editor}/>
                        </div>
                        <HeartbeatPulse
                            key='unchanging'
                            dates={dates}
                            ratings={ratings}
                        />
                        <p className={localStyles.averagePulse}>
                            Avg pulse:
                            {' '}
                            <span
                                className={localStyles.averageIndicator}
                                style={{
                                    background: averageRatingColor,
                                }}
                            />
                            {' '}
                            {averageRating.toFixed(1)}
                        </p>
                        <div className={localStyles.reactions}>
                            <Reactions
                                reactions={formattedHeartbeatReactions}
                                userID={profile.id}
                                users={teamMembers}
                                onReactionClicked={onHeartbeatReactionClicked}
                            />
                        </div>
                        {
                            commentSectionOpen
                                ? (
                                    <div className={localStyles.commentSection}>
                                        {renderExistingHeartbeatComents()}
                                        <CommentInput
                                            key={heartbeatComments.length}
                                            id='heartbeat-new-comment-input'
                                            setValue={setHeartbeatComment}
                                            user={profile}
                                            value={heartbeatComment}
                                            onSaveClicked={postHeartbeatComment}
                                        />
                                    </div>
                                )
                                : null
                        }
                    </div>
                    <div className={localStyles.priorities}>
                        <header>
                            <h3>
                                Priorities
                            </h3>
                        </header>
                        <div className={localStyles.prioritiesListSection}>
                            {
                                incompletePriorities === undefined
                            || prioritiesCompletedThisSurvey === undefined
                            || prioritiesCreatedThisSurvey === undefined
                                    ? <CircularSpinner/>
                                    : renderPriorities()
                            }
                        </div>
                    </div>
                    <div className={localStyles.questions}>
                        <header>
                            <h3>
                                Questions
                            </h3>
                        </header>
                        <div className={localStyles.questionsListSection}>
                            {
                                surveyQuestions === undefined
                                    ? <CircularSpinner/>
                                    : renderQuestions()
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    };

    /**
     * If the survey is not loaded yet, show a loading state.
     */
    if (survey === undefined || pastFourSurveys === undefined) {
        return <CircularSpinner/>;
    }

    /**
     * Main render.
     */
    return (
        <>
            {
                showHeader
                    ? (
                        <h2 className={localStyles.header}>
                            {survey ? renderTitle() : 'Invalid Survey' }
                        </h2>
                    )
                    : null
            }
            <ReviewersInfo reviewers={reviewers}/>
            {survey ? renderSurvey() : renderNoSurvey()}
        </>
    );

    // #endregion
}

ReviewSurvey.propTypes = {
    showHeader: PropTypes.bool,
    survey:     PropTypes.object,
};

ReviewSurvey.defaultProps = {
    showHeader: false,
    survey:     undefined,
};

export default ReviewSurvey;
