import { useEffect, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import {
    StickyTooltip,
    Button,
    IconButton,
} from '@psionic/ui';
import {
    ArrowBack,
} from '@mui/icons-material';
import BiddingRoomConfigModel, { CARD_TYPES } from '@models/bidding-room-config';
import TeamContextLevel from '@contexts/team';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import { EditDeckField } from '@components/inputs';
import { LoadingPage } from '@components/loading';
import localStyles from './settings.module.scss';

// #region Magic Numbers

const MIN_BIDDING_CARD_COUNT = 2;

// #endregion

/**
 * Bidding room settings view.
 */
function Settings() {
    // #region Hooks

    /**
     * Use the "Settings Open" context API.
     */
    const settingsOpenContextAPI = BiddingRoomContextLevel.use.settingsOpen.api();

    /**
     * Use the "Current Team" context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Track a local copy of the bidding room's deck in state.
     */
    const [
        localDeck,
        setLocalDeck,
    ] = useState(null);

    /**
     * Track any deck validation errors in state.
     */
    const [
        localDeckError,
        setLocalDeckError,
    ] = useState('');

    /**
     * When the component first mounts, get the bidding room config's current deck from Firestore and store
     * a copy of it in state.
     */
    useEffect(() => {
        const fn = async() => {
            setLocalDeck(
                (await BiddingRoomConfigModel.getByID(currentTeam.id)).deck
            );
        };

        fn();
    }, []);

    // #endregion

    // #region Functions

    /**
     * Handler for when the back button is clicked.
     */
    const onBackButtonClicked = () => {
        settingsOpenContextAPI.set(false);
    };

    /**
     * Handler for when the deck changes.
     */
    const onDeckChange = (newDeck) => {
        setLocalDeckError(validateDeck(newDeck));
        setLocalDeck(newDeck);
    };

    /**
     * Validates the new deck.
     */
    const validateDeck = (newDeck) => {
        // If the deck is empty, return an empty error
        if (!newDeck || newDeck.length < MIN_BIDDING_CARD_COUNT) {
            return `You must have at least ${ MIN_BIDDING_CARD_COUNT } cards in the bidding deck.`;
        }

        // Card-specific checking
        for (const card of newDeck) {
            // If the card doesn't have a type, return an error
            if (!card.type) {
                return 'At least one of your cards does not have a type selected.';
            }

            // Ensure that the card is non-empty
            if (!card.value) {
                return 'You must select a value for all cards.';
            }

            // NUMBER-specific card validation
            if (card.type === CARD_TYPES.NUMBER) {
                // Ensure that the card's value can be type case as a number (and actually do the type case)
                if (Number.isNaN(Number(card.value))) {
                    return 'Invalid number provided for a card of type "NUMBER".';
                }
            }
        }

        // If we haven't returned yet, everything is valid
        return '';
    };

    /**
     * Handle the "Save Changes" button being clicked.
     */
    const onSaveChanges = async() => {
        // Type case all of the cards of type NUMBER to actual numbers
        for (const card of localDeck) {
            if (card.type === CARD_TYPES.NUMBER) {
                card.value = Number(card.value);
            }
        }

        // Sort the local deck's cards
        const sortedDeck = sortDeck(localDeck);

        // Save the changes to the bidding room config model
        await BiddingRoomConfigModel.writeToID(
            currentTeam.id,
            {
                deck: sortedDeck,
            },
            { mergeWithExisting: true }
        );

        // Close the settings page
        settingsOpenContextAPI.set(false);
    };

    /**
     * Function to sort a bidding room deck.
     */
    const sortDeck = (deck) => {
        // Sort the number cards into one array
        const sortedNumbers = sortBy(
            filter(deck, [
                'type',
                CARD_TYPES.NUMBER,
            ]),
            [ 'value' ]
        );

        // Sort the string cards into one array
        const sortedStrings = sortBy(
            filter(deck, [
                'type',
                CARD_TYPES.STRING,
            ]),
            [ 'value' ]
        );

        // Return the concatenated sorted arrays, with numbers first
        return concat(sortedNumbers, sortedStrings);
    };

    // #endregion

    // #region Render Functions

    /**
     * If the local deck has not loaded yet, return a loading state.
     */
    if (!localDeck) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <div className={localStyles.settingsPage}>
            <header>
                <h5>
                    Bidding Room Settings
                </h5>
                <StickyTooltip content='Back to Bidding Room'>
                    <IconButton
                        SvgIcon={ArrowBack}
                        onClick={onBackButtonClicked}
                    />
                </StickyTooltip>
            </header>
            <section>
                <EditDeckField
                    deck={localDeck}
                    error={localDeckError}
                    onDeckChange={onDeckChange}
                />
            </section>
            <section className={localStyles.actionsSection}>
                <Button
                    variant='text'
                    darkMode
                    onClick={onBackButtonClicked}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!!localDeckError}
                    variant='contained'
                    darkMode
                    onClick={onSaveChanges}
                >
                    Save Changes
                </Button>
            </section>
        </div>
    );

    // #endregion
}

export default Settings;
