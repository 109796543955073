import { Model } from '@unifire-js/firebase/firestore';

const AgendaModel = new Model({
    collectionName:  'agendas',
    collectionProps: [
        'name',
        'teamID',
    ],
    propDefaults: {
        name: 'Default',
    },
});

export default AgendaModel;
