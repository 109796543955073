import { Model } from '@unifire-js/firebase/firestore';

/**
 * Some non-number values for room decks.
 */
export const OTHER_DECK_VALUES = {
    ABSTAINING: 'ABSTAINING',
    NEED_MORE_INFO: 'NEED MORE INFO',
};

/**
 * The list of card types.
 */
export const CARD_TYPES = {
    NUMBER: 'NUMBER',
    STRING: 'STRING',
};

const BiddingRoomConfigModel = new Model({
    collectionName: 'biddingRoomConfigs',
    collectionProps: [
        'deck',
    ],
    propDefaults: {
        deck: [
            {
                type: CARD_TYPES.NUMBER,
                value: 1,
            },
            {
                type: CARD_TYPES.NUMBER,
                value: 2,
            },
            {
                type: CARD_TYPES.NUMBER,
                value: 3,
            },
            {
                type: CARD_TYPES.NUMBER,
                value: 5,
            },
            {
                type: CARD_TYPES.NUMBER,
                value: 8,
            },
            {
                type: CARD_TYPES.NUMBER,
                value: 13,
            },
            {
                type: CARD_TYPES.STRING,
                value: OTHER_DECK_VALUES.ABSTAINING,
            },
        ]
    },
});

export default BiddingRoomConfigModel;