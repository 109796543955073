import {
    CircularProgress,
} from '@mui/material';
import {
    CheckCircle,
    Error,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import localStyles from './loading-page-with-content.module.scss';

/**
 * Component for displaying a full-page UI for some loading operation. The difference between this and
 * `LoadingPage` is that this component allows you to specify content below the loading indicator. It
 * also expects that once the loading has finished, you won't want to redirect immediately -- thus it
 * provides variants via `loadingState` to display whether the operation is loading, succeeded, or failed.
 */
function LoadingPageWithContent({
    loadingState,
    children,
}) {
    // #region Render Functions

    /**
     * Render the appropriate indicator for the page, depending on the loading state.
     */
    const renderIndicator = () => (
        <>
            {/** The LOADING indicator */}
            <CircularProgress
                size={100}
                className={`
                        absolute
                        ${localStyles.adjustIcon}
                        ${
        loadingState === 'loading'
            ? null
            : 'hidden'
        }
                    `}
            />
            {/** The SUCCESS indicator */}
            <CheckCircle
                className={`
                        absolute
                        ${localStyles.adjustIcon}
                        ${
        loadingState === 'success'
            ? localStyles.successIcon
            : 'hidden'
        }
                    `}
            />
            {/** The FAILURE indicator */}
            <Error
                className={`
                        absolute
                        ${localStyles.adjustIcon}
                        ${
        loadingState === 'failure'
            ? localStyles.failureIcon
            : 'hidden'
        }
                    `}
            />
        </>
    );

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pageWrapper}>
            {renderIndicator()}
            <div className={localStyles.contentWrapper}>
                {
                    typeof children === 'string'
                        ? <h5>{children}</h5>
                        : children
                }
            </div>
        </div>
    );

    // #endregion
}

LoadingPageWithContent.propTypes = {
    /**
     * The children to display on the loading page.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    /**
     * The loading state for the component.
     */
    loadingState: PropTypes.oneOf([
        'loading',
        'success',
        'failure',
    ]),
};

LoadingPageWithContent.defaultProps = {
    loadingState: 'loading',
};

export default LoadingPageWithContent;
