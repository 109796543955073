/* eslint-disable max-lines */
/* eslint-disable complexity */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import {
    Home,
    Settings,
    Logout,
    ChevronLeft,
    ChevronRight,
    ListAlt,
    LibraryAddCheck,
    ManageAccounts,
    Groups,
    Edit,
    WorkspacePremium,
    SwapHoriz,
    Delete,
    SentimentVeryDissatisfied,
    Warning,
    Beenhere,
    Info,
} from '@mui/icons-material';
import {
    IconLink,
    IconButton,
    StickyTooltip,
    CircularSpinner,
    LabeledIconButton,
    Dialog,
    Button,
} from '@psionic/ui';
import LogoWhite from '@assets/images/logo-white.svg';
import Bidding from '@assets/images/bidding.svg';
import Pushpin from '@assets/images/pushpin.svg';
import { ROLES } from '@models/association';
import ReleaseNotesService from '@services/release-notes/release-notes-service';
import { signOutUser } from '@services/user';
import {
    ActionMenu,
} from '@components/menus';
import {
    UserAvatar,
} from '@components/avatars';
import { TransferTeamOwnershipDialog } from '@components/dialogs';
import localStyles from './workspace-sidebar.module.scss';

// #region Magic Numbers

const EXPANDED_ALL_LOADING_SIZE = 60;
const COLLAPSED_ALL_LOADING_SIZE = 36;
const AVATAR_LOADING_SIZE = 20;

// #endregion

// #region Other Constants

const REQUIRES_PREMIUM_TEXT = 'This team needs a premium subscription to use this feature.';

// #endregion

/**
 * Sidebar for a user's workspace.
 */
function WorkspaceSidebar({
    currentTeam,
    teamSubscription,
    teamAssociations,
    children,
    expandedInitially,
    profile,
    onDisbandTeamConfirmed,
    onLeaveTeamConfirmed,
    onTransferTeamOwnershipConfirmed,
    teamMembers,
    userAssociation,
    getFeatureIsEnabled,
}) {

    // #region Constants

    const SOLUTION_KEYS = {
        BULLETIN_BOARD: 'bulletinBoard',
        AGENDA:         'agenda',
        TASK_BIDDING:   'taskBidding',
        CHECK_IN:       'checkIn',
    };

    // #endregion

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region State

    /**
     * Track whether the sidebar is expanded or not.
     */
    const [
        expanded,
        setExpanded,
    ] = useState(expandedInitially);

    const [
        actionMenuAnchorEl,
        setActionMenuAnchorEl,
    ] = useState(null);

    const [
        transferTeamOwnershipDialogOpen,
        setTransferTeamOwnershipDialogOpen,
    ] = useState(false);

    const [
        disbandTeamConfirmDialogOpen,
        setDisbandTeamConfirmDialogOpen,
    ] = useState(false);

    const [
        leaveTeamConfirmDialogOpen,
        setLeaveTeamConfirmDialogOpen,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    const closeActionMenu = () => {
        setActionMenuAnchorEl(null);
    };

    const atLeastOneSolution = () => {
        let atLeastOneFound = false;

        for (const solutionKey of Object.values(SOLUTION_KEYS)) {
            if (getFeatureIsEnabled(solutionKey)) {
                atLeastOneFound = true;
                break;
            }
        }

        return atLeastOneFound;
    };

    // #endregion

    // #region Render Functions

    const renderDialogs = () => {
        return (
            <>
                {/* Leave Team Confirm Dialog */}
                <Dialog
                    className='dialog withIcon'
                    isOpen={leaveTeamConfirmDialogOpen}
                    setIsOpen={setLeaveTeamConfirmDialogOpen}
                >
                    <div className='leftSide'>
                        <SentimentVeryDissatisfied className='primaryIcon'/>
                    </div>
                    <div className='rightSide'>
                        <h1>
                            Are you sure?
                        </h1>
                        <p>
                            Are you sure you want to leave
                            {' '}
                            {currentTeam.name}
                            ? They will miss you a lot!
                        </p>
                        <div className='actionSection'>
                            <Button
                                variant='text'
                                darkMode
                                onClick={() => { return setLeaveTeamConfirmDialogOpen(false); }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                darkMode
                                onClick={onLeaveTeamConfirmed}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* Disband Team Confirm Dialog */}
                <Dialog
                    className='dialog withIcon'
                    isOpen={disbandTeamConfirmDialogOpen}
                    setIsOpen={setDisbandTeamConfirmDialogOpen}
                >
                    <div className='leftSide'>
                        <Delete className='redIcon'/>
                    </div>
                    <div className='rightSide'>
                        <h1>
                            Are you sure?
                        </h1>
                        <p>
                            Are you sure you want to disband the
                            {' '}
                            {currentTeam.name}
                            {' '}
                            team? Once deleted, you will not be able to
                            recover any data from this team.
                        </p>
                        <div className='actionSection'>
                            <Button
                                variant='text'
                                darkMode
                                onClick={() => { return setDisbandTeamConfirmDialogOpen(false); }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                darkMode
                                onClick={onDisbandTeamConfirmed}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <TransferTeamOwnershipDialog
                    associations={teamAssociations}
                    members={Object.values(teamMembers || {})}
                    open={transferTeamOwnershipDialogOpen}
                    setOpen={setTransferTeamOwnershipDialogOpen}
                    team={currentTeam}
                    onPromote={
                        (member) => onTransferTeamOwnershipConfirmed(currentTeam, teamSubscription, profile, member)
                    }
                />
            </>
        );
    };

    const renderAvatarButton = () => {
        if (!profile) {
            return (
                <CircularSpinner size={AVATAR_LOADING_SIZE}/>
            );
        }

        return (
            <>
                {/* Action Menu */}
                <ActionMenu
                    anchorEl={actionMenuAnchorEl}
                    actions={[
                        {
                            label:   'Account Settings',
                            icon:    Settings,
                            onClick: () => {
                                return navigate('/user/account-settings');
                            },
                        },
                        {
                            label:   'Sign Out',
                            icon:    Logout,
                            onClick: signOutUser,
                            color:   'reject',
                        },
                    ]}
                    onClose={closeActionMenu}
                />
                {/* Main Button */}
                <button
                    className={localStyles.avatarButton}
                    type='button'
                    onClick={(event) => {
                        return setActionMenuAnchorEl(event.target);
                    }}
                >
                    <UserAvatar
                        hasPopover={false}
                        hasTooltip={false}
                        imageURL={profile?.avatarURL}
                        size='extra-extra-small'
                        userEmail={profile?.email}
                        userName={profile?.displayName}
                    />
                </button>
            </>
        );
    };

    /**
     * Main render.
     */
    return (
        <>
            {currentTeam ? renderDialogs() : null}
            <div
                className={localStyles.childContainer}
                data-expanded={expanded}
            >
                <div
                    className={localStyles.childrenWrapper}
                >
                    {children}
                </div>
            </div>
            <section
                className={localStyles.sidebar}
                data-expanded={expanded}
            >
                <div className={localStyles.content}>
                    <header>
                        <Link
                            className={localStyles.logo}
                            to='/home'
                        >
                            <LogoWhite/>
                            {expanded ? <h1>Eager Lead</h1> : null}
                        </Link>
                    </header>
                    <div className={localStyles.mainContent}>
                        {
                            currentTeam?.id
                                ? (
                                    <>
                                        <section className={localStyles.homeAndAccountSection}>
                                            <StickyTooltip
                                                active={!expanded}
                                                content='Team Home'
                                            >
                                                <IconLink
                                                    inactiveColor='inactiveLink'
                                                    label={expanded ? 'Team Home' : null}
                                                    SvgIcon={Home}
                                                    to={`/team/${ currentTeam?.id }`}
                                                />
                                            </StickyTooltip>
                                            <span className={localStyles.divider}/>
                                            {renderAvatarButton()}
                                        </section>
                                        <section className={localStyles.shortcutsSection}>
                                            {
                                                expanded
                                                    ? <h3>Solutions</h3>
                                                    : null
                                            }
                                            {
                                                atLeastOneSolution()
                                                    ? null
                                                    : (
                                                        <StickyTooltip
                                                            content={
                                                                [
                                                                    ROLES.OWNER,
                                                                    ROLES.ADMIN,
                                                                ].includes(userAssociation.role)
                                                                    ? (
                                                                        <p>
                                                                            Your team has no features enabled yet!
                                                                            <br/>
                                                                            Go to the "Configure Team Features" page
                                                                            <br/>
                                                                            to enable your first team feature!
                                                                        </p>
                                                                    )
                                                                    : (
                                                                        <p>
                                                                            Your team has no features enabled yet!
                                                                            <br/>
                                                                            Ask your team admin to go to the
                                                                            <br/>
                                                                            "Configure Team Features" page to enable
                                                                            <br/>
                                                                            your first team feature!
                                                                        </p>
                                                                    )
                                                            }
                                                        >
                                                            {
                                                                [
                                                                    ROLES.OWNER,
                                                                    ROLES.ADMIN,
                                                                ].includes(userAssociation.role)
                                                                    ? (
                                                                        <IconButton
                                                                            color='warning'
                                                                            // eslint-disable-next-line no-magic-numbers
                                                                            size={expanded ? 32 : 20}
                                                                            SvgIcon={Warning}
                                                                            className={`
                                                                                ${ localStyles.warningIconButton }
                                                                                ${ expanded ? localStyles.expandedAdjustment : '' }
                                                                            `}
                                                                            onClick={() => navigate(`/team/${ currentTeam?.id }/admin/configure-features`)}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <Warning
                                                                            className={`
                                                                                ${ localStyles.warningIcon }
                                                                                ${ expanded ? localStyles.expandedAdjustment : '' }
                                                                            `}
                                                                        />
                                                                    )
                                                            }
                                                        </StickyTooltip>
                                                    )
                                            }
                                            {
                                                getFeatureIsEnabled('bulletinBoard')
                                                    ? (
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Bulletin'
                                                        >
                                                            <IconLink
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Bulletin' : null}
                                                                SvgIcon={Pushpin}
                                                                to={`/team/${ currentTeam?.id }/free/bulletin`}
                                                            />
                                                        </StickyTooltip>
                                                    )
                                                    : null
                                            }
                                            {
                                                getFeatureIsEnabled('agenda')
                                                    ? (
                                                        <StickyTooltip
                                                            active={!expanded || !teamSubscription}
                                                            content={teamSubscription ? 'Agenda' : REQUIRES_PREMIUM_TEXT}
                                                        >
                                                            <IconLink
                                                                disabled={!teamSubscription}
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Agenda' : null}
                                                                SvgIcon={ListAlt}
                                                                to={`/team/${ currentTeam?.id }/premium/agenda`}
                                                            />
                                                        </StickyTooltip>
                                                    )
                                                    : null
                                            }
                                            {
                                                getFeatureIsEnabled('checkIn')
                                                    ? (
                                                        <StickyTooltip
                                                            active={!expanded || !teamSubscription}
                                                            content={teamSubscription ? 'Check-In' : REQUIRES_PREMIUM_TEXT}
                                                        >
                                                            <IconLink
                                                                disabled={!teamSubscription}
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Check-In' : null}
                                                                SvgIcon={Beenhere}
                                                                to={`/team/${ currentTeam?.id }/premium/check-in`}
                                                            />
                                                        </StickyTooltip>
                                                    )
                                                    : null
                                            }
                                            {
                                                getFeatureIsEnabled('taskBidding')
                                                    ? (
                                                        <StickyTooltip
                                                            active={!expanded || !teamSubscription}
                                                            content={teamSubscription ? 'Task Bidding' : REQUIRES_PREMIUM_TEXT}
                                                        >
                                                            <IconLink
                                                                disabled={!teamSubscription}
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Task Bidding' : null}
                                                                SvgIcon={Bidding}
                                                                to={`/team/${ currentTeam?.id }/premium/bidding-room`}
                                                            />
                                                        </StickyTooltip>
                                                    )
                                                    : null
                                            }
                                        </section>
                                        <section className={localStyles.shortcutsSection}>
                                            {
                                                expanded
                                                    ? <h3>Personal</h3>
                                                    : null
                                            }
                                            <StickyTooltip
                                                active={!expanded}
                                                content='To Do'
                                            >
                                                <IconLink
                                                    inactiveColor='inactiveLink'
                                                    label={expanded ? 'To Do' : null}
                                                    SvgIcon={LibraryAddCheck}
                                                    to={`/team/${ currentTeam?.id }/personal/to-do`}
                                                />
                                            </StickyTooltip>
                                            {
                                                userAssociation.role === ROLES.OWNER
                                                    ? null
                                                    : (
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Leave Team'
                                                        >
                                                            <LabeledIconButton
                                                                color='red'
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Leave Team' : null}
                                                                SvgIcon={SentimentVeryDissatisfied}
                                                                onClick={() => setLeaveTeamConfirmDialogOpen(true)}
                                                            />
                                                        </StickyTooltip>
                                                    )
                                            }
                                        </section>
                                        {
                                            [
                                                ROLES.OWNER,
                                                ROLES.ADMIN,
                                            ].includes(userAssociation.role)
                                                ? (
                                                    <section className={localStyles.shortcutsSection}>
                                                        {
                                                            expanded
                                                                ? <h3>Administration</h3>
                                                                : null
                                                        }
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Manage Team Members'
                                                        >
                                                            <IconLink
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Manage Team Members' : null}
                                                                SvgIcon={ManageAccounts}
                                                                to={`/team/${ currentTeam?.id }/admin/manage-team-members`}
                                                            />
                                                        </StickyTooltip>
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Organize Groups'
                                                        >
                                                            <IconLink
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Organize Groups' : null}
                                                                SvgIcon={Groups}
                                                                to={`/team/${ currentTeam?.id }/admin/organize-groups`}
                                                            />
                                                        </StickyTooltip>
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Edit Team Info'
                                                        >
                                                            <IconLink
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Edit Team Info' : null}
                                                                SvgIcon={Edit}
                                                                to={`/team/${ currentTeam?.id }/admin/edit-team-info`}
                                                            />
                                                        </StickyTooltip>
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Configure Team Features'
                                                        >
                                                            <IconLink
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Configure Team Features' : null}
                                                                SvgIcon={Settings}
                                                                to={`/team/${ currentTeam?.id }/admin/configure-features`}
                                                            />
                                                        </StickyTooltip>
                                                    </section>
                                                )
                                                : null
                                        }
                                        {
                                            userAssociation.role === ROLES.OWNER
                                                ? (
                                                    <section className={localStyles.shortcutsSection}>
                                                        {
                                                            expanded
                                                                ? <h3>Owner</h3>
                                                                : null
                                                        }
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Manage Subscription'
                                                        >
                                                            <IconLink
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Manage Subscription' : null}
                                                                SvgIcon={WorkspacePremium}
                                                                to={`/team/${ currentTeam?.id }/owner/manage-subscription`}
                                                            />
                                                        </StickyTooltip>
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Transfer Team Ownership'
                                                        >
                                                            <LabeledIconButton
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Transfer Team Ownership' : null}
                                                                SvgIcon={SwapHoriz}
                                                                onClick={() => setTransferTeamOwnershipDialogOpen(true)}
                                                            />
                                                        </StickyTooltip>
                                                        <StickyTooltip
                                                            active={!expanded}
                                                            content='Disband Team'
                                                        >
                                                            <LabeledIconButton
                                                                color='red'
                                                                inactiveColor='inactiveLink'
                                                                label={expanded ? 'Disband Team' : null}
                                                                SvgIcon={Delete}
                                                                onClick={() => setDisbandTeamConfirmDialogOpen(true)}
                                                            />
                                                        </StickyTooltip>
                                                    </section>
                                                )
                                                : null
                                        }
                                    </>
                                )
                                : (
                                    <div className={localStyles.loading}>
                                        <CircularSpinner
                                            size={expanded ? EXPANDED_ALL_LOADING_SIZE : COLLAPSED_ALL_LOADING_SIZE}
                                        />
                                    </div>
                                )
                        }
                    </div>
                    <footer data-expanded={expanded}>
                        { expanded ? (
                            <div className={localStyles.versionInfo}>
                                <p>
                                    v
                                    {ReleaseNotesService.getCurrentVersion()}
                                </p>
                                <IconButton
                                    color='lowEmphasis'
                                    SvgIcon={Info}
                                    onClick={() => navigate(`/home/release-notes?version=${ ReleaseNotesService.getCurrentVersion() }`)}
                                />
                            </div>
                        ) : null}
                        <IconButton
                            color='inactiveLink'
                            SvgIcon={expanded ? ChevronLeft : ChevronRight}
                            onClick={() => setExpanded((prev) => !prev)}
                        />
                    </footer>
                </div>
            </section>
        </>
    );

    // #endregion
}

WorkspaceSidebar.propTypes = {
    /**
     * The children to render beside the sidebar.
     */
    children:                         PropTypes.any.isRequired,
    /**
     * The current team.
     */
    currentTeam:                      PropTypes.object,
    /**
     * Flag indicating whether the sidebar should be expanded initially.
     */
    expandedInitially:                PropTypes.bool,
    /**
     * Function to call to check if a feature is enabled for the current team.
     */
    getFeatureIsEnabled:              PropTypes.func,
    /**
     * Function to call when the disband team action has been confirmed.
     */
    onDisbandTeamConfirmed:           PropTypes.func.isRequired,
    /**
     * Function to call when the leave team action has been confirmed.
     */
    onLeaveTeamConfirmed:             PropTypes.func.isRequired,
    /**
     * Function to call when the transfer team ownership action has been confirmed.
     */
    onTransferTeamOwnershipConfirmed: PropTypes.func.isRequired,
    /**
     * The current user's profile.
     */
    profile:                          PropTypes.object,
    /**
     * The current team's associations.
     */
    teamAssociations:                 PropTypes.arrayOf(PropTypes.object),
    /**
     * The current team's members.
     */
    teamMembers:                      PropTypes.object,
    /**
     * The current team's subscription.
     */
    teamSubscription:                 PropTypes.object,
    /**
     * The current user's association to the current team.
     */
    userAssociation:                  PropTypes.object,
};

WorkspaceSidebar.defaultProps = {
    expandedInitially:   true,
    currentTeam:         undefined,
    profile:             undefined,
    teamAssociations:    undefined,
    teamMembers:         undefined,
    teamSubscription:    undefined,
    userAssociation:     undefined,
    getFeatureIsEnabled: () => true,
};

export default WorkspaceSidebar;
