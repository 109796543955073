import TeamContextLevel from '@contexts/team';
import {
    Banner,
} from '@components/cards';
import { TeamInfo } from '@components/details';

/**
 * Home page for a team context.
 */
function TeamHome() {
    // #region Hooks

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the current team's members context value.
     */
    const teamMembers = TeamContextLevel.use.teamMembers.value();

    // #endregion

    /**
     * Main render.
     */
    return (
        <div className='workspacePage'>
            <Banner>
                <TeamInfo
                    members={Object.values(teamMembers || {})}
                    team={currentTeam || {}}
                />
            </Banner>
        </div>
    );

    // #endregion
}

export default TeamHome;
