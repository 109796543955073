import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    find,
    includes,
} from 'lodash';
import {
    IconButton,
    CircularProgress,
} from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import {
    Person,
    PersonOff,
} from '@mui/icons-material';
import { UserAvatar } from '@components/avatars';
import localStyles from './required-members-info.module.scss';

/**
 * Detail showing the required members for the day, given the minutes for the day.
 */
function RequiredMembersInfo({
    minutes,
    teammates,
    teamGroups,
}) {
    // #region State

    /**
     * Track whether the users to show is in the "required" or "not required" state.
     */
    const [
        showState,
        setShowState,
    ] = useState(SHOW_STATES.REQUIRED);

    // #endregion

    // #region Effects

    // #endregion

    // #region Memoized Values

    /**
     * Memoized unique team member IDs for both show states.
     */
    const uniqueTeamMemberIDsByShowState = useMemo(() => {
        if (!minutes) {
            return null;
        }

        let allGroupFound = false;

        const teamMemberIDsByShowState = {
            [ SHOW_STATES.REQUIRED ]:     [],
            [ SHOW_STATES.NOT_REQUIRED ]: [],
        };

        for (const minute of minutes) {
            if (minute.reviewed) {
                continue;
            }

            if (!includes(teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ], minute.owner)) {
                teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ].push(minute.owner);
            }

            for (const requiredObj of minute.requires) {
                if (requiredObj.type === 'GROUP') {
                    const group = find(teamGroups, [
                        'id',
                        requiredObj.value,
                    ]);

                    for (const member of group?.members || []) {
                        if (!includes(teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ], member.value)) {
                            teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ].push(member.value);
                        }
                    }
                } else if (requiredObj.type === 'TEAMMATE') {
                    if (!includes(teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ], requiredObj.value)) {
                        teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ].push(requiredObj.value);
                    }
                } else if (requiredObj.type === 'ALL') {
                    allGroupFound = true;
                    break;
                }
            }
        }

        for (const teammate of teammates) {
            if (!includes(teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ], teammate.id)) {
                if (allGroupFound) {
                    teamMemberIDsByShowState[ SHOW_STATES.REQUIRED ].push(teammate.id);
                } else {
                    teamMemberIDsByShowState[ SHOW_STATES.NOT_REQUIRED ].push(teammate.id);
                }
            }
        }

        return teamMemberIDsByShowState;
    }, [
        minutes,
        teammates,
    ]);

    /**
     * Memoized team member avatars to display by show state.
     */
    const teamMemberItemsByShowState = useMemo(() => {
        const listItemsByShowState = {
            [ SHOW_STATES.REQUIRED ]:     [],
            [ SHOW_STATES.NOT_REQUIRED ]: [],
        };

        if (!uniqueTeamMemberIDsByShowState) {
            listItemsByShowState[ SHOW_STATES.REQUIRED ].push(
                <li key='required-loading-state'>
                    <CircularProgress/>
                </li>
            );
            listItemsByShowState[ SHOW_STATES.NOT_REQUIRED ].push(
                <li key='required-loading-state'>
                    <CircularProgress/>
                </li>
            );
        } else if (uniqueTeamMemberIDsByShowState[ SHOW_STATES.REQUIRED ].length <= 0) {
            listItemsByShowState[ SHOW_STATES.REQUIRED ].push(
                <li key='required-empty-state'>
                    <p className={localStyles.emptyStateText}>
                        Everyone is free to go!
                    </p>
                </li>
            );
            listItemsByShowState[ SHOW_STATES.NOT_REQUIRED ].push(
                <li key='required-empty-state'>
                    <p className={localStyles.emptyStateText}>
                        Everyone is free to go!
                    </p>
                </li>
            );
        } else if (uniqueTeamMemberIDsByShowState[ SHOW_STATES.NOT_REQUIRED ].length <= 0) {
            listItemsByShowState[ SHOW_STATES.NOT_REQUIRED ].push(
                <li key='not-required-empty-state'>
                    <p className={localStyles.emptyStateText}>
                        Everyone is still required!
                    </p>
                </li>
            );
            listItemsByShowState[ SHOW_STATES.REQUIRED ].push(
                <li key='not-required-empty-state'>
                    <p className={localStyles.emptyStateText}>
                        Everyone is still required!
                    </p>
                </li>
            );
        } else {
            for (const teammateID of uniqueTeamMemberIDsByShowState[ SHOW_STATES.REQUIRED ]) {
                const teammate = find(teammates, [
                    'id',
                    teammateID,
                ]);

                if (teammate) {
                    listItemsByShowState[ SHOW_STATES.REQUIRED ].push(
                        <li key={teammateID}>
                            <UserAvatar
                                imageURL={teammate?.avatarURL}
                                size='extra-small'
                                userEmail={teammate?.email}
                                userName={teammate?.displayName}
                            />
                        </li>
                    );
                }
            }

            for (const teammateID of uniqueTeamMemberIDsByShowState[ SHOW_STATES.NOT_REQUIRED ]) {
                const teammate = find(teammates, [
                    'id',
                    teammateID,
                ]);

                if (teammate) {
                    listItemsByShowState[ SHOW_STATES.NOT_REQUIRED ].push(
                        <li key={teammateID}>
                            <UserAvatar
                                imageURL={teammate?.avatarURL}
                                size='extra-small'
                                userEmail={teammate?.email}
                                userName={teammate?.displayName}
                            />
                        </li>
                    );
                }
            }
        }

        return listItemsByShowState;
    }, [ uniqueTeamMemberIDsByShowState ]);

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <section className={localStyles.requiredMembersInfo}>
            <header>
                <StickyTooltip
                    content={
                        showState === SHOW_STATES.REQUIRED
                            ? 'Show Teammates Free to Leave'
                            : 'Show Required Teammates'
                    }
                >
                    <IconButton
                        className={localStyles.toggleButton}
                        color={showState === SHOW_STATES.REQUIRED ? 'primary' : 'rejectDull'}
                        onClick={() => { return setShowState((prevState) => { return prevState === SHOW_STATES.REQUIRED ? SHOW_STATES.NOT_REQUIRED : SHOW_STATES.REQUIRED; }); }}
                    >
                        {showState === SHOW_STATES.REQUIRED ? <Person/> : <PersonOff/>}
                    </IconButton>
                </StickyTooltip>
                <h6>
                    {showState === SHOW_STATES.REQUIRED ? 'Required Teammates' : 'Teammates Free to Leave'}
                </h6>
            </header>
            <ul className={localStyles.membersList}>
                {teamMemberItemsByShowState[ showState ]}
            </ul>
        </section>
    );

    // #endregion
}

RequiredMembersInfo.propTypes = {
    /**
     * Array of minutes for the day.
     */
    minutes:    PropTypes.arrayOf(PropTypes.object),
    /**
     * Array of team group definitions for the team.
     */
    teamGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Array of teammates for the team.
     */
    teammates:  PropTypes.arrayOf(PropTypes.object).isRequired,
};

RequiredMembersInfo.defaultProps = {
    minutes: [],
};

export default RequiredMembersInfo;

// #region Constants

/**
 * Reference enum for the different show states.
 */
const SHOW_STATES = {
    REQUIRED:     'REQUIRED',
    NOT_REQUIRED: 'NOT_REQUIRED',
};

// #endregion
