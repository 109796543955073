import { useState } from 'react';
import {
    Button,
    StickyTooltip,
    ControlledTextField,
} from '@psionic/ui';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import {
    Info,
} from '@mui/icons-material';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import TeamContextLevel from '@contexts/team';
import CheckInSurveyConfigurationModel from '@models/check-in-survey-configuration';
import localStyles from './global-settings.module.scss';

// #region Magic Numbers

// eslint-disable-next-line no-magic-numbers
const MILLIS_PER_DAY = 1000 * 60 * 60 * 24;
// eslint-disable-next-line no-magic-numbers
const MILLIS_PER_WEEK = MILLIS_PER_DAY * 7;

const WEEKLY = Number(MILLIS_PER_WEEK);
const BI_WEEKLY = MILLIS_PER_WEEK * 2;
// eslint-disable-next-line no-magic-numbers
const MONTHLY = MILLIS_PER_WEEK * 4;

// #endregion

function GlobalSettingsTab() {

    // #region Constants

    const DEFAULT_FREQUENCY = BI_WEEKLY;
    const DEFAULT_NUM_ROTATING_QUESTIONS = 3;

    // #endregion

    // #region Context

    const checkInSurveyConfiguration = TeamCheckInContextLevel.use.checkInSurveyConfiguration.value();

    const team = TeamContextLevel.use.currentTeam.value();

    // #endregion

    // #region State

    const [
        editedSurveyConfiguration,
        setEditedSurveyConfiguration,
    ] = useState({
        ...checkInSurveyConfiguration,
        frequency:            checkInSurveyConfiguration?.frequency || DEFAULT_FREQUENCY,
        numRotatingQuestions: checkInSurveyConfiguration?.numRotatingQuestions || DEFAULT_NUM_ROTATING_QUESTIONS,
    });

    const [
        changesMade,
        setChangesMade,
    ] = useState(false);

    const [
        saving,
        setSaving,
    ] = useState(false);

    // #endregion

    // #region Functions

    const updateField = (fieldID, newValue) => {
        setEditedSurveyConfiguration((prev) => ({ ...prev, [ fieldID ]: newValue }));
        setChangesMade(true);
    };

    const saveChanges = async() => {
        setSaving(true);

        await CheckInSurveyConfigurationModel.writeToPath(
            `teams/${ team?.id }/checkInSurveyConfigurations/default`,
            {
                ...editedSurveyConfiguration,
                numRotatingQuestions: Number(editedSurveyConfiguration?.numRotatingQuestions),
            },
            {
                mergeWithDefaultValues: true,
            }
        );

        setSaving(false);
        setChangesMade(false);
    };

    const discardChanges = () => {
        setEditedSurveyConfiguration({
            ...checkInSurveyConfiguration,
            frequency:            checkInSurveyConfiguration?.frequency || DEFAULT_FREQUENCY,
            numRotatingQuestions: checkInSurveyConfiguration?.numRotatingQuestions || DEFAULT_NUM_ROTATING_QUESTIONS,
        });
        setChangesMade(false);
    };

    // #endregion

    // #region Variables

    const frequencyFieldValid = editedSurveyConfiguration?.frequency;

    const numRotatingQuestionsFieldValid = editedSurveyConfiguration?.numRotatingQuestions >= 0 && ![
        undefined,
        '',
        null,
    ].includes(editedSurveyConfiguration?.numRotatingQuestions);

    const isValid = frequencyFieldValid && numRotatingQuestionsFieldValid;

    // #endregion

    // #region Render Functions

    return (
        <div className={localStyles.globalSettingsTab}>
            <p className={localStyles.noteOnChanges}>
                Changes to global settings will not affect any surveys that have already been sent out
                to your team.
            </p>
            <div className={localStyles.formFields}>
                <div className={localStyles.frequencyField}>
                    <FormControl fullWidth>
                        <InputLabel id='check-in-frequency-label'>Check-in Frequency</InputLabel>
                        <Select
                            label='Check-in Frequency'
                            labelId='check-in-frequency-label'
                            value={editedSurveyConfiguration?.frequency || DEFAULT_FREQUENCY}
                            onChange={(event) => updateField('frequency', event.target.value)}
                        >
                            <MenuItem value={WEEKLY}>Weekly</MenuItem>
                            <MenuItem value={BI_WEEKLY}>Bi-Weekly</MenuItem>
                            <MenuItem value={MONTHLY}>Monthly</MenuItem>
                        </Select>
                    </FormControl>
                    <StickyTooltip content='This sets how often surveys get sent out to your team.'>
                        <Info className={localStyles.tooltipIcon}/>
                    </StickyTooltip>
                </div>
                <div className={localStyles.numRotatingQuestionsField}>
                    <ControlledTextField
                        hasError={!numRotatingQuestionsFieldValid}
                        helperMessage={numRotatingQuestionsFieldValid ? '' : 'Must provide a number greater than or equal to 0'}
                        label='# Rotating Questions Per Survey'
                        type='number'
                        value={
                            [
                                undefined,
                                null,
                            ].includes(editedSurveyConfiguration?.numRotatingQuestions)
                                ? DEFAULT_NUM_ROTATING_QUESTIONS
                                : editedSurveyConfiguration?.numRotatingQuestions
                        }
                        darkMode
                        onChange={(newValue) => updateField('numRotatingQuestions', newValue)}
                    />
                    <StickyTooltip
                        content={(
                            <p>
                                This sets how many rotating questions are asked per survey sent out.
                                <br/>
                                If the number of available rotating questions is fewer than the value set here,
                                <br/>
                                all available rotating questions will be asked with each survey.
                            </p>
                        )}
                    >
                        <Info className={localStyles.tooltipIcon}/>
                    </StickyTooltip>
                </div>
            </div>
            <div className={localStyles.actions}>
                <Button
                    color='reject'
                    disabled={!changesMade || saving}
                    variant='text'
                    darkMode
                    onClick={discardChanges}
                >
                    Discard
                </Button>
                <Button
                    color='primary'
                    disabled={saving || !changesMade || !isValid}
                    variant='contained'
                    darkMode
                    onClick={saveChanges}
                >
                    Save
                </Button>
            </div>
        </div>
    );

    // #endregion
}

export default GlobalSettingsTab;
