import {
    Outlet,
} from 'react-router-dom';
import { useRedirectOnCondition } from '@unifire-js/hooks';
import { Warning } from '@mui/icons-material';
import { Snackbar } from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import { LoadingPage } from '@components/loading';

/**
 * Wrapper for any team routes that require that the team has a premium subscription.
 */
function TeamPremiumWrapper() {
    // #region Hooks

    /**
     * Use the team subscription context.
     */
    const teamSubscription = TeamContextLevel.use.teamSubscription.value();

    /**
     * Use the current team context.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Redirect the user if the team does not have a premium subscription.
     */
    const subscriptionChecked = useRedirectOnCondition(
        `/team/${ currentTeam.id }`,
        () => {
            if (!teamSubscription) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='Your team requires a premium subscription to access this page!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        },
        [ teamSubscription ]
    );

    // #endregion

    // #region Render Functions

    /**
     * Display a loading state if the subscription check has not been performed yet.
     */
    if (!subscriptionChecked) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <Outlet/>
    );

    // #endregion
}

export default TeamPremiumWrapper;
