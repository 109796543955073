import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    where,
    limit,
    orderBy,
} from 'firebase/firestore';
import {
    IconButton,
} from '@psionic/ui';
import {
    ArrowBack,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import MyCheckInsContextLevel from '@contexts/my-check-ins';
import CheckInSurveyModel from '@models/check-in-survey';
import CheckInSurveyQuestionModel from '@models/check-in-survey-question';
import PrioritiesModel from '@models/priorities';
import ActivityLogModel from '@models/activity-log';
import {
    useTrackLiveDataByQueryInContext,
} from '@hooks/context';
import CurrentSurvey from './components/current-survey';
import AllSurveys from './components/all-surveys';
import localStyles from './my-check-ins.module.scss';

// #region Helper Functions

function getSurveyActivityLogListenerName(surveyID) {
    return `REVIEWEE_SURVEY_ACTIVITY_LOG_${ surveyID }_LISTENER`;
}

// #endregion

function MyCheckIns() {

    // #region Constants

    const surveysPerChunk = 100;

    // #endregion

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const team = TeamContextLevel.use.currentTeam.value();

    const currentSurvey = TeamCheckInContextLevel.use.myCurrentCheckIn.value();

    const myCheckInsCurrentSurveyAPI = MyCheckInsContextLevel.use.currentSurvey.api();

    const currentSurveyQuestionsAPI = MyCheckInsContextLevel.use.currentSurveyQuestions.api();

    const [
        // eslint-disable-next-line no-unused-vars
        priorities,
        // eslint-disable-next-line no-unused-vars
        prioritiesFetched,
    ] = useTrackLiveDataByQueryInContext(
        'MY_PRIORITIES_TRACKER',
        MyCheckInsContextLevel.use.priorities,
        PrioritiesModel,
        [
            where('teamID', '==', team.id),
            where('userID', '==', profile?.id),
            where('completed', '==', false),
        ]
    );

    // #endregion

    // #region State

    const [
        currentTab,
        setCurrentTab,
    ] = useState(TABS.CURRENT_SURVEY);

    const [
        numSurveysToLoad,
        setNumSurveysToLoad,
    ] = useState(surveysPerChunk);

    const [
        surveys,
        setSurveys,
    ] = useState([]);

    const [
        latestActivityLogsBySurveyID,
        setLatestActivityLogsBySurveyID,
    ] = useState({});

    // #endregion

    // #region Effects

    /**
     * Whenever the surveys update, create listeners for each survey's latest activity log.
     */
    useEffect(() => {
        for (const survey of surveys) {
            ActivityLogModel.addListenerByQueryInInstance(
                getSurveyActivityLogListenerName(survey.id),
                `teams/${ team.id }/checkInSurveys/${ survey.id }/activityLogs`,
                [
                    orderBy('timestamp', 'desc'),
                    limit(1),
                ],
                (newActivityLogs) => {
                    const newActivityLog = newActivityLogs[ 0 ];

                    setLatestActivityLogsBySurveyID((prev) => ({
                        ...prev,
                        [ survey.id ]: newActivityLog,
                    }));
                }
            );
        }

        // Cleanup all listeners
        return () => {
            for (const survey of surveys) {
                ActivityLogModel.removeListener(
                    getSurveyActivityLogListenerName(survey.id)
                );
            }
        };
    }, [ surveys ]);

    /**
     * Keep track of the user's surveys.
     */
    useEffect(() => {
        // Subscribe to the user's surveys
        CheckInSurveyModel.addListenerByQueryInInstance(
            'my-surveys-tracker',
            `teams/${ team.id }/checkInSurveys`,
            [
                where('userID', '==', profile?.id),
                orderBy('endDate', 'desc'),
                limit(numSurveysToLoad),
            ],
            setSurveys
        );

        // In the cleanup function, remove the created listener
        return () => {
            CheckInSurveyModel.removeListener('my-surveys-tracker');
        };
    }, [ numSurveysToLoad ]);

    /**
     * Keep the current survey data loaded into context.
     */
    useEffect(() => {
        myCheckInsCurrentSurveyAPI.set(currentSurvey);

        // Subscribe to the current check-in survey's questions
        if (currentSurvey) {
            CheckInSurveyQuestionModel.addListenerByQueryInInstance(
                'current-survey-questions-tracker',
                `teams/${ team.id }/checkInSurveys/${ currentSurvey.id }/checkInSurveyQuestions`,
                [],
                (questions) => {
                    currentSurveyQuestionsAPI.set(questions);
                }
            );
        }

        // In the cleanup function, remove the created listeners
        return () => {
            try {
                CheckInSurveyQuestionModel.removeListener('current-survey-questions-tracker');
            } catch {
                // Do nothing
            }
        };
    }, [ currentSurvey ]);

    // #endregion

    // #region Functions

    const incrementNumSurveysToLoad = () => {
        setNumSurveysToLoad((prev) => prev + surveysPerChunk);
    };

    // #endregion

    // #region Render Functions

    const renderSelectedTab = () => {
        switch (currentTab) {
            case TABS.CURRENT_SURVEY:
                return (
                    <CurrentSurvey/>
                );
            case TABS.ALL_SURVEYS:
                return (
                    <AllSurveys
                        incrementNumSurveysToLoad={incrementNumSurveysToLoad}
                        latestActivityLogsBySurveyID={latestActivityLogsBySurveyID}
                        moreSurveysToLoad={surveys.length >= numSurveysToLoad}
                        surveys={surveys}
                        onFillOutCurrentSurveyClicked={() => setCurrentTab(TABS.CURRENT_SURVEY)}
                    />
                );
            default:
                return null;
        }
    };

    /**
     * Main render.
     */
    return (
        <div
            className={[
                'workspacePage',
                localStyles.workspacePage,
            ].join(' ')}
        >
            <header className={localStyles.header}>
                <IconButton
                    color='white'
                    SvgIcon={ArrowBack}
                    onClick={() => navigate('../')}
                />
                <h1>
                    My Check-Ins
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                <section
                    className={[
                        'tabs',
                        localStyles.tabs,
                    ].join(' ')}
                >
                    <button
                        className='tab'
                        data-selected={currentTab === TABS.CURRENT_SURVEY}
                        type='button'
                        onClick={() => setCurrentTab(TABS.CURRENT_SURVEY)}
                    >
                        <p>
                            Current Survey
                        </p>
                    </button>
                    <button
                        className='tab'
                        data-selected={currentTab === TABS.ALL_SURVEYS}
                        type='button'
                        onClick={() => setCurrentTab(TABS.ALL_SURVEYS)}
                    >
                        <p>
                            All Surveys
                        </p>
                    </button>
                </section>
                <section className={localStyles.tabContent}>
                    {renderSelectedTab()}
                </section>
            </section>
        </div>
    );

    // #endregion
}

export default MyCheckIns;

// #region Constants

const TABS = {
    CURRENT_SURVEY: 'CURRENT_SURVEY',
    ALL_SURVEYS:    'ALL_SURVEYS',
};

// #endregion
