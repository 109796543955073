import PropTypes from 'prop-types';
import {
    MenuItem,
    TextField,
    IconButton,
} from '@mui/material';
import {
    StickyTooltip,
    Button,
} from '@psionic/ui';
import {
    ArrowBack,
    Close,
} from '@mui/icons-material';
import { OTHER_DECK_VALUES, CARD_TYPES } from '@models/bidding-room-config';
import localStyles from './editable-card.module.scss';

// #region Magic Numbers

const SMALL_TEXT_CUTOFF = 255;
const SIZE_RATIO_ADJUSTMENT = 0.8;

// #endregion

/**
 * An editable Eager Lead bidding card.
 */
function EditableCard({
    card,
    onChange,
    deleteCardFn,
    error,
    sizeRatio,
}) {
    // #region Constants

    /**
     * Define the default width of this element.
     */
    const DEFAULT_WIDTH = 300;

    /**
     * Define the default height of this element.
     */
    const DEFAULT_HEIGHT = 426;

    // #endregion

    // #region Functions

    /**
     * Handler for when the "Back To Card Type" button is pressed.
     */
    const goBackToCardTypeSelect = () => {
        onChange({ type: null, value: '' });
    };

    // #endregion

    // #region Render Functions

    /**
     * Render the string value options as menu items.
     */
    const renderStringValueOptions = () => {
        const menuItems = [];

        for (const value of Object.values(OTHER_DECK_VALUES)) {
            menuItems.push(
                <MenuItem
                    key={value}
                    value={value}
                >
                    {value}
                </MenuItem>
            );
        }

        return menuItems;
    };

    /**
     * Render the correct content based on the card type.
     */
    const renderStateContent = () => {
        switch (card.type) {
            case CARD_TYPES.NUMBER:
                return renderNumType();
            case CARD_TYPES.STRING:
                return renderStringType();
            default:
                return renderTypeSelect();
        }
    };

    /**
     * Render the type select content.
     */
    const renderTypeSelect = () => { return (
        <div className={localStyles.content}>
            <h5
                className={`
                    ${ localStyles.selectText }
                    ${ DEFAULT_WIDTH * sizeRatio > SMALL_TEXT_CUTOFF ? null : localStyles.smallText }
                `}
            >
                Select a card type:
            </h5>
            <div className={localStyles.selectButtonsSection}>
                <Button
                    height='50px'
                    variant='outlined'
                    width='100%'
                    darkMode
                    onClick={() => { return onChange({ type: CARD_TYPES.NUMBER, value: '' }); }}
                >
                    Number
                </Button>
                <Button
                    height='50px'
                    variant='outlined'
                    width='100%'
                    darkMode
                    onClick={() => { return onChange({ type: CARD_TYPES.STRING, value: '' }); }}
                >
                    Other
                </Button>
            </div>
        </div>
    ); };

    /**
     * Render the num type content.
     */
    const renderNumType = () => { return (
        <div
            className={localStyles.valueContent}
            style={{ height: `${ DEFAULT_HEIGHT * sizeRatio * SIZE_RATIO_ADJUSTMENT }px` }}
        >
            <Button
                className={localStyles.backButton}
                variant='text'
                darkMode
                onClick={goBackToCardTypeSelect}
            >
                <span className={localStyles.buttonContent}>
                    <ArrowBack/>
                    Card Type
                </span>
            </Button>
            <div className={localStyles.inputSection}>
                <input
                    type='text'
                    value={card.value}
                    className={`
                            ${ localStyles.cardValueInput }
                            ${ error ? localStyles.cardValueInputError : null }
                        `}
                    onChange={(event) => {
                        onChange({
                            type:  CARD_TYPES.NUMBER,
                            value: event.target.value,
                        });
                    }}
                />
                <p className={localStyles.errorMessage}>
                    {error}
                </p>
            </div>
        </div>
    ); };

    /**
     * Render the string type content.
     */
    const renderStringType = () => { return (
        <div
            className={localStyles.valueContent}
            style={{ height: `${ DEFAULT_HEIGHT * sizeRatio * SIZE_RATIO_ADJUSTMENT }px` }}
        >
            <Button
                className={localStyles.backButton}
                variant='text'
                darkMode
                onClick={goBackToCardTypeSelect}
            >
                <span className={localStyles.buttonContent}>
                    <ArrowBack/>
                    Card Type
                </span>
            </Button>
            <div className={localStyles.inputSection}>
                <TextField
                    className={localStyles.selectInput}
                    label='Value'
                    value={card.value}
                    select
                    onChange={(event) => {
                        onChange({
                            type:  CARD_TYPES.STRING,
                            value: event.target.value,
                        });
                    }}
                >
                    {renderStringValueOptions()}
                </TextField>
                <p className={localStyles.errorMessageSelect}>
                    {error}
                </p>
            </div>
        </div>
    ); };

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.card}
            style={{
                width:  `${ DEFAULT_WIDTH * sizeRatio }px`,
                height: `${ DEFAULT_HEIGHT * sizeRatio }px`,
            }}
        >
            <div
                className={localStyles.header}
            >
                <div className={localStyles.deleteButtonWrapper}>
                    <StickyTooltip content='Delete Card'>
                        <IconButton
                            className={localStyles.deleteCardButton}
                            onClick={deleteCardFn}
                        >
                            <Close/>
                        </IconButton>
                    </StickyTooltip>
                </div>
            </div>
            {renderStateContent()}
            <div className={localStyles.footer}/>
        </div>
    );

    // #endregion
}

EditableCard.propTypes = {
    /**
     * The card to represent.
     */
    card:         PropTypes.object.isRequired,
    /**
     * Callback for when the card should be deleted. This function should not take any
     * arguments.
     */
    deleteCardFn: PropTypes.func.isRequired,
    /**
     * The error to display on the card.
     */
    error:        PropTypes.string,
    /**
     * Callback for when the card is changed. The function's only argument should
     * be the new value.
     */
    onChange:     PropTypes.func.isRequired,
    /**
     * The size ratio for the card.
     */
    sizeRatio:    PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

EditableCard.defaultProps = {
    sizeRatio: 1.0,
    error:     undefined,
};

export default EditableCard;
