import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTrackFunction } from '@unifire-js/hooks';
import {
    find,
    difference,
    map,
    filter,
} from 'lodash';
import {
    StickyTooltip,
    IconButton,
} from '@psionic/ui';
import {
    Check,
    Close,
} from '@mui/icons-material';
import { OPTION_TYPES } from '@utils/constants';
import {
    TeammateSelectInput,
    FilledTextField,
} from '@components/inputs';
import localStyles from './minute-input.module.scss';

/**
 * Input for minutes in 15 minutes.
 */
function MinuteInput({
    minute,
    teammates,
    groups,
    onSave,
    onCancel,
}) {
    // #region State

    /**
     * Store a local copy of the minute to edit.
     */
    const [
        localMinute,
        setLocalMinute,
    ] = useState(minute);

    // #endregion

    // #region Effects

    // #endregion Effects

    // #region Tracked Functions

    /**
     * Tracked save function.
     */
    const [
        saving,
        save,
    ] = useTrackFunction(() => { return onSave(localMinute); });

    /**
     * Tracked cancel function.
     */
    const [
        canceling,
        cancel,
    ] = useTrackFunction(onCancel);

    // #endregion

    // #region Memoized Values

    /**
     * Memoized owner from the teammates list.
     */
    const owner = useMemo(() => { return find(teammates, [
        'id',
        localMinute.owner,
    ]); }, [
        localMinute,
        teammates,
    ]);

    /**
     * Memoized flag indicating whether a tracked function is running.
     */
    const actionRunning = useMemo(() => { return saving || canceling; }, [
        saving,
        canceling,
    ]);

    /**
     * Memoized error flag.
     */
    const error = useMemo(
        () =>
        { return Object.values(localMinute?.requires || {}).length < 1
            || !localMinute.message; },
        [ localMinute ]
    );

    /**
     * Memoized required teammates.
     */
    const requiredTeammates = useMemo(
        () =>
        { return filter(
            Object.values(localMinute?.requires) || [],
            (requiredTeammate) => {
                if (requiredTeammate.type === OPTION_TYPES.TEAMMATE) {
                    return find(teammates, [
                        'id',
                        requiredTeammate.value,
                    ]);
                }

                if (requiredTeammate.type === OPTION_TYPES.GROUP) {
                    return find(groups, [
                        'id',
                        requiredTeammate.value,
                    ]);
                }

                return true;
            }
        ); },
        [ localMinute ]
    );

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.minuteInput}>
            <section className={localStyles.inputs}>
                <TeammateSelectInput
                    groups={groups}
                    label='Requires'
                    teammates={difference(teammates, [ owner ])}
                    value={requiredTeammates}
                    setValue={(newRequires) => { return setLocalMinute((prevState) => { return {
                        ...prevState,
                        requires: map(
                            newRequires,
                            (requirement) => { return { type: requirement.type, value: requirement.value }; }
                        ),
                    }; }); }}
                    isMulti
                />
                <FilledTextField
                    label='Message'
                    value={localMinute.message}
                    setValue={(value) => { return setLocalMinute((prevState) => { return {
                        ...prevState,
                        message: value,
                    }; }); }}
                />
            </section>
            <section className={localStyles.actions}>
                <StickyTooltip content='Save Changes'>
                    <IconButton
                        className='acceptButton'
                        color='approve'
                        disabled={actionRunning || error}
                        SvgIcon={Check}
                        onClick={save}
                    />
                </StickyTooltip>
                <StickyTooltip content='Cancel Changes'>
                    <IconButton
                        className='declineButton'
                        color='reject'
                        disabled={actionRunning}
                        SvgIcon={Close}
                        onClick={cancel}
                    />
                </StickyTooltip>
            </section>
        </div>
    );

    // #endregion
}

MinuteInput.propTypes = {
    /**
     * Array of groups the minute could be associated with.
     */
    groups:    PropTypes.arrayOf(PropTypes.object),
    /**
     * The minute object that is being modified by the input.
     */
    minute:    PropTypes.object.isRequired,
    /**
     * Callback for when the "Cancel" button is clicked.
     */
    onCancel:  PropTypes.func.isRequired,
    /**
     * Callback for when the "Save" button is clicked.
     */
    onSave:    PropTypes.func.isRequired,
    /**
     * Array of teammates to allow the user to select from for the minute input.
     */
    teammates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MinuteInput.defaultProps = {
    groups: undefined,
};

export default MinuteInput;
