import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Component which triggers some action whenever it is scrolled into
 * view.
 */
const LazyLoadTrigger = ({
    action,
    debounceTime,
    children,
    ignore,
}) => {
    /*********
     * HOOKS *
     *********/

    /**
     * Track whether the component is visible on the screen.
     */
    const [isIntersecting, setIntersecting] = useState(false);

    /**
     * Track whether the action is on "cooldown".
     */
    const [actionOnCooldown, setActionOnCooldown] = useState(false);

    /**
     * Track a ref to the component wrapper itself.
     */
    const componentRef = useRef();

    /**
     * Track a ref to the debounce timeout.
     */
    const timeoutRef = useRef();

    /**
     * When the component mounts, create an intersection observer. When the component unmounts, destroy the
     * intersection observer.
     */
    useEffect(() => {
        let observer;

        if (!ignore) {
            observer = new IntersectionObserver(
                ([entry]) => setIntersecting(entry.isIntersecting)
            );
            observer.observe(componentRef.current);
        }

        // Remove the observer when the component unmounts
        return () => {
            if (observer) {
                observer.disconnect();
            }
        }
    }, [ignore]);

    /**
     * When the intersecting flag toggles to true, perform the given action.
     */
    useEffect(() => {
        if (isIntersecting && !actionOnCooldown) {
            action();
            setActionOnCooldown(true);
            timeoutRef.current = setTimeout(() => setActionOnCooldown(false), debounceTime);
        }
    }, [isIntersecting, actionOnCooldown]);

    /**
     * When the component unmounts, cancel any timeout that may still be running.
     */
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    /*************
     * FUNCTIONS *
     *************/

    /********************
     * RENDER FUNCTIONS *
     ********************/

    /**
     * Main render.
     */
    return (
        <section ref={componentRef}>
            {children}
        </section>
    );
};

LazyLoadTrigger.propTypes = {
    /**
     * The callback for when the trigger component is intersecting with the viewport.
     */
    action: PropTypes.func.isRequired,
    /**
     * The amount of time to wait in between actions (where the action goes on "cooldown").
     */
    debounceTime: PropTypes.number.isRequired,
    /**
     * Any children to render within the trigger component wrapper.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    /**
     * Flag indicating whether the observer should be ignored.
     */
    ignore: PropTypes.bool,
};

LazyLoadTrigger.defaultProps = {
    ignore: false,
};

export default LazyLoadTrigger;