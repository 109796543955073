import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
    Edit,
    Delete,
} from '@mui/icons-material';
import {
    usePseudoSelectors,
    IconButton,
} from '@psionic/ui';
import StarterKit from '@tiptap/starter-kit';
import { EditorContent, useEditor } from '@tiptap/react';
import { Reactions } from '@components/core';
import { UserAvatar } from '@components/avatars';
import localStyles from './comment.module.scss';

/**
 * UI component for displaying a comment.
 */
function Comment({
    value,
    author,
    datePosted,
    reactions,
    onReactionClicked,
    onEditClicked,
    onDeleteClicked,
    users,
    userID,
}) {

    // #region Misc Hooks

    const [
        pseudoSelectorProps,
        pseudoSelectorStates,
    ] = usePseudoSelectors();

    const editor = useEditor({
        content:    value,
        extensions: [ StarterKit ],
        editable:   false,
    });

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.comment}>
            <div className={localStyles.leftSide}>
                <UserAvatar
                    hasTooltip={false}
                    imageURL={author?.avatarURL}
                    size='extra-extra-small'
                    userEmail={author?.email}
                    userName={author?.displayName}
                />
            </div>
            <div className={localStyles.rightSide}>
                <header>
                    <span className={localStyles.authorName}>
                        {author?.displayName || author?.email || 'Unknown'}
                    </span>
                    {
                        datePosted
                            ? (
                                <span className={localStyles.datePosted}>
                                    {DateTime.fromJSDate(datePosted).toFormat('LLL d')}
                                </span>
                            )
                            : null
                    }
                </header>
                <div
                    className={localStyles.content}
                    {...pseudoSelectorProps}
                >
                    <EditorContent editor={editor}/>
                    <div className={localStyles.actions}>
                        {
                            onEditClicked
                            && pseudoSelectorStates.isHovered
                            && author?.id === userID
                                ? (
                                    <IconButton
                                        color='mediumEmphasis'
                                        size={18}
                                        SvgIcon={Edit}
                                        onClick={onEditClicked}
                                    />
                                )
                                : null
                        }
                        {
                            onDeleteClicked
                            && pseudoSelectorStates.isHovered
                            && author?.id === userID
                                ? (
                                    <IconButton
                                        color='reject'
                                        size={18}
                                        SvgIcon={Delete}
                                        onClick={onDeleteClicked}
                                    />
                                )
                                : null
                        }
                    </div>
                </div>
                <Reactions
                    reactions={reactions}
                    userID={userID}
                    users={users}
                    onReactionClicked={onReactionClicked}
                />
            </div>
        </div>
    );

    // #endregion
}

Comment.propTypes = {
    /**
     * The author data.
     */
    author: PropTypes.shape({
        /**
         * The author's avatar URL.
         */
        avatarURL:   PropTypes.string,
        /**
         * The author's display name.
         */
        displayName: PropTypes.string,
        /**
         * The author's email.
         */
        email:       PropTypes.string,
        /**
         * The author's ID.
         */
        id:          PropTypes.string,
    }),
    /**
     * The date the comment was posted, as a JS Date object.
     */
    datePosted: PropTypes.object.isRequired,
    /**
     * The reactions data.
     */
    reactions:  PropTypes.shape({
        /**
         * The native emoji icon.
         */
        native: PropTypes.string.isRequired,
        /**
         * The IDs of users who had this reaction.
         */
        users:  PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    /**
     * The comment's content.
     */
    value:             PropTypes.string.isRequired,
    /**
     * The callback for when a reaction is clicked.
     */
    onReactionClicked: PropTypes.func.isRequired,
    /**
     * The callback for when the edit button is clicked.
     */
    onEditClicked:     PropTypes.func,
    /**
     * The callback for when the delete button is clicked.
     */
    onDeleteClicked:   PropTypes.func,
    /**
     * The users data.
     */
    users:             PropTypes.arrayOf(PropTypes.shape({
        /**
         * The user's ID.
         */
        id:          PropTypes.string.isRequired,
        /**
         * The user's display name.
         */
        displayName: PropTypes.string.isRequired,
    })).isRequired,
    /**
     * The current user's ID.
     */
    userID: PropTypes.string.isRequired,
};

Comment.defaultProps = {
    author:          undefined,
    reactions:       undefined,
    onEditClicked:   undefined,
    onDeleteClicked: undefined,
};

export default Comment;
