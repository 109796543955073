import { useState } from 'react';
import PropTypes from 'prop-types';
import TeamGroupModel from '@models/team-group';
import {
    uploadGroupAvatar,
    deleteGroupAvatar,
} from '@services/group';
import { GroupInfo } from '@components/details';
import { GroupInput } from '@components/inputs';
import localStyles from './group-control.module.scss';

/**
 * Control for viewing and editing a group.
 */
function GroupControl({
    group,
    teammates,
    currentTeam,
}) {
    // #region State

    /**
     * Track whether the group is in edit mode or not.
     */
    const [
        editMode,
        setEditMode,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    /**
     * Callback to update the given group.
     */
    const onUpdateGroup = async(data, newAvatar) => {
        setEditMode(null);
        await TeamGroupModel.writeToID(
            data.id,
            data
        );

        if (newAvatar) {
            await uploadGroupAvatar(currentTeam.id, data.id, newAvatar);
        }
    };

    /**
     * Callback to delete the group.
     */
    const onDeleteGroup = async() => {
        try {
            deleteGroupAvatar(currentTeam.id, group.id);
        } catch (err) {
            // This is not critical, and may just be an issue of the file not existing
        }
        await TeamGroupModel.deleteByID(group.id);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.groupControl}>
            {
                editMode
                    ? (
                        <GroupInput
                            group={group}
                            teammates={teammates}
                            onCancel={() => { return setEditMode(false); }}
                            onSave={onUpdateGroup}
                        />
                    )
                    : (
                        <GroupInfo
                            group={group}
                            teammates={teammates}
                            onDelete={onDeleteGroup}
                            onEdit={() => { return setEditMode(true); }}
                        />
                    )
            }
        </div>
    );

    // #endregion
}

GroupControl.propTypes = {
    currentTeam: PropTypes.object.isRequired,
    group:       PropTypes.object.isRequired,
    teammates:   PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GroupControl;
