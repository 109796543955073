import PropTypes from 'prop-types';
import localStyles from './block-menu-button.module.scss';

/**
 * A button for selecting an element type for a block.
 */
function BlockMenuButton({
    imageSrc,
    name,
    description,
    onClick,
}) {

    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <button
            className={localStyles.blockMenuButton}
            type='button'
            onClick={onClick}
        >
            <img
                alt={`${ name } element`}
                src={imageSrc}
            />
            <div className={localStyles.textContent}>
                <h5>{name}</h5>
                <p>{description}</p>
            </div>
        </button>
    );

    // #endregion
}

BlockMenuButton.propTypes = {
    /**
     * A short description of the element type.
     */
    description: PropTypes.string.isRequired,
    /**
     * The image source to display to represent the element type.
     */
    imageSrc:    PropTypes.string.isRequired,
    /**
     * The name of the element type.
     */
    name:        PropTypes.string.isRequired,
    /**
     * The callback for when the button is clicked.
     */
    onClick:     PropTypes.func.isRequired,
};

BlockMenuButton.defaultProps = {

};

export default BlockMenuButton;
