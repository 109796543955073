import { useState } from 'react';
import PropTypes from 'prop-types';
import { Deferred } from '@unifire-js/async';
import {
    StickyTooltip,
    IconButton,
    Button,
    Dialog,
} from '@psionic/ui';
import {
    Delete,
    ArrowUpward,
    ArrowDownward,
} from '@mui/icons-material';
import { useUserContext } from '@unifire-js/firebase/auth';
import AssociationModel, { ROLES } from '@models/association';
import InviteModel from '@models/invite';
import {
    UserAvatar,
} from '@components/avatars';
import localStyles from './member-item.module.scss';

function MemberItem({
    member,
    memberAssociation,
    memberInvite,
}) {
    // #region Context

    const { profile } = useUserContext();

    // #endregion

    // #region State

    const [
        changingRole,
        setChangingRole,
    ] = useState(false);

    const [
        removingUser,
        setRemovingUser,
    ] = useState(false);

    const [
        confirmingRemove,
        setConfirmingRemove,
    ] = useState(null);

    // #endregion

    // #region Functions

    const revokeInvitation = async() => {
        setRemovingUser(true);

        const confirmation = new Deferred();
        setConfirmingRemove(confirmation);

        try {
            await confirmation.promise;

            await InviteModel.deleteByID(memberInvite?.id);
        } catch {
            // Do nothing
        } finally {
            setConfirmingRemove(null);
            setRemovingUser(false);
        }

        setRemovingUser(false);
    };

    const removeUserFromTeam = async() => {
        setRemovingUser(true);

        const confirmation = new Deferred();
        setConfirmingRemove(confirmation);

        try {
            await confirmation.promise;

            await AssociationModel.deleteByID(memberAssociation?.id);
        } catch {
            // Do nothing
        } finally {
            setConfirmingRemove(null);
            setRemovingUser(false);
        }
    };

    const promoteUserToAdmin = async() => {
        setChangingRole(true);

        await AssociationModel.writeToID(
            memberAssociation?.id,
            { role: ROLES.ADMIN },
            { mergeWithExistingValues: true }
        );

        setChangingRole(false);
    };

    const demoteUserToMember = async() => {
        setChangingRole(true);

        await AssociationModel.writeToID(
            memberAssociation?.id,
            { role: ROLES.MEMBER },
            { mergeWithExistingValues: true }
        );

        setChangingRole(false);
    };

    // #endregion

    // #region Render Functions

    const renderInviteActions = () => {
        return (
            <div className={localStyles.actions}>
                <StickyTooltip content='Revoke Invitation'>
                    <IconButton
                        color='reject'
                        SvgIcon={Delete}
                        onClick={revokeInvitation}
                    />
                </StickyTooltip>
            </div>
        );
    };

    const renderMemberActions = () => {
        if (member?.id === profile?.id) {
            return null;
        }

        if (memberAssociation?.role === ROLES.OWNER) {
            return null;
        }

        return (
            <div className={localStyles.actions}>
                {
                    memberAssociation?.role === ROLES.MEMBER
                        ? (
                            <StickyTooltip content='Promote to Admin'>
                                <IconButton
                                    color='primary'
                                    disabled={removingUser}
                                    SvgIcon={ArrowUpward}
                                    onClick={promoteUserToAdmin}
                                />
                            </StickyTooltip>
                        )
                        : (
                            <StickyTooltip content='Demote to Member'>
                                <IconButton
                                    color='primary'
                                    disabled={removingUser}
                                    SvgIcon={ArrowDownward}
                                    onClick={demoteUserToMember}
                                />
                            </StickyTooltip>
                        )
                }
                <StickyTooltip content='Remove User From Team'>
                    <IconButton
                        color='reject'
                        disabled={changingRole}
                        SvgIcon={Delete}
                        onClick={removeUserFromTeam}
                    />
                </StickyTooltip>
            </div>
        );
    };

    const renderConfirmRemoveDialog = () => {
        return (
            <Dialog
                className='dialog withIcon'
                closeOnClickOutside={false}
                isOpen={confirmingRemove}
                setIsOpen={setConfirmingRemove}
            >
                <div className='leftSide'>
                    <Delete className='redIcon'/>
                </div>
                <div className='rightSide'>
                    <h1>
                        Confirm
                        {' '}
                        {memberInvite ? 'Revoke Invitation' : 'Remove User'}
                    </h1>
                    <p>
                        Are you sure you want to
                        {' '}
                        {
                            memberInvite
                                ? `revoke the invitation for ${ member?.email }?`
                                : `remove ${ member?.displayName || member?.email } from the team?`
                        }
                    </p>
                    <div className='actionSection'>
                        <Button
                            variant='text'
                            darkMode
                            onClick={confirmingRemove?.reject}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            darkMode
                            onClick={confirmingRemove?.resolve}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    };

    /**
     * Main render.
     */
    return (
        <>
            {confirmingRemove ? renderConfirmRemoveDialog() : null}
            <div className={localStyles.memberItem}>
                <UserAvatar
                    imageURL={member?.avatarURL}
                    size='small'
                    userEmail={member?.email}
                    userName={member?.displayName}
                />
                <p>
                    {member?.displayName || member?.email}
                </p>
                {memberInvite ? renderInviteActions() : renderMemberActions()}
            </div>
        </>
    );

    // #endregion
}

MemberItem.propTypes = {
    /**
     * The member to display.
     */
    member:            PropTypes.object.isRequired,
    /**
     * The member's association.
     */
    memberAssociation: PropTypes.object,
    /**
     * The member's invite.
     */
    memberInvite:      PropTypes.object,
};

MemberItem.defaultProps = {
    memberAssociation: undefined,
    memberInvite:      undefined,
};

export default MemberItem;
