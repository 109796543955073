import { useMemo } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { DateTime } from 'luxon';
import { StickyTooltip } from '@psionic/ui';
import pluralize from 'pluralize';
import {
    Cancel,
    Undo,
} from '@mui/icons-material';
import { TeamAvatar } from '@components/avatars';
import { FancyIconButton } from '@components/buttons';
import localStyles from './subscription-info.module.scss';

// #region Magic Numbers

const PENNIES_PER_DOLLAR = 100.0;
const DECIMALS_AFTER_DOLLAR = 2;

// #endregion

/**
 * Subscription info detail.
 */
function SubscriptionInfo({
    team,
    subscription,
    onCancelSubscription,
    onUndoCancellation,
    subscriptionPurchasedByUser,
}) {
    // #region Memoized Values

    const baseFeeSubscriptionItem = useMemo(() => {
        if (subscription) {
            return find(
                subscription?.items || [],
                (item) => item?.price?.recurring?.usage_type === 'licensed'
            );
        }
    }, [ subscription ]);

    const perUserSubscriptionItem = useMemo(() => {
        if (subscription) {
            return find(
                subscription?.items || [],
                (item) => item?.price?.recurring?.usage_type === 'metered'
            );
        }
    }, [ subscription ]);

    // #endregion

    // #region Render Functions

    /**
     * Foreign subscription badge.
     */
    const foreignSubscriptionBadge = (
        <span className={localStyles.foreignSubscriptionBadge}>
            <StickyTooltip content='You will be able to purchase another subscription after this one expires.'>
                Purchased by Previous Owner
            </StickyTooltip>
        </span>
    );

    /**
     * Trialing badge.
     */
    const trialingBadge = (
        <span className={localStyles.trialingBadge}>
            Trialing
        </span>
    );

    /**
     * Canceled badge.
     */
    const canceledBadge = (
        <span className={localStyles.canceledBadge}>
            Canceled
        </span>
    );

    /**
     * Main render.
     */
    return (
        <div className={localStyles.subscriptionInfo}>
            <table>
                <thead>
                    <tr>
                        <th>Team</th>
                        <th>Plan</th>
                        <th>Price</th>
                        <th>Billing Frequency</th>
                        <th>{subscription?.cancelAtPeriodEnd ? 'Plan Ends' : 'Next Bill Date'}</th>
                        {
                            subscription && !subscription.cancelAtPeriodEnd && subscriptionPurchasedByUser
                                ? <th>Cancel Subscription</th>
                                : null
                        }
                        {
                            subscription?.cancelAtPeriodEnd && subscriptionPurchasedByUser
                                ? <th>Undo Cancellation</th>
                                : null
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className={localStyles.teamInfo}>
                                <TeamAvatar
                                    imageURL={team.avatarURL}
                                    size='extra-small'
                                    teamName={team.name}
                                />
                                <span>{team.name}</span>
                            </div>
                        </td>
                        <td>{baseFeeSubscriptionItem?.plan?.nickname || 'Free'}</td>
                        <td>
                            <span>
                                {`${ baseFeeSubscriptionItem?.plan?.currency?.toUpperCase() || 'USD' } ${ ((baseFeeSubscriptionItem?.price?.unit_amount || 0) / PENNIES_PER_DOLLAR).toFixed(DECIMALS_AFTER_DOLLAR) }`}
                            </span>
                            {
                                perUserSubscriptionItem
                                    ? (
                                        <span>
                                            {` + ${ ((perUserSubscriptionItem?.price?.unit_amount || 0) / PENNIES_PER_DOLLAR).toFixed(DECIMALS_AFTER_DOLLAR) } per user`}
                                        </span>
                                    )
                                    : null
                            }
                        </td>
                        <td>
                            {
                                baseFeeSubscriptionItem?.plan?.interval_count
                                    ? `Every ${ baseFeeSubscriptionItem?.plan?.interval_count === 1 ? '' : baseFeeSubscriptionItem?.plan?.interval_count } ${ pluralize('month', baseFeeSubscriptionItem?.plan?.interval_count) }`
                                    : '-'
                            }
                        </td>
                        <td>{subscription?.currentPeriodEnd ? DateTime.fromSeconds(subscription.currentPeriodEnd).toFormat('MMM d, y') : '-'}</td>
                        {
                            subscription && !subscription.cancelAtPeriodEnd && subscriptionPurchasedByUser
                                ? (
                                    <td>
                                        <FancyIconButton
                                            className={`${ localStyles.cancelButton } ${ localStyles.actionButton }`}
                                            Icon={Cancel}
                                            tooltip='Cancel Subscription'
                                            onClick={onCancelSubscription}
                                        />
                                    </td>
                                )
                                : null
                        }
                        {
                            subscription?.cancelAtPeriodEnd && subscriptionPurchasedByUser
                                ? (
                                    <td>
                                        <FancyIconButton
                                            className={`${ localStyles.undoButton } ${ localStyles.actionButton }`}
                                            Icon={Undo}
                                            tooltip='Undo Cancellation'
                                            onClick={onUndoCancellation}
                                        />
                                    </td>
                                )
                                : null
                        }
                    </tr>
                </tbody>
            </table>
            <div className={localStyles.badges}>
                {subscriptionPurchasedByUser || !subscription ? null : foreignSubscriptionBadge}
                {subscription?.status === 'trialing' ? trialingBadge : null}
                {subscription?.cancelAtPeriodEnd ? canceledBadge : null}
            </div>
        </div>
    );

    // #endregion
}

SubscriptionInfo.propTypes = {
    /**
     * Callback for when the "Cancel Subscription" button is clicked.
     */
    onCancelSubscription:        PropTypes.func.isRequired,
    /**
     * Callback for when the "Undo Cancellation" button is clicked.
     */
    onUndoCancellation:          PropTypes.func.isRequired,
    /**
     * The subscription object from Stripe.
     */
    subscription:                PropTypes.object,
    /**
     * Flag indicating whether the subscription was purchased by the signed-in user or not.
     */
    subscriptionPurchasedByUser: PropTypes.bool.isRequired,
    /**
     * The team the subscription belongs to.
     */
    team:                        PropTypes.object.isRequired,
};

SubscriptionInfo.defaultProps = {
    subscription: null,
};

export default SubscriptionInfo;
