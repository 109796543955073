import { useMemo } from 'react';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    useMediaQuery,
} from '@mui/material';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import TeamContextLevel from '@contexts/team';
import {
    submitVote,
} from '@services/bidding-room';
import { BiddingRoomLayout } from '@components/page-layouts';
import { BiddingCard } from '@components/cards';
import localStyles from './bid-selection.module.scss';

/**
 * View for the user to select their bid.
 */
const BidSelection = ({

}) => {
    /*********
     * HOOKS *
     *********/

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the bidding room's public info context value.
     */
    const biddingRoomPublicInfo = BiddingRoomContextLevel.use.biddingRoomPublicInfo.value();

    /**
     * Use the bid card context API.
     */
    const bidCardContextAPI = BiddingRoomContextLevel.use.bidCard.api();

    /**
     * Use the "has admin permissions" context value.
     */
    const hasAdminPermissions = TeamContextLevel.use.hasAdminPermissions.value();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Set up media query to flip between desktop and mobile rendering.
     */
    const isMobileView = useMediaQuery('(max-width: 1200px)');

    /*************
     * FUNCTIONS *
     *************/

    /**
     * Handler for when a bidding card is clicked.
     */
    const onBiddingCardClicked = (cardIndex) => {
        bidCardContextAPI.set(biddingRoomPublicInfo.deck[cardIndex]);
        if (profile?.id && currentTeam?.id) {
            submitVote(profile.id, currentTeam.id, cardIndex);
        }
    };

    /********************
     * RENDER FUNCTIONS *
     ********************/

    /**
     * Memoized renders of all of the bidding cards available.
     */
    const biddingCards = useMemo(() => {
        const listItems = [];
        for (let i = 0; i < biddingRoomPublicInfo?.deck?.length; i++) {
            const card = biddingRoomPublicInfo.deck[i];
            listItems.push(
                <li key={`card-${i}`}>
                    <BiddingCard
                        card={card}
                        sizeRatio={isMobileView ? 0.4 : 0.7}
                        onClick={() => onBiddingCardClicked(i)}
                    />
                </li>
            )
        }
        return listItems;
    }, [biddingRoomPublicInfo, isMobileView]);

    /**
     * Main render.
     */
    return (
        <BiddingRoomLayout
            title="Select Your Bid"
            hasAdminPermissions={hasAdminPermissions}
        >
            <section className={localStyles.votingSection}>
                <ul>
                    {biddingCards}
                </ul>
            </section>
        </BiddingRoomLayout>
    );
};

export default BidSelection;