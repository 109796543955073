import PropTypes from 'prop-types';
import {
    Tooltip,
} from '@mui/material';
import localStyles from './action-tooltip.module.scss';

/**
 * An action tooltip for displaying controls to the user.
 */
function ActionTooltip({
    lines,
    hidden,
    children,
}) {
    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * The memoized renders of the given lines.
     */
    const lineRenders = lines.map((line, index) => (
        <p key={index}>
            <span>{line.action}</span>&nbsp;
            <span>{line.result}</span>
        </p>
    ));

    /**
     * The action tooltip popper component.
     */
    const actionTooltip = (
        <div className={localStyles.actionTooltip}>
            {lineRenders}
        </div>
    );

    /**
     * Main render.
     */
    return (
        <Tooltip
            title={hidden ? '' : actionTooltip}
            classes={{
                tooltip: localStyles.tooltipOverride,
            }}
            enterDelay={500}
            disableInteractive
            PopperProps={{
                disablePortal: true,
            }}
        >
            {children}
        </Tooltip>
    );

    // #endregion
}

ActionTooltip.propTypes = {
    /**
     * Lines to display within the action tooltip.
     */
    lines: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.string,
            result: PropTypes.string,
        }),
    ).isRequired,
    /**
     * Flag indicating whether the tooltip should be hidden.
     */
    hidden:   PropTypes.bool,
    /**
     * The children to wrap with the action tooltip.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

ActionTooltip.defaultProps = {
    hidden: false,
};

export default ActionTooltip;
