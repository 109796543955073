import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
    Settings,
    Grading,
    HistoryEdu,
    CheckCircle,
} from '@mui/icons-material';
import {
    StickyTooltip,
} from '@psionic/ui';
import { useInterval } from '@hooks/timing';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import { ROLES } from '@models/association';
import { formatToLargestUnit } from '@utils/time-utils';
import localStyles from './index.module.scss';

// #region Magic Numbers

// eslint-disable-next-line no-magic-numbers
const ONCE_A_MINUTE = 1000 * 60;

// #endregion

function CheckInPage() {

    // #region Constants

    const PAGES = {
        MANAGE:           'MANAGE',
        REVIEW_CHECK_INS: 'REVIEW_CHECK_INS',
        MY_CHECK_INS:     'MY_CHECK_INS',
    };

    // #endregion

    // #region Context

    const userAssociation = TeamContextLevel.use.userAssociation.value();

    const checkInServiceInfo = TeamCheckInContextLevel.use.checkInServiceInfo.value();

    const myCurrentCheckIn = TeamCheckInContextLevel.use.myCurrentCheckIn.value();

    const myReviewAssociations = TeamCheckInContextLevel.use.myReviewAssociations.value();

    // #endregion

    // #region State

    const [
        currentDate,
        setCurrentDate,
    ] = useState(DateTime.local());

    // #endregion

    // #region Effects

    /**
     * Every minute, update the current date.
     */
    useInterval(
        () => {
            setCurrentDate(DateTime.local());
        },
        ONCE_A_MINUTE
    );

    // #endregion

    // #region Memoized Values

    const isAdminOrOwner = useMemo(() => {
        return [
            ROLES.ADMIN,
            ROLES.OWNER,
        ].includes(userAssociation?.role);
    }, [ userAssociation ]);

    const pagesShown = useMemo(() => {
        const pages = [];

        if (isAdminOrOwner) {
            pages.push(PAGES.MANAGE);
        }

        if (myReviewAssociations?.length > 0) {
            pages.push(PAGES.REVIEW_CHECK_INS);
        }

        pages.push(PAGES.MY_CHECK_INS);

        return pages;
    }, [ isAdminOrOwner ]);

    const timeLeftBeforeCheckInDue = useMemo(() => {
        if (!myCurrentCheckIn?.endDate || !currentDate) {
            return null;
        }

        return DateTime.fromJSDate(myCurrentCheckIn?.endDate)?.diff(currentDate)
            .shiftTo('days', 'hours', 'minutes');
    }, [
        myCurrentCheckIn,
        currentDate,
    ]);

    // #endregion

    // #region Render Functions

    const renderEmptyState = () => {
        return (
            <div className={localStyles.emptyState}>
                <p>
                    There's nothing for you to see here, yet! Check back later or ask your manager
                    for more information.
                </p>
            </div>
        );
    };

    const renderTimeRemaining = () => {
        if (!timeLeftBeforeCheckInDue) {
            return null;
        }

        if (myCurrentCheckIn?.dateSubmitted) {
            return (
                <p className={localStyles.submittedText}>
                    <CheckCircle/>
                    Submitted
                </p>
            );
        }

        const formattedDuration = formatToLargestUnit(timeLeftBeforeCheckInDue);

        let colorToUse = 'rgba(255, 255, 255, 0.4)';

        if (timeLeftBeforeCheckInDue.as('days') <= 2) {
            colorToUse = '#ffc652';
        }

        if (timeLeftBeforeCheckInDue.as('days') <= 1) {
            colorToUse = '#FF495C';
        }

        if (timeLeftBeforeCheckInDue) {
            return (
                <p
                    className={localStyles.dueDate}
                    style={{ color: colorToUse }}
                >
                    Due in
                    {' '}
                    {formattedDuration}
                </p>
            );
        }
    };

    const renderNavigationOptions = () => {
        return (
            <div className={localStyles.navigationOptions}>
                {
                    pagesShown.includes(PAGES.MY_CHECK_INS)
                        ? (
                            <Link
                                className={localStyles.navigationLink}
                                to='my-check-ins'
                            >
                                <HistoryEdu/>
                                My Check-Ins
                                { myCurrentCheckIn?.endDate ? renderTimeRemaining() : null}
                            </Link>
                        )
                        : null
                }
                {
                    pagesShown.includes(PAGES.REVIEW_CHECK_INS)
                        ? (
                            <Link
                                className={localStyles.navigationLink}
                                to='review'
                            >
                                <Grading/>
                                Review
                            </Link>
                        )
                        : null
                }
                {
                    pagesShown.includes(PAGES.MANAGE)
                        ? (
                            <Link
                                className={localStyles.navigationLink}
                                to='manage'
                            >
                                <Settings/>
                                Manage
                            </Link>
                        )
                        : null
                }
            </div>
        );
    };

    return (
        <div
            className={[
                'workspacePage',
                localStyles.workspacePage,
            ].join(' ')}
        >
            <header className={localStyles.header}>
                <h1>
                    Check-In Dashboard
                </h1>
                <StickyTooltip content={checkInServiceInfo?.enabled ? 'Service Enabled' : 'Service Disabled'}>
                    <div
                        className={localStyles.serviceEnabledIndicator}
                        data-service-enabled={Boolean(checkInServiceInfo?.enabled)}
                    />
                </StickyTooltip>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                {
                    pagesShown.length <= 0
                        ? renderEmptyState()
                        : renderNavigationOptions()
                }
            </section>
        </div>
    );

    // #endregion
}

export default CheckInPage;
