/* eslint-disable no-magic-numbers */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import find from 'lodash/find';
import SingularInput from './components/singular-input';
import localStyles from './question-input.module.scss';

/**
 * An input for a question.
 */
function QuestionInput({
    question,
    isOptional,
    id,
    responsesRef,
    onChange,
    groupAsked,
    showErrors,
}) {

    // #region Constants

    // #endregion

    // #region State

    const [
        responseLocalIDs,
        setResponseLocalIDs,
    ] = useState(
        filter(
            responsesRef.current,
            (response) => response.surveyQuestionID === question.id && response.content
        ).map((response) => response.id)
    );

    // #endregion

    // #region Effects

    /**
     * Whenever the response local IDs change, if it is empty, we want to add a
     * new one.
     */
    useEffect(() => {
        if (responseLocalIDs.length === 0) {
            const newResponseLocalID = createResponseLocalID();
            setResponseLocalIDs((prev) => [
                ...prev,
                newResponseLocalID,
            ]);
            responsesRef.current.push({
                surveyQuestionID: question.id,
                id:               newResponseLocalID,
                content:          null,
            });
            onChange();
        }
    }, [ responseLocalIDs ]);

    // #endregion

    // #region Functions

    const createResponseLocalID = () => {
        const timestamp = new Date().getTime()
            .toString(36);

        const randomString = Math.random().toString(36)
            .substr(2, 10);

        const uniqueID = `${ timestamp }-${ randomString }`;

        return uniqueID;
    };

    const onInputBlurred = (inputID, value) => {
        if (
            responseLocalIDs[ responseLocalIDs.length - 1 ] !== inputID
            && !value
        ) {
            // Remove the input
            setResponseLocalIDs((prev) => prev.filter((testInputID) => testInputID !== inputID));
            remove(
                responsesRef.current,
                (response) => response.id === inputID
            );
            onChange();
        }
    };

    const onInputChanged = (inputID, newValue) => {
        const lastInputID = responseLocalIDs[ responseLocalIDs.length - 1 ];

        const responseFromRef = find(
            responsesRef.current,
            (response) => response.id === inputID
        );

        if (responseFromRef) {
            responseFromRef.content = newValue;
        }

        if (
            lastInputID === inputID
            && newValue !== ''
        ) {
            const newResponseLocalID = createResponseLocalID();
            setResponseLocalIDs((prev) => [
                ...prev,
                newResponseLocalID,
            ]);
            responsesRef.current.push({
                surveyQuestionID: question.id,
                id:               newResponseLocalID,
                content:          null,
            });
        }

        onChange();
    };

    // #endregion

    // #region Variables

    const hasError = showErrors && !isOptional && responseLocalIDs.length <= 1;

    // #endregion

    // #region Render Functions

    const renderValues = () => {
        const renderedItems = [];

        for (const responseLocalID of responseLocalIDs) {
            renderedItems.push(
                <li key={`question-input-${ id }-${ responseLocalID }`}>
                    <SingularInput
                        hasError={hasError}
                        id={`question-input-${ id }-${ responseLocalID }`}
                        responseID={responseLocalID}
                        responsesRef={responsesRef}
                        onBlur={(value) => onInputBlurred(responseLocalID, value)}
                        onChange={(newValue) => onInputChanged(responseLocalID, newValue)}
                    />
                </li>
            );
        }

        return renderedItems;
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.questionInput}>
            <header>
                <h5>
                    {question.question}
                </h5>
                { isOptional ? <span className={localStyles.optional}>(optional)</span> : null }
            </header>
            <p className={localStyles.askedToNote}>
                {
                    question?.isAlwaysAsked
                        ? 'Always asked to'
                        : 'Asked to'
                }
                {' '}
                {
                    groupAsked
                        ? groupAsked.id === 'ALL' ? 'everyone' : `the ${ groupAsked?.name || 'unknown' } group`
                        : 'the unknown group'
                }
                {' '}
                {
                    question?.isAlwaysAsked
                        ? ''
                        : 'on a variable basis'
                }
            </p>
            <ul>
                {renderValues()}
            </ul>
            {
                hasError
                    ? (
                        <p className={localStyles.errorMessage}>
                            This question is marked as required; please enter at least one response
                        </p>
                    )
                    : null
            }
        </div>
    );

    // #endregion
}

QuestionInput.propTypes = {
    /**
     * The text of the question.
     */
    question:     PropTypes.string.isRequired,
    /**
     * Whether or not the question is optional.
     */
    isOptional:   PropTypes.bool,
    /**
     * The ID of the input.
     */
    id:           PropTypes.string.isRequired,
    /**
     * The reference to the responses array.
     */
    responsesRef: PropTypes.any.isRequired,
    /**
     * The function to call whenever responses are updated.
     */
    onChange:     PropTypes.func,
    /**
     * The group that is asked the question.
     */
    groupAsked:   PropTypes.shape({
        id:   PropTypes.string.isRequired,
        name: PropTypes.string,
    }),
    /**
     * Flag indicating whether errors should be shown if any are detected or not.
     */
    showErrors: PropTypes.bool,
};

QuestionInput.defaultProps = {
    isOptional: false,
    onChange:   undefined,
    groupAsked: undefined,
    showErrors: false,
};

export default QuestionInput;
