import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOnEmit } from '@psionic/emit-react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import {
    GroupAvatar,
} from '@components/avatars';
import {
    TeammateSelectInput,
} from '@components/inputs';
import localStyles from './group-reviewer-input.module.scss';

/**
 * Input for updating a group's reviewers.
 */
function GroupReviewerInput({
    group,
    markChangesMade,
    editedAssociationsByGroupRef,
}) {

    // #region Context

    const teammates = TeamContextLevel.use.teamMembers.value();

    const reviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    // #endregion

    // #region Memoized Values

    const groupReviewers = useMemo(() => {
        const reviewerAssociationsForGroup = filter(
            reviewerAssociations,
            (reviewerAssociation) => reviewerAssociation.groupID === group.id
        );

        return map(
            reviewerAssociationsForGroup,
            (reviewerAssociation) => {
                return find(
                    teammates,
                    (teammate) => teammate.id === reviewerAssociation.reviewerID
                );
            }
        );
    }, [ reviewerAssociations ]);

    // #endregion

    // #region State

    const [
        editedGroupReviewers,
        setEditedGroupReviewers,
    ] = useState([ ...groupReviewers ]);

    // #endregion

    // #region Second Memoized Values

    const editedGroupReviewersForInput = useMemo(() => {
        return map(
            editedGroupReviewers,
            (editedGroupReviewer) => ({
                label: editedGroupReviewer?.label || editedGroupReviewer?.displayName || editedGroupReviewer?.email,
                value: editedGroupReviewer?.value || editedGroupReviewer?.id,
                type:  editedGroupReviewer?.type || 'TEAMMATE',
            })
        );
    }, [ editedGroupReviewers ]);

    // #endregion

    // #region Effects

    /**
     * When the edited group reviewers change, update the associationsByGroupRef.
     */
    useEffect(() => {
        if (editedAssociationsByGroupRef?.current) {
            editedAssociationsByGroupRef.current[ group.id ] = editedGroupReviewersForInput;
        }
    }, [ editedGroupReviewersForInput ]);

    // #endregion

    // #region Emit Handlers

    useOnEmit(
        'reviewer-associations-changes-discarded',
        () => {
            setEditedGroupReviewers([ ...groupReviewers ]);
        }
    );

    // #endregion

    // #region Render Functions

    return (
        <div className={localStyles.groupReviewerInput}>
            <GroupAvatar
                groupName={group?.name}
                imageURL={group?.avatarURL}
                size='medium'
            />
            <TeammateSelectInput
                includeGroups={false}
                label='Reviewer(s)'
                placeholder='Select reviewer(s)'
                setValue={setEditedGroupReviewers}
                teammates={teammates}
                value={editedGroupReviewersForInput}
                isMulti
                onChange={markChangesMade}
            />
        </div>
    );

    // #endregion
}

GroupReviewerInput.propTypes = {
    /**
     * The group to render the reviewer input for.
     */
    group: PropTypes.shape({
        id:        PropTypes.string.isRequired,
        name:      PropTypes.string.isRequired,
        avatarURL: PropTypes.string,
    }).isRequired,
    /**
     * Callback to mark changes made.
     */
    markChangesMade:              PropTypes.func.isRequired,
    /**
     * Ref to the editedAssociationsByGroupRef object.
     */
    editedAssociationsByGroupRef: PropTypes.shape({
        current: PropTypes.object,
    }),
};

GroupReviewerInput.defaultProps = {
    editedAssociationsByGroupRef: undefined,
};

export default GroupReviewerInput;
