import PropTypes from 'prop-types';
import { UserAvatar } from '@components/avatars';
import localStyles from './user-info.module.scss';

/**
 * User info component.
 */
function UserInfo({
    profile,
    status,
}) {
    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.userInfo}>
            <UserAvatar
                userName={profile?.displayName}
                userEmail={profile?.email}
                imageURL={profile?.avatarURL}
                status={status}
                size="small"
            />
            <p>
                {profile?.displayName || profile?.email}
            </p>
        </div>
    );

    // #endregion
}

UserInfo.propTypes = {
    ...UserAvatar.propTypes,
};

UserInfo.defaultProps = {

};

export default UserInfo;
