/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import without from 'lodash/without';
import {
    Face,
    Grade,
} from '@mui/icons-material';
import Select, { components } from 'react-select';
import { OPTION_TYPES } from '@utils/constants';
import {
    UserAvatar,
    GroupAvatar,
} from '@components/avatars';
import localStyles from './teammate-select-input.module.scss';

/**
 * Input for selecting teammates from a list of teammates.
 */
function TeammateSelectInput({
    teammates,
    groups,
    includeGroups,
    label,
    value,
    setValue,
    isMulti,
    placeholder,
    error,
    helperText,
    onChange,
}) {
    // #region Memoized Values

    /**
     * Memoized array of options to display in the select component.
     */
    const options = useMemo(() => {
        // Add in the individual teammate options
        const optionsArray = without(
            map(teammates, (teammate) => {
                if (teammate.id) {
                    return {
                        value: teammate.id,
                        label: teammate.displayName || teammate.email,
                        type:  OPTION_TYPES.TEAMMATE,
                    };
                }

                return null;
            }),
            null
        );

        // If the `includeGroups` flag is set to true, add the groups
        if (includeGroups) {
            // Add in the "All" option
            optionsArray.push({
                value: OPTION_TYPES.ALL,
                label: 'All',
                type:  OPTION_TYPES.ALL,
            });

            // Add in the group options
            for (const group of groups) {
                if (group.id) {
                    optionsArray.push({
                        value: group.id,
                        label: group.name,
                        type:  OPTION_TYPES.GROUP,
                    });
                }
            }
        }

        return optionsArray;
    }, [
        teammates,
        includeGroups,
    ]);

    // #endregion

    // #region State

    /**
     * Track whether the input is focused.
     */
    const [
        focused,
        // eslint-disable-next-line no-unused-vars
        setFocused,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion Effects

    // #region Functions

    /**
     * Function to retrieve the given teammate's data.
     */
    const getTeammate = (id) => { return find(teammates, [
        'id',
        id,
    ]); };

    /**
     * Function to retrieve the given group's data.
     */
    const getGroup = (id) => { return find(groups, [
        'id',
        id,
    ]); };

    /**
     * Function to call when the input value changes.
     */
    const handleOnChange = (newValue) => {
        setValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * The avatar to display for a given option.
     */
    // eslint-disable-next-line react/no-multi-comp
    function OptionAvatar({ option }) {
        switch (option.type) {
            case OPTION_TYPES.TEAMMATE:
                const teammate = getTeammate(option.value);

                if (teammate) {
                    return (
                        <UserAvatar
                            imageURL={teammate.avatarURL}
                            size='extra-small'
                            userEmail={teammate.email}
                            userName={teammate.displayName}
                        />
                    );
                }

                return null;
            case OPTION_TYPES.GROUP:
                const group = getGroup(option.value);

                if (group) {
                    return (
                        <GroupAvatar
                            groupName={group.name}
                            imageURL={group.avatarURL}
                            size='extra-small'
                        />
                    );
                }

                return null;
            case OPTION_TYPES.ALL:
                return (
                    <GroupAvatar
                        groupName='All'
                        Icon={Grade}
                        size='extra-small'
                    />
                );
            default:
                return null;
        }
    }

    /**
     * Custom `Menu` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomMenu(props) {
        return (
            <components.Menu
                {...props}
                className={localStyles.menu}
            />
        );
    }

    /**
     * Custom `MenuList` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomMenuList(props) {
        return (
            <components.MenuList
                {...props}
                className={localStyles.menuList}
            />
        );
    }

    /**
     * Custom `Control` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomControl(props) {
        return (
            <components.Control
                {...props}
                className={localStyles.control}
            />
        );
    }

    /**
     * Custom `MultiValueLabel` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomMultiValueLabel(props) {
        return (
            <div className={localStyles.multiValueLabel}>
                <OptionAvatar option={props.data}/>
            </div>
        );
    }

    /**
     * Custom `SingleValue` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomSingleValue(props) {
        return (
            <components.SingleValue {...props}>
                <div className={localStyles.singleValue}>
                    <OptionAvatar option={props.data}/>
                    <p>
                        {props.data.label}
                    </p>
                </div>
            </components.SingleValue>
        );
    }

    /**
     * Custom `DropdownIndicator` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomDropdownIndicator(props) {
        return (
            <components.DropdownIndicator {...props}>
                <Face/>
            </components.DropdownIndicator>
        );
    }

    /**
     * Custom `Option` component.
     */
    // eslint-disable-next-line react/no-multi-comp
    function CustomOption(props) {
        return (
            <components.Option {...props}>
                <div className={localStyles.option}>
                    <OptionAvatar option={props.data}/>
                    <p>
                        {props.data.label}
                    </p>
                </div>
            </components.Option>
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function CustomMultiValue(props) {
        return (
            <components.MultiValue
                {...props}
                className={localStyles.multiValue}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function CustomMultiValueRemove(props) {
        return (
            <components.MultiValueRemove
                {...props}
                className={localStyles.multiValueRemove}
            />
        );
    }

    // eslint-disable-next-line react/no-multi-comp
    function CustomInput(props) {
        return (
            <components.Input
                {...props}
                className={localStyles.input}
            />
        );
    }

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.wrapper}
            data-error={error}
            data-focused={focused}
        >
            {
                label
                    ? <p className={localStyles.label}>{label}</p>
                    : null
            }
            <Select
                isMulti={isMulti}
                options={options}
                placeholder={placeholder}
                value={value}
                components={{
                    Control:           CustomControl,
                    MultiValueLabel:   CustomMultiValueLabel,
                    SingleValue:       CustomSingleValue,
                    DropdownIndicator: CustomDropdownIndicator,
                    Option:            CustomOption,
                    Menu:              CustomMenu,
                    MenuList:          CustomMenuList,
                    MultiValue:        CustomMultiValue,
                    MultiValueRemove:  CustomMultiValueRemove,
                    Input:             CustomInput,
                }}
                isClearable
                onChange={handleOnChange}
            />
            <p className={localStyles.helperText}>
                {helperText}
            </p>
        </div>
    );

    // #endregion
}

TeammateSelectInput.propTypes = {
    /**
     * Flag indicating whether the input is in an error state.
     */
    error:         PropTypes.bool,
    /**
     * Array of all of the groups that can be selected.
     */
    groups:        PropTypes.arrayOf(PropTypes.object),
    /**
     * Any helper text to render below the select field.
     */
    helperText:    PropTypes.string,
    /**
     * Flag indicating whether groups (+ the default "All" group) should be selectable.
     */
    includeGroups: PropTypes.bool,
    /**
     * Flag indicating whether multiple values can be selected or not.
     */
    isMulti:       PropTypes.bool,
    /**
     * The label to display for the input.
     */
    label:         PropTypes.string,
    /**
     * The text to render as placeholder text when no option is selected.
     */
    placeholder:   PropTypes.string,
    /**
     * The callback to set the controlled value in state.
     */
    setValue:      PropTypes.func.isRequired,
    /**
     * Array of teammate objects that can be selected.
     */
    teammates:     PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * The value of the select input, likely controlled by a `useState` hook.
     */
    value:         PropTypes.any,
    /**
     * Optional additional callback for when changes are made.
     */
    onChange:      PropTypes.func,
};

TeammateSelectInput.defaultProps = {
    isMulti:       false,
    placeholder:   'Select a teammate...',
    error:         false,
    helperText:    '',
    groups:        [],
    includeGroups: true,
    label:         undefined,
    value:         undefined,
    onChange:      undefined,
};

export default TeammateSelectInput;
