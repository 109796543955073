import PropTypes from 'prop-types';
import {
    StickyTooltip,
    IconButton,
} from '@psionic/ui';
import {
    Add,
} from '@mui/icons-material';

/**
 * Button to add a new item to some list.
 */
function AddItemButton({
    label,
    ...passThruProps
}) {
    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <StickyTooltip content={label}>
            <IconButton
                SvgIcon={Add}
                {...passThruProps}
            />
        </StickyTooltip>
    );

    // #endregion
}

AddItemButton.propTypes = {
    /**
     * The label to use in the tooltip wrapping the button.
     */
    label:         PropTypes.string.isRequired,
    /**
     * Any props to pass through to the internal `IconButton` component.
     */
    passThruProps: PropTypes.object,
};

AddItemButton.defaultProps = {

};

export default AddItemButton;
