/* eslint-disable id-length */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import {
    StickyTooltip,
} from '@psionic/ui';
import AIAvatar from '@assets/images/ai.png';
import localStyles from './ai-thoughts.module.scss';

/**
 * A component that displays the AI's thoughts.
 */
function AIThoughts({
    aiLog,
    overUsageLimits,
}) {
    // #region Constants

    // #endregion

    // #region State

    const [
        isOpen,
        setIsOpen,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.container}>
            <AnimatePresence>
                {
                    isOpen ? (
                        <motion.div
                            className={localStyles.content}
                            animate={{
                                scale:   1,
                                opacity: 1,
                                y:       -100,
                                x:       0,
                            }}
                            exit={{
                                scale:   0,
                                opacity: 0,
                                y:       0,
                                x:       150,
                            }}
                            initial={{
                                scale:   0,
                                opacity: 0,
                                bottom:  0,
                                y:       0,
                                x:       150,
                            }}
                            transition={{
                                duration:  0.15,
                                type:      'spring',
                                stiffness: 200,
                                damping:   20,
                            }}
                        >
                            {
                                overUsageLimits
                                    ? (
                                        <p className={localStyles.overUsageLimits}>
                                            Oops! I'm feeling pretty tired. I've had to think a lot this month already.
                                            <br/>
                                            <br/>
                                            I'll be back to my normal self next month. Sorry for the inconvenience!
                                        </p>
                                    )
                                    : (
                                        <p className={aiLog?.content?.content ? null : localStyles.thinking}>
                                            {aiLog?.content?.content || 'Thinking...'}
                                        </p>
                                    )
                            }
                        </motion.div>
                    ) : null
                }
            </AnimatePresence>
            <StickyTooltip
                content={isOpen ? 'Minimize AI\'s thoughts' : 'See AI\'s thoughts'}
                delay={1000}
            >
                <button
                    className={localStyles.avatar}
                    type='button'
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    <img
                        alt='ai avatar'
                        src={AIAvatar}
                    />
                </button>
            </StickyTooltip>
        </div>
    );

    // #endregion
}

AIThoughts.propTypes = {
    /**
     * The AI log to display.
     */
    aiLog:           PropTypes.object,
    /**
     * Flag indicating whether the user is over the usage limits for the month.
     */
    overUsageLimits: PropTypes.bool,
};

AIThoughts.defaultProps = {
    aiLog:           undefined,
    overUsageLimits: false,
};

export default AIThoughts;
