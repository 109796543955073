import {
    ref,
    uploadBytes,
    getDownloadURL,
} from 'firebase/storage';
import { storage } from '@services/firebase';
import TeamModel from '@models/team';

/**
 * Uploads the given file to Firebase storage, and links the download URL to the given team.
 */
export default async function uploadTeamLogo(team, file) {
    const storageRef = ref(storage, `team-logos/${team.id}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    await TeamModel.writeToID(
        team.id,
        { avatarURL: downloadURL },
        { mergeWithExistingValues: true },
    );
}
