import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '@services/firebase';
import ProfileModel from '@models/profile';

class ReleaseNotesService {

    static hasUserSeenReleaseNotes(profile) {
        if (!profile) {
            return false;
        }

        const usersLastSeenVersion = profile.lastAppVersionSeen;
        const currentVersion = ReleaseNotesService.getCurrentVersion();

        return usersLastSeenVersion === currentVersion;
    }

    static async markVersionAsSeenByUser(profile) {
        const currentVersion = ReleaseNotesService.getCurrentVersion();

        if (profile.lastAppVersionSeen !== currentVersion) {
            await ProfileModel.writeToID(
                profile.id,
                { lastAppVersionSeen: currentVersion },
                { mergeWithExistingValues: true }
            );
        }
    }

    static async markVersionAsDismissedByUser(profile) {
        await ReleaseNotesService.markVersionAsSeenByUser(profile);
    }

    static async getReleaseNotes(version) {
        const releaseNotesDownloadURL = await ReleaseNotesService.#getReleaseNotesDownloadURL(version);

        if (!releaseNotesDownloadURL) {
            return null;
        }

        const response = await fetch(releaseNotesDownloadURL);
        const data = await response.json();

        return data;
    }

    static getCurrentVersion() {
        return process.env.VERSION.replace(/['"]+/g, '');
    }

    static async #getReleaseNotesDownloadURL(version) {
        try {
            const releaseNotesRef = ref(storage, `release-notes/${ version }.json`);
            const downloadURL = await getDownloadURL(releaseNotesRef);

            return downloadURL;
        } catch (err) {
            if (err.code === 'storage/object-not-found') {
                return null;
            }

            throw err;
        }
    }
}

export default ReleaseNotesService;
