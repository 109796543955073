import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import filter from 'lodash/filter';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import {
    ExpandMore,
    Grade,
    Check,
    Close,
} from '@mui/icons-material';
import {
    Button,
    ControlledTextField,
    ControlledCheckbox,
    IconButton,
} from '@psionic/ui';
import { OPTION_TYPES } from '@utils/constants';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import CheckInQuestionModel from '@models/check-in-question';
import { GroupAvatar } from '@components/avatars';
import Question from './question';
import localStyles from './group-questions-accordion.module.scss';

function GroupQuestionsAccordion({
    team,
    group,
}) {

    // #region Context

    const existingQuestions = TeamCheckInContextLevel.use.checkInQuestions.value();

    // #endregion

    // #region State

    const [
        newQuestion,
        setNewQuestion,
    ] = useState(null);

    const [
        savingNewQuestion,
        setSavingNewQuestion,
    ] = useState(false);

    // #endregion

    // #region Variables

    const isAllGroup = group.id === OPTION_TYPES.ALL;

    // #endregion

    // #region Memoized Values

    const existingQuestionsForGroup = useMemo(() => {
        return filter(
            existingQuestions || [],
            (question) => question.groupToAsk === group.id
        );
    }, [
        existingQuestions,
        group,
    ]);

    // #endregion

    // #region Functions

    const addNewQuestion = async() => {
        setSavingNewQuestion(true);

        const newQuestionAtTimeOfSubmit = newQuestion;

        setNewQuestion(null);

        await CheckInQuestionModel.writeToNewDoc(
            `teams/${ team?.id }/checkInQuestions`,
            newQuestionAtTimeOfSubmit,
            { mergeWithDefaultValues: true }
        );

        setSavingNewQuestion(false);
    };

    // #endregion

    // #region Render Functions

    const renderExistingQuestionsForGroup = () => {
        if (existingQuestionsForGroup?.length <= 0) {
            return (
                <p className={localStyles.emptyState}>
                    There are no questions for this group yet!
                </p>
            );
        }

        const renderedAlwaysAskedQuestions = [];

        const alwaysAskedQuestions = filter(
            existingQuestionsForGroup,
            (question) => question.isAlwaysAsked
        );

        for (const question of alwaysAskedQuestions) {
            renderedAlwaysAskedQuestions.push(
                <li key={question.id}>
                    <Question
                        question={question}
                        team={team}
                    />
                </li>
            );
        }

        const rotationalQuestions = filter(
            existingQuestionsForGroup,
            (question) => !question.isAlwaysAsked
        );

        const renderedRotationalQuestions = [];

        for (const question of rotationalQuestions) {
            renderedRotationalQuestions.push(
                <li key={question.id}>
                    <Question
                        question={question}
                        team={team}
                    />
                </li>
            );
        }

        return (
            <div className={localStyles.questionsLists}>
                <h5>
                    Always Asked Questions
                </h5>
                <ul className={localStyles.questionsList}>
                    {
                        renderedAlwaysAskedQuestions?.length > 0
                            ? renderedAlwaysAskedQuestions
                            : (
                                <p className={localStyles.emptyState}>
                                    No questions of this type yet!
                                </p>
                            )
                    }
                </ul>
                <h5>
                    Rotating Questions
                </h5>
                <ul className={localStyles.questionsList}>
                    {
                        renderedRotationalQuestions?.length > 0
                            ? renderedRotationalQuestions
                            : (
                                <p className={localStyles.emptyState}>
                                    No questions of this type yet!
                                </p>
                            )
                    }
                </ul>
            </div>
        );
    };

    const renderNewQuestionInput = () => {
        return (
            <div className={localStyles.newQuestionInput}>
                <ControlledTextField
                    className={localStyles.input}
                    color='primary'
                    id='newQuestionInput'
                    label={newQuestion?.content ? null : 'Add a new question'}
                    value={newQuestion?.content || ''}
                    darkMode
                    onChange={(newValue) => setNewQuestion((prev) => ({ ...prev, content: newValue }))}
                />
                <ControlledCheckbox
                    checked={newQuestion?.isAlwaysAsked}
                    className={localStyles.checkbox}
                    color='primary'
                    id='newQuestionInputAlwaysAsk'
                    label='Always Ask?'
                    darkMode
                    onChange={(event) => setNewQuestion((prev) => ({ ...prev, isAlwaysAsked: event.target.checked }))}
                />
                <ControlledCheckbox
                    checked={newQuestion?.isOptional}
                    className={localStyles.checkbox}
                    color='primary'
                    id='newQuestionInputIsOptional'
                    label='Optional'
                    darkMode
                    onChange={(event) => setNewQuestion((prev) => ({ ...prev, isOptional: event.target.checked }))}
                />
                <IconButton
                    color='approve'
                    disabled={!newQuestion?.content || savingNewQuestion}
                    SvgIcon={Check}
                    onClick={addNewQuestion}
                />
                <IconButton
                    color='reject'
                    disabled={savingNewQuestion}
                    SvgIcon={Close}
                    onClick={() => setNewQuestion(null)}
                />
            </div>
        );
    };

    const renderAddNewQuestionButton = () => {
        return (
            <Button
                color='primary'
                variant='outlined'
                onClick={() => setNewQuestion({
                    groupToAsk: group?.id, content: '', isAlwaysAsked: true, isOptional: false,
                })}
            >
                Add New Question
            </Button>
        );
    };

    return (
        <Accordion
            className={localStyles.accordion}
            defaultExpanded={isAllGroup}
        >
            <AccordionSummary
                className={localStyles.accordionSummary}
                expandIcon={<ExpandMore/>}
            >
                <GroupAvatar
                    groupName={isAllGroup ? 'All' : group?.name}
                    Icon={isAllGroup ? Grade : null}
                    imageURL={isAllGroup ? null : group?.avatarURL}
                    size='extra-small'
                />
                <p>
                    {isAllGroup ? 'All' : group?.name}
                    {' '}
                    <span className={localStyles.note}>
                        (
                        {existingQuestionsForGroup?.length}
                        {' '}
                        {pluralize('questions', existingQuestionsForGroup?.length || 0)}
                        )
                    </span>
                </p>
            </AccordionSummary>
            <AccordionDetails className={localStyles.accordionDetails}>
                { renderExistingQuestionsForGroup() }
                <div className={localStyles.newQuestionSection}>
                    { newQuestion ? renderNewQuestionInput() : renderAddNewQuestionButton() }
                </div>
            </AccordionDetails>
        </Accordion>
    );

    // #endregion
}

GroupQuestionsAccordion.propTypes = {
    /**
     * The group to render the questions for.
     */
    group: PropTypes.shape({
        id:        PropTypes.string.isRequired,
        name:      PropTypes.string,
        avatarURL: PropTypes.string,
    }).isRequired,
    /**
     * The team to render the questions for.
     */
    team: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

GroupQuestionsAccordion.defaultProps = {

};

export default GroupQuestionsAccordion;
