import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import localStyles from './fancy-icon-button.module.scss';

/**
 * Fancy icon button.
 */
const FancyIconButton = forwardRef(({
    Icon,
    tooltip,
    ...passThruProps
}, ref) => {
    return (
        <StickyTooltip content={tooltip}>
            <IconButton
                {...passThruProps}
                ref={ref}
                className={`${ localStyles.fancyIconButton } ${ passThruProps?.className }`}
            >
                <Icon/>
            </IconButton>
        </StickyTooltip>
    );
});

FancyIconButton.propTypes = {
    /**
     * The icon to use in the button.
     */
    Icon:          PropTypes.any.isRequired,
    /**
     * Any props to pass through to the internal MUI `IconButton` component.
     */
    passThruProps: PropTypes.object,
    /**
     * The text to display as a tooltip when the icon button is hovered.
     */
    tooltip:       PropTypes.string,
};

FancyIconButton.defaultProps = {

};

export default FancyIconButton;
