import PropTypes from 'prop-types';
import { CARD_TYPES } from '@models/bidding-room-config';
import {
    DIMENSIONS,
    ICONS,
} from '@utils/constants';
import localStyles from './bidding-card.module.scss';

/**
 * A basic bidding card.
 */
function BiddingCard({
    card,
    sizeRatio,
    badgeNum,
    onClick,
    // Pass-thru props
    ...passThruProps
}) {
    // #region Render Functions

    /**
     * Render a badge number on the bidding card (for tallying user votes, for example).
     */
    const renderBadgeNum = () => (
        <div className={localStyles.badgeNum}>
            <p>
                {badgeNum}
            </p>
        </div>
    );

    /**
     * Render a numerical card value.
     */
    const renderCardNumberValue = () => (
        <h3
            className={`
                ${sizeRatio < 0.4 ? localStyles.extraSmall : null}
                ${localStyles.cardValueText}
            `}
            style={{
                fontSize: String(card.value).length > 8 ? '1.5rem' : '3rem',
            }}
        >
            {card.value}
        </h3>
    );

    /**
     * Render a card's string value as an icon, as determined by the ICONS constant collection.
     */
    const renderCardStringValue = () => {
        const Icon = ICONS[card.value];

        return (
            <Icon className={sizeRatio < 0.5 ? localStyles.smallIcon : localStyles.largeIcon} />
        );
    };

    /**
     * Main render.
     */
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            {...passThruProps}
            className={`
                ${localStyles.card}
                ${onClick ? localStyles.clickable : null}
                ${passThruProps?.className}
            `}
            onClick={onClick}
            style={{
                width:    `${DIMENSIONS.DEFAULT_CARD_WIDTH * sizeRatio}px`,
                minWidth: `${DIMENSIONS.DEFAULT_CARD_WIDTH * sizeRatio}px`,
                height:   `${DIMENSIONS.DEFAULT_CARD_HEIGHT * sizeRatio}px`,
                ...passThruProps?.style,
            }}
        >
            { badgeNum != null ? renderBadgeNum() : null }
            <div className={localStyles.header} />
            <div className={localStyles.content}>
                {
                    card.type === CARD_TYPES.NUMBER
                        ? renderCardNumberValue()
                        : renderCardStringValue()
                }
            </div>
            <div className={localStyles.footer} />
        </div>
    );

    // #endregion
}

BiddingCard.propTypes = {
    /**
     * The card object to render.
     */
    card:          PropTypes.object.isRequired,
    /**
     * The size ratio for the card.
     */
    sizeRatio:     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * The badge number to put on the card.
     */
    badgeNum:      PropTypes.number,
    /**
     * Optional callback function for when the card is clicked.
     */
    onClick:       PropTypes.func,
    /**
     * Any props to pass to the highest-level element in the component.
     */
    passThruProps: PropTypes.object,
};

BiddingCard.defaultProps = {
    sizeRatio: 1.0,
};

export default BiddingCard;
