import { useState } from 'react';
import { useUserContext } from '@unifire-js/firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
    Snackbar,
    Form,
    TextField,
    Button,
    Dialog,
    Checkbox,
} from '@psionic/ui';
import {
    Face,
    Error,
    Save,
    Delete,
} from '@mui/icons-material';
import {
    uploadProfilePicture,
    deleteUser,
    updateEmail,
} from '@services/user';
import { SnackbarService } from '@services/snackbar';
import ProfileModel from '@models/profile';
import { CORE } from '@utils/constants';
import { UploadAvatarButton } from '@components/buttons';
import { LoadingPage } from '@components/loading';
import { ReauthenticateDialog } from '@components/dialogs';
import localStyles from './account-settings.module.scss';

// #region Magic Numbers

const NUMBER_OF_RERENDERS_BEFORE_LOADING = 1;

// #endregion

function AccountSettings() {
    // #region Hooks

    const navigate = useNavigate();

    const { user, profile } = useUserContext();

    // #endregion

    // #region State

    const [
        localAvatarImage,
        setLocalAvatarImage,
    ] = useState(null);

    const [
        avatarChanged,
        setAvatarChanged,
    ] = useState(false);

    const [
        confirmDeleteDialogOpen,
        setConfirmDeleteDialogOpen,
    ] = useState(false);

    const [
        callbackAfterReauth,
        setCallbackAfterReauth,
    ] = useState(null);

    const [
        reauthDialogOpen,
        setReauthDialogOpen,
    ] = useState(false);

    const [
        changesMade,
        setChangesMade,
    ] = useState(-NUMBER_OF_RERENDERS_BEFORE_LOADING);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    const onImageChange = (image) => {
        setLocalAvatarImage(image);
        setAvatarChanged(true);
        setChangesMade(true);
    };

    const saveChanges = async(formData) => {
        // If the profile does not exist, display an error global snackbar
        if (!profile) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text='Profile not loaded yet!'
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        }

        // If the email has changed, we need to update it
        if (formData.email.value !== profile?.email) {
            try {
                await updateEmail(user, formData.email.value);
            } catch (err) {
                if (err.id === 'REQUIRES_RECENT_LOGIN') {
                    setCallbackAfterReauth(() => { return () => { return saveChanges(formData); }; });
                    setReauthDialogOpen(true);

                    return;
                }
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='reject'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Error}
                            text={err.message}
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return;
            }
        }

        // Perform the save operations
        await ProfileModel.writeToID(
            profile.id,
            {
                displayName:            formData.displayName.value,
                unsubscribedFromEmails: !formData.subscribedToEmails.checked,
            },
            { mergeWithExistingValues: true }
        );

        // If the avatar has changed, we need to upload the file to Firebase storage
        if (avatarChanged) {
            await uploadProfilePicture(profile, localAvatarImage);
        }

        // Inform the user that the save completed by displaying a global snackbar
        SnackbarService.addSnackbar(
            ({ removeSnackbar }) => { return (
                <Snackbar
                    color='approve'
                    removeSnackbar={removeSnackbar}
                    SvgIcon={Save}
                    text='Profile saved!'
                />
            ); },
            CORE.SNACKBAR_DURATION
        );
    };

    const cancel = () => {
        navigate('/home');
    };

    const verifyEmail = () => {
        sendEmailVerification(user);
        navigate('/user/awaiting-email-verification');
    };

    const changePassword = () => {
        navigate('/user/change-password');
    };

    const onDeleteAccountConfirmed = async() => {
        try {
            await deleteUser(user, profile);
        } catch (err) {
            if (err.id === 'REQUIRES_RECENT_LOGIN') {
                setCallbackAfterReauth(() => { return () => { return onDeleteAccountConfirmed(); }; });
                setReauthDialogOpen(true);
            }
            setConfirmDeleteDialogOpen(false);
        }
    };

    // #endregion

    // #region Render Functions

    const renderDialogs = () => { return (
        <>
            {/* Reauth Dialog */}
            <ReauthenticateDialog
                isOpen={reauthDialogOpen}
                setIsOpen={setReauthDialogOpen}
                onReauthenticationSuccess={callbackAfterReauth || (() => {})}
            />
            {/* Delete Account Dialog */}
            <Dialog
                className='dialog withIcon'
                isOpen={confirmDeleteDialogOpen}
                setIsOpen={setConfirmDeleteDialogOpen}
            >
                <div className='leftSide'>
                    <Delete className='redIcon'/>
                </div>
                <div className='rightSide'>
                    <h1>
                        Are you sure?
                    </h1>
                    <p>
                        Are you sure you want to delete your account? This action cannot be undone.
                    </p>
                    <div className='actionSection'>
                        <Button
                            variant='text'
                            darkMode
                            onClick={() => { return setConfirmDeleteDialogOpen(false); }}
                        >
                            Back
                        </Button>
                        <Button
                            variant='contained'
                            darkMode
                            onClick={onDeleteAccountConfirmed}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    ); };

    // If the profile is not loaded yet, return a loading page.
    if (!profile) {
        return <LoadingPage/>;
    }

    // Main render
    return (
        <>
            {/* Dialogs */}
            {renderDialogs()}
            {/* Main Page */}
            <div className='nonWorkspacePage'>
                <header>
                    <h1>Account Settings</h1>
                </header>
                <Form
                    onChange={() => { return setChangesMade((prev) => { return prev + 1; }); }}
                    onSubmit={saveChanges}
                >
                    <div
                        className={[
                            'pageContent',
                            localStyles.contentGrid,
                        ].join(' ')}
                    >
                        <div className={localStyles.leftSide}>
                            <UploadAvatarButton
                                className={localStyles.avatarUploadButton}
                                FallbackIcon={Face}
                                imageURL={localAvatarImage ? URL.createObjectURL(localAvatarImage) : profile.avatarURL}
                                size='large'
                                onImageChange={onImageChange}
                            />
                            {
                                profile?.emailVerified
                                    ? (
                                        <p className={localStyles.verifiedEmailText}>
                                            Email Verified!
                                        </p>
                                    )
                                    : (
                                        <Button
                                            color='primary'
                                            variant='outlined'
                                            width='100%'
                                            darkMode
                                            disabledOnFormSubmitting
                                            onClick={verifyEmail}
                                        >
                                            Verify Email
                                        </Button>
                                    )
                            }
                            <Button
                                variant='outlined'
                                width='100%'
                                darkMode
                                disabledOnFormSubmitting
                                onClick={changePassword}
                            >
                                Change Password
                            </Button>
                            <Button
                                color='reject'
                                variant='outlined'
                                width='100%'
                                darkMode
                                disabledOnFormSubmitting
                                onClick={() => { return setConfirmDeleteDialogOpen(true); }}
                            >
                                Delete Account
                            </Button>
                        </div>
                        <div className={localStyles.divider}/>
                        <div className={localStyles.rightSide}>
                            <TextField
                                fieldKey='displayName'
                                initialValue={profile?.displayName}
                                label='Name'
                                darkMode
                                required
                            />
                            <TextField
                                fieldKey='email'
                                initialValue={profile?.email}
                                label='Email'
                                darkMode
                                required
                            />
                            <Checkbox
                                fieldKey='subscribedToEmails'
                                initialValue={!profile?.unsubscribedFromEmails}
                                label='Subscribed to Email Notifications'
                                darkMode
                            />
                        </div>
                    </div>
                    <div className={localStyles.actionsSection}>
                        <Button
                            color='primary'
                            variant='text'
                            darkMode
                            onClick={cancel}
                        >
                            {changesMade > 0 ? 'Cancel' : 'Back'}
                        </Button>
                        <Button
                            color='primary'
                            disabled={changesMade < 1}
                            type='submit'
                            variant='contained'
                            darkMode
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );

    // #endregion
}

export default AccountSettings;
