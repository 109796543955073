import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    useMediaQuery,
} from '@mui/material';
import { EditableCard } from '@components/core';
import { AddCardButton } from '@components/buttons';
import localStyles from './edit-deck-field.module.scss';

/**
 * A field where a user can edit the deck of a room.
 */
function EditDeckField({
    deck,
    onDeckChange,
    error,
}) {
    // #region Hooks

    /**
     * Use a media query to tell when a screen becomes very small.
     */
    const isMobileView = useMediaQuery('(max-width: 575px)');

    /**
     * Store the card renders in state.
     */
    const [cardComponents, setCardComponents] = useState([]);

    /**
     * Whenever the deck changes, create the new card renders in state.
     */
    useEffect(() => {
        createCardComponents();
    }, [deck, isMobileView]);

    // #endregion

    // #region Functions

    /**
     * Create the card components from the deck.
     */
    const createCardComponents = () => {
        const createdCardComponents = [];
        for (let i = 0; i < deck.length; i++) {
            const card = deck[i];
            // Push the render into the array
            createdCardComponents.push(
                <EditableCard
                    key={i}
                    card={card}
                    deleteCardFn={() => deleteCard(i)}
                    onChange={(newCard) => updateCard(i, newCard)}
                    sizeRatio={isMobileView ? 0.69 : 0.75}
                />,
            );
        }
        setCardComponents(createdCardComponents);
    };

    /**
     * Delete the card at the specified index (key).
     */
    const deleteCard = (key) => {
        const newDeck = [...deck];
        newDeck.splice(key, 1);
        onDeckChange(newDeck);
    };

    /**
     * Add a new card to the deck.
     */
    const addNewCard = () => {
        const newDeck = [...deck, { value: '', type: null }];
        onDeckChange(newDeck);
    };

    /**
     * Update the card at the specified index (key).
     */
    const updateCard = (key, newCard) => {
        const newDeck = [...deck];
        newDeck.splice(key, 1, newCard);
        onDeckChange(newDeck);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div>
            <div
                className={`
                    ${localStyles.wrapper}
                    ${error ? localStyles.wrapperError : null}
                `}
            >
                <h5 className={localStyles.formLabel}>
                    Edit Deck
                </h5>
                <div
                    className={localStyles.formWrapper}
                    style={{
                        gridTemplateColumns: `repeat(auto-fill, ${isMobileView ? '207' : '225'}px)`,
                    }}
                >
                    {cardComponents}
                    <AddCardButton
                        tooltip="Add Card"
                        onClick={addNewCard}
                        sizeRatio={isMobileView ? 0.69 : 0.75}
                    />
                </div>
            </div>
            <p className={localStyles.errorMessage}>
                {error || <>&nbsp;</>}
            </p>
        </div>
    );

    // #endregion
}

EditDeckField.propTypes = {
    /**
     * An array of objects representing the room's cards.
     */
    deck:         PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * A function which should take in the new deck as its only arg, and will update
     * state with the new deck value.
     */
    onDeckChange: PropTypes.func.isRequired,
    /**
     * The error message to display if the field has an error.
     */
    error:        PropTypes.string,
};

EditDeckField.defaultProps = {

};

export default EditDeckField;
