import PropTypes from 'prop-types';
import localStyles from './question.module.scss';

/**
 * Component displaying a check-in question and a list of items below it (typically answers to the question).
 */
function Question({
    question,
    isOptional,
    listItems,
}) {

    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.question}>
            <header>
                <h5>
                    {question}
                </h5>
                {isOptional ? <span className={localStyles.optional}>(optional)</span> : null}
            </header>
            <ul>
                {
                    listItems.map((item, index) => (
                        <li key={`value-${ index }`}>
                            <div className={localStyles.customBullet}/>
                            <div className={localStyles.item}>
                                {item}
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    );

    // #endregion
}

Question.propTypes = {
    /**
     * The text of the question.
     */
    question:   PropTypes.string.isRequired,
    /**
     * Whether or not the question is optional.
     */
    isOptional: PropTypes.bool,
    /**
     * The list items to display below the question.
     */
    listItems:  PropTypes.arrayOf(PropTypes.node),
};

Question.defaultProps = {
    isOptional: false,
    listItems:  [],
};

export default Question;
