import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { ControlledTextField, IconButton } from '@psionic/ui';
import { EmojiEmotions } from '@mui/icons-material';
import { Popover } from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import localStyles from './singular-input.module.scss';

/**
 * An input for a question.
 */
function SingularInput({
    id,
    onBlur,
    onChange,
    responsesRef,
    responseID,
    hasError,
}) {

    // #region Refs

    const inputRef = useRef();

    // #endregion

    // #region Constants

    // #endregion

    // #region State

    const [
        value,
        setValue,
    ] = useState(
        find(
            responsesRef.current,
            (response) => response.id === responseID
        )?.content
        || ''
    );

    const [
        emojiPickerAnchor,
        setEmojiPickerAnchor,
    ] = useState(null);

    // #endregion

    // #region Effects

    /**
     * Whenever the value changes, call the onChange function.
     */
    useEffect(() => {
        onChange(value);
    }, [ value ]);

    // #endregion

    // #region Functions

    const onEmojiSelect = (event) => {
        setValue((prev) => {
            const emoji = event.native;
            const newValue = { ...prev };

            if (!newValue || isEmpty(newValue)) {
                return {
                    type:    'doc',
                    content: [ {
                        type:    'paragraph',
                        content: [ {
                            type: 'text',
                            text: emoji,
                        } ],
                    } ],
                };
            }

            const lastContentEntry = newValue.content[ newValue.content.length - 1 ];

            if (!lastContentEntry.content) {
                lastContentEntry.content = [ { type: 'text', text: '' } ];
            }

            lastContentEntry.content[ 0 ].text += emoji;

            inputRef.current.setContent(newValue);

            return newValue;
        });
        setEmojiPickerAnchor(null);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.singularInput}>
            <ControlledTextField
                ref={inputRef}
                hasError={hasError}
                id={id}
                value={value}
                label={
                    value
                        ? ''
                        : 'Add a new answer'
                }
                darkMode
                multiline
                onBlur={() => onBlur(value)}
                onChange={setValue}
            />
            <IconButton
                ref={emojiPickerAnchor}
                color='lowEmphasis'
                size={30}
                SvgIcon={EmojiEmotions}
                onClick={(event) => setEmojiPickerAnchor(event.currentTarget)}
            />
            <Popover
                anchorEl={emojiPickerAnchor}
                open={Boolean(emojiPickerAnchor)}
                anchorOrigin={{
                    vertical:   'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    className: localStyles.popover,
                }}
                onClose={() => setEmojiPickerAnchor(null)}
            >
                <div className='emojiPickerWrapper'>
                    <Picker
                        data={data}
                        onEmojiSelect={onEmojiSelect}
                    />
                </div>
            </Popover>
        </div>
    );

    // #endregion
}

SingularInput.propTypes = {
    /**
     * The ID for the singular input.
     */
    id:           PropTypes.string.isRequired,
    /**
     * The function to call when the input is blurred.
     */
    onBlur:       PropTypes.func.isRequired,
    /**
     * The function to call when the input changes.
     */
    onChange:     PropTypes.func.isRequired,
    /**
     * Reference of all of the responses.
     */
    responsesRef: PropTypes.any.isRequired,
    /**
     * The ID of the response this singular input is controlling.
     */
    responseID:   PropTypes.string.isRequired,
    /**
     * Flag indicating whether or not the input has an error.
     */
    hasError:     PropTypes.bool,
};

SingularInput.defaultProps = {
    hasError: false,
};

export default SingularInput;
