import { where } from 'firebase/firestore';
import { Autobatcher } from '@unifire-js/firebase/firestore';
import FirebaseFunctions from '@utils/constants/firebase-functions';
import AssociationModel, { ROLES } from '@models/association';

/**
 * Transfers team ownership from one user to another.
 */
export default async function transferTeamOwnership(team, teamSubscription, fromProfile, toProfile) {
    // If the team subscription is non-null, we want to make sure to cancel the subscription
    if (teamSubscription) {
        const result = await FirebaseFunctions.cancelSubscription({ subscriptionID: teamSubscription.id });

        if (!result) {
            throw new Error('An error occurred while trying to cancel the team\'s subscription. Aborting the transfer team ownership operation.');
        }
    }

    // Retrieve the association related to the `from` profile
    const fromAssociation = await (async() => {
        const matchingAssociations = await AssociationModel.getByQuery([
            where('userID', '==', fromProfile.id),
            where('teamID', '==', team.id),
        ]);

        if (matchingAssociations.length > 0) {
            return matchingAssociations[ 0 ];
        } else {
            return null;
        }
    })();

    // Retrieve the association related to the `to` profile
    const toAssociation = await (async() => {
        const matchingAssociations = await AssociationModel.getByQuery([
            where('userID', '==', toProfile.id),
            where('teamID', '==', team.id),
        ]);

        if (matchingAssociations.length > 0) {
            return matchingAssociations[ 0 ];
        } else {
            return null;
        }
    })();

    // If both associations were found, update both of them in a batch
    if (fromAssociation && toAssociation) {
        const autobatcher = new Autobatcher();
        AssociationModel.writeToID(
            fromAssociation.id,
            { role: ROLES.ADMIN },
            {
                autobatcher,
                mergeWithExistingValues: true,
            }
        );
        AssociationModel.writeToID(
            toAssociation.id,
            { role: ROLES.OWNER },
            {
                autobatcher,
                mergeWithExistingValues: true,
            }
        );
        await autobatcher.allBatchesFinalized();
    }
}
