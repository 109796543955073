import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

// #region Magic Numbers

// eslint-disable-next-line no-magic-numbers
export const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

// #endregion

const CheckInSurveyConfigurationModel = new Submodel({
    collectionName:  'checkInSurveyConfigurations',
    parent:          TeamModel,
    collectionProps: [
        'frequency',
        'numRotatingQuestions',
    ],
    propDefaults: {
        frequency:            TWO_WEEKS,
        numRotatingQuestions: 3,
    },
});

export default CheckInSurveyConfigurationModel;
