import { Submodel } from '@unifire-js/firebase/firestore';
import AgendaModel from '@models/agenda';

const DayModel = new Submodel({
    collectionName:  'days',
    parent:          AgendaModel,
    collectionProps: [ 'dateValue' ],
});

export default DayModel;
