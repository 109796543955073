import { Submodel } from '@unifire-js/firebase/firestore';
import MinuteModel from '@models/minute';

const ActionItemModel = new Submodel({
    collectionName:  'actionItems',
    parent:          MinuteModel,
    collectionProps: [
        'requires',
        'complete',
        'message',
        'teamID',
    ],
    propDefaults: {
        requires: [],
        complete: false,
        message:  '',
    },
});

export default ActionItemModel;
