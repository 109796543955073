import { Model } from '@unifire-js/firebase/firestore';

const ReactionModel = new Model({
    collectionName:  'reactions',
    collectionProps: [
        'associatedData',
        'users',
        'native',
        'emojiID',
    ],
});

export default ReactionModel;
