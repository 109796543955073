import { BrowserRouter } from 'react-router-dom';
import '@services/firebase';
import routes from './routes';

function Root() {
    return (
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    );
}

export default Root;
