import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * React component that can be inserted into the Router which will cause all components within
 * the same hierarchy to be scrolled to the top when first navigated to.
 */
const ScrollToTop = () => {
    const { pathName } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathName]);

    return null;
};

export default ScrollToTop;