import PropTypes from 'prop-types';
import {
    useMediaQuery
} from '@mui/material';
import { BiddingCard } from '@components/cards';
import localStyles from './voting-form.module.scss';
import { DIMENSIONS } from '@utils/constants';

/**
 * Reusable voting form that displays the given deck to the user and allows
 * the user to select a card from the deck as their vote.
 */
const VotingForm = ({
    deck,
    onCardSelected,
}) => {
    /*********
     * HOOKS *
     *********/

    /**
     * Set up a media query to flip between desktop and mobile rendering.
     */
    const isMobileView = useMediaQuery('(max-width: 800px)');

    /*************
     * VARIABLES *
     *************/

    /**
     * Track size ratio, based on media query.
     */
    const sizeRatio = isMobileView ? 0.45 : 0.75;

    /**
     * Set card gap, depending on the card size ratio.
     */
    const cardGap = sizeRatio >= 0.58 ? '20px' : '10px';

    /*************
     * FUNCTIONS *
     *************/

    /********************
     * RENDER FUNCTIONS *
     ********************/

    /**
     * Render all cards in the deck.
     */
    const renderCards = () => {
        const cardRenders = [];
        for (let i = 0; i < deck.length; i++) {
            const card = deck[i];
            cardRenders.push(renderCard(card, i));
        }
        return cardRenders;
    };

    /**
     * Render a particular card.
     */
    const renderCard = (card, index) => {
        return <BiddingCard
            key={index}
            card={card}
            onClick={() => onCardSelected(index)}
            sizeRatio={sizeRatio}
        />;
    };

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.votingForm}
            style={{
                gridTemplateColumns: `repeat(auto-fill, ${DIMENSIONS.DEFAULT_CARD_WIDTH*sizeRatio}px)`,
                gridGap: cardGap,
            }}
        >
            {renderCards()}
        </div>
    );
};

VotingForm.propTypes = {
    /**
     * The deck to render in the voting form.
     */
    deck: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * The callback function for when a card is selected; should accept
     * the card's index in the given deck as the only arg.
     */
    onCardSelected: PropTypes.func.isRequired,
};

VotingForm.defaultProps = {

};

export default VotingForm;