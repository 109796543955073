/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { usePseudoSelectors } from '@psionic/ui';
import { HEARTBEAT_COLORS } from '@utils/constants';
import localStyles from './heartbeat-radio-button.module.scss';

/**
 * A radio button for the heartbeat input.
 */
function HeartbeatRadioButton({
    value,
    selected,
    onChange,
}) {

    // #region Misc Hooks

    const [
        pseudoSelectorProps,
        pseudoSelectorStates,
    ] = usePseudoSelectors();

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Variables

    const isAnimatingHover = (pseudoSelectorStates.isHovered || pseudoSelectorStates.isFocused) && !selected;

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.heartbeatRadioButton}>
            <input
                checked={selected}
                name='heartbeat'
                type='radio'
                value={value}
                onChange={() => onChange(value)}
            />
            <motion.button
                className={localStyles.customRadioButton}
                type='button'
                animate={{
                    scale: isAnimatingHover ? 1.1 : 1,
                }}
                initial={{
                    scale: isAnimatingHover ? 1.1 : 1,
                }}
                style={{
                    borderColor: isAnimatingHover || selected ? HEARTBEAT_COLORS[ value ] : 'white',
                    color:       isAnimatingHover ? HEARTBEAT_COLORS[ value ] : 'white',
                    background:  selected ? HEARTBEAT_COLORS[ value ] : 'none',
                }}
                onClick={() => onChange(value)}
                {...pseudoSelectorProps}
            >
                {value}
            </motion.button>
        </div>
    );

    // #endregion
}

HeartbeatRadioButton.propTypes = {
    /**
     * The function to call when the radio button is clicked.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Flag indicating whether the radio button is selected.
     */
    selected: PropTypes.bool,
    /**
     * The value of the radio button.
     */
    value:    PropTypes.oneOf([
        1,
        2,
        3,
        4,
        5,
    ]).isRequired,
};

HeartbeatRadioButton.defaultProps = {
    selected: false,
};

export default HeartbeatRadioButton;
