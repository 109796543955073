import { Model } from '@unifire-js/firebase/firestore';

const TeamSubscriptionModel = new Model({
    collectionName:  'teamSubscriptions',
    collectionProps: [
        'cancelAtPeriodEnd',
        'currentPeriodEnd',
        'currentPeriodStart',
        'customerID',
        'items',
        'teamID',
        'status',
        'lastEventTimestamp',
        'purchasedBy',
    ],
});

export default TeamSubscriptionModel;
