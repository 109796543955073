import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { where } from 'firebase/firestore';
import { auth } from '@services/firebase';
import FirebaseFunctions from '@utils/constants/firebase-functions';
import ProfileModel from '@models/profile';

// #region Main Handler

/**
 * Creates a new user in Firebase Auth + a new profile for that user in Firestore. If either a profile or an auth
 * account already exists, an error is thrown.
 *
 * @param {string} email The email to create the account and profile for
 * @param {string} password The password to set for the user's account
 * @param {string} confirmPassword The confirmed password the user provided
 * @returns {Promise<null>} Resolves whenever the user account and profile are created
 */
export default async function(email, password, confirmPassword) {
    // Trim the email and make it all lowercase
    const sanitizedEmail = email.trim().toLowerCase();

    // Throw an error if the passwords do not match
    if (password !== confirmPassword) {
        throw ERRORS.factories.PASSWORDS_DO_NOT_MATCH();
    }

    // Throw an error if the profile exists already
    const profileExists = await profileWithEmailExists(sanitizedEmail);

    if (profileExists) {
        throw ERRORS.factories.USER_EXISTS();
    }

    // Otherwise, continue with account creation
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, sanitizedEmail, password);
        const stripeCustomerID = await (async() => {
            const result = await FirebaseFunctions.createStripeCustomer({ sanitizedEmail });

            return result?.stripeCustomerID;
        })();
        await createUserProfile(userCredential.user, sanitizedEmail, stripeCustomerID);
        sendEmailVerification(userCredential.user);
    } catch (err) {
        switch (err.code) {
            case 'auth/email-already-in-use':
                throw ERRORS.factories.USER_EXISTS();
            default:
                throw ERRORS.factories.OTHER_ERROR();
        }
    }
}

// #endregion

// #region Categorized Errors

const ERRORS = new CategorizedErrorFactory({
    PASSWORDS_DO_NOT_MATCH: {
        message: 'Passwords do not match!',
    },
    USER_EXISTS: {
        message: 'An account already exists with this email.',
    },
    OTHER_ERROR: {
        message: 'An error occurred while attempting to sign you up!',
    },
});

// #endregion

// #region Helper Functions

/**
 * Tests to see if a profile with the given email already exists or not.
 *
 * @param {string} email The email to query the profiles by
 * @returns {Promise<boolean} Resolves with a flag indicating whether at least one profile already exists with that
 * email or not
 */
async function profileWithEmailExists(email) {
    const matches = await ProfileModel.getByQuery([ where('email', '==', email.trim().toLowerCase()) ]);

    return matches.length > 0;
}

/**
 * Creates the user's profile in Firestore.
 *
 * @param {Firebase.Auth.User} user The Firebase auth user object
 * @param {string} email The user's email for the account
 * @param {string} stripeCustomerID The user's new Stripe customer ID
 * @returns {Promise<void>} Resolves when the user's profile has been created
 */
async function createUserProfile(user, email, stripeCustomerID) {
    await ProfileModel.writeToID(
        user.uid,
        {
            email: email.trim().toLowerCase(),
            stripeCustomerID,
        },
        { mergeWithDefaultValues: true }
    );
}

// #endregion
