import { Outlet } from 'react-router-dom';

/**
 * Wrapper for any team solution routes that do not require a premium subscription.
 */
const TeamFreeWrapper = ({

}) => {

    //#region Render Functions

    /**
     * Main render.
     */
    return (
        <Outlet/>
    );

    //#endregion
};

export default TeamFreeWrapper;
