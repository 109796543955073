import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
    IconButton,
    Button,
    ControlledTextField,
} from '@psionic/ui';
import { EmojiEmotions } from '@mui/icons-material';
import { Popover } from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { UserAvatar } from '@components/avatars';
import localStyles from './comment-input.module.scss';

/**
 * An input for a comment.
 */
function CommentInput({
    value,
    setValue,
    user,
    inEditMode,
    onCancelClicked,
    onSaveClicked,
    id,
}) {

    // #region Refs

    const inputRef = useRef();

    // #endregion

    // #region State

    const [
        emojiPickerAnchor,
        setEmojiPickerAnchor,
    ] = useState(null);

    // #endregion

    // #region Functions

    const onEmojiSelect = (event) => {
        setValue((prev) => {
            const newValue = { ...prev };

            if (!newValue || isEmpty(newValue)) {
                return {
                    type:    'doc',
                    content: [ {
                        type:    'paragraph',
                        content: [ {
                            type: 'text',
                            text: event.native,
                        } ],
                    } ],
                };
            }

            const lastContentEntry = newValue.content[ newValue.content.length - 1 ];

            if (!lastContentEntry.content) {
                lastContentEntry.content = [ { type: 'text', text: '' } ];
            }

            lastContentEntry.content[ 0 ].text += event.native;

            return newValue;
        });
        setEmojiPickerAnchor(null);

        if (inputRef.current) {
            inputRef.current.setContent(value);
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.commentInput}>
            <div className={localStyles.leftSide}>
                <UserAvatar
                    hasTooltip={false}
                    imageURL={user?.avatarURL}
                    size='extra-extra-small'
                    userEmail={user?.email}
                    userName={user?.displayName}
                />
            </div>
            <div className={localStyles.rightSide}>
                <div className={localStyles.input}>
                    <ControlledTextField
                        ref={inputRef}
                        id={id}
                        value={value}
                        label={
                            value
                                ? ''
                                : inEditMode ? 'Edit comment' : 'Add comment'
                        }
                        darkMode
                        multiline
                        onChange={setValue}
                    />
                    <IconButton
                        ref={emojiPickerAnchor}
                        color='lowEmphasis'
                        size={30}
                        SvgIcon={EmojiEmotions}
                        onClick={(event) => setEmojiPickerAnchor(event.currentTarget)}
                    />
                </div>
                <div className={localStyles.actions}>
                    {
                        inEditMode
                            ? (
                                <>
                                    <Button
                                        key='cancelButton'
                                        color='reject'
                                        variant='outlined'
                                        width={75}
                                        onClick={onCancelClicked}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        key='saveButton'
                                        color='approve'
                                        disabled={!value || value?.content?.length === 0}
                                        variant='outlined'
                                        width={75}
                                        onClick={onSaveClicked}
                                    >
                                        Save
                                    </Button>
                                </>
                            )
                            : (
                                <Button
                                    key='postButton'
                                    color='mediumEmphasis'
                                    disabled={!value || value?.content?.length === 0}
                                    variant='outlined'
                                    onClick={onSaveClicked}
                                >
                                    Post comment
                                </Button>
                            )
                    }
                </div>
            </div>
            <Popover
                anchorEl={emojiPickerAnchor}
                open={Boolean(emojiPickerAnchor)}
                anchorOrigin={{
                    vertical:   'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    className: localStyles.popover,
                }}
                onClose={() => setEmojiPickerAnchor(null)}
            >
                <div className='emojiPickerWrapper'>
                    <Picker
                        data={data}
                        onEmojiSelect={onEmojiSelect}
                    />
                </div>
            </Popover>
        </div>
    );

    // #endregion
}

CommentInput.propTypes = {
    /**
     * The comment's current value.
     */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /**
     * The callback to call when the value changes.
     */
    setValue: PropTypes.func.isRequired,
    /**
     * The user who is commenting / editing the existing comment.
     */
    user:     PropTypes.shape({
        /**
         * The user's avatar URL.
         */
        avatarURL:   PropTypes.string,
        /**
         * The user's email.
         */
        email:       PropTypes.string,
        /**
         * The user's display name.
         */
        displayName: PropTypes.string,
    }).isRequired,
    /**
     * Whether or not the comment is being edited.
     */
    inEditMode:      PropTypes.bool,
    /**
     * The callback to call when the user cancels editing the comment.
     */
    onCancelClicked: PropTypes.func,
    /**
     * The callback to call when the user saves the comment.
     */
    onSaveClicked:   PropTypes.func.isRequired,
    /**
     * The ID of the comment input, for accessibility purposes.
     */
    id:              PropTypes.string.isRequired,
};

CommentInput.defaultProps = {
    value:           '',
    inEditMode:      false,
    onCancelClicked: undefined,
};

export default CommentInput;
