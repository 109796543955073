import PropTypes from 'prop-types';
import {
    Popover,
} from '@mui/material';
import {
    LabeledIconButton,
} from '@psionic/ui';

/**
 * Replace this with a comment describing the component.
 */
function ActionMenu({
    anchorEl,
    onClose,
    actions,
}) {
    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Render all of the actions.
     */
    const renderActions = () => {
        return actions.map((action) => {
            const {
                label,
                icon,
                onClick,
                color,
            } = action;

            return (
                <LabeledIconButton
                    key={label}
                    className='menuButton'
                    color={color}
                    inactiveColor='#bbb'
                    label={label}
                    SvgIcon={icon}
                    onClick={() => {
                        onClick();
                        onClose();
                    }}
                />
            );
        });
    };

    /**
     * Main render.
     */
    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical:   'bottom',
                horizontal: 'center',
            }}
            PaperProps={{
                className: 'menu',
            }}
            transformOrigin={{
                vertical:   'top',
                horizontal: 'center',
            }}
            disableAutoFocus
            disableEnforceFocus
            onClose={onClose}
        >
            {renderActions()}
        </Popover>
    );

    // #endregion
}

ActionMenu.propTypes = {
    /**
     * The actions to display in the menu.
     */
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon:    PropTypes.any.isRequired,
        label:   PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    })).isRequired,
    /**
     * The element to anchor the menu to; if no `anchorEl` is passed, the menu will be hidden.
     */
    anchorEl: PropTypes.object,
    /**
     * Callback function for when the menu should be closed.
     */
    onClose:  PropTypes.func.isRequired,
};

ActionMenu.defaultProps = {
    anchorEl: null,
};

export default ActionMenu;
