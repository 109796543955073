import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import shuffle from 'lodash/shuffle';
import { DateTime } from 'luxon';
import {
    where,
    getFirestore,
    doc,
    collection,
} from 'firebase/firestore';
import { Autobatcher, runTransaction } from '@unifire-js/firebase/firestore';
import {
    serverTimestamp,
    arrayUnion,
} from '@firebase/firestore';
import CheckInSurveyConfigurationModel from '@models/check-in-survey-configuration';
import CheckInSurveyBatchModel from '@models/check-in-survey-batch';
import CheckInServiceInfoModel from '@models/check-in-service-info';
import CheckInSurveyModel from '@models/check-in-survey';
import CheckInSurveyQuestionModel from '@models/check-in-survey-question';
import CheckInSurveyQuestionResponseModel from '@models/check-in-survey-question-response';
import WorkerModel from '@models/worker';
import PrioritiesModel from '@models/priorities';
import ActivityLogModel from '@models/activity-log';
import CommentModel from '@models/comment';
import { ACTIVITY_LOG_TYPES } from '@utils/constants';

// #region Public Functions

/**
 * Disables the check-in service for the given team.
 *
 * @param {string} teamID The ID of the team to disable the check-in service for
 * @returns {Promise<*>} Resolves once the service has been successfully disabled
 */
export async function disableCheckInService(teamID) {
    await runTransaction(async(transaction) => {
        // Read the existing check-in service info
        const existingCheckInServiceInfo = await CheckInServiceInfoModel.getByPath(
            `teams/${ teamID }/checkInServiceInfo/default`,
            { transaction }
        );

        // Update the existing check-in service info
        await CheckInServiceInfoModel.writeToPath(
            `teams/${ teamID }/checkInServiceInfo/default`,
            { enabled: false, latestBatchID: null, workerIDForCreatingNextBatch: null },
            { transaction, mergeWithExistingValues: true }
        );

        // If a latestBatchID value existed, we need to delete all surveys w/ that batch ID and
        // the associated check-in survey batch doc
        if (existingCheckInServiceInfo.latestBatchID) {
            // Delete the check-in survey batch doc
            await CheckInSurveyBatchModel.deleteByPath(
                `teams/${ teamID }/checkInSurveyBatches/${ existingCheckInServiceInfo.latestBatchID }`,
                { transaction }
            );

            // Delete all survey documents that are a part of the batch
            const surveysToDelete = await CheckInSurveyModel.getByQueryInInstance(
                `teams/${ teamID }/checkInSurveys`,
                [ where('surveyBatchID', '==', existingCheckInServiceInfo.latestBatchID) ],
                { transaction }
            );

            await Promise.all(
                map(
                    surveysToDelete,
                    async(surveyToDelete) => {
                        await CheckInSurveyModel.deleteByPath(
                            `teams/${ teamID }/checkInSurveys/${ surveyToDelete.id }`,
                            { transaction }
                        );
                    }
                )
            );
        }

        // If a workerIDForCreatingNextBatch value existed, we need to delete that worker
        if (existingCheckInServiceInfo.workerIDForCreatingNextBatch) {
            await WorkerModel.deleteByID(
                existingCheckInServiceInfo.workerIDForCreatingNextBatch,
                { transaction }
            );
        }
    });
}

/**
 * Enables the check-in service for the given team.
 *
 * @param {string} teamID The ID of the team to enable the check-in service for
 * @returns {Promise<*>} Resolves once the service has been successfully enabled
 */
export async function enableCheckInService(teamID) {
    await runTransaction(async(transaction) => {
        // Create the new FF task to create the check-in survey batch
        const newWorker = await WorkerModel.writeToNewDoc(
            {
                options: {
                    timezone: DateTime.local().zone.name,
                    teamID,
                },
                performAt: DateTime.now().toJSDate(),
                worker:    'createCheckInSurveyBatch',
            }
        );

        // Update the check-in service info
        await CheckInServiceInfoModel.writeToPath(
            `teams/${ teamID }/checkInServiceInfo/default`,
            { enabled: true, workerIDForCreatingNextBatch: newWorker.id },
            { transaction, mergeWithExistingValues: true }
        );
    });
}

/**
 * Saves the survey configuration data for a team.
 *
 * @param {string} teamID The ID of the team to save the survey configuration for
 * @param {Object} data The data to save to the database
 */
export async function saveSurveyConfiguration(teamID, data) {
    await CheckInSurveyConfigurationModel.writeToPath(
        `teams/${ teamID }/checkInSurveyConfigurations/configuration`,
        data,
        { mergeWithDefaultValues: true }
    );
}

/**
 * Submits the given survey.
 *
 * @param {string} userID The ID of the user submitting the survey
 * @param {Object} survey The survey object to save to the database
 * @param {string[]} prioritiesCompleted The array of priority IDs that were completed in this survey
 * @param {Object[]} newPriorities The array of new priority content objects to save to the database
 * @param {Object[]} responses The array of responses to save to the database
 * @returns {Promise<void>} Resolves once the survey has been successfully submitted
 */
export async function submitSurvey(userID, survey, prioritiesCompleted, newPriorities, responses) {
    const autobatcher = new Autobatcher();

    // Create the survey data to write
    const surveyToWrite = {
        ...survey,
        dateSubmitted: serverTimestamp(),
    };

    delete surveyToWrite.endDate;
    delete surveyToWrite.startDate;

    // Write the survey data
    CheckInSurveyModel.writeToPath(
        `teams/${ survey.teamID }/checkInSurveys/${ survey.id }`,
        {
            ...survey,
            dateSubmitted: serverTimestamp(),
        },
        {
            autobatcher,
            mergeWithExistingValues: true,
        }
    );

    // Write the activity log
    ActivityLogModel.writeToNewDoc(
        `teams/${ survey.teamID }/checkInSurveys/${ survey.id }/activityLogs`,
        {
            type:          ACTIVITY_LOG_TYPES.SUBMITTED_SURVEY,
            userID,
            timestamp:     serverTimestamp(),
            acknowledgeBy: [],
        },
        { autobatcher }
    );

    // Write the survey responses
    for (const response of responses) {
        if (response.content) {
            CheckInSurveyQuestionResponseModel.writeToNewDoc(
                `teams/${ survey.teamID }/checkInSurveys/${ survey.id }/checkInSurveyQuestions/${ response.surveyQuestionID }/checkInSurveyQuestionResponses`,
                {
                    ...response,
                    surveyID: survey.id,
                    teamID:   survey.teamID,
                },
                { autobatcher }
            );
        }
    }

    // Update the completed priorities
    for (const priorityID of prioritiesCompleted) {
        PrioritiesModel.writeToID(
            priorityID,
            {
                completed:       true,
                surveyCompleted: survey.id,
            },
            {
                autobatcher,
                mergeWithExistingValues: true,
            }
        );
    }

    // Write the new survey priorities
    for (const priority of newPriorities) {
        if (priority) {
            PrioritiesModel.writeToNewDoc(
                {
                    content:     priority,
                    teamID:      survey.teamID,
                    userID,
                    completed:   false,
                    dateCreated: serverTimestamp(),
                },
                { autobatcher }
            );
        }
    }

    // Wait for the autobatcher to finish
    await autobatcher.allBatchesFinalized();
}

/**
 * Post a comment to the given data.
 *
 * @param {DocumentReference} associatedData The document reference to the data to post the comment to
 * @param {Object[]} reviewers Any reviewers assigned to the survey
 * @param {Object} profile The profile of the user posting the comment
 * @param {Object} content The comment's content
 * @param {string} teamID The ID of the team associated with the survey
 * @param {string} surveyID The ID of the survey associated with the comment
 * @param {Object} surveyOwner The profile of the survey's owner
 */
export async function postComment(associatedData, reviewers, profile, content, teamID, surveyID, surveyOwner) {
    // In the background, create or update the notification worker
    await updateOrCreateNotificationWorker(
        teamID,
        surveyID,
        profile,
        reviewers,
        surveyOwner
    );

    await runTransaction(async(transaction) => {
        await CommentModel.writeToNewDoc(
            {
                associatedData,
                authorID:    profile.id,
                content,
                dateCreated: serverTimestamp(),
            },
            { transaction }
        );

        await ActivityLogModel.writeToNewDoc(
            `teams/${ teamID }/checkInSurveys/${ surveyID }/activityLogs`,
            {
                type:      ACTIVITY_LOG_TYPES.LEFT_COMMENT,
                userID:    profile.id,
                timestamp: serverTimestamp(),
            },
            { transaction }
        );
    });
}

/**
 * Marks the given activity log as seen by the given user.
 *
 * @param {string} userID The ID of the user seeing the activity
 * @param {Object} activityLog The activity log to mark as seen
 * @param {string} teamID The ID of the team associated with the activity
 * @param {string} surveyID The ID of the survey associated with the activity
 * @returns {Promise<void>} Resolves once the activity has been marked as seen
 */
export async function markActivityAsSeen(userID, activityLog, teamID, surveyID) {
    // In the background, remove the user from the notification worker
    removeUserFromNotificationWorker(
        userID,
        surveyID
    );

    await ActivityLogModel.writeToPath(
        `teams/${ teamID }/checkInSurveys/${ surveyID }/activityLogs/${ activityLog.id }`,
        {
            acknowledgedBy: arrayUnion(userID),
        },
        { mergeWithExistingValues: true }
    );
}

/**
 * Creates a survey for the given user.
 *
 * @param {string} userID The ID of the user to create the survey for
 * @param {string} teamID The ID of the team to create the survey for
 * @param {Object} surveyBatch The current survey batch information
 * @param {Object} surveyConfiguration The current survey configuration object
 * @param {Object[]} reviewerAssociations All of the team's current reviewer associations
 * @param {Object[]} teamGroups All of the team's current groups
 * @param {Object[]} teamCheckInQuestions All of the team's current check-in questions
 */
export async function sendSurveyToUser(
    userID,
    teamID,
    surveyBatch,
    surveyConfiguration,
    reviewerAssociations,
    teamGroups,
    teamCheckInQuestions
) {
    // Grab the team groups the user is a member of
    const userGroups = teamGroups.filter((group) => group.members.find((member) => member.value === userID));

    // Filter the user's groups to only include those that the user is not a reviewer for
    const userNonReviewerGroups = userGroups.filter((group) => !reviewerAssociations.find(
        (reviewerAssociation) => reviewerAssociation.groupID === group.id
            && reviewerAssociation.reviewerID === userID
    ));

    // Filter the user's non-reviewer groups to only include those that have other reviewer associations
    const userGroupsWithOtherReviewers = userNonReviewerGroups.filter((group) => {
        return reviewerAssociations.find(
            (reviewerAssociation) => reviewerAssociation.groupID === group.id
                && reviewerAssociation.reviewerID !== userID
        );
    });

    // Filter the check-in questions to only include those that include the user's groups with other reviewers +
    // questions directed to ALL users
    const userCheckInQuestions = teamCheckInQuestions.filter(
        (question) => question.groupToAsk === 'ALL'
            || userNonReviewerGroups.find((group) => question.groupToAsk === group.id)
    );

    // If the user has no check-in questions, throw an error
    if (userCheckInQuestions.length <= 0) {
        throw new Error(`No check-in questions found for user ${ userID }`);
    }

    // If the user has no groups with other reviewers, throw an error
    if (userGroupsWithOtherReviewers.length <= 0) {
        throw new Error(`No groups with reviewers found for user ${ userID }`);
    }

    // Create the survey for the user
    const autobatcher = new Autobatcher();

    const surveyID = doc(collection(getFirestore(), `teams/${ teamID }/checkInSurveys`)).id;

    CheckInSurveyModel.writeToPath(
        `teams/${ teamID }/checkInSurveys/${ surveyID }`,
        {
            userID,
            startDate:      surveyBatch.startDate,
            endDate:        surveyBatch.endDate,
            surveyBatchID:  surveyBatch.id,
            heartbeatValue: null,
            teamID,
            dateSubmitted:  null,
            reminderSent:   false,
        },
        { autobatcher }
    );

    // Filter the check-in questions for only required questions
    const requiredQuestions = userCheckInQuestions.filter(
        (question) => question.isAlwaysAsked === true
    );

    // Add all of the required questions to the survey
    for (const question of requiredQuestions) {
        CheckInSurveyQuestionModel.writeToNewDoc(
            `teams/${ teamID }/checkInSurveys/${ surveyID }/checkInSurveyQuestions`,
            {
                question:      question.content,
                isAlwaysAsked: question.isAlwaysAsked,
                isOptional:    question.isOptional,
                surveyID,
                teamID,
                groupID:       question.groupToAsk,
            },
            { autobatcher }
        );
    }

    // Filter the check-in questions for only rotational questions and then shuffle the list
    const rotationalQuestions = shuffle(
        userCheckInQuestions.filter(
            (question) => question.isAlwaysAsked === false
        )
    );

    // Randomly add only `n` rotational questions to the survey, based on the survey configuration
    let numRotatingQuestions = surveyConfiguration?.numRotatingQuestions === undefined
        // eslint-disable-next-line no-magic-numbers
        ? 3
        : surveyConfiguration.numRotatingQuestions;

    if (numRotatingQuestions > rotationalQuestions.length) {
        numRotatingQuestions = rotationalQuestions.length;
    }

    for (let rotatingQuestionNum = 0; rotatingQuestionNum < numRotatingQuestions; rotatingQuestionNum++) {
        const question = rotationalQuestions[ rotatingQuestionNum ];

        CheckInSurveyQuestionModel.writeToNewDoc(
            `teams/${ teamID }/checkInSurveys/${ surveyID }/checkInSurveyQuestions`,
            {
                question:      question.content,
                isAlwaysAsked: question.isAlwaysAsked,
                isOptional:    question.isOptional,
                surveyID,
                teamID,
                groupID:       question.groupToAsk,
            },
            { autobatcher }
        );
    }

    // Wait for the autobatcher to complete
    await autobatcher.allBatchesFinalized();

}

// #endregion

// #region Private Functions

/**
 * Retrieves the existing notification worker for the given team and survey.
 *
 * @param {string} surveyID The ID of the survey associated with the notification worker
 * @param {Transaction} transaction The Firestore transaction to use
 * @returns {Promise<Object | null>} Resolves with the existing notification worker or null if one does not exist
 */
async function getExistingNotificationWorker(surveyID, transaction) {
    const existingWorkers = await WorkerModel.getByQuery(
        [ where('searchableMetadata', 'array-contains', `surveyID=${ surveyID }`) ],
        { transaction }
    );

    return existingWorkers[ 0 ] || null;
}

/**
 * Removes the given user from the notification worker for the survey (if one exists).
 *
 * @param {string} userID The ID of the user to remove from the notification worker
 * @param {string} surveyID The ID of the survey associated with the notification worker
 */
function removeUserFromNotificationWorker(userID, surveyID) {
    runTransaction(async(transaction) => {
        const existingWorker = await getExistingNotificationWorker(
            surveyID,
            transaction
        );

        if (existingWorker) {
            const remainingRecipients = existingWorker.options.recipients.filter(
                (recipient) => recipient.id !== userID
            );

            if (remainingRecipients.length > 0) {
                await WorkerModel.writeToID(
                    existingWorker.id,
                    {
                        recipients: remainingRecipients,
                    },
                    {
                        transaction,
                        mergeWithExistingValues: true,
                    }
                );
            } else {
                await WorkerModel.deleteByID(
                    existingWorker.id,
                    { transaction }
                );
            }
        }
    });
}

/**
 * Get the recipients for the notification worker's creation / update.
 *
 * @param {Object} profile The profile of the user who left the comment
 * @param {Object[]} reviewers The profiles of any reviewers assigned to the survey
 * @param {Object} surveyOwner The survey owner's profile
 * @returns {string[]} The recipients for the notification worker's creation / update
 */
function getRecipients(profile, reviewers, surveyOwner) {
    const allUsers = concat(
        reviewers,
        surveyOwner
    );

    const recipients = filter(
        allUsers,
        (user) => user.id !== profile.id
    );

    return uniqBy(recipients, 'id');
}

/**
 * Gets the new notification worker's performAt date.
 *
 * @returns {Date} The new notification worker's performAt date
 */
function getNotificationWorkerPerformAtDate() {
    return DateTime.now()
        .plus({ minutes: 30 })
        .toJSDate();
}

/**
 * Updates or creates the notification worker for the given team and survey.
 *
 * @param {string} teamID The ID of the team associated with the notification worker
 * @param {string} surveyID The ID of the survey associated with the notification worker
 * @param {Object} profile The profile of the user triggering the notification worker's creation / update
 * @param {Object[]} reviewers Any reviewers assigned to the survey
 * @param {Object} surveyOwner The profile of the survey owner
 * @returns {Promise<void>} Resolves once the notification worker is created or updated
 */
function updateOrCreateNotificationWorker(teamID, surveyID, profile, reviewers, surveyOwner) {
    return runTransaction(async(transaction) => {
        const existingWorker = await getExistingNotificationWorker(
            surveyID,
            transaction
        );

        const recipients = getRecipients(
            profile,
            reviewers,
            surveyOwner
        );

        if (existingWorker) {
            await WorkerModel.writeToID(
                existingWorker.id,
                {
                    performAt: getNotificationWorkerPerformAtDate(),
                    options:   {
                        ...existingWorker.options,
                        recipients,
                    },
                },
                {
                    transaction,
                    mergeWithExistingValues: true,
                }
            );
        } else {
            await WorkerModel.writeToNewDoc(
                {
                    performAt:          getNotificationWorkerPerformAtDate(),
                    worker:             'sendNewActivityAlert',
                    searchableMetadata: [
                        `teamID=${ teamID }`,
                        `surveyID=${ surveyID }`,
                    ],
                    options: {
                        teamID,
                        surveyID,
                        surveyOwnerID: surveyOwner.id,
                        recipients,
                    },
                },
                { transaction }
            );
        }
    });
}

// #endregion
