import {
    Outlet,
} from 'react-router-dom';

/**
 * Wrapper for any personal non-premium routes.
 */
const TeamPersonalWrapper = ({

}) => {
    
    //#region Render Functions

    /**
     * Main render.
     */
    return (
        <Outlet/>
    );

    //#endregion
};

export default TeamPersonalWrapper;
