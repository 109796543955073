import PropTypes from 'prop-types';
import { useTrackFunction } from '@unifire-js/hooks';
import { Button } from '@psionic/ui';
import BulletinBoardContextLevel from '@contexts/bulletin-board';
import localStyles from './bulletin-board.module.scss';

/**
 * Bulletin board component that displays the given bulletin board's elements.
 */
function BulletinBoard({
    readOnly,
    onSave,
    onDiscard,
    children,
}) {
    // #region Context

    /**
     * Use the `hasEdits` context level value.
     */
    const hasEdits = BulletinBoardContextLevel.use.hasEdits.value();

    /**
     * Use the `hasEdits` context level API.
     */
    const hasEditsAPI = BulletinBoardContextLevel.use.hasEdits.api();

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Tracked Functions

    /**
     * Create a tracked version of the `onSave` function.
     */
    const [
        performingSave,
        performSave,
    ] = useTrackFunction(async() => {
        await onSave();
        hasEditsAPI.set(false);
    });

    /**
     * Create a tracked version of the `onDiscard` function.
     */
    const [
        performingDiscard,
        performDiscard,
    ] = useTrackFunction(async() => {
        await onDiscard();
        hasEditsAPI.set(false);
    });

    // #endregion

    // #region Variables

    /**
     * Flag indicating whether an action is currently running.
     */
    const actionRunning = performingSave || performingDiscard;

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Renders an empty state for the bulletin board.
     */
    const renderEmptyState = () => { return (
        <p className={localStyles.emptyStateText}>Your team has not added anything to this bulletin board yet!</p>
    ); };

    /**
     * Main render.
     */
    return (
        <section className={localStyles.bulletinBoard}>
            <header>
                {
                    readOnly
                        ? null
                        : (
                            <div className={localStyles.saveAndDiscardSection}>
                                <Button
                                    color='reject'
                                    disabled={!hasEdits || actionRunning}
                                    variant='contained'
                                    darkMode
                                    onClick={performDiscard}
                                >
                                    Discard Changes
                                </Button>
                                <Button
                                    disabled={!hasEdits || actionRunning}
                                    variant='contained'
                                    darkMode
                                    onClick={performSave}
                                >
                                    Publish
                                </Button>
                            </div>
                        )
                }
            </header>
            <section>
                {
                    readOnly && (!children || children.length <= 0)
                        ? renderEmptyState()
                        : children
                }
            </section>
        </section>
    );

    // #endregion
}

BulletinBoard.propTypes = {
    /**
     * Children to render within the bulletin board.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    /**
     * The callback for when the discard button is clicked.
     */
    onDiscard: PropTypes.func.isRequired,
    /**
     * The callback for when the save button is clicked.
     */
    onSave:    PropTypes.func.isRequired,
    /**
     * Flag indicating whether the bulletin board is in a read only state or not.
     */
    readOnly:  PropTypes.bool,
};

BulletinBoard.defaultProps = {
    readOnly: true,
    hasEdits: false,
};

export default BulletinBoard;
