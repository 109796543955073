import PropTypes from 'prop-types';
import {
    Popover,
} from '@mui/material';
import { DateTime } from 'luxon';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

/**
 * Popover for picking a date.
 */
function DatePickerPopover({
    anchorEl,
    open,
    onClose,
    onDateSelected,
    minDate,
    maxDate,

    // Pass-thru props
    ...passThruProps
}) {
    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    /**
     * Augmented callback for when a date is selected, which closes the popover before
     * continuing with the callback passed to the component.
     */
    const augmentedOnDateSelected = (value) => {
        onClose();
        onDateSelected(DateTime.fromJSDate(value));
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            {...passThruProps}
        >
            <StaticDatePicker
                displayStaticWrapperAs='desktop'
                maxDate={maxDate}
                minDate={minDate}
                openTo='day'
                renderInput={() => {}}
                value={null}
                onChange={augmentedOnDateSelected}
            />
        </Popover>
    );

    // #endregion
}

DatePickerPopover.propTypes = {
    /**
     * The HTML element to anchor to.
     */
    anchorEl:       PropTypes.object,
    /**
     * Maximum date that can be selected. Javascript `Date` object.
     */
    maxDate:        PropTypes.object,
    /**
     * Minimum date that can be selected. Javascript `Date` object.
     */
    minDate:        PropTypes.object,
    /**
     * Callback for when the popover should be closed.
     */
    onClose:        PropTypes.func.isRequired,
    /**
     * Callback for when a date is selected.
     */
    onDateSelected: PropTypes.func,
    /**
     * Flag indicating whether the popover should be open or not.
     */
    open:           PropTypes.bool,
    /**
     * Any remaining props to pass to the internal MUI `Popover` component.
     */
    passThruProps:  PropTypes.object,
};

DatePickerPopover.defaultProps = {

};

export default DatePickerPopover;
