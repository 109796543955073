import { createRoot } from 'react-dom/client';
import Root from './root/root';
import './robots.txt';

const render = (Component) => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<Component/>);
};

render(Root);
