import { Model } from '@unifire-js/firebase/firestore';

const ProfileModel = new Model({
    collectionName:  'profiles',
    collectionProps: [
        'displayName',
        'email',
        'emailVerified',
        'avatarURL',
        'stripeCustomerID',
        'unsubscribedFromEmails',
        'lastAppVersionSeen',
    ],
    propDefaults: {
        emailVerified:          false,
        unsubscribedFromEmails: false,
    },
});

export default ProfileModel;
