import { useEffect, useState } from 'react';
import { Error } from '@mui/icons-material';
import { useUserContext } from '@unifire-js/firebase/auth';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Snackbar } from '@psionic/ui';
import ReleaseNotesService from '@services/release-notes/release-notes-service';
import { SnackbarService } from '@services/snackbar';
import {
    LandingPageHeader,
    Footer,
} from '@components/core';
import {
    LoadingPage,
} from '@components/loading';
import localStyles from './release-notes.module.scss';

function ReleaseNotes() {
    const navigate = useNavigate();

    const { user, profile } = useUserContext();

    const [
        releaseNotes,
        setReleaseNotes,
    ] = useState(undefined);

    const [
        searchParams,
        // eslint-disable-next-line no-unused-vars
        setSearchParams,
    ] = useSearchParams();

    useEffect(() => {
        if (!profile || !searchParams.get('version')) {
            return;
        }

        if (ReleaseNotesService.getCurrentVersion() === searchParams.get('version')) {
            ReleaseNotesService.markVersionAsSeenByUser(profile);
        }
    }, [
        profile,
        searchParams,
    ]);

    useEffect(() => {
        if (!searchParams.get('version')) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text='No version specified. Redirecting...'
                    />
                ); }
            );

            navigate('/home');
        }
    }, [ searchParams ]);

    useEffect(() => {
        async function performAction() {
            const version = searchParams.get('version');

            if (!version) {
                return;
            }

            try {
                const foundReleaseNotes = await ReleaseNotesService.getReleaseNotes(version);

                if (!foundReleaseNotes) {
                    throw new Error('Could not find release notes.');
                }

                setReleaseNotes(foundReleaseNotes);
            } catch (err) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='reject'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Error}
                            text='No release notes found. Redirecting...'
                        />
                    ); }
                );

                navigate('/home');
            }
        }

        performAction();
    }, [ searchParams ]);

    if (releaseNotes === undefined) {
        return <LoadingPage/>;
    }

    return (
        <>
            <LandingPageHeader
                profile={profile}
                user={user}
                displaysWorkspaceAndNotifications
            />
            <main
                className={[
                    'nonWorkspacePage',
                    localStyles.container,
                ].join(' ')}
            >
                <header>
                    <h1>
                        <b>
                            v
                            {searchParams.get('version')}
                        </b>
                        {' '}
                        Release Notes
                    </h1>
                </header>
                <div
                    className={[
                        'pageContent',
                        localStyles.content,
                    ].join(' ')}
                >
                    {
                        releaseNotes?.desc.map((section, index) => {
                            return renderSection(section, index);
                        })
                    }
                </div>
            </main>
            <Footer/>
        </>
    );
}

export default ReleaseNotes;

// #region Helper Functions

function renderSection(section, index) {
    switch (section.type) {
        case 'p':
            return (
                <p key={index}>
                    {section.text}
                </p>
            );
        case 'ul':
            return (
                <ul key={index}>
                    {
                        section.values.map((value, listIndex) => {
                            return (
                                <li key={listIndex}>
                                    {value}
                                </li>
                            );
                        })
                    }
                </ul>
            );
        default:
            throw new Error('Invalid section type.');
    }
}

// #endregion
