import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    verifyPasswordResetCode,
    confirmPasswordReset,
    getAuth,
} from 'firebase/auth';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    useRedirectOnCondition,
} from '@unifire-js/hooks';
import { Error, Check } from '@mui/icons-material';
import {
    Snackbar,
    Button,
    Form,
    TextField,
} from '@psionic/ui';
import { loginUser } from '@services/user';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import { LoadingPage } from '@components/loading';
import {
    LandingPageHeader,
    Footer,
} from '@components/core';
import localStyles from './reset-password.module.scss';

/**
 * View for resetting the user's password.
 */
function ResetPassword() {
    // #region Hooks

    /**
     * Use the `navigate` function from `react-router-dom`.
     */
    const navigate = useNavigate();

    /**
     * Use the user context.
     */
    const { profile, user } = useUserContext();

    /**
     * Hook for grabbing search params from the URL.
     */
    const [
        searchParams,
        // eslint-disable-next-line no-unused-vars
        setSearchParams,
    ] = useSearchParams();

    /**
     * Track the account email in state.
     */
    const [
        accountEmail,
        setAccountEmail,
    ] = useState(null);

    /**
     * Attempt to verify the action when the component mounts.
     */
    const actionVerified = useRedirectOnCondition(
        '/send-password-reset',
        async() => {
            try {
                const email = await verifyPasswordResetCode(
                    getAuth(),
                    searchParams.get('oobCode')
                );
                setAccountEmail(email);

                return false;
            } catch (err) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='error'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Error}
                            text='This reset password link is invalid and may have expired. Try requesting a new one.'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }
        },
        []
    );

    // #endregion

    // #region Functions

    /**
     * Submit the reset password request.
     */
    const submitResetPasswordRequest = async(formData) => {
        try {
            await confirmPasswordReset(
                getAuth(),
                searchParams.get('oobCode'),
                formData.password.value
            );

            if (accountEmail) {
                await loginUser(
                    accountEmail,
                    formData.password.value
                );
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='approve'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Check}
                            text='Password reset successfully! You are now logged in!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );
                navigate('/home');
            } else {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='approve'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Check}
                            text='Password reset successfully! Try logging in!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );
                navigate('/home/login');
            }
        } catch (err) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text='We encountered an error while attempting to reset your password.'
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * If the action has not been verified yet, then return a loading page.
     */
    if (!actionVerified) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <>
            <LandingPageHeader
                profile={profile}
                user={user}
            />
            <main>
                <Form
                    className={`nonWorkspacePage ${ localStyles.resetPasswordPage }`}
                    onSubmit={submitResetPasswordRequest}
                >
                    <header>
                        <h1>
                            Reset Password
                        </h1>
                    </header>
                    <div className='pageContent'>
                        <div className={localStyles.form}>
                            <p>
                                Enter your new password:
                            </p>
                            <div className={localStyles.fields}>
                                <TextField
                                    fieldKey='password'
                                    label='New Password'
                                    type='password'
                                    validator={(value) => {
                                        if (value.length < CORE.MIN_PASSWORD_LENGTH) {
                                            return `Password must be at least ${ CORE.MIN_PASSWORD_LENGTH } characters long`;
                                        }

                                        return null;
                                    }}
                                    darkMode
                                    required
                                />
                                <TextField
                                    fieldKey='passwordRepeat'
                                    label='New Password (Repeat)'
                                    type='password'
                                    validator={(value, formData) => {
                                        if (value !== formData.password.value) {
                                            return 'Passwords do not match';
                                        }

                                        return null;
                                    }}
                                    darkMode
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        type='submit'
                        variant='contained'
                        darkMode
                    >
                        Reset Password
                    </Button>
                </Form>
            </main>
            <Footer/>
        </>
    );

    // #endregion
}

export default ResetPassword;
