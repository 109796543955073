import { signOut } from 'firebase/auth';
import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';
import { auth } from '@services/firebase';

/**
 * Attempt to sign a user out of their Firebase auth account.
 */
export default async function() {
    try {
        await signOut(auth);
    } catch (err) {
        throw ERRORS.factories.UNDEFINED_ERROR();
    }
}

/**********************
 * CATEGORIZED ERRORS *
 **********************/

const ERRORS = new CategorizedErrorFactory({
    UNDEFINED_ERROR: {
        message: 'We encountered an issue while attempting to log you out!',
    }
});