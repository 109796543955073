import { useMemo, useEffect } from 'react';
import map from 'lodash/map';
import { Button, CircularSpinner } from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import {
    startNextBiddingRound,
    getPrivateInfo,
} from '@services/bidding-room';
import { useAsyncMemo } from '@hooks/async';
import { BiddingRoomLayout } from '@components/page-layouts';
import { Histogram } from '@components/graphs';
import { UserVotesList } from '@components/lists';
import localStyles from './results.module.scss';

/**
 * Results view.
 */
function Results() {
    // #region Hooks

    /**
     * Use the bidding room public info context value.
     */
    const biddingRoomPublicInfo = BiddingRoomContextLevel.use.biddingRoomPublicInfo.value();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the team members context value.
     */
    const teamMembers = TeamContextLevel.use.teamMembers.value();

    /**
     * Use the "has admin permissions" context value.
     */
    const hasAdminPermissions = TeamContextLevel.use.hasAdminPermissions.value();

    /**
     * Memoized room private info.
     */
    const [
        roomPrivateInfo,
        roomPrivateInfoFetched,
    ] = useAsyncMemo(() => {
        return getPrivateInfo(currentTeam.id);
    }, []);

    /**
     * Memoized graph x values.
     */
    const graphXValues = useMemo(() => {
        return map(Object.values(biddingRoomPublicInfo?.deck || {}), (card) => { return card.value; });
    }, []);

    /**
     * Memoized graph y values.
     */
    const graphYValues = useMemo(() => {
        // Construct a base array of zeros for each card count
        const values = [];

        for (let cardIndex = 0; cardIndex < Object.keys(biddingRoomPublicInfo?.deck || {}).length; cardIndex++) {
            values.push(0);
        }

        // If the necessary data hasn't loaded yet, return early
        if (!roomPrivateInfo) {
            return values;
        }

        // Otherwise, start counting votes
        for (const userVote of Object.values(roomPrivateInfo?.userVotes || {})) {
            values[ userVote ]++;
        }

        return values;
    }, [ roomPrivateInfo ]);

    /**
     * If the room private info has been fetched and the voting information is not available, then we
     * need to start the next bidding round, since an error occurred.
     */
    useEffect(() => {
        if (roomPrivateInfoFetched && !roomPrivateInfo?.userVotes) {
            startNextBiddingRound(currentTeam?.id, biddingRoomPublicInfo.users);
        }
    }, [
        roomPrivateInfo,
        roomPrivateInfoFetched,
    ]);

    // #endregion

    // #region Render Functions

    /**
     * If the room private info has not been fetched yet, return a loading page.
     */
    if (!roomPrivateInfoFetched) {
        return (
            <div className={localStyles.loading}>
                <CircularSpinner size={120}/>
            </div>
        );
    }

    /**
     * Main render.
     */
    return (
        <BiddingRoomLayout
            hasAdminPermissions={hasAdminPermissions}
            title='Results'
            actionsChildren={
                hasAdminPermissions
                    ? (
                        <Button
                            variant='contained'
                            darkMode
                            onClick={() => {
                                return startNextBiddingRound(currentTeam?.id, biddingRoomPublicInfo.users);
                            }}
                        >
                            Start Next Round
                        </Button>
                    )
                    : null
            }
        >
            <section className={localStyles.graph}>
                <Histogram
                    title='Team Votes'
                    x={graphXValues}
                    y={graphYValues}
                />
            </section>
            <section className={localStyles.voteList}>
                <UserVotesList
                    deck={biddingRoomPublicInfo.deck}
                    teamMembers={teamMembers}
                    userVotes={roomPrivateInfo?.userVotes || {}}
                />
            </section>
        </BiddingRoomLayout>
    );

    // #endregion
}

export default Results;
