import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    find,
    filter,
    cloneDeep,
} from 'lodash';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import { Autobatcher } from '@unifire-js/firebase/firestore';
import { ROLES } from '@models/association';
import ElementModel from '@models/element';
import { ELEMENT_TYPES } from '@utils/constants';
import {
    move,
    addAfter,
} from '@utils/array-operations';
import TeamContextLevel from '@contexts/team';
import BulletinBoardContextLevel from '@contexts/bulletin-board';
import { usePageLoading } from '@hooks/async';
import { BulletinBoardTracker } from '@services/bulletin-board';
import { LoadingPage } from '@components/loading';
import {
    ElementWrapper,
    BlockSelectMenu,
} from '@components/custom-ui-global';
import { BulletinBoard } from '@components/custom-ui-boards';
import {
    TextBlock,
    HeadingOneBlock,
} from '@components/custom-ui-blocks';
import localStyles from './index.module.scss';

/**
 * The index page for the bulletin solution route.
 */
function Bulletin() {
    // #region Misc Hooks

    /**
     * Use the URL search params.
     */
    // eslint-disable-next-line no-unused-vars
    const [
        searchParams,
        // eslint-disable-next-line no-unused-vars
        setSearchParams,
    ] = useSearchParams();

    // #endregion

    // #region Context

    /**
     * Use the user's association to the team from the Team context level.
     */
    const userAssociation = TeamContextLevel.use.userAssociation.value();

    /**
     * Use the current team value from the Team context level.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the bulletin tracker from the bulletin board context level.
     */
    const bulletinTracker = BulletinBoardContextLevel.use.bulletinTracker.value();

    /**
     * Use the bulletin tracker API from the bulletin board context level.
     */
    const bulletinTrackerAPI = BulletinBoardContextLevel.use.bulletinTracker.api();

    /**
     * Use the `hasEdits` context level API.
     */
    const hasEditsAPI = BulletinBoardContextLevel.use.hasEdits.api();

    // #endregion

    // #region State

    /**
     * Track the bulletin in state.
     */
    const [
        bulletin,
        setBulletin,
    ] = useState(undefined);

    /**
     * Track the bulletin elements in state.
     */
    const [
        bulletinElements,
        setBulletinElements,
    ] = useState(undefined);

    /**
     * Track a local copy of the bulletin element ordering.
     */
    const [
        elementIDsInOrder,
        setElementIDsInOrder,
    ] = useState(undefined);

    /**
     * Track the block select menu anchor.
     */
    const [
        blockSelectMenuAnchor,
        setBlockSelectMenuAnchor,
    ] = useState(null);

    // #endregion

    // #region Memoized Values

    /**
     * Memoize a `readOnly` flag based upon the user's association to the team.
     */
    const readOnly = useMemo(() => {
        return ![
            ROLES.ADMIN,
            ROLES.OWNER,
        ].includes(userAssociation?.role);
    }, [ userAssociation ]);

    /**
     * Memoize the relevant bulletin ID from the URL search params.
     */
    const bulletinID = useMemo(
        () => {
            // If no bulletin ID has been specified, then default to the root bulletin
            return searchParams.get('bulletinID') || 'root';
        },
        [ searchParams ]
    );

    // #endregion

    // #region Functions

    /**
     * Saves the data to the database.
     */
    const saveElements = async() => {
        const autobatcher = new Autobatcher();

        // Write the elements out first
        for (const element of bulletinTracker.elements) {
            ElementModel.writeToPath(
                `teams/${ currentTeam?.id }/bulletins/${ bulletinID }/elements/${ element.id }`,
                element,
                { autobatcher }
            );
        }

        // Delete any elements that should be deleted
        for (const elementID of bulletinTracker.elementsToDelete) {
            ElementModel.deleteByPath(
                `teams/${ currentTeam?.id }/bulletins/${ bulletinID }/elements/${ elementID }`,
                { autobatcher }
            );
        }

        // Then, write the bulletin board itself
        currentTeam.subcollections.bulletins.writeToID(
            bulletinID,
            {
                ...bulletin,
                elementIDsInOrder,
            },
            { autobatcher }
        );

        // Wait for the elements to save
        await autobatcher.allBatchesFinalized();
    };

    /**
     * Sets the data to be whatever is currently in the database.
     */
    const syncWithDatabase = async() => {
        setBulletin(undefined);
        setBulletinElements(undefined);
        const loadedBulletin = await loadBulletin();
        const loadedBulletinElements = await loadBulletinElements(loadedBulletin);
        bulletinTrackerAPI.set(new BulletinBoardTracker(loadedBulletinElements || []));
    };

    /**
     * Load the bulletin board for the page.
     */
    const loadBulletin = async() => {
        const fetchedBulletin = await currentTeam.subcollections.bulletins.getByID(bulletinID);
        setBulletin(fetchedBulletin || { elementIDsInOrder: [] });
        setElementIDsInOrder(fetchedBulletin?.elementIDsInOrder || []);

        return fetchedBulletin;
    };

    /**
     * Load the bulletin board's elements.
     */
    const loadBulletinElements = async(fetchedBulletin) => {
        // If no bulletin has been loaded, set the elements to an empty array
        if (!fetchedBulletin) return setBulletinElements([]);

        // Otherwise, fetch the bulletin's elements
        const fetchedBulletinElements = await fetchedBulletin.subcollections.elements.getByQuery([]);
        setBulletinElements(fetchedBulletinElements);

        return fetchedBulletinElements;
    };

    /**
     * Creates a new element of a specified type.
     */
    const createNewElement = (elementType) => {
        return {
            id:     ElementModel.createNewDocID(`teams/${ currentTeam.id }/bulletins/${ bulletinID }/elements`),
            type:   elementType,
            value:  ELEMENT_TYPES[ elementType ]?.defaultValue,
            pinned: false,
        };
    };

    /**
     * Get the component for the specified element type.
     *
     * @param {string} elementTypeID The ID of the element type to get the component for
     */
    const getComponentForElementType = (elementTypeID) => {
        switch (elementTypeID) {
            case ELEMENT_TYPES.TEXT.id:
                return TextBlock;
            case ELEMENT_TYPES.HEADING_1.id:
                return HeadingOneBlock;
            default:
                throw new Error(`Unknown element type ID: \`${ elementTypeID }\``);
        }
    };

    // #endregion

    // #region Page Loading

    // eslint-disable-next-line no-unused-vars
    const [
        pageLoading,
        // eslint-disable-next-line no-unused-vars
        pageLoadingErr,
    ] = usePageLoading([ syncWithDatabase ]);

    // #endregion

    // #region Render Functions

    /**
     * Sorted renders of the bulletin elements.
     */
    const sortedBulletinElementComponents = useMemo(() => {
        // If the data hasn't loaded yet, return null
        if (!bulletinElements || !elementIDsInOrder) return null;

        // Otherwise, create an array of elements, wrapped in element wrappers
        const components = [];

        for (let elementIndex = 0; elementIndex < elementIDsInOrder.length; elementIndex++) {
            // Find the specified bulletin element
            const elementID = elementIDsInOrder[ elementIndex ];
            const bulletinElement = find(bulletinElements, [
                'id',
                elementID,
            ]);

            // Push the element to the components array
            components.push(
                <ElementWrapper
                    key={elementID}
                    Element={getComponentForElementType(ELEMENT_TYPES[ bulletinElement?.type ]?.id)}
                    ElementProps={bulletinElement}
                    index={elementIndex}
                    pinned={bulletinElement?.pinned}
                    readOnly={readOnly}
                    onAdd={(elementType) => {
                        const newElement = createNewElement(elementType);
                        setBulletinElements((prev) => {
                            prev.push(newElement);

                            return prev;
                        });
                        setElementIDsInOrder((prev) => {
                            return addAfter(prev, elementIndex, newElement.id);
                        });
                        bulletinTracker.addElementAfter(newElement, elementIndex);
                    }}
                    onDelete={() => {
                        setBulletinElements((prev) => {
                            return filter(prev, (element) => {
                                return element.id !== elementID;
                            });
                        });
                        setElementIDsInOrder((prev) => {
                            return filter(prev, (itrElementID) => {
                                return itrElementID !== elementID;
                            });
                        });
                        bulletinTracker.removeElement(elementIndex);
                    }}
                    onDuplicate={(currentValue) => {
                        const newElementID = ElementModel.createNewDocID(
                            `teams/${ currentTeam.id }/bulletins/${ bulletinID }/elements`
                        );
                        const clonedElement = {
                            ...cloneDeep(bulletinElement),
                            value: currentValue,
                            id:    newElementID,
                        };
                        setBulletinElements((prev) => {
                            prev.push(clonedElement);

                            return prev;
                        });
                        setElementIDsInOrder((prev) => {
                            return addAfter(prev, elementIndex, newElementID);
                        });
                        bulletinTracker.addElementAfter(clonedElement, elementIndex);
                    }}
                    onMove={(from, to) => {
                        setElementIDsInOrder((prev) => {
                            return move(prev, from, to);
                        });
                        bulletinTracker.moveElement(from, to);
                    }}
                    onPinElement={() => {
                        bulletinTracker.updateElement({
                            ...bulletinElement,
                            pinned: !bulletinElement.pinned,
                        });
                    }}
                />
            );
        }

        return components;
    }, [
        bulletinElements,
        elementIDsInOrder,
    ]);

    /**
     * Render of the empty state for users with write permissions.
     */
    const writeEmptyState = (
        <>
            <BlockSelectMenu
                anchorEl={blockSelectMenuAnchor}
                onClose={() => {
                    return setBlockSelectMenuAnchor(null);
                }}
                onSelect={(elementType) => {
                    const newElement = createNewElement(elementType);
                    setBulletinElements([ newElement ]);
                    setElementIDsInOrder([ newElement.id ]);
                    bulletinTracker.setElements([ newElement ]);
                    hasEditsAPI.set(true);
                }}
            />
            <section className={localStyles.emptyState}>
                <p>
                    Your team has not added any data to this bulletin board yet. Click the button below
                    to add your first content block!
                </p>
                <Button
                    variant='contained'
                    onClick={(event) => {
                        return setBlockSelectMenuAnchor(event.currentTarget);
                    }}
                >
                    Add First Content Block
                </Button>
            </section>
        </>
    );

    /**
     * If the page's initial load is ongoing, display a loading state.
     */
    if (pageLoading) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <div className='workspacePage'>
            <header>
                <h1>Bulletin Board</h1>
            </header>
            <section className='pageContent'>
                {
                    !bulletinElements || !bulletin || !sortedBulletinElementComponents
                        ? <CircularProgress/>
                        : (
                            <BulletinBoard
                                readOnly={readOnly}
                                onDiscard={syncWithDatabase}
                                onSave={saveElements}
                            >
                                {sortedBulletinElementComponents}
                            </BulletinBoard>
                        )
                }
                {
                    sortedBulletinElementComponents?.length <= 0 && !readOnly
                        ? writeEmptyState
                        : null
                }
            </section>
        </div>
    );

    // #endregion
}

/**
 * Wrap the bulletin board component in a context level provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ContextWrapper() {
    return (
        <BulletinBoardContextLevel.Provider>
            <Bulletin/>
        </BulletinBoardContextLevel.Provider>
    );
}

export default ContextWrapper;
