import PropTypes from 'prop-types';
import { EditorContent } from '@tiptap/react';
import {
    useTextEditor,
    useBulletinTracker,
} from '@hooks/tiptap';
import { TextContextMenu } from '@components/custom-ui-global';
import localStyles from './text-block.module.scss';

/**
 * General text block component.
 */
function TextBlock({
    elementID,
    value,
    readOnly,
}) {

    // #region Text Editor Hook

    /**
     * Use the `useTextEditor` hook.
     */
    const [
        unsavedValue,
        editor,
        editorContentRef,
        contextMenuProps,
    ] = useTextEditor({
        readOnly,
        initialValue: value,
    });

    /**
     * Use the bulletin tracker.
     */
    useBulletinTracker(elementID, unsavedValue);

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            <TextContextMenu {...contextMenuProps}/>
            <div
                ref={editorContentRef}
                className={localStyles.textBlock}
            >
                <EditorContent editor={editor}/>
            </div>
        </>
    );

    // #endregion
}

TextBlock.propTypes = {
    /**
     * The ID of the element the text block represents.
     */
    elementID: PropTypes.string.isRequired,
    /**
     * Flag indicating whether the text block is read-only or not.
     */
    readOnly:  PropTypes.bool,
    /**
     * The initial value for the text block, as a JSON string (for TipTap).
     */
    value:     PropTypes.any,
};

TextBlock.defaultProps = {
    readOnly: false,
    value:    {},
};

export default TextBlock;
