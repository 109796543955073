
//#region Public Functions

/**
 * Moves an element at one index to another index.
 *
 * @param {*[]} arr The array to operate on
 * @param {number} fromIndex The index of the element to move
 * @param {number} toIndex The index to move the element to
 * @returns {*[]} The array with the element moved
 */
export function move(arr, from, to) {
    const newArr = [];
    let oldIndex = 0;
    while (true) {
        if (newArr.length === to) {
            newArr.push(arr[from]);
            continue;
        }

        if (oldIndex >= arr.length) {
            break;
        }


        if (oldIndex !== from) {
            newArr.push(arr[oldIndex++]);
            continue;
        }
        oldIndex++;
    }
    return newArr;
}

/**
 * Duplicates an element at an index, inserting the new copy immediately after
 * the original element.
 *
 * @param {*[]} arr The array to operate on
 * @param {number} index The index of the element to duplicate
 * @returns {*[]} The array with the element duplicated
 */
export function duplicate(arr, index) {
    return arr.reduce((prev, current, idx) => {
        if (idx === index) {
            prev.push(current);
            prev.push(current);
        } else {
            prev.push(current);
        }
        return prev;
    }, []);
}

/**
 * Removes the element at the given index.
 *
 * @param {*[]} arr The array to operate on
 * @param {number} index The index of the element to remove
 * @returns {*[]} The array with the element removed
 */
export function remove(arr, index) {
    return arr.reduce((prev, current, idx) => {
        if (idx !== index) {
            prev.push(current);
        }
        return prev;
    }, []);
}

/**
 * Adds the specified item to the given array after the specified index.
 *
 * @param {*[]} arr The array to operate on
 * @param {number} index The index of the element to add after
 * @param {*} item The item to add to the array after the specified index
 * @returns {*[]} The array with the given element added
 */
export function addAfter(arr, index, item) {
    return arr.reduce((prev, current, idx) => {
        prev.push(current);
        if (idx === index) {
            prev.push(item);
        }
        return prev;
    }, []);
}

//#endregion

//#region Private Functions

//#endregion
