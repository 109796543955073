import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
    Error,
} from '@mui/icons-material';
import {
    useRedirectOnAuthCondition,
} from '@unifire-js/firebase/auth';
import { Snackbar } from '@psionic/ui';
import { SnackbarService } from '@services/snackbar';
import { LoginCard } from '@components/cards';
import { LoadingPage } from '@components/loading';
import {
    createUser,
    loginUser,
} from '@services/user';
import { CORE } from '@utils/constants';
import localStyles from './login.module.scss';

/**
 * Login / Sign Up page for users to login and sign up for an Eager
 * Lead account.
 */
function Login() {
    // #region Hooks

    /**
     * Use the `navigate` function from `react-router-dom`.
     */
    const navigate = useNavigate();

    /**
     * Set up a media query to flip between a desktop and mobile view.
     */
    const isMobileView = useMediaQuery('(max-width: 1200px)');

    /**
     * Hook for automatically redirecting the user to the home route
     * if they are signed in and their email is verified.
     */
    const hasLoaded = useRedirectOnAuthCondition(
        '/home',
        (userContext) => { return userContext.profile?.emailVerified; }
    );

    /**
     * Hook for automatically redirecting the user to the awaiting
     * email verification route if they are signed in and their email
     * is not verified.
     */
    useRedirectOnAuthCondition(
        '/user/awaiting-email-verification',
        (userContext) => { return userContext.profile && !userContext.profile.emailVerified; }
    );

    /**
     * Use the login card imperative handle.
     */
    const loginCardRef = useRef();

    // #endregion

    // #region Functions

    /**
     * Attempt to log a user in. Display an error snackbar if an
     * error occurs.
     *
     * @param {Object} formData The form data the user provided
     * @returns {Promise<null>} Resolves when the user is either logged
     * in or if login has failed
     */
    const login = async(formData) => {
        try {
            await loginUser(formData?.email?.value, formData?.password?.value);
        } catch (err) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text={err.message}
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        }
    };

    /**
     * Attempt to sign a user up for a new account. Display an error
     * snackbar if an error occurs.
     *
     * @param {Object} formData The form data the user provided
     * @returns {Promise<null>} Resolves when the user is either signed
     * up or if sign up has failed
     */
    const signUp = async(formData) => {
        try {
            await createUser(
                formData?.email?.value,
                formData?.password?.value,
                formData?.confirmPassword?.value
            );
        } catch (err) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text={err.message}
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        }
    };

    /**
     * Handler for when the "Forgot Password" button is clicked.
     */
    const onForgotPasswordClicked = () => {
        navigate('/home/send-password-reset');
    };

    // #endregion

    // #region Render Functions

    /**
     * If the page's auth user context has not loaded yet, display the
     * loading page.
     */
    if (!hasLoaded) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pageWrapper}>
            {
                isMobileView
                    ? null
                    : (
                        <div className={localStyles.backgroundWrapper}>
                            <div className={localStyles.blueHalf}/>
                            <div className={localStyles.whiteHalf}/>
                        </div>
                    )
            }
            <LoginCard
                ref={loginCardRef}
                className={localStyles.cardAdjustments}
                forgotPasswordFn={onForgotPasswordClicked}
                loginFn={login}
                signUpFn={signUp}
            />
        </div>
    );

    // #endregion
}

export default Login;
