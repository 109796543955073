
//#region Public Functions

/**
 * Opens the given link in a new tab, forcing the link to be absolute, if it isn't already.
 *
 * @param {string} link The link to open in a new tab
 */
export function openLinkInNewTab(link) {
    const absoluteLink = prependWithHTTPIfNeeded(link);
    window.open(absoluteLink, '_blank');
}

//#endregion

//#region Private Functions

/**
 * Inserts `http://` before the link, if it isn't already present.
 *
 * @param {string} link The link to test
 * @returns {string} The link, prepended with `http://`, if it wasn't already prepended by that string
 */
function prependWithHTTPIfNeeded(link) {
    const httpPrefixRegex = /^http(s)?:\/\/*/;
    return httpPrefixRegex.test(link) ? link : `http://${link}`;
}

//#endregion
