import { Middleware } from '@unifire-js/firebase/auth';
import ProfileModel from '@models/profile';

/**
 * Global storage for middleware.
 */

const middlewareMemory = {};

/**
 * Middleware to initialize a profile listener for the user's associated profile whenever auth state
 * changes. This listener will automatically set the `profile` property in the user context whenever
 * changes are detected to the associated profile.
 */
export const initializeProfileListener = new Middleware({
    fn: (user, updateState) => {
        try {
            const listenerName = user ? `${ user.uid }-profileListener` : null;
            const prevListenerName = middlewareMemory.prevProfileListenerName;

            // We only need to update / create a listener if the new name does not match the previous
            // listener name
            if (listenerName !== prevListenerName) {
                // If a previous listener name existed, we have to remove that listener from the
                // ProfileModel first
                if (prevListenerName) {
                    ProfileModel.removeListener(prevListenerName);
                }

                // If the new listener name is not null, we then want to create the new listener
                if (listenerName) {
                    ProfileModel.addListenerByID(
                        listenerName,
                        user.uid,
                        (doc) => {
                            // This will update the user context to have a `profile` property that is
                            // always updated via the listener
                            updateState({ profile: doc });
                        }
                    );
                }

                // If the new listener name is null, then we want to update state, stating that the profile not longer
                // exists
                if (!listenerName) {
                    updateState({ profile: null });
                }

                // We then want to set the previous listener name to the new listener name
                middlewareMemory.prevProfileListenerName = listenerName;
            }
        } catch {
            // Pass
        }
    },
});
