import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
    IconButton,
} from '@mui/material';
import {
    DragIndicator,
    Add,
    DeleteOutline,
    ContentCopy,
} from '@mui/icons-material';
import BulletinBoardContextLevel from '@contexts/bulletin-board';
import {
    DRAG_DROP_TYPES,
} from '@utils/constants';
import { ActionTooltip } from '@components/popovers';
import { BlockSelectMenu } from '@components/custom-ui-global';
import { ActionMenu } from '@components/menus';
import localStyles from './element-wrapper.module.scss';

/**
 * Wrapper for any custom UI elements to provide controls to the user.
 */
function ElementWrapper({
    Element,
    ElementProps,
    readOnly,
    index,
    onMove,
    onAdd,
    onDelete,
    onDuplicate,
}) {
    // #region Drag Hook

    const [
        dragProps,
        drag,
        preview,
    ] = useDrag(() => {
        return {
            type:    DRAG_DROP_TYPES.ELEMENT,
            item:    { index },
            collect: (monitor) => {
                return {
                    isDragging: !!monitor.isDragging(),
                };
            },
        };
    }, [ index ]);

    // #endregion

    // #region Drop Hook

    const [
        dropProps,
        drop,
    ] = useDrop(() => {
        return {
            accept: DRAG_DROP_TYPES.ELEMENT,
            drop:   (item) => {
                onMove(item.index, index);
                bulletinBoardHasEditsAPI.set(true);
            },
            canDrop: (item) => {
                return index !== item.index;
            },
            collect: (monitor) => {
                return {
                    isOver:  !!monitor.isOver(),
                    canDrop: !!monitor.canDrop(),

                    // Flag indicating whether the new target is above the current source
                    // or not
                    targetIsAbove: monitor.getItem()?.index < index,
                };
            },
        };
    }, [ index ]);

    // #endregion

    // #region Drag Layer Hook

    const {
        // eslint-disable-next-line no-unused-vars
        itemType,
        isDragging,
        item,
        // eslint-disable-next-line no-unused-vars
        initialOffset,
        currentOffset,
    } = useDragLayer((monitor) => ({
        item:          monitor.getItem(),
        itemType:      monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging:    monitor.isDragging(),
    }));

    // #endregion

    // #region Context

    /**
     * Use the `hasEdits` flag API from the bulletin board context level.
     */
    const bulletinBoardHasEditsAPI = BulletinBoardContextLevel.use.hasEdits.api();

    // #endregion

    // #region State

    /**
     * Track the anchor element for the action menu.
     */
    const [
        actionMenuAnchor,
        setActionMenuAnchor,
    ] = useState(null);

    /**
     * Track the anchor element for the block select menu.
     */
    const [
        blockSelectMenuAnchor,
        setBlockSelectMenuAnchor,
    ] = useState(null);

    // #endregion

    // #region Effects

    /**
     * Remove the base drag preview.
     */
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    // #endregion

    // #region Functions

    /**
     * Action menu callback factory.
     */
    const actionMenuCallbackFactory = (action) => {
        return () => {
            setActionMenuAnchor(null);
            action();
            bulletinBoardHasEditsAPI.set(true);
        };
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            {/* Menu Components */}
            <ActionMenu
                anchorEl={actionMenuAnchor}
                actions={[
                    {
                        label:   'Duplicate',
                        icon:    ContentCopy,
                        onClick: actionMenuCallbackFactory(() => {
                            return onDuplicate(ElementProps.value);
                        }),
                    },
                    {
                        label:   'Delete',
                        icon:    DeleteOutline,
                        onClick: actionMenuCallbackFactory(() => {
                            return onDelete();
                        }),
                        color: 'reject',
                    },
                ]}
                onClose={() => {
                    return setActionMenuAnchor(null);
                }}
            />
            <BlockSelectMenu
                anchorEl={blockSelectMenuAnchor}
                onClose={() => {
                    return setBlockSelectMenuAnchor(null);
                }}
                onSelect={(elementProp) => {
                    onAdd(elementProp);
                    bulletinBoardHasEditsAPI.set(true);
                }}
            />
            {/* Main Component */}
            <div
                ref={drop}
                className={localStyles.elementWrapper}
                data-is-dragging={dragProps.isDragging}
                data-is-over={dropProps.isOver}
                data-target-is-above={dropProps.targetIsAbove}
            >
                {
                    readOnly
                        ? null
                        : (
                            <div className={localStyles.sideControlPanel}>
                                <section>
                                    <ActionTooltip
                                        hidden={dragProps.isDragging}
                                        lines={[ {
                                            action: 'Click',
                                            result: 'to add a block below',
                                        } ]}
                                    >
                                        <IconButton
                                            className={localStyles.button}
                                            color='subtle'
                                            onClick={(event) => {
                                                return setBlockSelectMenuAnchor(event.currentTarget);
                                            }}
                                        >
                                            <Add/>
                                        </IconButton>
                                    </ActionTooltip>
                                    <ActionTooltip
                                        hidden={dragProps.isDragging}
                                        lines={[
                                            {
                                                action: 'Drag',
                                                result: 'to move',
                                            },
                                            {
                                                action: 'Click',
                                                result: 'to open menu',
                                            },
                                        ]}
                                    >
                                        <IconButton
                                            ref={drag}
                                            className={localStyles.button}
                                            color='subtle'
                                            onClick={(event) => {
                                                return setActionMenuAnchor(event.currentTarget);
                                            }}
                                        >
                                            <DragIndicator/>
                                        </IconButton>
                                    </ActionTooltip>
                                </section>
                            </div>
                        )
                }
                <div className={localStyles.content}>
                    <Element
                        elementID={ElementProps.id}
                        readOnly={readOnly}
                        {...ElementProps}
                    />
                </div>
            </div>
            {/* Custom Drag Layer */}
            {
                isDragging && item?.index === index
                    ? (
                        <div
                            className={localStyles.dragLayer}
                            style={{
                                transform: `translate(${ currentOffset.x }px, ${ currentOffset.y }px)`,
                            }}
                        >
                            <Element
                                elementID={ElementProps.id}
                                readOnly={readOnly}
                                {...ElementProps}
                            />
                        </div>
                    )
                    : null
            }
        </>
    );

    // #endregion
}

ElementWrapper.propTypes = {
    /**
     * The element to wrap.
     */
    Element:      PropTypes.any.isRequired,
    /**
     * Any props to provide to the wrapped Element component.
     */
    ElementProps: PropTypes.object,
    /**
     * The index of the wrapped element.
     */
    index:        PropTypes.number.isRequired,
    /**
     * The callback for when a new element is added; should accept the new element type id
     * as the only argument.
     */
    onAdd:        PropTypes.func.isRequired,
    /**
     * The callback for when the element is deleted.
     */
    onDelete:     PropTypes.func.isRequired,
    /**
     * The callback for when the element is duplicated.
     */
    onDuplicate:  PropTypes.func.isRequired,
    /**
     * The callback for when the element is moved; should accept `from` and `to`
     * indices as the first and second arguments.
     */
    onMove:       PropTypes.func.isRequired,
    /**
     * The callback for when the element is pinned.
     */
    onPinElement: PropTypes.func.isRequired,
    /**
     * Flag indicating whether the element is in a read only state or not.
     */
    readOnly:     PropTypes.bool,
};

ElementWrapper.defaultProps = {
    ElementProps: {},
    readOnly:     true,
};

export default ElementWrapper;
