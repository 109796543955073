import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const CheckInServiceInfoModel = new Submodel({
    collectionName:  'checkInServiceInfo',
    parent:          TeamModel,
    collectionProps: [
        'latestBatchID',
        'enabled',
        'workerIDForCreatingNextBatch',
    ],
    propDefaults: {
        enabled: false,
    },
});

export default CheckInServiceInfoModel;
