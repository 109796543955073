import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Popover,
    TextField,
} from '@mui/material';
import {
    IconButton,
    Button,
} from '@psionic/ui';
import {
    OpenInNew,
} from '@mui/icons-material';
import BulletinBoardContextLevel from '@contexts/bulletin-board';
import { openLinkInNewTab } from '@utils/navigation';
import { ActionTooltip } from '@components/popovers';
import localStyles from './add-link-menu.module.scss';

/**
 * Menu for adding a link.
 */
function AddLinkMenu({
    anchorEl,
    onClose,
    onAddLink,
    onRemoveLink,
}) {
    // #region Context

    /**
     * Use the bulletin board `currentLink` context value.
     */
    const bulletinBoardCurrentLink = BulletinBoardContextLevel.use.currentLink.value();

    // #endregion

    // #region State

    /**
     * Link text value.
     */
    const [
        linkValue,
        setLinkValue,
    ] = useState(bulletinBoardCurrentLink);

    // #endregion

    // #region Effects

    /**
     * Whenever the `currentLink` context value changes, update the link text value.
     */
    useEffect(() => {
        if (bulletinBoardCurrentLink) {
            setLinkValue(bulletinBoardCurrentLink);
        } else {
            setLinkValue('');
        }
    }, [ bulletinBoardCurrentLink ]);

    // #endregion

    // #region Memoized Values

    /**
     * Memoized flag indicating whether the open link button is disabled.
     */
    const openLinkButtonDisabled = useMemo(() => { return !linkValue; }, [ linkValue ]);

    // #endregion

    // #region Functions

    /**
     * Augmented `onClose` function that also clears out the link value.
     */
    const augmentedOnClose = () => {
        setLinkValue('');
        onClose();
    };

    /**
     * Augmented `onAddLink` function that also closes the menu.
     */
    const augmentedOnAddLink = () => {
        let linkToAdd = linkValue;

        // We want to add `http://` to the link value if it isn't already set.
        if (!(/^http(s)?:\/\//).test(linkValue)) {
            linkToAdd = `http://${ linkValue }`;
        }

        setLinkValue(linkToAdd);
        onAddLink(linkToAdd);
        augmentedOnClose();
    };

    /**
     * Augmented `onRemoveLink` function that also closes the menu.
     */
    const augmentedOnRemoveLink = () => {
        onRemoveLink();
        augmentedOnClose();
    };

    /**
     * Handler for when the "open link in new tab" button is clicked.
     */
    const onOpenLink = () => {
        openLinkInNewTab(linkValue);
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical:   'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                className: localStyles.linkMenu,
            }}
            transformOrigin={{
                vertical:   'top',
                horizontal: 'center',
            }}
            disableAutoFocus
            disableEnforceFocus
            onClose={augmentedOnClose}
        >
            <div>
                <section>
                    <TextField
                        placeholder='Paste link'
                        value={linkValue}
                        variant='outlined'
                        onChange={(event) => { return setLinkValue(event.target.value); }}
                    />
                    <ActionTooltip
                        lines={[ {
                            action: 'Click',
                            result: 'to open link in new tab',
                        } ]}
                    >
                        <IconButton
                            disabled={openLinkButtonDisabled}
                            SvgIcon={OpenInNew}
                            onClick={onOpenLink}
                        />
                    </ActionTooltip>
                </section>
                <Button
                    disabled={!linkValue || linkValue === bulletinBoardCurrentLink}
                    variant='outlined'
                    width={254}
                    darkMode
                    onClick={augmentedOnAddLink}
                >
                    Add Link
                </Button>
                <Button
                    color='reject'
                    disabled={!bulletinBoardCurrentLink}
                    variant='outlined'
                    width={254}
                    darkMode
                    onClick={augmentedOnRemoveLink}
                >
                    Remove Link
                </Button>
            </div>
        </Popover>
    );

    // #endregion
}

AddLinkMenu.propTypes = {
    /**
     * The element to anchor the menu to; if no `anchorEl` is passed, the menu will
     * be hidden.
     */
    anchorEl:     PropTypes.object,
    /**
     * Callback function for when the "add link" button is clicked; should take the link
     * value as the only param.
     */
    onAddLink:    PropTypes.func.isRequired,
    /**
     * Callback function for when the menu should be closed.
     */
    onClose:      PropTypes.func.isRequired,
    /**
     * Callback function for when the "remove link" button is clicked.
     */
    onRemoveLink: PropTypes.func.isRequired,
};

AddLinkMenu.defaultProps = {
    anchorEl: null,
};

export default AddLinkMenu;
