import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { runTransaction } from '@unifire-js/firebase/firestore';
import {
    where,
    collection,
    doc,
    serverTimestamp,
} from 'firebase/firestore';
import { CircularSpinner } from '@psionic/ui';
import { firestore } from '@services/firebase';
import ActivityLogModel from '@models/activity-log';
import CommentModel from '@models/comment';
import ReactionModel from '@models/reaction';
import { useFormattedReactions } from '@hooks/reactions';
import { onReactionClickedFactory } from '@utils/reactions';
import { ACTIVITY_LOG_TYPES } from '@utils/constants';
import {
    CommentInput,
    Comment,
} from '@components/core';
import localStyles from './existing-comment-control.module.scss';

function ExistingCommentControl({
    teamID,
    surveyID,
    comment,
    teamMembers,
    profile,
}) {

    // #region State

    const [
        inEditMode,
        setInEditMode,
    ] = useState(false);

    const [
        editedValue,
        setEditedValue,
    ] = useState(comment.content);

    const [
        savingEdits,
        setSavingEdits,
    ] = useState(false);

    const [
        deleting,
        setDeleting,
    ] = useState(false);

    const [
        reactions,
        setReactions,
    ] = useState(null);

    // #endregion

    // #region Effects

    /**
     * Whenever the comment exits edit mode, reset the edited value.
     */
    useEffect(() => {
        if (!inEditMode) {
            setEditedValue(comment.content);
        }
    }, [ inEditMode ]);

    /**
     * Track the reactions for this comment.
     */
    ReactionModel.useListenerByQuery(
        `comment-${ comment.id }-reactions-listener`,
        [ where('associatedData', '==', doc(collection(firestore, 'comments'), comment.id)) ],
        setReactions
    );

    // #endregion

    // #region Memoized Values

    const formattedReactions = useFormattedReactions(reactions);

    // #endregion

    // #region Functions

    const saveEdits = async() => {
        setSavingEdits(true);
        setInEditMode(false);

        await runTransaction(async(transaction) => {
            await CommentModel.writeToID(
                comment.id,
                {
                    content: editedValue,
                },
                {
                    mergeWithExistingValues: true,
                    transaction,
                }
            );

            await ActivityLogModel.writeToNewDoc(
                `teams/${ teamID }/checkInSurveys/${ surveyID }/activityLogs`,
                {
                    type:      ACTIVITY_LOG_TYPES.EDITED_COMMENT,
                    userID:    profile.id,
                    timestamp: serverTimestamp(),
                }
            );
        });

        setSavingEdits(false);
    };

    const deleteComment = async() => {
        setDeleting(true);

        await CommentModel.deleteByID(comment.id);

        setDeleting(false);
    };

    const onReactionClicked = onReactionClickedFactory(
        doc(collection(firestore, 'comments'), comment.id),
        profile
    );

    // #endregion

    // #region Render Functions

    if (savingEdits) {
        return (
            <div className={localStyles.saving}>
                <CircularSpinner size={24}/>
                Updating comment...
            </div>
        );
    }

    if (deleting) {
        return (
            <div className={localStyles.deleting}>
                <CircularSpinner
                    color='reject'
                    size={24}
                />
                Deleting comment...
            </div>
        );
    }

    if (inEditMode) {
        return (
            <div className={localStyles.editModeWrapper}>
                <CommentInput
                    key={comment.id}
                    id={`comment-${ comment.id }-edit-input`}
                    setValue={setEditedValue}
                    user={profile}
                    value={editedValue}
                    inEditMode
                    onCancelClicked={() => setInEditMode(false)}
                    onSaveClicked={saveEdits}
                />
            </div>
        );
    }

    return (
        <Comment
            author={teamMembers[ comment.authorID ]}
            datePosted={comment.dateCreated}
            reactions={formattedReactions}
            userID={profile.id}
            users={teamMembers}
            value={comment.content}
            onDeleteClicked={deleteComment}
            onEditClicked={() => setInEditMode(true)}
            onReactionClicked={onReactionClicked}
        />
    );

    // #endregion
}

ExistingCommentControl.propTypes = {
    /**
     * The ID of the team this comment is associated with.
     */
    teamID:      PropTypes.string.isRequired,
    /**
     * The ID of the survey this comment is associated with.
     */
    surveyID:    PropTypes.string.isRequired,
    /**
     * The comment to display.
     */
    comment:     PropTypes.object.isRequired,
    /**
     * The team members.
     */
    teamMembers: PropTypes.object.isRequired,
    /**
     * The current user's profile.
     */
    profile:     PropTypes.object.isRequired,
};

ExistingCommentControl.defaultProps = {

};

export default ExistingCommentControl;
