import { useEffect, useState } from 'react';
import {
    Outlet,
    useParams,
    useNavigate,
} from 'react-router-dom';
import find from 'lodash/find';
import map from 'lodash/map';
import { useUserContext } from '@unifire-js/firebase/auth';
import { where } from 'firebase/firestore';
import { Snackbar } from '@psionic/ui';
import {
    Error,
    Warning,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import {
    removeUserFromTeam,
    transferTeamOwnership,
} from '@services/team';
import AssociationModel, { ROLES } from '@models/association';
import InviteModel from '@models/invite';
import AgendaModel from '@models/agenda';
import TeamModel from '@models/team';
import TeamSubscriptionModel from '@models/team-subscription';
import ProfileModel from '@models/profile';
import TeamGroupModel from '@models/team-group';
import FeatureConfigModel from '@models/feature-config';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import { useRedirectWhenNotSignedInOrVerified } from '@hooks/authorization';
import {
    useTrackLiveDataByQueryInContext,
    useTrackLiveDataByIDInContext,
    useTrackLiveSubmodelDataByQueryInContext,
} from '@hooks/context';
import { setSelectedWorkspace } from '@utils/cookies';
import FirebaseFunctions from '@utils/constants/firebase-functions';
import { WorkspaceSidebar } from '@components/navigation';
import { LoadingPage } from '@components/loading';

/**
 * Wrapper for any team routes.
 */
function TeamWrapper() {
    // #region Constants

    /**
     * The listener name to use for the authorization check.
     */
    const AUTHORIZATION_LISTENER_NAME = 'AUTHORIZATION_CHECK_LISTENER';

    // #endregion

    // #region Hooks

    /**
     * Use react-router-dom's navigate function.
     */
    const navigate = useNavigate();

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the URL params.
     */
    const { teamID } = useParams();

    /**
     * Track the team groups in context.
     */
    const [
        teamGroups,
        // eslint-disable-next-line no-unused-vars
        teamGroupsFetched,
    ] = useTrackLiveDataByQueryInContext(
        'CURRENT_TEAM_GROUPS_TRACKER',
        TeamContextLevel.use.teamGroups,
        TeamGroupModel,
        [ where('teamID', '==', teamID) ]
    );

    /**
     * Track the team feature configs in context.
     */
    const [
        teamFeatureConfigs,
        // eslint-disable-next-line no-unused-vars
        teamFeatureConfigsFetched,
    ] = useTrackLiveSubmodelDataByQueryInContext(
        'CURRENT_TEAM_FEATURE_CONFIGS_TRACKER',
        TeamContextLevel.use.featureConfigs,
        FeatureConfigModel,
        `teams/${ teamID }/featureConfigs`,
        []
    );

    /**
     * Track the current team in context.
     */
    const [
        currentTeam,
        // eslint-disable-next-line no-unused-vars
        currentTeamFetched,
    ] = useTrackLiveDataByIDInContext(
        'CURRENT_TEAM_TRACKER',
        TeamContextLevel.use.currentTeam,
        TeamModel,
        teamID
    );

    /**
     * Track the team subscription in context.
     */
    const [
        teamSubscription,
        // eslint-disable-next-line no-unused-vars
        teamSubscriptionFetched,
    ] = useTrackLiveDataByQueryInContext(
        'CURRENT_TEAM_SUBSCRIPTIONS_TRACKER',
        TeamContextLevel.use.teamSubscription,
        TeamSubscriptionModel,
        [
            where('teamID', '==', teamID),
            where('status', 'in', [
                'active',
                'trialing',
            ]),
        ],
        (liveData) => {
            if (liveData?.length > 0) {
                return liveData[ 0 ];
            } else {
                return null;
            }
        }
    );

    /**
     * Track the team associations in context.
     */
    const [
        teamAssociations,
        // eslint-disable-next-line no-unused-vars
        teamAssociationsFetched,
    ] = useTrackLiveDataByQueryInContext(
        'CURRENT_TEAM_ASSOCIATIONS_TRACKER',
        TeamContextLevel.use.teamAssociations,
        AssociationModel,
        [ where('teamID', '==', teamID) ]
    );

    /**
     * Track the team invites in context.
     */
    const [
        teamInvites,
        // eslint-disable-next-line no-unused-vars
        teamInvitesFetched,
    ] = useTrackLiveDataByQueryInContext(
        'CURRENT_TEAM_INVITES_TRACKER',
        TeamContextLevel.use.teamInvites,
        InviteModel,
        [ where('teamID', '==', teamID) ]
    );

    /**
     * Track teh team agendas in context.
     */
    const [
        // eslint-disable-next-line no-unused-vars
        teamAgendas,
        teamAgendasFetched,
    ] = useTrackLiveDataByQueryInContext(
        'CURRENT_TEAM_AGENDAS_TRACKER',
        TeamContextLevel.use.teamAgendas,
        AgendaModel,
        [ where('teamID', '==', teamID) ]
    );

    /**
     * Use the team members context API.
     */
    const teamMembersContextAPI = TeamContextLevel.use.teamMembers.api();

    /**
     * Use the team members context value.
     */
    const teamMembersContextValue = TeamContextLevel.use.teamMembers.value();

    /**
     * Use the user association context API.
     */
    const userAssociationContextAPI = TeamContextLevel.use.userAssociation.api();

    /**
     * Use the user association context value.
     */
    const userAssociationContextValue = TeamContextLevel.use.userAssociation.value();

    /**
     * Use the "has admin permissions" context API.
     */
    const hasAdminPermissionsContextAPI = TeamContextLevel.use.hasAdminPermissions.api();

    /**
     * Track whether the initial authorization check has been run yet or not.
     */
    const [
        authorizationChecked,
        setAuthorizationChecked,
    ] = useState(false);

    /**
     * Use redirect if the user is not signed in or verified.
     */
    const authenticationChecked = useRedirectWhenNotSignedInOrVerified();

    /**
     * When the profile changes, create a listener for the association mapping the current user to the current
     * team, and use that listener to redirect the user if they are ever not associated with the team.
     */
    useEffect(() => {
        // If the profile does not exist, we can return early.
        if (!profile) {
            return;
        }

        // If the profile does exist, we want to create a new authorization check listener
        AssociationModel.addListenerByQuery(
            AUTHORIZATION_LISTENER_NAME,
            [
                where('userID', '==', profile.id),
                where('teamID', '==', teamID),
            ],
            (docs) => {
                if (docs.length < 1) {
                    SnackbarService.addSnackbar(
                        ({ removeSnackbar }) => { return (
                            <Snackbar
                                color='warning'
                                removeSnackbar={removeSnackbar}
                                SvgIcon={Warning}
                                text='You are not associated with this team!'
                            />
                        ); },
                        CORE.SNACKBAR_DURATION
                    );

                    return navigate('/home');
                }
                setAuthorizationChecked(true);
                userAssociationContextAPI.set(docs[ 0 ]);
            }
        );

        // Return a cleanup function that will remove the listener
        return () => { return AssociationModel.removeListener(AUTHORIZATION_LISTENER_NAME); };
    }, [ profile ]);

    /**
     * When the team associations update, fetch all of the related profiles, as needed.
     */
    useEffect(() => {
        const fn = async() => {
            // If the team associations data has not loaded yet, return null
            if (!teamAssociations) {
                return null;
            }

            // Fetch all of the profiles for the joined team members
            const teamMemberProfilesByID = {};
            await Promise.all(map(teamAssociations, async(association) => {
                const fetchedProfile = await ProfileModel.getByID(association.userID);
                teamMemberProfilesByID[ association.userID ] = fetchedProfile;
            }));
            teamMembersContextAPI.set(teamMemberProfilesByID);
        };

        fn();
    }, [ teamAssociations ]);

    /**
     * Whenever the user association updates, set the `hasAdminPermissions` context value.
     */
    useEffect(() => {
        hasAdminPermissionsContextAPI.set(
            [
                ROLES.ADMIN,
                ROLES.OWNER,
            ].includes(userAssociationContextValue?.role)
        );
    }, [ userAssociationContextValue ]);

    /**
     * Whenever the component first mounts, set the team as the selected workspace in localstorage.
     */
    useEffect(() => {
        setSelectedWorkspace(teamID);
    }, []);

    // #endregion

    // #region Functions

    const onLeaveTeamConfirmed = async() => {
        await removeUserFromTeam(profile, currentTeam);
        navigate('/home');
    };

    const onDisbandTeamConfirmed = async() => {
        const response = await FirebaseFunctions.disbandTeam({ teamID: currentTeam.id });

        if (response?.success) {
            navigate('/home');
        } else {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text='There was an issue while trying to disband the team. Please try again later.'
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        }
    };

    const getFeatureIsEnabled = (key) => {
        return find(
            teamFeatureConfigs || [],
            [
                'id',
                key,
            ]
        )?.enabled;
    };

    // #endregion

    // #region Render Functions

    /**
     * Display a loading state if the necessary data has not been loaded yet or if the auth checks haven't been
     * performed yet.
     */
    if (
        !authenticationChecked
        || !authorizationChecked
        || !profile
        || !currentTeam
        || !teamSubscriptionFetched
        || !teamAssociations
        || !teamInvites
        || !teamMembersContextValue
        || !userAssociationContextValue
        || !teamAgendasFetched
        || !teamGroups
        || !teamFeatureConfigs
    ) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <WorkspaceSidebar
            currentTeam={currentTeam}
            getFeatureIsEnabled={getFeatureIsEnabled}
            profile={profile}
            teamAssociations={teamAssociations}
            teamMembers={teamMembersContextValue}
            teamSubscription={teamSubscription}
            userAssociation={userAssociationContextValue}
            onDisbandTeamConfirmed={onDisbandTeamConfirmed}
            onLeaveTeamConfirmed={onLeaveTeamConfirmed}
            onTransferTeamOwnershipConfirmed={transferTeamOwnership}
        >
            <main>
                <Outlet/>
            </main>
        </WorkspaceSidebar>
    );

    // #endregion
}

/**
 * Wrapper to provide the team-level Provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ProviderWrapper() {
    return (
        <TeamContextLevel.Provider>
            <TeamWrapper/>
        </TeamContextLevel.Provider>
    );
}

export default ProviderWrapper;
