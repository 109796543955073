import { Outlet } from 'react-router-dom';

/**
 * Wrapper for any 15 minutes views.
 */
function FifteenMinutesWrapper() {

    // #region State

    // #endregion

    // #region Effects

    // #endregion Effects

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Outlet/>
    );

    // #endregion

}

export default FifteenMinutesWrapper;
