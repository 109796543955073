/* eslint-disable no-magic-numbers */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DateTime } from 'luxon';
import { HEARTBEAT_COLORS } from '@utils/constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip
);

/**
 * Displays a line chart of the user's heartbeat pulse.
 */
function HeartbeatPulse({
    dates,
    ratings,

    // Pass-thru props
    ...passThruProps
}) {

    // #region Hooks

    /**
     * Memoized options for the line chart.
     */
    const chartOptions = useMemo(() => {
        return {
            responsive: true,
            plugins:    {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    displayColors: false,
                    filter:        (tooltipItem) => {
                        return tooltipItem.datasetIndex === 0;
                    },
                },
            },
            maintainAspectRatio: false,
            scales:              {
                // eslint-disable-next-line id-length
                x: {
                    grid: {
                        display:    false,
                        drawBorder: false,
                        lineWidth:  0,
                    },
                    ticks: {
                        display: false,
                    },
                },
                // eslint-disable-next-line id-length
                y: {
                    suggestedMax: 5,
                    suggestedMin: 1,
                    grid:         {
                        display:    false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
        };
    }, []);

    /**
     * Memoized formatted dates for the x-axis.
     */
    const formattedDates = useMemo(() => {
        const formatted = [];

        for (const date of dates) {
            formatted.push(DateTime.fromMillis(date).toFormat('LLL d'));
        }

        return formatted;
    }, [ dates ]);

    /**
     * Memoized average rating.
     */
    const averageRating = useMemo(() => {
        let total = 0;
        let numDataPoints = 0;

        for (const rating of ratings) {
            if (rating !== null && rating !== undefined) {
                total += rating;
                numDataPoints++;
            }
        }

        return total / numDataPoints;
    }, [ ratings ]);

    /**
     * Memoized color to use based on the average rating.
     */
    const averageRatingColor = useMemo(() => {
        if (averageRating >= 4.5) {
            return HEARTBEAT_COLORS[ 5 ];
        }

        if (averageRating >= 3.5) {
            return HEARTBEAT_COLORS[ 4 ];
        }

        if (averageRating >= 2.5) {
            return HEARTBEAT_COLORS[ 3 ];
        }

        if (averageRating >= 1.5) {
            return HEARTBEAT_COLORS[ 2 ];
        }

        return HEARTBEAT_COLORS[ 1 ];
    }, [ averageRating ]);

    /**
     * Memoized data object for the line chart.
     */
    const chartData = useMemo(() => {
        return {
            labels:   formattedDates,
            datasets: [
                {
                    label:            'Feeling',
                    data:             ratings,
                    pointStyle:       'circle',
                    pointRadius:      6,
                    pointHoverRadius: 10,
                    backgroundColor:  `${ averageRatingColor }ff`,
                    elements:         {
                        line: {
                            borderColor: `${ averageRatingColor }ff`,
                        },
                    },
                },
                {
                    label:           'Feeling',
                    data:            ratings,
                    backgroundColor: (context) => {
                        const { ctx } = context.chart;
                        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                        gradient.addColorStop(0, `${ averageRatingColor }55`);
                        gradient.addColorStop(1, `${ averageRatingColor }00`);

                        return gradient;
                    },
                    fill:        'start',
                    borderColor: `${ averageRatingColor }ff`,
                    spanGaps:    true,
                    borderDash:  [
                        5,
                        5,
                    ],
                    elements: {
                        point: {
                            radius: 0,
                        },
                    },
                },
            ],
        };
    }, [
        dates,
        ratings,
    ]);

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div {...passThruProps}>
            <Line
                data={chartData}
                options={chartOptions}
            />
        </div>
    );

    // #endregion
}

HeartbeatPulse.propTypes = {
    /**
     * The array of dates to use for the x-axis, in order. Should be in milliseconds.
     */
    dates:              PropTypes.arrayOf(PropTypes.number).isRequired,
    /**
     * The array of ratings to use for the y-axis, in order. Any missing data should be `null` or
     * `undefined`.
     */
    ratings:            PropTypes.array.isRequired,
    /**
     * Any remaining props to spread to the root element.
     */
    '...passThruProps': PropTypes.any,
};

HeartbeatPulse.defaultProps = {
    '...passThruProps': undefined,
};

export default HeartbeatPulse;
