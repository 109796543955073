import { useState } from 'react';
import { useUserContext } from '@unifire-js/firebase/auth';
import { Link } from 'react-router-dom';
import {
    LandingPageHeader,
    Footer,
    SolutionInfo,
} from '@components/core';
import heroImg from '@assets/images/hero.png';
import { LoadingPage } from '@components/loading';
import localStyles from './home.module.scss';

/**
 * The home page.
 */
function Home() {
    // #region Hooks

    /**
     * Track the currently selected solutions tab.
     */
    const [
        currentSolutionsTab,
        setCurrentSolutionsTab,
    ] = useState(SOLUTIONS_TABS.AGENDAS);

    /**
     * Use the user context.
     */
    const { user, profile, initialLoadDone } = useUserContext();

    // #endregion

    // #region Functions

    /**
     * Handles the tab `onChange` events.
     */
    const onTabChange = (newValue) => {
        setCurrentSolutionsTab(newValue);
    };

    // #endregion

    // #region Render Functions

    /**
     * Tab Panel render.
     */
    const renderTabPanel = () => {
        switch (currentSolutionsTab) {
            case SOLUTIONS_TABS.BIDDING:
                return (
                    <SolutionInfo
                        subtitle='Host task bidding sessions with your team to determine task complexity and effectively plan your future sprints.'
                        title='Task bidding sessions'
                        featuresList={
                            [
                                'Vote on task complexity with customizable room decks.',
                                'Kick inactive users.',
                                'Allow selected users on your team to host a room session at any time.',
                            ]
                        }
                    />
                );
            case SOLUTIONS_TABS.AGENDAS:
                return (
                    <SolutionInfo
                        subtitle='Create agendas, tracking who needs to be present and who can leave each day to stay productive.'
                        title='Agendas'
                        featuresList={
                            [
                                'Schedule agenda items for specific days.',
                                'Track whether agenda items have been reviewed.',
                                'Track who needs to stay and who can leave each day when discussing agenda items.',
                                'Track action items that come up as a result of an agenda item discussion.',
                            ]
                        }
                    />
                );
            case SOLUTIONS_TABS.BULLETINS:
                return (
                    <SolutionInfo
                        subtitle='Create Notion-like bulletins to keep your team informed of important information.'
                        title='Bulletins'
                        featuresList={
                            [
                                'Create bulletins with a Notion-like editor.',
                                'Keep your team informed of important information.',
                                'Allow team administrators to update bulletin boards with new information.',
                            ]
                        }
                    />
                );
            case SOLUTIONS_TABS.CHECK_IN:
                return (
                    <SolutionInfo
                        subtitle={'Create check-in sessions for managers to keep track of their team members\' wellbeing.'}
                        title='Check In'
                        featuresList={
                            [
                                'Send out check-in surveys on a weekly, bi-weekly, or monthly basis.',
                                'Track how your team members are feeling.',
                                'Create customizable check-in surveys for individual groups within your team.',
                            ]
                        }
                    />
                );
            default:
                return null;
        }
    };

    /**
     * If the page's auth user context has not loaded yet, display the loading page.
     */
    if (!initialLoadDone) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <>
            <LandingPageHeader
                profile={profile}
                user={user}
                displaysWorkspaceAndNotifications
            />
            <main
                className={localStyles.main}
            >
                <section className={localStyles.heroCard}>
                    <img
                        alt='decorative background'
                        src={heroImg}
                    />
                    <div className={localStyles.backgroundFilter}/>
                    <h1>
                        Enriching Teams
                        <br/>
                        <span>
                            and the leaders who guide them
                        </span>
                    </h1>
                </section>
                <section className={localStyles.actionBlurb}>
                    <div className={localStyles.content}>
                        <h2>
                            Collaborate, plan, execute.
                        </h2>
                        <p>
                            Eager Lead provides a suite of tools to assist your team in feeling connected,
                            organized, and productive. Create a team, invite your teammates, and start achieving!
                        </p>
                        <Link
                            className={localStyles.getStartedLink}
                            to={
                                user && profile
                                    ? '/user/create-team'
                                    : '/home/login'
                            }
                        >
                            Get Started for Free
                        </Link>
                    </div>
                </section>
                <div className={localStyles.content}>
                    <section className={localStyles.ourSolutions}>
                        <h5 className='accentHeader'>
                            Our Solutions
                        </h5>
                        <div className='tabs'>
                            <button
                                className='tab'
                                data-selected={currentSolutionsTab === SOLUTIONS_TABS.AGENDAS}
                                type='button'
                                onClick={() => onTabChange(SOLUTIONS_TABS.AGENDAS)}
                            >
                                <p>
                                    Agendas
                                </p>
                            </button>
                            <button
                                className='tab'
                                data-selected={currentSolutionsTab === SOLUTIONS_TABS.CHECK_IN}
                                type='button'
                                onClick={() => onTabChange(SOLUTIONS_TABS.CHECK_IN)}
                            >
                                <p>
                                    Check In
                                </p>
                            </button>
                            <button
                                className='tab'
                                data-selected={currentSolutionsTab === SOLUTIONS_TABS.BULLETINS}
                                type='button'
                                onClick={() => onTabChange(SOLUTIONS_TABS.BULLETINS)}
                            >
                                <p>
                                    Bulletins
                                </p>
                            </button>
                            <button
                                className='tab'
                                data-selected={currentSolutionsTab === SOLUTIONS_TABS.BIDDING}
                                type='button'
                                onClick={() => onTabChange(SOLUTIONS_TABS.BIDDING)}
                            >
                                <p>
                                    Task Bidding
                                </p>
                            </button>
                        </div>
                        {renderTabPanel()}
                    </section>
                </div>
            </main>
            <Footer/>
        </>
    );

    // #endregion
}

export default Home;

/**
 * Reference enum for the different solutions tab panel values.
 */
const SOLUTIONS_TABS = {
    BIDDING:   'BIDDING',
    AGENDAS:   'AGENDAS',
    BULLETINS: 'BULLETINS',
    CHECK_IN:  'CHECK_IN',
};
