import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    map,
} from 'lodash';
import {
    Button,
    Menu,
    MenuItem,
} from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import { PRIORITIES } from '@utils/constants';
import localStyles from './priority-indicator.module.scss';

/**
 * Priority indicator to show the priority of an element.
 */
function PriorityIndicator({
    priority,
    onChangePriority,
}) {
    // #region Refs

    /**
     * Use the priority indicator button ref.
     */
    const priorityIndicatorButtonRef = useRef();

    // #endregion

    // #region Constants

    // #endregion

    // #region State

    /**
     * Track whether the priority indicator menu is open.
     */
    const [
        menuOpen,
        setMenuOpen,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion

    // #region Memoized Values

    /**
     * Memoized priority coloring.
     */
    const priorityColor = useMemo(() => {
        switch (priority) {
            case PRIORITIES.LOW:
                return 'primary';
            case PRIORITIES.MEDIUM:
                return 'warningDull';
            case PRIORITIES.HIGH:
                return 'reject';
            default:
                return 'inactive';
        }
    }, [ priority ]);

    // #endregion

    // #region Functions

    /**
     * Helper function to get the priority text.
     */
    const getPriorityText = (newPriority) => {
        switch (newPriority) {
            case PRIORITIES.LOW:
                return 'Low';
            case PRIORITIES.MEDIUM:
                return 'Medium';
            case PRIORITIES.HIGH:
                return 'High';
            default:
                return 'Unknown';
        }
    };

    /**
     * Callback to handle the `onClose` menu event.
     */
    const handleOnClose = (event) => {
        event.stopPropagation();
        setMenuOpen(false);
    };

    /**
     * Callback to handle the `onClick` button event.
     */
    const handleOnPriorityButtonClick = (event) => {
        if (onChangePriority) {
            event.stopPropagation();
            setMenuOpen(true);
        }
    };

    /**
     * Callback to change priority.
     */
    const changePriority = (event, newPriority) => {
        event.stopPropagation();
        setMenuOpen(false);
        onChangePriority(newPriority);
    };

    // #endregion

    // #region Render Functions

    /**
     * Priority menu items.
     */
    const priorityMenuItems = () => { return map(
        PRIORITIES,
        (newPriority) => { return (
            <MenuItem
                key={newPriority}
                onClick={(event) => { return changePriority(event, newPriority); }}
            >
                {getPriorityText(newPriority)}
            </MenuItem>
        ); }
    ); };

    /**
     * Main render.
     */
    return (
        <>
            {/* Menu */}
            <Menu
                anchorEl={priorityIndicatorButtonRef.current}
                open={menuOpen}
                onClose={handleOnClose}
            >
                {priorityMenuItems()}
            </Menu>
            {/* Main Component */}
            <StickyTooltip content={`${ getPriorityText(priority) } Priority`}>
                <Button
                    ref={priorityIndicatorButtonRef}
                    className={localStyles.priorityIndicator}
                    color={priorityColor}
                    data-priority={priority}
                    variant='contained'
                    onClick={handleOnPriorityButtonClick}
                >
                    {getPriorityText(priority)}
                </Button>
            </StickyTooltip>
        </>
    );

    // #endregion
}

PriorityIndicator.propTypes = {
    /**
     * Callback for when the priority changes.
     */
    onChangePriority: PropTypes.func,
    /**
     * The priority level of the element.
     */
    priority:         PropTypes.string,
};

PriorityIndicator.defaultProps = {
    onChangePriority: undefined,
    priority:         undefined,
};

export default PriorityIndicator;
