import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import App from '@root/app';
import Home from '@views/outside-workspace/home';
import Login from '@views/outside-workspace/login';
import OutsideWorkspaceWrapper from '@views/outside-workspace/wrapper';
import AwaitingEmailVerification from '@views/user/awaiting-email-verification';
import EmailActionHandler from '@views/email-action-handler';
import AccountSettings from '@views/user/account-settings';
import CreateTeamStepper from '@views/user/create-team-stepper';
import UserWrapper from '@views/user/wrapper';
import PageNotFound from '@views/page-not-found';
import TeamWrapper from '@views/team/wrapper';
import TeamHome from '@views/team/team-home';
import TeamAdminWrapper from '@views/team/admin/wrapper';
import ManageTeamMembers from '@views/team/admin/manage-team-members';
import PremiumWrapper from '@views/team/premium/wrapper';
import BiddingRoomWrapper from '@views/team/premium/bidding-room/wrapper';
import EditTeamInfo from '@views/team/admin/edit-team-info/edit-team-info';
import SendPasswordReset from '@views/outside-workspace/send-password-reset/send-password-reset';
import TeamOwnerWrapper from '@views/team/owner/wrapper';
import ManageSubscription from '@views/team/owner/manage-subscription/manage-subscription';
import FifteenMinutesWrapper from '@views/team/premium/fifteen-minutes/wrapper';
import OrganizeGroups from '@views/team/admin/organize-groups/organize-groups';
import TeamPersonalWrapper from '@views/team/personal/wrapper';
import TeamFreeWrapper from '@views/team/free/wrapper';
import Bulletin from '@views/team/free/bulletin';
import ToDo from '@views/team/personal/to-do';
import ChangePassword from '@views/user/change-password/change-password';
import AgendaSelect from '@views/team/premium/fifteen-minutes';
import AgendaItemsListPage from '@views/team/premium/fifteen-minutes/agenda-items-list-page';
import ConfigureFeatures from '@views/team/admin/configure-features/configure-features';
import CheckInWrapper from '@views/team/premium/check-in/wrapper';
import CheckInPage from '@views/team/premium/check-in';
import ManageCheckInService from '@views/team/premium/check-in/manage-check-in-service/manage-check-in-service';
import ReviewCheckIns from '@views/team/premium/check-in/review-check-ins/review-check-ins';
import MyCheckIns from '@views/team/premium/check-in/my-check-ins/my-check-ins';
import ViewSurvey from '@views/team/premium/check-in/my-check-ins/view-survey/view-survey';
import RevieweePage from '@views/team/premium/check-in/review-check-ins/reviewee-page/reviewee-page';
import RevieweePageWrapper from '@views/team/premium/check-in/review-check-ins/reviewee-page/wrapper';
import ViewSurveyWrapper from '@views/team/premium/check-in/my-check-ins/view-survey/wrapper';
import ReviewSurveyPage from '@views/team/premium/check-in/review-check-ins/reviewee-page/review-survey-page/review-survey-page';
import MyCheckInsWrapper from '@views/team/premium/check-in/my-check-ins/wrapper';
import FillOutSurveyWrapper from '@views/team/premium/check-in/my-check-ins/fill-out-survey/wrapper';
import FillOutSurvey from '@views/team/premium/check-in/my-check-ins/fill-out-survey/fill-out-survey';
import ReleaseNotes from '@views/outside-workspace/release-notes';
import IndexPage from '@views';

export default (
    <Routes>
        <Route
            element={<App/>}
            path='/'
        >
            {/* Email Action Handler */}
            <Route
                element={<EmailActionHandler/>}
                path='email-action-handler'
            />
            {/* Redirect index to home */}
            <Route
                element={<IndexPage/>}
                index
            />
            {/* Outside Workspace */}
            <Route
                element={<OutsideWorkspaceWrapper/>}
                path='home'
            >
                <Route
                    element={<Home/>}
                    index
                />
                <Route
                    element={<Login/>}
                    path='login'
                />
                <Route
                    element={<SendPasswordReset/>}
                    path='send-password-reset'
                />
                <Route
                    element={<ReleaseNotes/>}
                    path='release-notes'
                />
            </Route>
            {/* User */}
            <Route
                element={<UserWrapper/>}
                path='user'
            >
                <Route
                    element={<AccountSettings/>}
                    path='account-settings'
                />
                <Route
                    element={<AwaitingEmailVerification/>}
                    path='awaiting-email-verification'
                />
                <Route
                    element={<CreateTeamStepper/>}
                    path='create-team'
                />
                <Route
                    element={<ChangePassword/>}
                    path='change-password'
                />
            </Route>
            {/* Team */}
            <Route
                element={<TeamWrapper/>}
                path='team/:teamID'
            >
                <Route
                    element={<TeamHome/>}
                    index
                />
                <Route
                    element={<TeamOwnerWrapper/>}
                    path='owner'
                >
                    <Route
                        element={<ManageSubscription/>}
                        path='manage-subscription'
                    />
                </Route>
                <Route
                    element={<TeamAdminWrapper/>}
                    path='admin'
                >
                    <Route
                        element={<ManageTeamMembers/>}
                        path='manage-team-members'
                    />
                    <Route
                        element={<EditTeamInfo/>}
                        path='edit-team-info'
                    />
                    <Route
                        element={<OrganizeGroups/>}
                        path='organize-groups'
                    />
                    <Route
                        element={<ConfigureFeatures/>}
                        path='configure-features'
                    />
                </Route>
                <Route
                    element={<TeamPersonalWrapper/>}
                    path='personal'
                >
                    <Route
                        element={<ToDo/>}
                        path='to-do'
                    />
                </Route>
                <Route
                    element={<PremiumWrapper/>}
                    path='premium'
                >
                    <Route
                        element={<BiddingRoomWrapper/>}
                        path='bidding-room'
                    />
                    <Route
                        element={<CheckInWrapper/>}
                        path='check-in'
                    >
                        <Route
                            element={<CheckInPage/>}
                            index
                        />
                        <Route
                            element={<ManageCheckInService/>}
                            path='manage'
                        />
                        <Route
                            element={<ReviewCheckIns/>}
                            path='review'
                        />
                        <Route path='review'>
                            <Route
                                element={<ReviewCheckIns/>}
                                index
                            />
                            <Route
                                element={<RevieweePageWrapper/>}
                                path=':revieweeID'
                            >
                                <Route
                                    element={<RevieweePage/>}
                                    index
                                />
                                <Route path='survey/:surveyID'>
                                    <Route
                                        element={<ReviewSurveyPage/>}
                                        index
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route
                            element={<MyCheckInsWrapper/>}
                            path='my-check-ins'
                        >
                            <Route
                                element={<MyCheckIns/>}
                                index
                            />
                            <Route
                                element={<ViewSurveyWrapper/>}
                                path=':surveyID'
                            >
                                <Route
                                    element={<ViewSurvey/>}
                                    index
                                />
                            </Route>
                            <Route
                                element={<FillOutSurveyWrapper/>}
                                path='fill-out/:surveyID'
                            >
                                <Route
                                    element={<FillOutSurvey/>}
                                    index
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route
                        element={<FifteenMinutesWrapper/>}
                        path='agenda'
                    >
                        <Route
                            element={<AgendaSelect/>}
                            index
                        />
                        <Route
                            element={<AgendaItemsListPage/>}
                            path=':agendaID'
                        />
                    </Route>
                    {/* Redirect old 15-minutes route to the agenda route */}
                    <Route
                        path='15-minutes'
                        element={(
                            <Navigate
                                to='/team/:teamID/premium/agenda'
                                replace
                            />
                        )}
                    />
                </Route>
                <Route
                    element={<TeamFreeWrapper/>}
                    path='free'
                >
                    <Route path='bulletin'>
                        <Route
                            element={<Bulletin/>}
                            index
                        />
                    </Route>
                </Route>
            </Route>
            {/* Page Not Found */}
            <Route
                element={<PageNotFound/>}
                path='*'
            />
        </Route>
    </Routes>
);
