import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const BulletinModel = new Submodel({
    collectionName:  'bulletins',
    parent:          TeamModel,
    collectionProps: [ 'elementIDsInOrder' ],
    propDefaults:    {
        elementIDsInOrder: [],
    },
});

export default BulletinModel;
