import { useMemo } from 'react';
import { useUserContext } from '@unifire-js/firebase/auth';
import { Button } from '@psionic/ui';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import TeamContextLevel from '@contexts/team';
import {
    startNextBiddingRound,
} from '@services/bidding-room';
import { UserInfo } from '@components/details';
import { BiddingRoomLayout } from '@components/page-layouts';
import localStyles from './lobby.module.scss';

/**
 * Bidding room lobby view.
 */
function Lobby() {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the bidding room's public info context value.
     */
    const biddingRoomPublicInfo = BiddingRoomContextLevel.use.biddingRoomPublicInfo.value();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the "has admin permissions" context value.
     */
    const hasAdminPermissions = TeamContextLevel.use.hasAdminPermissions.value();

    // #endregion

    // #region Render Functions

    /**
     * Memoized list of users in the lobby (not including the signed-in user, themself).
     */
    const userListItems = useMemo(() => {
        const listItems = [];

        for (const biddingUser of Object.values(biddingRoomPublicInfo?.users || {})) {
            // If the user is the currently signed-in user, don't render them here
            if (biddingUser?.profile?.id === profile?.id) {
                continue;
            }

            // If the user has been kicked, don't render them here
            if (biddingUser?.kicked) {
                continue;
            }

            // Otherwise, create the list item
            listItems.push(
                <li key={biddingUser?.profile?.id}>
                    <UserInfo profile={biddingUser?.profile}/>
                </li>
            );
        }

        return listItems;
    }, [ biddingRoomPublicInfo ]);

    /**
     * Main render.
     */
    return (
        <BiddingRoomLayout
            hasAdminPermissions={hasAdminPermissions}
            title='Lobby'
            actionsChildren={
                hasAdminPermissions
                    ? (
                        <Button
                            variant='contained'
                            darkMode
                            onClick={() => {
                                return startNextBiddingRound(currentTeam?.id, biddingRoomPublicInfo.users);
                            }}
                        >
                            Start Bidding!
                        </Button>
                    )
                    : null
            }
        >
            <section className={localStyles.userListSection}>
                <ul>
                    <li>
                        <UserInfo profile={profile}/>
                    </li>
                    {userListItems}
                </ul>
            </section>
        </BiddingRoomLayout>
    );

    // #endregion
}

export default Lobby;
