import PropTypes from 'prop-types';
import {
    Add,
} from '@mui/icons-material';
import {
    StickyTooltip,
    IconButton,
} from '@psionic/ui';

/**
 * Button to add a section to a list.
 */
function AddSectionButton({
    label,
    onClick,
}) {
    return (
        <StickyTooltip content={label}>
            <IconButton
                size={32}
                SvgIcon={Add}
                onClick={onClick}
            />
        </StickyTooltip>
    );
}

AddSectionButton.propTypes = {
    /**
     * The label to display on the tooltip when the button is hovered.
     */
    label:   PropTypes.string.isRequired,
    /**
     * The callback function for when the button is clicked.
     */
    onClick: PropTypes.func.isRequired,
};

AddSectionButton.defaultProps = {

};

export default AddSectionButton;
