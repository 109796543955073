import { useMemo } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {
    AvatarGroup,
    CircularProgress,
    Tooltip,
} from '@mui/material';
import {
    TeamAvatar,
    UserAvatar,
} from '@components/avatars';
import localStyles from './team-info.module.scss';

/**
 * Team info component.
 */
function TeamInfo({
    team,
    members,
}) {
    // #region Render Functions

    /**
     * Memoized avatars of team members.
     */
    const memberAvatars = useMemo(() => {
        const avatars = [];

        for (const member of members) {
            avatars.push(
                <span key={`${ member.email }-wrapper`}>
                    <UserAvatar
                        imageURL={member.avatarURL}
                        size='extra-small'
                        userEmail={member.email}
                        userName={member.displayName}
                    />
                </span>
            );
        }

        return avatars;
    }, [ members ]);

    /**
     * Main render.
     */
    return (
        <div className={localStyles.wrapper}>
            <TeamAvatar
                imageURL={team.avatarURL}
                size='large'
                teamName={team.name}
            />
            <section className={localStyles.teamInfo}>
                <h5>
                    {team.name}
                </h5>
                <div className={localStyles.memberInfo}>
                    <p>
                        Team Members:
                    </p>
                    {
                        members.length > 0
                            ? (
                                <AvatarGroup
                                    className={localStyles.members}
                                    max={5}
                                >
                                    {memberAvatars}
                                </AvatarGroup>
                            )
                            : <CircularProgress className={localStyles.loading}/>
                    }
                </div>
            </section>
        </div>
    );

    // #endregion
}

TeamInfo.propTypes = {
    /**
     * Object representing a team. Should be a document from Firestore.
     */
    team:    PropTypes.object.isRequired,
    /**
     * Array of team member profiles (documents from Firestore). If the array is empty, it is
     * assumed to be in a loading state since the team wouldn't be displayed if no one was a member.
     */
    members: PropTypes.arrayOf(PropTypes.object),
};

TeamInfo.defaultProps = {
    members: [],
};

export default TeamInfo;
