import textImg from '@assets/images/element-blocks/text.png';
import h1Img from '@assets/images/element-blocks/h1.png';

export default {
    TEXT: {
        id:           'TEXT',
        name:         'Text',
        description:  'Just start writing with plain text.',
        imageSrc:     textImg,
        defaultValue: 'Here\'s a new Text block!',
    },
    HEADING_1: {
        id:           'HEADING_1',
        name:         'Heading 1',
        description:  'Big section heading.',
        imageSrc:     h1Img,
        defaultValue: 'Here\'s a new Heading 1 block!',
    },
};
