import { createContextLevel } from 'luxe-js/context';

export default createContextLevel({
    checkInServiceInfo:           undefined,
    checkInQuestions:             undefined,
    checkInSurveyConfiguration:   undefined,
    checkInReviewerAssociations:  undefined,
    myCurrentCheckIn:             undefined,
    currentSurveysByTeamMemberID: undefined,
    latestActivityLogBySurveyID:  undefined,
    myReviewAssociations:         undefined,
    currentSurveyBatch:           undefined,
});
