import { findIndex } from 'lodash';
import {
    addAfter,
    remove,
    move,
} from '@utils/array-operations';

/**
 * Replace with your own documentation.
 */
class BulletinBoardTracker {
    constructor(elements) {
        this.elements = elements;
        this.elementsToDelete = [];
    }

    //#region Public Functions

    /**
     * Sets the elements array to the given value.
     */
    setElements = (elements) => {
        this.elements = elements;
    };

    /**
     * Add the given element to the tracker after the given index.
     */
    addElementAfter = (element, index) => {
        this.elements = addAfter(this.elements, index, element);
    };

    /**
     * Remove the given element from the tracker.
     */
    removeElement = (index) => {
        const elementID = this.elements[index]?.id;
        this.elements = remove(this.elements, index);
        if (elementID) {
            this.elementsToDelete.push(elementID);
        }
    };

    /**
     * Move element from one index to another.
     */
    moveElement = (from, to) => {
        this.elements = move(this.elements, from, to);
    };

    /**
     * Updates the given element in the tracker.
     */
    updateElement = (element) => {
        const elementIndex = this.getElementIndex(element.id);
        this.elements[elementIndex] = element;
    };

    /**
     * Gets the index of the element w/ the given ID in the tracker.
     */
    getElementIndex = (elementID) => {
        return findIndex(this.elements, element => element.id === elementID);
    };

    //#endregion

    //#region Private Functions

    //#endregion

}

export default BulletinBoardTracker
