import {
    where,
} from 'firebase/firestore';
import { runTransaction } from '@unifire-js/firebase/firestore';
import ReactionModel from '@models/reaction';

// #region Public Functions

/**
 * Factory for creating a function for handling reactions being clicked and saving the data to Firestore.
 *
 * @param {Firestore.DocRef} associatedData The doc ref for the data the reactions are associated with
 * @param {Object} profile The current user's profile
 * @returns {function} Function to call to handle a reaction being clicked
 */
export function onReactionClickedFactory(associatedData, profile) {
    return async(emoji) => {
        await runTransaction(async(transaction) => {
            // Grab the existing reaction doc for the given emoji ID
            const existingReactionDocs = await ReactionModel.getByQuery(
                [
                    where('associatedData', '==', associatedData),
                    where('emojiID', '==', emoji.id),
                ],
                { transaction }
            );
            const existingReactionDoc = existingReactionDocs?.length > 0 ? existingReactionDocs[ 0 ] : null;

            // If an existing reaction doc exists, simply update it
            if (existingReactionDoc) {
                if (existingReactionDoc.users.includes(profile.id)) {
                    const filteredUsers = existingReactionDoc.users.filter((id) => id !== profile.id);

                    if (filteredUsers.length <= 0) {
                        await ReactionModel.deleteByID(
                            existingReactionDoc.id,
                            { transaction }
                        );
                    } else {
                        await ReactionModel.writeToID(
                            existingReactionDoc.id,
                            {
                                users: filteredUsers,
                            },
                            {
                                transaction,
                                mergeWithExistingValues: true,
                            }
                        );
                    }
                } else {
                    await ReactionModel.writeToID(
                        existingReactionDoc.id,
                        {
                            users: [
                                ...existingReactionDoc.users,
                                profile.id,
                            ],
                        },
                        {
                            transaction,
                            mergeWithExistingValues: true,
                        }
                    );
                }
            }

            // Otherwise, create a new reaction doc
            else {
                await ReactionModel.writeToNewDoc({
                    associatedData,
                    emojiID: emoji.id,
                    users:   [ profile.id ],
                    native:  emoji.native,
                }, { transaction });
            }
        });
    };
}

// #endregion

// #region Private Functions

// #endregion
