import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    Delete,
    Check,
    Close,
} from '@mui/icons-material';
import {
    ControlledCheckbox,
    ControlledTextField,
    CircularSpinner,
    IconButton,
    usePseudoSelectors,
} from '@psionic/ui';
import CheckInQuestionModel from '@models/check-in-question';
import localStyles from './question.module.scss';

function Question({
    question,
    team,
}) {

    // #region State

    const [
        inEditMode,
        setInEditMode,
    ] = useState(false);

    const [
        editedQuestion,
        setEditedQuestion,
    ] = useState({ ...question });

    const [
        isDeleting,
        setIsDeleting,
    ] = useState(false);

    const [
        savingUpdates,
        setSavingUpdates,
    ] = useState(false);

    const [
        questionPseudoSelectorProps,
        questionPseudoSelectorStates,
    ] = usePseudoSelectors();

    // #endregion

    // #region Variables

    const displayEditAndDeleteIcons = questionPseudoSelectorStates.isHovered;

    // #endregion

    // #region Functions

    const deleteQuestion = async() => {
        setIsDeleting(true);

        await CheckInQuestionModel.deleteByPath(`teams/${ team?.id }/checkInQuestions/${ question?.id }`);

        setIsDeleting(false);
    };

    const updateQuestion = async() => {
        setSavingUpdates(true);

        await CheckInQuestionModel.writeToPath(
            `teams/${ team?.id }/checkInQuestions/${ question?.id }`,
            editedQuestion
        );

        setSavingUpdates(false);
        setInEditMode(false);
    };

    // #endregion

    // #region Render Functions

    if (inEditMode) {
        return (
            <div className={localStyles.questionInput}>
                <ControlledTextField
                    className={localStyles.input}
                    color='primary'
                    id={`edit-question-${ editedQuestion?.id }-content`}
                    label={editedQuestion?.content ? null : 'Edit question'}
                    value={editedQuestion?.content || ''}
                    darkMode
                    onChange={(newValue) => setEditedQuestion((prev) => ({ ...prev, content: newValue }))}
                />
                <ControlledCheckbox
                    checked={editedQuestion?.isAlwaysAsked}
                    className={localStyles.checkbox}
                    color='primary'
                    id={`edit-question-${ editedQuestion?.id }-is-always-asked`}
                    label='Always Ask?'
                    darkMode
                    onChange={
                        (event) => setEditedQuestion((prev) => ({ ...prev, isAlwaysAsked: event.target.checked }))
                    }
                />
                <ControlledCheckbox
                    checked={editedQuestion?.isOptional}
                    className={localStyles.checkbox}
                    color='primary'
                    id={`edit-question-${ editedQuestion?.id }-is-optional`}
                    label='Optional'
                    darkMode
                    onChange={
                        (event) => setEditedQuestion((prev) => ({ ...prev, isOptional: event.target.checked }))
                    }
                />
                <IconButton
                    color='approve'
                    disabled={savingUpdates || !editedQuestion?.content}
                    SvgIcon={Check}
                    onClick={updateQuestion}
                />
                <IconButton
                    color='reject'
                    disabled={savingUpdates}
                    SvgIcon={Close}
                    onClick={() => setInEditMode(false)}
                />
            </div>
        );
    } else if (isDeleting) {
        return (
            <CircularSpinner
                color='reject'
                size={24}
            />
        );
    } else {
        return (
            <div
                className={localStyles.question}
                {...questionPseudoSelectorProps}
            >
                <h5 className={localStyles.mainContent}>
                    {question.content}
                </h5>
                {
                    question?.isOptional
                        ? (
                            <p className={localStyles.isOptionalNote}>
                                (optional)
                            </p>
                        )
                        : null
                }
                <div className={localStyles.actions}>
                    <IconButton
                        color='primary'
                        style={{ opacity: displayEditAndDeleteIcons ? 1 : 0 }}
                        SvgIcon={Edit}
                        onClick={() => setInEditMode(true)}
                    />
                    <IconButton
                        color='reject'
                        style={{ opacity: displayEditAndDeleteIcons ? 1 : 0 }}
                        SvgIcon={Delete}
                        onClick={deleteQuestion}
                    />
                </div>
            </div>
        );
    }

    // #endregion
}

Question.propTypes = {
    /**
     * The team to render the questions for.
     */
    team: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * The question to render.
     */
    question: PropTypes.shape({
        id:            PropTypes.string.isRequired,
        content:       PropTypes.string.isRequired,
        isAlwaysAsked: PropTypes.bool.isRequired,
        isOptional:    PropTypes.bool.isRequired,
        groupToAsk:    PropTypes.string.isRequired,
    }).isRequired,
};

Question.defaultProps = {

};

export default Question;
