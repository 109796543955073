import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    where,
    orderBy,
    collection,
    doc,
} from 'firebase/firestore';
import {
    IconButton,
} from '@psionic/ui';
import { ChatBubble } from '@mui/icons-material';
import { firestore } from '@services/firebase';
import { postComment } from '@services/check-in';
import CommentModel from '@models/comment';
import ReactionModel from '@models/reaction';
import { useFormattedReactions } from '@hooks/reactions';
import { onReactionClickedFactory } from '@utils/reactions';
import {
    PastPriority,
} from '@components/check-in';
import {
    CommentInput,
} from '@components/core';
import ExistingCommentControl from './existing-comment-control';
import localStyles from './connected-past-priority.module.scss';

/**
 * Displays a past priority, and maintains listeners for all of its associated comments and reactions.
 */
function ConnectedPastPriority({
    teamID,
    surveyID,
    surveyOwner,
    priority,
    profile,
    teamMembers,
    setChecked,
    reviewers,
}) {

    // #region State

    const [
        comments,
        setComments,
    ] = useState(null);

    const [
        reactions,
        setReactions,
    ] = useState(null);

    const [
        comment,
        setComment,
    ] = useState('');

    const [
        commentSectionOpen,
        setCommentSectionOpen,
    ] = useState(undefined);

    // #endregion

    // #region Effects

    /**
     * If there is at least 1 existing comment, open the comment section.
     */
    useEffect(() => {
        if (comments?.length > 0 && commentSectionOpen === undefined) {
            setCommentSectionOpen(true);
        }
    }, [ comments ]);

    /**
     * Track the comments for this priority.
     */
    CommentModel.useListenerByQuery(
        `priority-${ priority.id }-comments-listener`,
        [
            where('associatedData', '==', doc(collection(firestore, 'priorities'), priority.id)),
            orderBy('dateCreated'),
        ],
        setComments
    );

    /**
     * Track the reactions for this priority.
     */
    ReactionModel.useListenerByQuery(
        `priority-${ priority.id }-reactions-listener`,
        [ where('associatedData', '==', doc(collection(firestore, 'priorities'), priority.id)) ],
        setReactions
    );

    // #endregion

    // #region Memoized Values

    const formattedReactions = useFormattedReactions(reactions);

    // #endregion

    // #region Functions

    const onPostCommentClicked = async() => {
        const content = comment;
        setComment('');

        await postComment(
            doc(collection(firestore, 'priorities'), priority.id),
            reviewers,
            profile,
            content,
            teamID,
            surveyID,
            surveyOwner
        );
    };

    const onReactionClicked = onReactionClickedFactory(
        doc(collection(firestore, 'priorities'), priority.id),
        profile
    );

    // #endregion

    // #region Render Functions

    const existingCommentRenders = useMemo(() => {
        if (!comments) {
            return null;
        }

        const renders = [];

        for (const existingComment of comments) {
            renders.push(
                <ExistingCommentControl
                    key={`${ existingComment.id }-${ new Date() }`}
                    comment={existingComment}
                    profile={profile}
                    surveyID={surveyID}
                    teamID={teamID}
                    teamMembers={teamMembers}
                />
            );
        }

        return renders;
    }, [ comments ]);

    /**
     * Main render.
     */
    return (
        <div className={localStyles.connectedPastPriority}>
            <div className={localStyles.mainContent}>
                <PastPriority
                    checked={priority.completed}
                    reactions={formattedReactions}
                    setChecked={setChecked}
                    userID={profile.id}
                    users={teamMembers}
                    value={priority.content}
                    onReactionClicked={onReactionClicked}
                />
                <div className={localStyles.iconButton}>
                    <IconButton
                        color='lowEmphasis'
                        SvgIcon={ChatBubble}
                        onClick={() => setCommentSectionOpen((prev) => !prev)}
                    />
                </div>
            </div>
            {
                commentSectionOpen
                    ? (
                        <div className={localStyles.commentSection}>
                            {existingCommentRenders}
                            <CommentInput
                                key={comments.length}
                                id={`priority-${ priority.id }-new-comment-input`}
                                setValue={setComment}
                                user={profile}
                                value={comment}
                                onSaveClicked={onPostCommentClicked}
                            />
                        </div>
                    )
                    : null
            }
        </div>
    );

    // #endregion
}

ConnectedPastPriority.propTypes = {
    /**
     * The ID of the team the past priority is a part of.
     */
    teamID:      PropTypes.string.isRequired,
    /**
     * The ID of the survey the past priority is a part of.
     */
    surveyID:    PropTypes.string.isRequired,
    /**
     * The survey owner's profile.
     */
    surveyOwner: PropTypes.object.isRequired,
    /**
     * The priority to display.
     */
    priority:    PropTypes.shape({
        completed: PropTypes.bool,
        content:   PropTypes.object.isRequired,
        id:        PropTypes.string.isRequired,
    }).isRequired,
    /**
     * The current user's profile.
     */
    profile: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * The team members profiles.
     */
    teamMembers: PropTypes.object.isRequired,
    /**
     * The function to call to set the priority as checked / unchecked.
     */
    setChecked:  PropTypes.func,
    /**
     * The array of reviewers for the survey.
     */
    reviewers:   PropTypes.array.isRequired,
};

ConnectedPastPriority.defaultProps = {
    setChecked: undefined,
};

export default ConnectedPastPriority;
