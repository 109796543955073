import PropTypes from 'prop-types';
import {
    StickyTooltip,
    Button,
} from '@psionic/ui';
import { Recommend } from '@mui/icons-material';
import localStyles from './subscription-option.module.scss';

/**
 * A React card showing a particular subscription option.
 */
function SubscriptionOption({
    priceInfo,
    recommended,
    onClick,
    disabled,
}) {
    // #region Hooks

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.wrapper}
            data-recommended={recommended}
        >
            <div
                className={localStyles.subscriptionOptionCard}
                data-recommended={recommended}
            >
                <div className={localStyles.badge}>
                    <StickyTooltip content='Recommended Option!'>
                        <div
                            className={localStyles.badgeContent}
                            data-recommended={recommended}
                        >
                            <Recommend/>
                        </div>
                    </StickyTooltip>
                </div>
                <header>
                    <h5>
                        {priceInfo.base.planName}
                    </h5>
                </header>
                <div className={localStyles.divider}/>
                <section>
                    <h3>
                        <span className={localStyles.currencySign}>
                            $
                        </span>
                        <span className={localStyles.price}>
                            {priceInfo.base.price}
                        </span>
                        <span className={localStyles.perMonth}>
                            /
                            {priceInfo.base.months === 1 ? 'month' : `${ priceInfo.base.months } months`}
                        </span>
                    </h3>
                    <h4 className={localStyles.perUserInfo}>
                        <span className={localStyles.additionSign}>
                            +
                        </span>
                        <span className={localStyles.perUserCurrencySign}>
                            $
                        </span>
                        <span className={localStyles.perUserPrice}>
                            {priceInfo.perUser.price}
                        </span>
                        <span className={localStyles.perUserPerMonth}>
                            /user *
                        </span>
                    </h4>
                    <Button
                        disabled={disabled}
                        height='50px'
                        variant={recommended ? 'contained' : 'outlined'}
                        width='100%'
                        darkMode
                        onClick={onClick}
                    >
                        Select Plan
                    </Button>
                </section>
            </div>
        </div>
    );

    // #endregion
}

SubscriptionOption.propTypes = {
    /**
     * Whether the "select option" button should be disabled or not.
     */
    disabled:    PropTypes.bool,
    /**
     * The price information for the subscription option.
     */
    priceInfo:   PropTypes.object.isRequired,
    /**
     * Callback for when the "Select Plan" button is clicked.
     */
    onClick:     PropTypes.func.isRequired,
    /**
     * Whether the subscription option is recommended or not.
     */
    recommended: PropTypes.bool,
};

SubscriptionOption.defaultProps = {
    recommended: false,
    disabled:    false,
};

export default SubscriptionOption;
