import { useRedirectOnCondition } from '@unifire-js/hooks';
import {
    useRedirectOnAuthCondition,
    useUserContext,
} from '@unifire-js/firebase/auth';
import { Warning } from '@mui/icons-material';
import { Snackbar } from '@psionic/ui';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';

/**
 * Redirect the user to the login page if they are not signed in, or if they haven't
 * verified their email yet.
 */
export function useRedirectWhenNotSignedInOrVerified() {
    // Use user context
    const { profile } = useUserContext();

    // Redirect to the login page if the user is not signed in
    const authChecked = useRedirectOnAuthCondition(
        '/home/login',
        (userContext) => {
            if (!userContext.user) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You must be signed in to view this page!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        }
    );

    // Redirect to home page whenever the user's profile is not verified
    const emailVerificationChecked = useRedirectOnCondition(
        '/',
        () => {
            if (!profile.emailVerified) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You must verify your email before you can view this page!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        },
        [ profile ],
        () => { return !profile; }
    );

    // Return a flag indicating whether both checks have been performed yet or not
    return authChecked && emailVerificationChecked;
}
