export default {
    primary:        '#009ad7',
    secondary:      '#003348',
    approve:        '#3DDC97',
    reject:         '#FF495C',
    warning:        '#ffc652',
    warningDull:    '#b37a08',
    notification:   '#ffc652',
    inactiveLink:   '#b3b3b3',
    mediumEmphasis: 'rgba(255, 255, 255, 0.8)',
    lowEmphasis:    'rgba(255, 255, 255, 0.4)',
    footerText:     '#4b4b4b',
};
