import { useMemo } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {
    Delete,
    Edit,
} from '@mui/icons-material';
import {
    StickyTooltip,
    IconButton,
} from '@psionic/ui';
import {
    GroupAvatar,
    UserAvatar,
} from '@components/avatars';
import localStyles from './group-info.module.scss';

/**
 * Detail component for showing group information.
 */
function GroupInfo({
    group,
    teammates,
    onDelete,
    onEdit,
}) {
    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Teammate list items.
     */
    const teammateItems = useMemo(() => {
        const items = [];

        for (const memberObj of group.members) {
            const teammate = find(teammates, [
                'id',
                memberObj.value,
            ]);

            if (teammate) {
                items.push(
                    <li key={memberObj.value}>
                        <UserAvatar
                            imageURL={teammate?.avatarURL}
                            size='small'
                            userEmail={teammate?.email}
                            userName={teammate?.displayName}
                        />
                    </li>
                );
            }
        }

        return items;
    }, [
        teammates,
        group,
    ]);

    /**
     * Main render.
     */
    return (
        <div className={localStyles.groupInfo}>
            <GroupAvatar
                groupName={group.name}
                imageURL={group.avatarURL}
                size='small'
            />
            <ul>
                {teammateItems}
            </ul>
            <p className={localStyles.groupName}>
                {group.name}
            </p>
            <div className={localStyles.actions}>
                <StickyTooltip content='Edit Group'>
                    <IconButton
                        SvgIcon={Edit}
                        onClick={onEdit}
                    />
                </StickyTooltip>
                <StickyTooltip content='Delete Group'>
                    <IconButton
                        color='reject'
                        SvgIcon={Delete}
                        onClick={onDelete}
                    />
                </StickyTooltip>
            </div>
        </div>
    );

    // #endregion
}

GroupInfo.propTypes = {
    /**
     * The group to represent the info of.
     */
    group:     PropTypes.object.isRequired,
    /**
     * Callback for when the "delete" button is clicked.
     */
    onDelete:  PropTypes.func.isRequired,
    /**
     * Callback for when the "edit" button is clicked.
     */
    onEdit:    PropTypes.func.isRequired,
    /**
     * Array of teammates the group can contain.
     */
    teammates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

GroupInfo.defaultProps = {

};

export default GroupInfo;
