import { Model } from '@unifire-js/firebase/firestore';

const WorkerModel = new Model({
    collectionName:  'workers',
    collectionProps: [
        'options',
        'performAt',
        'worker',
        'searchableMetadata',
    ],
    propDefaults: {
        options: {},
    },
});

export default WorkerModel;
