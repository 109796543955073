import { useState } from 'react';
import PropTypes from 'prop-types';
import localStyles from './filled-text-field.module.scss';

/**
 * A filled text field component.
 */
function FilledTextField({
    label,
    value,
    setValue,
    error,
    helperText,
}) {
    // #region State

    /**
     * Track whether the input is focused.
     */
    const [focused, setFocused] = useState(false);

    // #endregion

    // #region Effects

    // #endregion Effects

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.filledTextField}
            data-focused={focused}
            data-error={error}
        >
            <p className={localStyles.label}>
                {label}
            </p>
            <textarea
                value={value}
                onChange={(event) => setValue(event.target.value)}
                rows="1"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            <p className={localStyles.helperText}>
                {helperText}
            </p>
        </div>
    );

    // #endregion
}

FilledTextField.propTypes = {
    /**
     * The label to display for the field.
     */
    label:      PropTypes.string.isRequired,
    /**
     * The value currently in the input, likely controlled by state.
     */
    value:      PropTypes.string,
    /**
     * The callback function to set the value.
     */
    setValue:   PropTypes.func.isRequired,
    /**
     * Flag indicating whether the field is in an error state.
     */
    error:      PropTypes.bool,
    /**
     * Helper text to display below the field.
     */
    helperText: PropTypes.string,
};

FilledTextField.defaultProps = {
    error:      false,
    helperText: '',
};

export default FilledTextField;
