import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
} from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import {
    ExpandMore,
    Grade,
} from '@mui/icons-material';
import {
    OPTION_TYPES,
} from '@utils/constants';
import {
    UserAvatar,
    GroupAvatar,
} from '@components/avatars';
import {
    PriorityIndicator,
    MinuteInfo,
} from '@components/details';
import {
    MinuteInput,
} from '@components/inputs';
import {
    ActionItemControl,
} from '@components/controls';
import { AddItemButton } from '@components/buttons';
import localStyles from './minute-accordion.module.scss';

/**
 * Accordion for displaying and modifying a minute.
 */
function MinuteAccordion({
    minute,
    actionItems,
    currentTeam,
    teammates,
    groups,
    openByDefault,
    onReviewUpdate,
    onDelete,
    onReschedule,
    onSave,
    onChangePriority,
    rescheduleLimitedToPresent,
    showMinuteEditButton,
    onActionItemCompleteUpdate,
    onActionItemDelete,
    onActionItemSave,
    onNewActionItemSave,
    highlightOnReviewed,
    showAddActionItemButton,
}) {
    // #region Constants

    // #endregion

    // #region State

    /**
     * Track whether the minute is in edit mode or not.
     */
    const [
        inEditMode,
        setInEditMode,
    ] = useState(false);

    /**
     * Track the action item to add.
     */
    const [
        actionItemToAdd,
        setActionItemToAdd,
    ] = useState(null);

    // #endregion

    // #region Effects

    // #endregion

    // #region Memoized Values

    /**
     * Memoized owner from the teammates list.
     */
    const owner = useMemo(() => {
        return find(teammates, [
            'id',
            minute.owner,
        ]);
    }, [
        minute,
        teammates,
    ]);

    // #endregion

    // #region Functions

    /**
     * Augmented `onSave` function that also sets the edit mode to false.
     */
    const augmentedOnSave = (newData) => {
        setInEditMode(false);
        onSave(newData);
    };

    /**
     * Augmented `onNewActionItemSave` function that also sets the new action item to null.
     */
    const augmentedOnNewActionItemSave = (newData) => {
        onNewActionItemSave(newData);
        setActionItemToAdd(null);
    };

    /**
     * Callback to create the action item to add item.
     */
    const createActionItemToAdd = () => {
        setActionItemToAdd({
            requires: [],
            complete: false,
            message:  '',
            teamID:   currentTeam.id,
        });
    };

    // #endregion

    // #region Render Functions

    /**
     * Users required render.
     */
    const usersRequired = useMemo(() => {
        const listItems = [];
        let userID,
            user,
            groupID,
            group;

        for (const required of Object.values(minute?.requires || {})) {
            switch (required.type) {
                case OPTION_TYPES.TEAMMATE:
                    userID = required.value;
                    user = find(teammates, [
                        'id',
                        userID,
                    ]);

                    if (user) {
                        listItems.push(
                            <li key={userID}>
                                <UserAvatar
                                    imageURL={user?.avatarURL}
                                    size='extra-small'
                                    userEmail={user?.email}
                                    userName={user?.displayName}
                                />
                            </li>
                        );
                    }
                    break;
                case OPTION_TYPES.GROUP:
                    groupID = required.value;
                    group = find(groups, [
                        'id',
                        groupID,
                    ]);

                    if (group) {
                        listItems.push(
                            <li key={groupID}>
                                <GroupAvatar
                                    groupName={group?.name}
                                    imageURL={group?.avatarURL}
                                    size='extra-small'
                                />
                            </li>
                        );
                    }
                    break;
                case OPTION_TYPES.ALL:
                    listItems.push(
                        <li key='all'>
                            <GroupAvatar
                                groupName='All'
                                Icon={Grade}
                                size='extra-small'
                            />
                        </li>
                    );
                    break;
                default:
                    break;
            }
        }

        return listItems;
    }, [
        minute,
        teammates,
        groups,
    ]);

    /**
     * Action items list items render.
     */
    const actionItemListItems = useMemo(() => {
        const listItems = [];

        for (const actionItem of actionItems || []) {
            listItems.push(
                <li key={actionItem.id}>
                    <ActionItemControl
                        actionItem={actionItem}
                        groups={groups}
                        teammates={teammates}
                        onSave={onActionItemSave}
                        onCompleteUpdate={(checked) => {
                            return onActionItemCompleteUpdate(actionItem.id, checked);
                        }}
                        onDelete={onActionItemDelete ? () => {
                            return onActionItemDelete(actionItem.id);
                        } : null}
                    />
                </li>
            );
        }

        return listItems;
    }, [ actionItems ]);

    /**
     * Action item to add list item render.
     */
    const actionItemToAddListItem = useMemo(() => {
        if (!actionItemToAdd) return null;

        return (
            <li key='newActionItem'>
                <ActionItemControl
                    actionItem={actionItemToAdd}
                    groups={groups}
                    teammates={teammates}
                    isNewActionItem
                    onCompleteUpdate={() => {}}
                    onDelete={() => {}}
                    onSave={augmentedOnNewActionItemSave}
                    onCancel={() => {
                        return setActionItemToAdd(null);
                    }}
                />
            </li>
        );
    }, [ actionItemToAdd ]);

    /**
     * Main render.
     */
    return (
        <Accordion
            className={localStyles.accordion}
            defaultExpanded={openByDefault}
        >
            <AccordionSummary
                className={localStyles.header}
                data-reviewed={minute.reviewed && highlightOnReviewed ? 'true' : 'false'}
                expandIcon={<ExpandMore/>}
            >
                {
                    onReviewUpdate
                        ? (
                            <section className={localStyles.reviewedInfo}>
                                <StickyTooltip
                                    content={
                                        minute.reviewed
                                            ? 'Mark as Not Reviewed'
                                            : 'Mark as Reviewed'
                                    }
                                >
                                    <Checkbox
                                        checked={minute.reviewed}
                                        onChange={(event) => {
                                            return onReviewUpdate(event.target.checked);
                                        }}
                                        onClick={(event) => {
                                            return event.stopPropagation();
                                        }}
                                    />
                                </StickyTooltip>
                            </section>
                        )
                        : null
                }
                <section className={localStyles.userInfo}>
                    <section className={localStyles.ownerInfo}>
                        <UserAvatar
                            imageURL={owner?.avatarURL}
                            size='extra-small'
                            userEmail={owner?.email}
                            userName={owner?.displayName}
                        />
                    </section>
                    <section className={localStyles.requiresInfo}>
                        <ul>
                            {usersRequired}
                        </ul>
                    </section>
                </section>
                <PriorityIndicator
                    priority={minute?.priority}
                    onChangePriority={onChangePriority}
                />
                <section className={localStyles.messagePreview}>
                    {minute.message}
                </section>
            </AccordionSummary>
            <AccordionDetails className={localStyles.body}>
                <section className={localStyles.messageInfo}>
                    <h5>Message</h5>
                    {
                        inEditMode
                            ? (
                                <MinuteInput
                                    groups={groups}
                                    minute={minute}
                                    teammates={teammates}
                                    onSave={augmentedOnSave}
                                    onCancel={() => {
                                        return setInEditMode(false);
                                    }}
                                />
                            )
                            : (
                                <MinuteInfo
                                    groups={groups}
                                    minute={minute}
                                    rescheduleLimitedToPresent={rescheduleLimitedToPresent}
                                    teammates={teammates}
                                    onDelete={onDelete}
                                    onReschedule={onReschedule}
                                    onReviewUpdate={onReviewUpdate}
                                    onEdit={showMinuteEditButton ? () => {
                                        return setInEditMode(true);
                                    } : null}
                                />
                            )
                    }
                </section>
                <section className={localStyles.actionItems}>
                    <h5>Action Items</h5>
                    <ul>
                        {actionItemListItems}
                        {
                            actionItemToAdd
                                ? actionItemToAddListItem
                                : showAddActionItemButton
                                    ? (
                                        <li>
                                            <AddItemButton
                                                label='Add Action Item'
                                                onClick={createActionItemToAdd}
                                            />
                                        </li>
                                    )
                                    : null
                        }
                    </ul>
                </section>
            </AccordionDetails>
        </Accordion>
    );

    // #endregion
}

MinuteAccordion.propTypes = {
    /**
     * Any action items the minute may have.
     */
    actionItems:                PropTypes.arrayOf(PropTypes.object),
    /**
     * The current team.
     */
    currentTeam:                PropTypes.object.isRequired,
    /**
     * The list of groups that can be associated to the minute.
     */
    groups:                     PropTypes.arrayOf(PropTypes.object),
    /**
     * Flag indicating whether the minute should be highlighted in green whenever it has been marked
     * as reviewed.
     */
    highlightOnReviewed:        PropTypes.bool,
    /**
     * The minute to represent.
     */
    minute:                     PropTypes.object.isRequired,
    /**
     * Callback for when an action item's "completed" flag is updated.
     */
    onActionItemCompleteUpdate: PropTypes.func.isRequired,
    /**
     * Callback for when an action item's "delete" button is clicked.
     */
    onActionItemDelete:         PropTypes.func,
    /**
     * Callback for when an action item's "save" button is clicked.
     */
    onActionItemSave:           PropTypes.func,
    /**
     * The callback for when the minute's priority is changed.
     */
    onChangePriority:           PropTypes.func,
    /**
     * The callback for wen the minute is deleted.
     */
    onDelete:                   PropTypes.func,
    /**
     * Callback for when a new action item is saved.
     */
    onNewActionItemSave:        PropTypes.func,
    /**
     * The callback for when the minute is rescheduled.
     */
    onReschedule:               PropTypes.func,
    /**
     * The callback for when the minute's "reviewed" flag is updated.
     */
    onReviewUpdate:             PropTypes.func,
    /**
     * The callback for when the minute is saved.
     */
    onSave:                     PropTypes.func,
    /**
     * Whether the accordion should be open by default.
     */
    openByDefault:              PropTypes.bool,
    /**
     * Whether the minute's rescheduling capability should be restricted to only
     * be for the present / future.
     */
    rescheduleLimitedToPresent: PropTypes.bool,
    /**
     * Whether the minute's edit button should be shown or not.
     */
    showMinuteEditButton:       PropTypes.bool,
    /**
     * Whether to show the "add action item" button.
     */
    showAddActionItemButton:    PropTypes.bool,
    /**
     * The list of teammates that can be associated to the minute.
     */
    teammates:                  PropTypes.arrayOf(PropTypes.object).isRequired,
};

MinuteAccordion.defaultProps = {
    openByDefault:              false,
    showMinuteEditButton:       true,
    actionItems:                [],
    groups:                     undefined,
    onActionItemDelete:         undefined,
    onActionItemSave:           undefined,
    onChangePriority:           undefined,
    onDelete:                   undefined,
    onNewActionItemSave:        undefined,
    onReschedule:               undefined,
    onReviewUpdate:             undefined,
    onSave:                     undefined,
    rescheduleLimitedToPresent: false,
    highlightOnReviewed:        true,
    showAddActionItemButton:    true,
};

export default MinuteAccordion;
