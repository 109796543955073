import { useEffect, useState } from 'react';

// #region Hook

/**
 * Tracks the specified live Firestore submodel data with a changing query in the specified context.
 *
 * @param {string} listenerID The ID to use for the listener; should be unique
 * @param {Object} contextUsageObj The context usage object to use to track the live data;
 * should come from the `SomeContextLevel.use.contextCategory`-style variables
 * @param {Object} submodel The Firestore submodel to track the live data of
 * @param {string} path The path to the submodel data to track
 * @param {Array<Object>} query The query to pass to the Firestore model `useLiveDataByQuery` function
 * @param {Array<*>} dependencies The dependencies to pass to the `useEffect` hook which will trigger
 * the changing query
 * @param {function} [skipIfFn] Optional function to determine whether to skip the live data tracking;
 * should return `true` to skip fetching the live data for this cycle
 * @param {function} [processingFn] Optional function to process the live data before it is set in the
 * context
 * @returns {[*, boolean, boolean]} The live data, the initial data fetched flag, and the up-to-date flag
 */
export default function(listenerID, contextUsageObj, submodel, path, query, dependencies, skipIfFn, processingFn) {
    // Track the live data in state
    const [
        liveData,
        setLiveData,
    ] = useState(undefined);

    // Track a state variable stating whether the initial live data has been fetched
    const [
        initialDataFetched,
        setInitialDataFetched,
    ] = useState(false);

    // Track another state variable stating whether the live data is currently up-to-date
    const [
        isUpToDate,
        setIsUpToDate,
    ] = useState(false);

    // Use both the context API and context value
    const api = contextUsageObj.api();

    // Create a new listener each time the dependencies change if the skip function is not
    // telling the hook to skip
    useEffect(() => {
        setLiveData(undefined);
        setIsUpToDate(false);

        if (skipIfFn?.()) {
            setInitialDataFetched(true);
            setIsUpToDate(true);

            return;
        }

        // Create a new listener
        submodel.addListenerByQueryInInstance(
            listenerID,
            path,
            query,
            (newData) => {
                const finalData = processingFn ? processingFn(newData) : newData;

                setLiveData(finalData);

                if (!initialDataFetched) {
                    setInitialDataFetched(true);
                }

                setIsUpToDate(true);
            }
        );

        return () => submodel.removeListener(listenerID);
    }, [ ...dependencies ]);

    // Whenever the live data changes, update the context
    useEffect(() => {
        api.set(liveData);
    }, [ liveData ]);

    // Return the live data, in case it is needed by the caller
    return [
        liveData,
        initialDataFetched,
        isUpToDate,
    ];
}

// #endregion

// #region Helper Functions

// #endregion
