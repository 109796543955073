import { where } from 'firebase/firestore';
import AssociationModel from '@models/association';

/**
 * Removes the given user from the team.
 */
export default async function removeUserFromTeam(profile, team) {
    // Retrieve the association related to the given profile and team
    const association = await (async() => {
        const matchingAssociations = await AssociationModel.getByQuery([
            where('userID', '==', profile.id),
            where('teamID', '==', team.id),
        ]);

        if (matchingAssociations.length > 0) {
            return matchingAssociations[ 0 ];
        } else {
            return null;
        }
    })();

    // If the association exists, delete it
    if (association) {
        await AssociationModel.deleteByID(association.id);
    }
}
