import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserContext } from '@unifire-js/firebase/auth';
import { where } from 'firebase/firestore';
import {
    userAssociations_FluxState,
    userInvites_FluxState,
} from '@data/user';
import AssociationModel from '@models/association';
import InviteModel from '@models/invite';

/**
 * Wrapper for any routes outside of any workspace.
 */
function OutsideWorkspaceWrapper() {
    // #region Hooks

    /**
     * Get the user context.
     */
    const { profile } = useUserContext();

    // #endregion

    // #region Effects

    useEffect(() => {
        if (!profile) {
            userAssociations_FluxState.set(null);
            userInvites_FluxState.set(null);
        }
    }, [ profile ]);

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            {profile ? <PopulateAssociationsContext profile={profile}/> : null}
            <Outlet/>
        </>
    );

    // #endregion
}

// eslint-disable-next-line react/prop-types, react/no-multi-comp
function PopulateAssociationsContext({ profile }) {
    const [
        associations,
        // eslint-disable-next-line no-unused-vars
        initialAssociationsFetchDone,
    ] = AssociationModel.useLiveDataByQuery(
        'UserAssociationsListener',
        // eslint-disable-next-line react/prop-types
        [ where('userID', '==', profile?.id) ]
    );

    const [
        invites,
        // eslint-disable-next-line no-unused-vars
        initialInvitesFetchDone,
    ] = InviteModel.useLiveDataByQuery(
        'UserInvitesListener',
        // eslint-disable-next-line react/prop-types
        [ where('email', '==', profile?.email) ]
    );

    useEffect(() => {
        userAssociations_FluxState.set(associations);
    }, [ associations ]);

    useEffect(() => {
        userInvites_FluxState.set(invites);
    }, [ invites ]);

    return null;
}

export default OutsideWorkspaceWrapper;
