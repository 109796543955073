import { useEffect } from 'react';
import BulletinBoardContextLevel from '@contexts/bulletin-board';

// #region Hook

/**
 * Replace with your own documentation.
 */
export default function(elementID, unsavedValue) {

    // #region Context

    /**
     * Use the bulletin tracker from the bulletin board context level.
     */
    const bulletinTracker = BulletinBoardContextLevel.use.bulletinTracker.value();

    // #endregion

    // #region Effects

    /**
     * Whenever the element ID or the unsaved value updates, update the bulletin tracker.
     */
    useEffect(() => {
        if (bulletinTracker?.elements) {
            const elementIndex = bulletinTracker.getElementIndex(elementID);
            bulletinTracker.elements[ elementIndex ].value = unsavedValue;
        }
    }, [
        elementID,
        unsavedValue,
        bulletinTracker,
    ]);

    // #endregion

}

// #endregion

// #region Helper Functions

// #endregion
