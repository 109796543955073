import PropTypes from 'prop-types';
import localStyles from './upper-toast.module.scss';

/**
 * A toast that appears at the top of the screen.
 */
function UpperToast({
    isOpen,
    children,
}) {
    return (
        <div
            className={localStyles.upperToastWrapper}
            data-is-open={isOpen}
        >
            <div className={localStyles.upperToast}>
                {children}
            </div>
        </div>
    );
}

UpperToast.propTypes = {
    /**
     * Whether or not the toast is open.
     */
    isOpen:   PropTypes.bool.isRequired,
    /**
     * The content of the toast.
     */
    children: PropTypes.node.isRequired,
};

export default UpperToast;
