import {
    useState, useMemo, useEffect, useRef,
} from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import { useUserContext } from '@unifire-js/firebase/auth';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { DateTime } from 'luxon';
import {
    CircularSpinner,
    ControlledTextField,
    Button,
    Snackbar,
} from '@psionic/ui';
import { Check, Error } from '@mui/icons-material';
import { SnackbarService } from '@services/snackbar';
import { useInterval } from '@hooks/timing';
import MyCheckInsContextLevel from '@contexts/my-check-ins';
import TeamContextLevel from '@contexts/team';
import { submitSurvey } from '@services/check-in';
import { formatToLargestUnit } from '@utils/time-utils';
import {
    getSavedSurvey,
    setSavedSurvey,
    getSavedResponses,
    setSavedResponses,
    getSavedPriorities,
    setSavedPriorities,
    getSavedCompletedPriorities,
    setSavedCompletedPriorities,
} from '@utils/localstorage';
import {
    HeartbeatInput,
    QuestionInput,
    ReviewersInfo,
} from '@components/check-in';
import ConnectedPastPriority from './subcomponents/connected-past-priority';
import localStyles from './current-survey.module.scss';

// #region Magic Numbers

// eslint-disable-next-line no-magic-numbers
const ONCE_A_MINUTE = 1000 * 60;

// #endregion

function CurrentSurvey() {

    // #region Refs

    const responsesRef = useRef(undefined);

    const heartbeatAdditionalCommentRef = useRef(undefined);

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const survey = MyCheckInsContextLevel.use.currentSurvey.value();

    const questions = MyCheckInsContextLevel.use.currentSurveyQuestions.value();

    const reviewers = MyCheckInsContextLevel.use.reviewers.value();

    const team = TeamContextLevel.use.currentTeam.value();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const pastPriorities = MyCheckInsContextLevel.use.priorities.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    // #endregion

    // #region State

    const [
        editedSurvey,
        setEditedSurvey,
    ] = useState(undefined);

    const [
        currentDate,
        setCurrentDate,
    ] = useState(DateTime.local());

    const [
        newPriorities,
        setNewPriorities,
    ] = useState(undefined);

    const [
        showErrors,
        setShowErrors,
    ] = useState(false);

    const [
        completedPriorities,
        setCompletedPriorities,
    ] = useState(undefined);

    const [
        heartbeatAdditionalCommentLoaded,
        setHeartbeatAdditionalCommentLoaded,
    ] = useState(false);

    // #endregion

    // #region Effects

    /**
     * Every minute, update the current date.
     */
    useInterval(
        () => {
            setCurrentDate(DateTime.local());
        },
        ONCE_A_MINUTE
    );

    /**
     * Whenever the edited survey updates, save it to local storage.
     */
    useEffect(() => {
        if (editedSurvey !== undefined) {
            setSavedSurvey(editedSurvey);
        }
    }, [ editedSurvey ]);

    /**
     * When the survey updates, if it's not undefined, load the survey, responses, and priorities from local storage.
     */
    useEffect(() => {
        if (survey) {
            // Load the saved survey
            const savedSurvey = getSavedSurvey();
            setEditedSurvey(
                savedSurvey?.id === survey?.id
                    ? {
                        ...savedSurvey,
                        startDate: survey.startDate,
                        endDate:   survey.endDate,
                    }
                    : survey
            );

            // Load the saved responses
            const savedResponses = getSavedResponses();
            responsesRef.current = savedSurvey?.id === survey?.id ? savedResponses || [] : [];

            // Load the saved priorities
            const savedPriorities = getSavedPriorities();

            if (savedPriorities?.length <= 0) {
                savedPriorities.push('');
            }
            setNewPriorities(savedSurvey?.id === survey?.id ? savedPriorities || [ '' ] : [ '' ]);

            // Load the saved completed priorities
            const savedCompletedPriorities = getSavedCompletedPriorities();
            setCompletedPriorities(savedSurvey?.id === survey?.id ? savedCompletedPriorities || [] : []);
        }
    }, [ survey ]);

    /**
     * Whenever the new priorities update, save them to local storage.
     */
    useEffect(() => {
        if (newPriorities !== undefined) {
            setSavedPriorities(newPriorities);
        }
    }, [ newPriorities ]);

    /**
     * Whenever the completed priorities update, save them to local storage.
     */
    useEffect(() => {
        if (completedPriorities !== undefined) {
            setSavedCompletedPriorities(completedPriorities);
        }
    }, [ completedPriorities ]);

    /**
     * Whenever the new priorities update, if the last priority in the array is not empty (or if no priority
     * yet exists), add a new empty priority to the end of the array.
     */
    useEffect(() => {
        if (newPriorities === undefined) {
            return;
        }

        if (newPriorities?.length <= 0) {
            setNewPriorities([ '' ]);
        }

        if (newPriorities[ newPriorities.length - 1 ] !== '') {
            setNewPriorities((prev) => [
                ...prev,
                '',
            ]);
        }
    }, [ newPriorities ]);

    /**
     * Set the content of the additional comment ref whenever appriorate.
     */
    useEffect(() => {
        if (
            !heartbeatAdditionalCommentLoaded
            && heartbeatAdditionalCommentRef.current
            && editedSurvey?.heartbeatAdditionalComment
        ) {
            heartbeatAdditionalCommentRef.current.setContent(editedSurvey.heartbeatAdditionalComment);
            setHeartbeatAdditionalCommentLoaded(true);
        }
    }, [ editedSurvey ]);

    // #endregion

    // #region Memoized Values

    const timeLeftBeforeCheckInDue = useMemo(() => {
        if (!survey?.endDate || !survey) {
            return null;
        }

        return DateTime.fromJSDate(survey?.endDate)?.diff(currentDate)
            .shiftTo('days', 'hours', 'minutes');
    }, [
        survey,
        currentDate,
    ]);

    // #endregion

    // #region Functions

    const formatSurveyDate = (date) => {
        const luxonObj = DateTime.fromJSDate(date);

        return luxonObj.toFormat('MMM d');
    };

    const updateFieldInSurvey = (fieldKey, newValue) => {
        setEditedSurvey((prev) => ({
            ...prev,
            [ fieldKey ]: newValue,
        }));
    };

    const saveResponsesToLocalStorage = () => {
        setSavedResponses(responsesRef.current);
    };

    const updateNewPriority = (index, newValue) => {
        setNewPriorities((prev) => [
            ...prev.slice(0, index),
            newValue,
            ...prev.slice(index + 1),
        ]);
    };

    const removeInputIfEmptyAndNotLast = (index) => {
        if (index === newPriorities.length - 1) {
            return;
        }

        if (!newPriorities[ index ]) {
            setNewPriorities((prev) => [
                ...prev.slice(0, index),
                ...prev.slice(index + 1),
            ]);
        }
    };

    const surveyIsValid = () => {
        // Validate that a heartbeat value has been selected
        if (!editedSurvey?.heartbeatValue) {
            return false;
        }

        // Validate that for each non-optional question, at least one response has been provided
        for (const question of questions) {
            const filledInResponsesToQuestion = filter(
                responsesRef.current,
                (response) => response.surveyQuestionID === question.id && response.content
            );

            if (!question.isOptional && filledInResponsesToQuestion.length <= 0) {
                return false;
            }
        }

        // Validate that at least one new priority was created
        if (!newPriorities || newPriorities?.length <= 0) {
            return false;
        }

        return true;
    };

    const onSubmitClicked = async() => {
        // If the survey is valid, submit the survey
        if (surveyIsValid()) {
            await submitSurvey(
                profile.id,
                editedSurvey,
                completedPriorities,
                newPriorities,
                responsesRef.current
            );

            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => {
                    return (
                        <Snackbar
                            color='approve'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Check}
                            text='Survey Submitted!'
                        />
                    );
                }
            );
        }

        // Otherwise, show an error snackbar
        else {
            setShowErrors(true);

            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => {
                    return (
                        <Snackbar
                            color='reject'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Error}
                            text='Please fill out all required fields.'
                        />
                    );
                }
            );
        }
    };

    const onPriorityCompleteStateUpdated = (priorityID, checked) => {
        if (checked) {
            setCompletedPriorities((prev) => [
                ...prev,
                priorityID,
            ]);
        } else {
            setCompletedPriorities((prev) => filter(prev, (id) => id !== priorityID));
        }
    };

    // #endregion

    // #region Render Functions

    const renderDueDate = () => {
        if (survey.dateSubmitted) {
            return (
                <p className={localStyles.submittedText}>
                    Submitted
                </p>
            );
        }

        const formattedDuration = formatToLargestUnit(timeLeftBeforeCheckInDue);

        let colorToUse = '#3DDC97';

        if (timeLeftBeforeCheckInDue.as('days') <= 2) {
            colorToUse = '#ffc652';
        }

        if (timeLeftBeforeCheckInDue.as('days') <= 1) {
            colorToUse = '#FF495C';
        }

        return (
            <span className={localStyles.dueDate}>
                <span
                    className={localStyles.colorIndicator}
                    style={{ background: colorToUse }}
                />
                <p>
                    Due in
                    {' '}
                    {formattedDuration}
                </p>
            </span>
        );
    };

    const renderQuestions = () => {
        if (!questions || responsesRef.current === undefined) {
            return <CircularSpinner/>;
        }

        const renders = [];

        const sortedQuestions = sortBy(
            questions,
            [
                (item) => (item.groupID === 'ALL' ? 0 : 1),
                'groupID',
                'isOptional',
            ]
        );

        for (const question of sortedQuestions) {
            renders.push(
                <li key={`question-${ question.id }-input`}>
                    <QuestionInput
                        id={`question-${ question.id }-input`}
                        isOptional={question.isOptional}
                        question={question}
                        responsesRef={responsesRef}
                        showErrors={showErrors}
                        groupAsked={
                            question.groupID === 'ALL'
                                ? { id: 'ALL' }
                                : teamGroups.find((group) => group.id === question.groupID)
                        }
                        onChange={saveResponsesToLocalStorage}
                    />
                </li>
            );
        }

        return renders;
    };

    const renderPastPriorities = () => {
        if (!pastPriorities) {
            return <CircularSpinner/>;
        }

        if (pastPriorities?.length <= 0) {
            return (
                <p className={localStyles.emptyState}>
                    You don't have any past priorities yet. Priorities that you create
                    on surveys which have not been completed yet will show up here.
                </p>
            );
        }

        const renders = [];

        for (const priority of pastPriorities) {
            renders.push(
                <li key={`priority-${ priority.id }-input`}>
                    <ConnectedPastPriority
                        profile={profile}
                        reviewers={reviewers}
                        setChecked={(newValue) => onPriorityCompleteStateUpdated(priority.id, newValue)}
                        surveyID={survey.id}
                        surveyOwner={profile}
                        teamID={team.id}
                        teamMembers={teamMembers}
                        priority={{
                            ...priority,
                            completed: (completedPriorities || []).includes(priority.id),
                        }}
                    />
                </li>
            );
        }

        return renders;
    };

    const renderCurrentPriorityInputs = () => {
        if (!newPriorities) {
            return <CircularSpinner/>;
        }

        const nonEmptyPriorities = filter(
            newPriorities || [],
            (priority) => Boolean(priority?.content)
        );

        const hasError = showErrors && nonEmptyPriorities?.length <= 0;

        const renders = [];

        for (const [
            newPriorityIndex,
            newPriority,
        ] of newPriorities.entries()) {
            renders.push(
                <li key={`new-priority-${ newPriorityIndex }-input`}>
                    <div className={localStyles.bulletPoint}/>
                    <ControlledTextField
                        color='primary'
                        hasError={hasError}
                        label={newPriority ? '' : 'New Priority...'}
                        value={newPriority}
                        darkMode
                        multiline
                        onBlur={() => removeInputIfEmptyAndNotLast(newPriorityIndex)}
                        onChange={(newValue) => updateNewPriority(newPriorityIndex, newValue)}
                    />
                </li>
            );
        }

        return (
            <>
                {
                    hasError
                        ? (
                            <p className={localStyles.requiresOnePriorityError}>
                                You must create at least one new priority
                            </p>
                        )
                        : null
                }
                <ul className={localStyles.currentPriorities}>
                    {renders}
                </ul>
            </>
        );
    };

    /**
     * If no survey is found, render nothing.
     */
    if (!survey) {
        return (
            <p className={localStyles.emptyState}>
                You don't have any surveys to fill out right now. Check out the "All Surveys" tab
                to see all of your past surveys.
            </p>
        );
    }

    /**
     * If the current survey has already been submitted, render a note about this.
     */
    if (survey.dateSubmitted) {
        return (
            <p className={localStyles.emptyState}>
                You've already submitted your check-in for this check-in cycle. Check out the "All Surveys" tab
                to see all of your past surveys.
            </p>
        );
    }

    /**
     * Main render.
     */
    return (
        <div className={localStyles.currentSurvey}>
            <header>
                <h2>
                    My Check-In:
                    {' '}
                    {formatSurveyDate(survey.startDate)}
                    {' '}
                    –
                    {' '}
                    {formatSurveyDate(survey.endDate)}
                </h2>
                {renderDueDate()}
            </header>
            <ReviewersInfo reviewers={reviewers}/>
            <section className={localStyles.form}>
                <div className={localStyles.heartbeat}>
                    <HeartbeatInput
                        ref={heartbeatAdditionalCommentRef}
                        additionalComment={editedSurvey?.heartbeatAdditionalComment}
                        selectedValue={editedSurvey?.heartbeatValue}
                        showErrors={showErrors}
                        onAdditionalCommentChange={(value) => updateFieldInSurvey('heartbeatAdditionalComment', value)}
                        onChange={(value) => updateFieldInSurvey('heartbeatValue', value)}
                    />
                </div>
                <div className={localStyles.priorities}>
                    <header>
                        <h3>
                            Priorities
                        </h3>
                    </header>
                    <div className={localStyles.pastPriorities}>
                        <h4>
                            Mark priorities from your past Check-in as complete
                        </h4>
                        <ul className={localStyles.pastPrioritiesList}>
                            {renderPastPriorities()}
                        </ul>
                    </div>
                    <div className={localStyles.newPriorities}>
                        <h4>
                            What do you intend to accomplish between now and your next Check-in?
                        </h4>
                        {renderCurrentPriorityInputs()}
                    </div>
                </div>
                <div className={localStyles.questions}>
                    <header>
                        <h3>
                            Questions
                        </h3>
                    </header>
                    <ul>
                        {renderQuestions()}
                    </ul>
                </div>
            </section>
            <section className={localStyles.actions}>
                <Button
                    color='primary'
                    variant='contained'
                    darkMode
                    onClick={onSubmitClicked}
                >
                    Submit Survey
                </Button>
            </section>
        </div>
    );

    // #endregion
}

CurrentSurvey.propTypes = {

};

CurrentSurvey.defaultProps = {

};

export default CurrentSurvey;
