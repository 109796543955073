import {
    Outlet,
} from 'react-router-dom';
import { useRedirectOnCondition } from '@unifire-js/hooks';
import { Snackbar } from '@psionic/ui';
import { Warning } from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import { ROLES } from '@models/association';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import { LoadingPage } from '@components/loading';

/**
 * Wrapper for any team routes that require owner access.
 */
function TeamOwnerWrapper() {
    // #region Hooks

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the user's association to the current team context value.
     */
    const userAssociation = TeamContextLevel.use.userAssociation.value();

    /**
     * Redirect the user if they are not an admin or owner of the team.
     */
    const authChecked = useRedirectOnCondition(
        `/team/${ currentTeam.id }`,
        () => {
            if (!currentTeam || !userAssociation) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='Data could not be loaded for this page!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            if (userAssociation.role !== ROLES.OWNER) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You do not have owner access for this team!'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        },
        [
            currentTeam,
            userAssociation,
        ]
    );

    // #endregion

    // #region Render Functions

    /**
     * Display a loading state if the auth check has not been performed yet.
     */
    if (!authChecked) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <Outlet/>
    );

    // #endregion
}

export default TeamOwnerWrapper;
