import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const CheckInReviewerAssociationModel = new Submodel({
    collectionName:  'checkInReviewerAssociations',
    parent:          TeamModel,
    collectionProps: [
        'groupID',
        'reviewerID',
    ],
});

export default CheckInReviewerAssociationModel;
