import { Submodel } from '@unifire-js/firebase/firestore';
import BulletinModel from '@models/bulletin';

const ElementModel = new Submodel({
    collectionName:  'elements',
    parent:          BulletinModel,
    collectionProps: [
        'type',
        'value',
        'pinned',
    ],
    propDefaults: {
        value:  {},
        pinned: false,
    },
});

export default ElementModel;
