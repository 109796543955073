import {
    map,
} from 'lodash';
import {
    Autobatcher,
    runTransaction,
} from '@unifire-js/firebase/firestore';
import { PRIORITIES } from '@utils/constants';
import ActionItemModel from '@models/action-item';
import MinuteModel from '@models/minute';
import DayModel from '@models/day';

// #region Public Functions

/**
 * Creates a default minute object for a given day that can be modified.
 *
 * @param {string} profileID The ID of the profile to set as the owner of the minute
 * @param {string} agendaID The ID of the agenda to create the minute for
 * @returns {Object} A default minute object for the given owner
 */
export const createDefaultMinuteForDay = (profileID, agendaID) => {
    return {
        message:  '',
        owner:    profileID,
        requires: [],
        reviewed: false,
        priority: PRIORITIES.LOW,
        agendaID,
    };
};

/**
 * Updates the minute's reviewed flag.
 *
 * @param {string} agendaID The ID of the agenda the minute belongs to
 * @param {number | string} dateValue The date value that the minute belongs to
 * @param {string} minuteID The ID of the minute to update
 * @param {boolean} reviewed Flag indicating whether the minute was reviewed or not
 * @returns {Promise<void>} Resolves once the minute is updated
 */
export const updateMinuteReviewed = async(agendaID, dateValue, minuteID, reviewed) => {
    await MinuteModel.writeToPath(
        `agendas/${ agendaID }/days/${ dateValue }/minutes/${ minuteID }`,
        { reviewed },
        { mergeWithExistingValues: true }
    );
};

/**
 * Updates the given minute.
 *
 * @param {string} agendaID The ID of the agenda the minute is associated with
 * @param {string | number} dateValue The date value that the minute belongs to
 * @param {Object} minute The minute data to write to the database
 * @returns {Promise<void>} Resolves once the minute is updated
 */
export const updateMinute = async(agendaID, dateValue, minute) => {
    await MinuteModel.writeToPath(
        `agendas/${ agendaID }/days/${ dateValue }/minutes/${ minute.id }`,
        minute
    );
};

/**
 * Updates the given minute's priority.
 *
 * @param {string} agendaID The ID of the agenda the minute is associated with
 * @param {string | number} dateValue The date value that the minute belongs to
 * @param {string} minuteID The ID of the minute to update
 * @param {string} priority The new priority to save for the minute
 * @returns {Promise<void>} Resolves once the minute's priority is updated
 *
 */
export const updateMinutePriority = async(agendaID, dateValue, minuteID, priority) => {
    await MinuteModel.writeToPath(
        `agendas/${ agendaID }/days/${ dateValue }/minutes/${ minuteID }`,
        { priority },
        { mergeWithExistingValues: true }
    );
};

/**
 * Deletes the minute and all of its action items from the database.
 *
 * @param {string} agendaID The ID of the agenda the minute is associated with
 * @param {number | string} dateValue The date value that the minute belongs to
 * @param {string} minuteID The ID of the minute to remove from the database
 * @returns {Promise<void>} Resolves once the minute is removed
 */
export const deleteMinute = async(agendaID, dateValue, minuteID) => {
    const autobatcher = new Autobatcher();
    await deleteAllActionItemsForMinute(agendaID, dateValue, minuteID, autobatcher);
    await MinuteModel.deleteByPath(`agendas/${ agendaID }/days/${ dateValue }/minutes/${ minuteID }`, { autobatcher });
    await autobatcher.allBatchesFinalized();
};

/**
 * Adds a given minute to the database.
 *
 * @param {string} agendaID The ID of the agenda the minute should belong to
 * @param {number | string} dateValue The date value that the minute belongs to
 * @param {Object} data The data to write to the minute doc
 * @returns {Promise<void>} Resolves once the minute is written
 */
export const addMinute = (agendaID, dateValue, data) => {
    runTransaction(async(transaction) => {
        await DayModel.writeToPath(
            `agendas/${ agendaID }/days/${ dateValue }`,
            { dateValue: parseInt(dateValue, 10) },
            { transaction }
        );
        await MinuteModel.writeToNewDoc(
            `agendas/${ agendaID }/days/${ dateValue }/minutes`,
            {
                dateToReview: parseInt(dateValue, 10),
                ...data,
            },
            { transaction }
        );
    });
};

/**
 * Reschedules the given minute to the new date value.
 *
 * @param {string} agendaID The ID of the agenda the minute is associated with
 * @param {number | string} prevDateValue The previous date value that the minute belonged to
 * @param {string} minuteID The ID of the minute to reschedule
 * @param {number | string} newDateValue The new date value to reschedule the minute to
 * @returns {Promise<void>} Resolves once the minute has been rescheduled
 */
export const rescheduleMinute = async(agendaID, prevDateValue, minuteID, newDateValue) => {
    const autobatcher = new Autobatcher();
    const minute = await MinuteModel.getByPath(
        `agendas/${ agendaID }/days/${ prevDateValue }/minutes/${ minuteID }`
    );
    await DayModel.writeToPath(
        `agendas/${ agendaID }/days/${ newDateValue }`,
        { dateValue: parseInt(newDateValue, 10) },
        { autobatcher }
    );
    const newMinuteDocRef = await MinuteModel.writeToNewDoc(
        `agendas/${ agendaID }/days/${ newDateValue }/minutes`,
        {
            ...minute,
            dateToReview: parseInt(newDateValue, 10),
        },
        { autobatcher }
    );
    await copyActionItemsToNewDoc(newMinuteDocRef, minute, autobatcher);
    await MinuteModel.deleteByPath(
        `agendas/${ agendaID }/days/${ prevDateValue }/minutes/${ minuteID }`,
        { autobatcher }
    );
    await autobatcher.allBatchesFinalized();
};

/**
 * Deletes a given day from the database.
 *
 * @param {string} agendaID The ID of the agenda to delete the day for
 * @param {string | number} dateValue The date value as a string or a number to delete from the database
 * @returns {Promise<void>} Resolves once the day is removed
 */
export const deleteDay = async(agendaID, dateValue) => {
    const autobatcher = new Autobatcher();
    await deleteAllMinutesForDay(agendaID, dateValue, autobatcher);
    await DayModel.deleteByPath(
        `agendas/${ agendaID }/days/${ dateValue }`,
        { autobatcher }
    );
    await autobatcher.allBatchesFinalized();
};

// #endregion

// #region Private Functions

/**
 * Delete all action items associated with a given minute.
 *
 * @param {string} agendaID The ID of the agenda the given minute belongs to
 * @param {number | string} dateValue The date value that the minute belongs to
 * @param {string} minuteID The ID of the minute to delete all action items for
 * @param {Autobatcher} autobatcher The autobatcher to use for the database operations
 * @returns {Promise<void>} Resolves once all of the minute's action items have been deleted
 */
const deleteAllActionItemsForMinute = async(agendaID, dateValue, minuteID, autobatcher) => {
    const actionItemsForMinute = await ActionItemModel.getByQueryInInstance(
        `agendas/${ agendaID }/days/${ dateValue }/minutes/${ minuteID }/actionItems`,
        []
    );
    await Promise.all(map(actionItemsForMinute, async(actionItem) => {
        await ActionItemModel.deleteByPath(
            `agendas/${ agendaID }/days/${ dateValue }/minutes/${ minuteID }/actionItems/${ actionItem.id }`,
            { autobatcher }
        );
    }));
};

/**
 * Deletes all minutes for the given day.
 *
 * @param {string} agendaID The ID of the agenda that owns the minutes to delete for the day
 * @param {string | number} dateValue The date value as a string or a number to delete all of the minutes for
 * @param {Autobatcher} autobatcher The autobatcher to use for the database operations
 * @returns {Promise<void>} Resolves once all of the day's minutes have been deleted
 */
const deleteAllMinutesForDay = async(agendaID, dateValue, autobatcher) => {
    const minutesForDay = await MinuteModel.getByQueryInInstance(
        `agendas/${ agendaID }/days/${ dateValue }/minutes`,
        []
    );
    await Promise.all(map(minutesForDay, async(minute) => {
        await deleteAllActionItemsForMinute(agendaID, dateValue, minute.id, autobatcher);
        await MinuteModel.deleteByPath(
            `agendas/${ agendaID }/days/${ dateValue }/minutes/${ minute.id }`,
            { autobatcher }
        );
    }));
};

/**
 * Copies action items from one minute to another minute.
 */
const copyActionItemsToNewDoc = async(newMinuteDocRef, minute, autobatcher) => {
    const actionItemsSubcollection = minute.subcollections.actionItems;
    const allActionItemsForMinute = await actionItemsSubcollection.getByQuery([]);
    await Promise.all(map(allActionItemsForMinute, async(actionItem) => {
        await newMinuteDocRef.subcollections.actionItems.writeToID(
            actionItem.id,
            actionItem,
            { autobatcher }
        );
        await actionItemsSubcollection.deleteByID(actionItem.id);
    }));
};

// #endregion
