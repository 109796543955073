import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import { Add } from '@mui/icons-material';
import localStyles from './add-card-button.module.scss';

/**
 * Button for adding a new card to a room's deck.
 */
function AddCardButton({
    tooltip,
    onClick,
    sizeRatio,
}) {
    // #region Constants

    /**
     * Define the default width of this element.
     */
    const DEFAULT_WIDTH = 300;

    /**
     * Define the default height of this element.
     */
    const DEFAULT_HEIGHT = 426;

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <StickyTooltip content={tooltip}>
            <Button
                variant="contained"
                className={localStyles.cardButton}
                onClick={onClick}
                sx={{
                    width:  `${DEFAULT_WIDTH * sizeRatio}px !important`,
                    height: `${DEFAULT_HEIGHT * sizeRatio}px !important`,
                }}
            >
                <Add className={localStyles.addIcon} />
            </Button>
        </StickyTooltip>
    );

    // #endregion
}

AddCardButton.propTypes = {
    /**
     * The text to use in the tooltip for the button.
     */
    tooltip:   PropTypes.string.isRequired,
    /**
     * The function to call when the button is clicked.
     */
    onClick:   PropTypes.func.isRequired,
    /**
     * The card's size ratio.
     */
    sizeRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AddCardButton.defaultProps = {
    sizeRatio: 1.0,
};

export default AddCardButton;
