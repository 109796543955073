import Cookies from 'js-cookie';

// #region Public Functions

export function setSelectedWorkspace(workspaceID) {
    Cookies.set('selectedWorkspace', workspaceID);
}

export function getSelectedWorkspace() {
    return Cookies.get('selectedWorkspace');
}

// #endregion

// #region Private Functions

// #endregion
