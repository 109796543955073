import { useState, useEffect } from 'react';

/**
 * A conditional `useEffect` hook that only runs the first time it passes a given `check` function.
 */
export default function(fn, check, dependencies) {
    // Track whether the `useEffect` has passed a check successfully already
    const [passedAtLeastOnce, setPassedAtLeastOnce] = useState(false);

    // Track whether the `useEffect` has completed successfully already
    const [hasRun, setHasRun] = useState(false);

    // Create the augmented `useEffect` that only runs once the given check passes, and only if this is the
    // first time the check passed
    useEffect(() => {
        const internalFn = async () => {
            // If this effect's check has already passed once before, we can return early
            if (passedAtLeastOnce) {
                return;
            }
            // If the effect's check passes, then set the `passedAtLeastOnce` flag, run the effect, and then set the
            // `hasRun` flag
            if (check()) {
                setPassedAtLeastOnce(true);
                await fn();
                setHasRun(true);
            }
        }

        internalFn();
    }, dependencies);

    // Return the hook's API
    return [passedAtLeastOnce, hasRun];
}
