import { Model } from '@unifire-js/firebase/firestore';

// #region Reference Enums

export const ROLES = {
    MEMBER: 'MEMBER',
    ADMIN:  'ADMIN',
    OWNER:  'OWNER',
};

// #endregion

// #region Model

const AssociationModel = new Model({
    collectionName:  'associations',
    collectionProps: [
        'teamID',
        'userID',
        'role',
    ],
    propDefaults: {
        role: ROLES.MEMBER,
    },
});

export default AssociationModel;

// #endregion
