import { Submodel } from '@unifire-js/firebase/firestore';
import TeamModel from '@models/team';

const CheckInQuestionModel = new Submodel({
    collectionName:  'checkInQuestions',
    parent:          TeamModel,
    collectionProps: [
        'content',
        'isAlwaysAsked',
        'isOptional',
        'groupToAsk',
    ],
    propDefaults: {},
});

export default CheckInQuestionModel;
