import { createContextLevel } from 'luxe-js/context';

export default createContextLevel({
    currentTeam:         null,
    teamSubscription:    null,
    teamMembers:         null,
    teamInvites:         null,
    teamAssociations:    null,
    userAssociation:     null,
    hasAdminPermissions: false,
    teamGroups:          null,
    teamAgendas:         null,
    featureConfigs:      null,
});
