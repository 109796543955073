import PropTypes from 'prop-types';
import {
    Menu,
} from '@mui/material';
import { ELEMENT_TYPES } from '@utils/constants';
import { BlockMenuButton } from '@components/custom-ui-global';

/**
 * A menu for selecting an element type for a block.
 */
function BlockSelectMenu({
    anchorEl,
    onClose,
    onSelect,
}) {

    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Block menu options.
     */
    const blockMenuOptions = Object.values(ELEMENT_TYPES).map((elementType) => {
        return (
            <BlockMenuButton
                key={elementType.name}
                description={elementType.description}
                imageSrc={elementType.imageSrc}
                name={elementType.name}
                onClick={() => {
                    onClose();
                    onSelect(elementType.id);
                }}
            />
        );
    });

    /**
     * Main render.
     */
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            PaperProps={{
                className: 'menu',
            }}
            onClose={onClose}
        >
            {blockMenuOptions}
        </Menu>
    );

    // #endregion
}

BlockSelectMenu.propTypes = {
    /**
     * The anchor element for the menu. If this isn't specified, the menu will not be shown.
     */
    anchorEl: PropTypes.instanceOf(Element),
    /**
     * The function to call to close the menu.
     */
    onClose:  PropTypes.func.isRequired,
    /**
     * The function to call when an option is selected from the menu.
     */
    onSelect: PropTypes.func.isRequired,
};

BlockSelectMenu.defaultProps = {
    anchorEl: null,
};

export default BlockSelectMenu;
