import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import {
    Button,
} from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import { AgendaService } from '@services/agendas';
import { ROLES } from '@models/association';
import { AgendaControl } from '@components/controls';
import localStyles from './index.module.scss';

function AgendaSelect() {

    // #region Context

    /**
     * Use the team agendas from context.
     */
    const teamAgendas = TeamContextLevel.use.teamAgendas.value();

    /**
     * Use the current team from context.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the user's association from context.
     */
    const userAssociation = TeamContextLevel.use.userAssociation.value();

    // #endregion

    // #region Memoized Values

    const sortedTeamAgendas = useMemo(() => {
        return sortBy(
            teamAgendas,
            [
                'name',
                'id',
            ]
        );
    }, [ teamAgendas ]);

    const isAdminOrOwner = useMemo(() => {
        return [
            ROLES.ADMIN,
            ROLES.OWNER,
        ].includes(userAssociation?.role);
    }, [ userAssociation ]);

    // #endregion

    // #region Render Functions

    const renderEmptyState = () => {
        return (
            <p className={localStyles.emptyState}>
                Your team has no agendas yet. Click the button below to get started!
            </p>
        );
    };

    const renderTeamAgendas = () => {
        const elements = [];

        for (const agenda of sortedTeamAgendas) {
            elements.push(
                <li key={`${ agenda.name }`}>
                    <AgendaControl
                        agenda={agenda}
                        showControls={isAdminOrOwner}
                        onDelete={() => AgendaService.deleteAgenda(agenda.id)}
                        onSave={(newData) => AgendaService.updateAgenda(agenda.id, newData)}
                    />
                </li>
            );
        }

        return (
            <ul className={localStyles.agendaList}>
                {elements}
            </ul>
        );
    };

    return (
        <div className='workspacePage'>
            <header>
                <h1>
                    Select Agenda
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                <h2 className={localStyles.infoText}>
                    Select an agenda to view.
                </h2>
                { teamAgendas?.length < 1 ? renderEmptyState() : renderTeamAgendas() }
                {
                    isAdminOrOwner
                        ? (
                            <Button
                                variant='outlined'
                                onClick={() => AgendaService.createNewAgenda(currentTeam.id, teamAgendas?.length || 0)}
                            >
                                Create New Agenda
                            </Button>
                        )
                        : null
                }
            </section>
        </div>
    );

    // #endregion
}

export default AgendaSelect;
