import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Edit,
    Delete,
    Update,
} from '@mui/icons-material';
import { Button } from '@psionic/ui';
import Linkify from 'react-linkify';
import { DatePickerPopover } from '@components/popovers';
import localStyles from './minute-info.module.scss';

/**
 * Detail that shows info about a given minute.
 */
function MinuteInfo({
    minute,
    onEdit,
    onDelete,
    onReschedule,
    rescheduleLimitedToPresent,
}) {
    // #region Refs

    /**
     * Track a ref to the reschedule button element.
     */
    const rescheduleButtonRef = useRef();

    // #endregion

    // #region State

    /**
     * Track a flag indicating if the reschedule minute popover should be open.
     */
    const [
        reschedulePopoverOpen,
        setReschedulePopoverOpen,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion Effects

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            {/* POPOVERS */}
            <DatePickerPopover
                anchorEl={rescheduleButtonRef.current}
                minDate={rescheduleLimitedToPresent ? new Date() : null}
                open={reschedulePopoverOpen}
                onDateSelected={onReschedule}
                onClose={() => {
                    return setReschedulePopoverOpen(false);
                }}
            />
            {/* MAIN COMPONENT */}
            <div className={localStyles.minuteInfo}>
                <section className={localStyles.messageInfo}>
                    <p>
                        <Linkify>
                            {minute.message}
                        </Linkify>
                    </p>
                </section>
                {
                    onEdit || onReschedule || onDelete
                        ? (
                            <section className={localStyles.actionButtons}>
                                {
                                    onEdit
                                        ? (
                                            <Button
                                                variant='outlined'
                                                darkMode
                                                onClick={onEdit}
                                            >
                                                <span className={localStyles.buttonContent}>
                                                    <Edit/>
                                                    Edit
                                                </span>
                                            </Button>
                                        )
                                        : null
                                }
                                {
                                    onReschedule
                                        ? (
                                            <Button
                                                ref={rescheduleButtonRef}
                                                color='warning'
                                                variant='outlined'
                                                darkMode
                                                onClick={() => {
                                                    return setReschedulePopoverOpen(true);
                                                }}
                                            >
                                                <span className={localStyles.buttonContent}>
                                                    <Update/>
                                                    Reschedule
                                                </span>
                                            </Button>
                                        )
                                        : null
                                }
                                {
                                    onDelete
                                        ? (
                                            <Button
                                                color='reject'
                                                variant='outlined'
                                                darkMode
                                                onClick={onDelete}
                                            >
                                                <span className={localStyles.buttonContent}>
                                                    <Delete/>
                                                    Delete
                                                </span>
                                            </Button>
                                        )
                                        : null
                                }
                            </section>
                        )
                        : null
                }
            </div>
        </>
    );

    // #endregion
}

MinuteInfo.propTypes = {
    /**
     * The minute object to display the info of.
     */
    minute:                     PropTypes.object.isRequired,
    /**
     * Callback for when the "Delete" button is clicked.
     */
    onDelete:                   PropTypes.func,
    /**
     * Callback for when the "Edit" button is clicked.
     */
    onEdit:                     PropTypes.func,
    /**
     * Callback for when the minute should be rescheduled. Should accept the new date as the only param.
     */
    onReschedule:               PropTypes.func,
    /**
     * If specified, the user can only reschedule the minute to a date after the present date.
     */
    rescheduleLimitedToPresent: PropTypes.bool,
};

MinuteInfo.defaultProps = {
    rescheduleLimitedToPresent: false,
};

export default MinuteInfo;
