import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSelectedWorkspace } from '@utils/cookies';
import { LoadingPage } from '@components/loading';

function IndexPage() {

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Effects

    /**
     * When the component first mounts, try to find the selected workspace in localstorage.
     * If found, navigate the user to that workspace. If not found, navigate the user to the home page.
     */
    useEffect(() => {
        const selectedWorkspace = getSelectedWorkspace();

        if (selectedWorkspace) {
            navigate(`team/${ selectedWorkspace }`);
        } else {
            navigate('home');
        }
    }, []);

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <LoadingPage/>
    );

    // #endregion
}

export default IndexPage;
