import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    IconButton,
} from '@psionic/ui';
import {
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {
    ArrowBack,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import RevieweeContextLevel from '@contexts/reviewee';
import CheckInSurveyModel from '@models/check-in-survey';
import ActivityLogModel from '@models/activity-log';
import { LoadingPage } from '@components/loading';
import ReviewSurvey from './components/review-survey';
import AllSurveys from './components/all-surveys';
import localStyles from './reviewee-page.module.scss';

// #region Helper Functions

function getSurveyActivityLogListenerName(surveyID) {
    return `REVIEWEE_SURVEY_ACTIVITY_LOG_${ surveyID }_LISTENER`;
}

// #endregion

function RevieweePage() {

    // #region Constants

    const surveysPerChunk = 5;

    // #endregion

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const team = TeamContextLevel.use.currentTeam.value();

    const reviewee = RevieweeContextLevel.use.reviewee.value();

    const currentSurvey = RevieweeContextLevel.use.currentSurvey.value();

    // #endregion

    // #region State

    const [
        currentTab,
        setCurrentTab,
    ] = useState(TABS.CURRENT_SURVEY);

    const [
        numSurveysToLoad,
        setNumSurveysToLoad,
    ] = useState(surveysPerChunk);

    const [
        surveys,
        setSurveys,
    ] = useState([]);

    const [
        latestActivityLogsBySurveyID,
        setLatestActivityLogsBySurveyID,
    ] = useState({});

    // #endregion

    // #region Effects

    /**
     * Whenever the surveys update, create listeners for each survey's latest activity log.
     */
    useEffect(() => {
        for (const survey of surveys) {
            ActivityLogModel.addListenerByQueryInInstance(
                getSurveyActivityLogListenerName(survey.id),
                `teams/${ team.id }/checkInSurveys/${ survey.id }/activityLogs`,
                [
                    orderBy('timestamp', 'desc'),
                    limit(1),
                ],
                (newActivityLogs) => {
                    const newActivityLog = newActivityLogs[ 0 ];

                    setLatestActivityLogsBySurveyID((prev) => ({
                        ...prev,
                        [ survey.id ]: newActivityLog,
                    }));
                }
            );
        }

        // Cleanup all listeners
        return () => {
            for (const survey of surveys) {
                ActivityLogModel.removeListener(
                    getSurveyActivityLogListenerName(survey.id)
                );
            }
        };
    }, [ surveys ]);

    /**
     * Keep track of the user's surveys.
     */
    useEffect(() => {
        // Subscribe to the user's surveys
        CheckInSurveyModel.addListenerByQueryInInstance(
            'REVIEWEE_SURVEYS_TRACKER',
            `teams/${ team.id }/checkInSurveys`,
            [
                where('userID', '==', reviewee.id),
                orderBy('endDate', 'desc'),
                limit(numSurveysToLoad),
            ],
            setSurveys
        );

        // In the cleanup function, remove the created listener
        return () => {
            CheckInSurveyModel.removeListener('REVIEWEE_SURVEYS_TRACKER');
        };
    }, [ numSurveysToLoad ]);

    // #endregion

    // #region Functions

    const incrementNumSurveysToLoad = () => {
        setNumSurveysToLoad((prev) => prev + surveysPerChunk);
    };

    // #endregion

    // #region Render Functions

    const renderSelectedTab = () => {
        switch (currentTab) {
            case TABS.CURRENT_SURVEY:
                if (currentSurvey === undefined) {
                    return <LoadingPage displayRefreshTip={false}/>;
                }

                if (!currentSurvey) {
                    return (
                        <p className={localStyles.emptyState}>
                            No current survey.
                        </p>
                    );
                }

                if (!currentSurvey.dateSubmitted) {
                    return (
                        <p className={localStyles.emptyState}>
                            This user's current survey has not been submitted yet.
                        </p>
                    );
                }

                return (
                    <ReviewSurvey
                        survey={currentSurvey}
                        showHeader
                    />
                );
            case TABS.ALL_SURVEYS:
                return (
                    <AllSurveys
                        incrementNumSurveysToLoad={incrementNumSurveysToLoad}
                        latestActivityLogsBySurveyID={latestActivityLogsBySurveyID}
                        moreSurveysToLoad={surveys.length >= numSurveysToLoad}
                        surveys={surveys}
                        onCurrentSurveyClicked={() => setCurrentTab(TABS.CURRENT_SURVEY)}
                    />
                );
            default:
                return null;
        }
    };

    /**
     * Main render.
     */
    return (
        <div
            className={[
                'workspacePage',
                localStyles.workspacePage,
            ].join(' ')}
        >
            <header className={localStyles.header}>
                <IconButton
                    color='white'
                    SvgIcon={ArrowBack}
                    onClick={() => navigate('../')}
                />
                <h1>
                    Reviewee:
                    {' '}
                    {reviewee?.displayName || reviewee?.email || 'Unknown'}
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                <section
                    className={[
                        'tabs',
                        localStyles.tabs,
                    ].join(' ')}
                >
                    <button
                        className='tab'
                        data-selected={currentTab === TABS.CURRENT_SURVEY}
                        type='button'
                        onClick={() => setCurrentTab(TABS.CURRENT_SURVEY)}
                    >
                        <p>
                            Current Survey
                        </p>
                    </button>
                    <button
                        className='tab'
                        data-selected={currentTab === TABS.ALL_SURVEYS}
                        type='button'
                        onClick={() => setCurrentTab(TABS.ALL_SURVEYS)}
                    >
                        <p>
                            All Surveys
                        </p>
                    </button>
                </section>
                <section className={localStyles.tabContent}>
                    {renderSelectedTab()}
                </section>
            </section>
        </div>
    );

    // #endregion
}

// #region Constants

const TABS = {
    CURRENT_SURVEY: 'currentSurvey',
    ALL_SURVEYS:    'allSurveys',
};

// #endregion

export default RevieweePage;
