import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
    Check,
} from '@mui/icons-material';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    Button,
    StickyTooltip,
} from '@psionic/ui';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import localStyles from './all-surveys.module.scss';

function AllSurveys({
    surveys,
    incrementNumSurveysToLoad,
    moreSurveysToLoad,
    onCurrentSurveyClicked,
    latestActivityLogsBySurveyID,
}) {

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const checkInServiceInfo = TeamCheckInContextLevel.use.checkInServiceInfo.value();

    // #endregion

    // #region Functions

    const formatSurveyDate = (date) => {
        let luxonObj;

        if (typeof date === 'string') {
            luxonObj = DateTime.fromISO(date);
        } else {
            luxonObj = DateTime.fromJSDate(date);
        }

        return luxonObj.toFormat('MMM d, yyyy');
    };

    const navigateToReviewSurvey = (survey) => {
        navigate(`survey/${ survey.id }`);
    };

    // #endregion

    // #region Render Functions

    const renderActivityStatus = (survey) => {
        const activityLogForSurvey = latestActivityLogsBySurveyID[ survey.id ];

        if (!activityLogForSurvey) {
            return null;
        }

        if (
            activityLogForSurvey.userID !== profile.id
            && !(activityLogForSurvey.acknowledgedBy || []).includes(profile.id)
        ) {
            return (
                <StickyTooltip content='New Activity'>
                    <div className={localStyles.newActivity}/>
                </StickyTooltip>
            );
        }

        return (
            <StickyTooltip content='Reviewed'>
                <div className={localStyles.reviewed}>
                    <Check/>
                </div>
            </StickyTooltip>
        );
    };

    const renderAction = (survey) => {
        if (checkInServiceInfo.latestBatchID === survey.surveyBatchID) {
            if (survey.dateSubmitted) {
                return (
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={onCurrentSurveyClicked}
                    >
                        Review Survey
                    </Button>
                );
            } else {
                return (
                    <p>
                        Not Submitted Yet
                    </p>
                );
            }
        }

        if (survey.dateSubmitted) {
            return (
                <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => navigateToReviewSurvey(survey)}
                >
                    Review Survey
                </Button>
            );
        } else {
            return (
                <p>
                    Not Submitted
                </p>
            );
        }
    };

    const renderPastSurveys = () => {
        const renders = [];

        for (const survey of surveys) {
            renders.push(
                <tr key={survey.id}>
                    <td>
                        {formatSurveyDate(survey.startDate)}
                        {' '}
                        –
                        {' '}
                        {formatSurveyDate(survey.endDate)}
                    </td>
                    <td>
                        {survey.dateSubmitted ? formatSurveyDate(survey.dateSubmitted) : '—'}
                    </td>
                    <td>
                        { renderAction(survey) }
                    </td>
                    <td>
                        { renderActivityStatus(survey) }
                    </td>
                </tr>
            );
        }

        return renders;
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pastSurveys}>
            <table className={localStyles.table}>
                <thead>
                    <tr>
                        <th>
                            Check-in Dates
                        </th>
                        <th>
                            Submitted
                        </th>
                        <th>
                            Action
                        </th>
                        <th>
                            Activity Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        surveys?.length > 0
                            ? renderPastSurveys()
                            : null
                    }
                </tbody>
            </table>
            {
                !surveys || surveys?.length <= 0
                    ? (
                        <p className={localStyles.emptyState}>
                            This reviewee has no past surveys yet. Any previous check-in surveys they have submitted
                            for this team will appear here.
                        </p>
                    )
                    : moreSurveysToLoad
                        ? (
                            <Button
                                color='primary'
                                variant='text'
                                onClick={incrementNumSurveysToLoad}
                            >
                                Load More Surveys
                            </Button>
                        )
                        : (
                            <p className={localStyles.reachedEndText}>
                                No more surveys to load.
                            </p>
                        )
            }
        </div>
    );

    // #endregion
}

AllSurveys.propTypes = {
    /**
     * The surveys to render.
     */
    surveys:                      PropTypes.arrayOf(PropTypes.object),
    /**
     * The function to call to increment the number of surveys to load.
     */
    incrementNumSurveysToLoad:    PropTypes.func.isRequired,
    /**
     * Flag indicating whether or not there are any additional surveys to load.
     */
    moreSurveysToLoad:            PropTypes.bool,
    /**
     * Function to call when the current survey is clicked.
     */
    onCurrentSurveyClicked:       PropTypes.func.isRequired,
    /**
     * The latest activity logs by survey ID.
     */
    latestActivityLogsBySurveyID: PropTypes.object.isRequired,
};

AllSurveys.defaultProps = {
    surveys:           [],
    moreSurveysToLoad: false,
};

export default AllSurveys;
