// #region Magic Numbers

const MILLISECONDS_IN_A_SECOND = 1000.0;

// #endregion

// #region Main Function

/**
 * Transform a luxon date value into a unix timestamp date value for the beginning of that day.
 */
export const getDateValue = (date) => {
    const startTimeOfDate = date.startOf('day');

    return startTimeOfDate.toMillis() / MILLISECONDS_IN_A_SECOND;
};

// #endregion

// #region Helper Functions

// #endregion
