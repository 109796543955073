import {
    useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@unifire-js/firebase/auth';
import { useRedirectOnCondition } from '@unifire-js/hooks';
import filter from 'lodash/filter';
import {
    CircularSpinner,
    IconButton,
    Snackbar,
} from '@psionic/ui';
import {
    ArrowBack,
    Check,
    Warning,
} from '@mui/icons-material';
import { SnackbarService } from '@services/snackbar';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import {
    SURVEY_ACTIVITY_STATUS,
    CORE,
} from '@utils/constants';
import {
    UserAvatar,
} from '@components/avatars';
import {
    LoadingPage,
} from '@components/loading';
import localStyles from './review-check-ins.module.scss';

function ReviewCheckIns() {

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const team = TeamContextLevel.use.currentTeam.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    const reviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    const currentSurveysByTeamMemberID = TeamCheckInContextLevel.use.currentSurveysByTeamMemberID.value();

    const latestActivityLogBySurveyID = TeamCheckInContextLevel.use.latestActivityLogBySurveyID.value();

    // #endregion

    // #region State

    // #endregion

    // #region Memoized Values

    const reviewerAssociationsForUser = useMemo(() => {
        if (!reviewerAssociations) {
            return undefined;
        }

        return filter(
            reviewerAssociations,
            (reviewerAssociation) => reviewerAssociation.reviewerID === profile.id
        );
    }, [
        profile,
        reviewerAssociations,
    ]);

    const assignedTeamMembers = useMemo(() => {
        if (!teamGroups || !teamMembers || !reviewerAssociationsForUser || !profile) {
            return undefined;
        }

        const assignedTeamMembersSet = new Set();

        for (const reviewerAssociation of reviewerAssociationsForUser) {
            const groupToReview = teamGroups.find((group) => group.id === reviewerAssociation.groupID);

            for (const member of groupToReview?.members || []) {
                // Don't add the user themself to the list.
                if (member.value === profile.id) {
                    continue;
                }

                // Don't add other reviewers in that group to the list.
                if (
                    reviewerAssociations.find((otherReviewAssociation) =>
                        otherReviewAssociation.reviewerID === member.value
                        && otherReviewAssociation.groupID === groupToReview.id)
                ) {
                    continue;
                }

                const memberProfile = teamMembers[ member.value ];

                if (memberProfile) {
                    assignedTeamMembersSet.add(memberProfile);
                }
            }
        }

        return Array.from(assignedTeamMembersSet);
    }, [
        teamGroups,
        teamMembers,
        reviewerAssociationsForUser,
        profile,
    ]);

    const surveyActivityStatusByTeamMemberID = useMemo(() => {
        const results = {};

        for (const teamMember of assignedTeamMembers || []) {
            const membersCurrentSurvey = currentSurveysByTeamMemberID[ teamMember.id ];

            if (!membersCurrentSurvey) {
                results[ teamMember.id ] = SURVEY_ACTIVITY_STATUS.NO_SURVEY;
                continue;
            }

            if (!membersCurrentSurvey?.dateSubmitted) {
                results[ teamMember.id ] = SURVEY_ACTIVITY_STATUS.NOT_SUBMITTED;
                continue;
            }

            const latestActivityLog = latestActivityLogBySurveyID[ membersCurrentSurvey.id ];

            if (latestActivityLog === undefined) {
                continue;
            }

            if (!latestActivityLog) {
                results[ teamMember.id ] = SURVEY_ACTIVITY_STATUS.REVIEWED;
                continue;
            }

            if (
                latestActivityLog.userID !== profile.id
                && !(latestActivityLog.acknowledgedBy || []).includes(profile.id)
            ) {
                results[ teamMember.id ] = SURVEY_ACTIVITY_STATUS.NEW_ACTIVITY;
                continue;
            }

            results[ teamMember.id ] = SURVEY_ACTIVITY_STATUS.REVIEWED;
        }

        return results;
    }, [
        assignedTeamMembers,
        latestActivityLogBySurveyID,
    ]);

    // #endregion

    // #region Effects

    /**
     * Redirect the user back to the check-in home page if they are not a reviewer for any users.
     */
    const reviewerStatusChecked = useRedirectOnCondition(
        `/team/${ team.id }/premium/check-in`,
        () => {
            if (reviewerAssociationsForUser.length <= 0) {
                SnackbarService.addSnackbar(
                    ({ removeSnackbar }) => { return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You are not a reviewer for any groups.'
                        />
                    ); },
                    CORE.SNACKBAR_DURATION
                );

                return true;
            }

            return false;
        },
        [ reviewerAssociationsForUser ],
        () => { return reviewerAssociationsForUser === undefined; }
    );

    // #endregion

    // #region Render Functions

    const renderActivityStatus = (activityStatus) => {
        switch (activityStatus) {
            case SURVEY_ACTIVITY_STATUS.NOT_REVIEWED:
                return (
                    <p className={localStyles.notReviewed}>
                        Not Reviewed
                    </p>
                );
            case SURVEY_ACTIVITY_STATUS.REVIEWED:
                return (
                    <p className={localStyles.reviewed}>
                        <Check/>
                        Reviewed
                    </p>
                );
            case SURVEY_ACTIVITY_STATUS.NEW_ACTIVITY:
                return (
                    <p className={localStyles.newActivity}>
                        New Activity
                    </p>
                );
            case SURVEY_ACTIVITY_STATUS.NO_SURVEY:
                return null;
            case SURVEY_ACTIVITY_STATUS.NOT_SUBMITTED:
                return (
                    <p className={localStyles.surveyNotSubmitted}>
                        Survey Not Submitted
                    </p>
                );
            default:
                return <CircularSpinner size={19}/>;
        }
    };

    const renderAssignedReviews = () => {
        if (assignedTeamMembers === undefined) {
            return <CircularSpinner/>;
        }

        if (assignedTeamMembers.length === 0) {
            return (
                <p className={localStyles.emptyState}>
                    You have no assigned reviewees.
                </p>
            );
        }

        const teamMemberRenders = [];

        for (const teamMember of assignedTeamMembers) {
            teamMemberRenders.push(
                <div className={localStyles.assignedReview}>
                    <button
                        type='button'
                        onClick={() => navigate(teamMember.id)}
                    >
                        <UserAvatar
                            hasPopover={false}
                            hasTooltip={false}
                            imageURL={teamMember.avatarURL}
                            size='medium'
                            userEmail={teamMember.email}
                            userName={teamMember.displayName}
                        />
                        <p>
                            {teamMember.displayName || teamMember.email}
                        </p>
                        {renderActivityStatus(surveyActivityStatusByTeamMemberID[ teamMember.id ])}
                    </button>
                </div>
            );
        }

        return (
            <div className={localStyles.assignedReviews}>
                {teamMemberRenders}
            </div>
        );
    };

    /**
     * If the reviewer status has not been checked yet, render a loading spinner.
     */
    if (!reviewerStatusChecked) {
        return <LoadingPage displayRefreshTip={false}/>;
    }

    /**
     * Main render.
     */
    return (
        <div className='workspacePage'>
            <header className={localStyles.header}>
                <IconButton
                    color='white'
                    SvgIcon={ArrowBack}
                    onClick={() => navigate('../')}
                />
                <h1>
                    Review Check-Ins
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                <h2 className={localStyles.assignedReviewsHeader}>
                    Your Assigned Reviewees
                </h2>
                {renderAssignedReviews()}
            </section>
        </div>
    );

    // #endregion
}

export default ReviewCheckIns;
