import {
    useEffect, useState, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
    includes,
    sortBy,
    map,
} from 'lodash';
import {
    Button,
} from '@psionic/ui';
import {
    limit,
    orderBy,
} from '@firebase/firestore';
import TeamContextLevel from '@contexts/team';
import {
    getDateValue,
} from '@services/minutes';
import {
    getValueForDateInTeamTimezone,
} from '@utils/time-utils';
import { LazyLoadTrigger } from '@components/loading';
import { DatePickerPopover } from '@components/popovers';
import DaysMinutes from '../days-minutes/days-minutes';
import localStyles from './teams-minutes.module.scss';
import DayModel from '@models/day';

/**
 * Component that displays the team's minutes.
 */
function TeamsMinutes({
    agenda,
}) {

    // #region Constants

    /**
     * The number of days in each chunk.
     */
    const daysPerChunk = 10;

    // #endregion

    // #region Refs

    /**
     * Track the add date button ref.
     */
    const addDateButtonRef = useRef();

    // #endregion

    // #region Context

    const team = TeamContextLevel.use.currentTeam.value();

    // #endregion

    // #region State

    /**
     * Track the number of days to load.
     */
    const [
        numDaysToLoad,
        setNumDaysToLoad,
    ] = useState(daysPerChunk);

    /**
     * Track any loaded days in state.
     */
    const [
        days,
        setDays,
    ] = useState([]);

    /**
     * Track whether the new date picker popover should be open.
     */
    const [
        newDatePopoverOpen,
        setNewDatePopoverOpen,
    ] = useState(false);

    // #endregion

    // #region Effects

    /**
     * Keep the days up-to-date.
     */
    useEffect(() => {
        // Subscribe to the team's days
        DayModel.addListenerByQueryInInstance(
            'team-day-tracker',
            `agendas/${ agenda.id }/days`,
            [
                orderBy('dateValue', 'desc'),
                limit(numDaysToLoad),
            ],
            (docs) => {
                setDays(
                    sortBy(
                        map(docs, (doc) => { return doc.dateValue; }),
                        (dateValue) => { return -parseInt(dateValue, 10); }
                    )
                );
            }
        );

        // In the cleanup function, remove the created listener
        return () => {
            DayModel.removeListener('team-day-tracker');
        };
    }, [ numDaysToLoad ]);

    // #endregion

    // #region Functions

    /**
     * Callback for when the lazy load trigger is triggered.
     */
    const onLazyLoadTriggered = () => {
        if (days.length === numDaysToLoad) {
            setNumDaysToLoad((prevState) => { return prevState + daysPerChunk; });
        }
    };

    /**
     * Adds a new date section to the page.
     */
    const addDateSection = (newDate) => {
        const newUnixTimestamp = getDateValue(getValueForDateInTeamTimezone(newDate, team?.timezone));

        if (!includes(days, newUnixTimestamp)) {
            DayModel.writeToPath(
                `agendas/${ agenda.id }/days/${ newUnixTimestamp }`,
                { dateValue: parseInt(newUnixTimestamp, 10) }
            );
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Memoized day sections.
     */
    const daySections = useMemo(() => {
        const listItems = [];

        for (const day of days) {
            listItems.push(
                <li key={day}>
                    <DaysMinutes
                        agenda={agenda}
                        day={day}
                    />
                </li>
            );
        }

        return listItems;
    }, [ days ]);

    /**
     * Main render.
     */
    return (
        <>
            {/* POPOVERS */}
            <DatePickerPopover
                anchorEl={addDateButtonRef.current}
                open={newDatePopoverOpen}
                anchorOrigin={{
                    vertical:   'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical:   'top',
                    horizontal: 'center',
                }}
                onClose={() => { return setNewDatePopoverOpen(false); }}
                onDateSelected={addDateSection}
            />
            {/* MAIN COMPONENT */}
            <section className={localStyles.teamsMinutes}>
                <Button
                    ref={addDateButtonRef}
                    height='75px'
                    variant='outlined'
                    width='100%'
                    darkMode
                    onClick={() => { return setNewDatePopoverOpen(true); }}
                >
                    Add New Date
                </Button>
                <ul>
                    {daySections}
                </ul>
                <LazyLoadTrigger
                    action={onLazyLoadTriggered}
                    debounceTime={1000}
                    ignore={days.length < numDaysToLoad}
                />
            </section>
        </>
    );

    // #endregion

}

TeamsMinutes.propTypes = {
    /**
     * The agenda to display minutes for.
     */
    agenda: PropTypes.object.isRequired,
};

export default TeamsMinutes;
