import { Info } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import {
    IconButton,
} from '@psionic/ui';
import ReleaseNotesService from '@services/release-notes/release-notes-service';
import LogoSolid from '@assets/images/logo-solid.svg';
import localStyles from './footer.module.scss';

/**
 * Footer to display at the bottom the page.
 */
function Footer() {
    const navigate = useNavigate();

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.footer}>
            <div className={localStyles.logo}>
                <LogoSolid fill='#000'/>
                <p className='logoText'>
                    Eager Lead
                </p>
            </div>
            <div className={localStyles.versionInfo}>
                <p>
                    v
                    {process.env.VERSION.replace(/['"]+/g, '')}
                </p>
                <IconButton
                    color='footerText'
                    SvgIcon={Info}
                    onClick={() => navigate(`/home/release-notes?version=${ ReleaseNotesService.getCurrentVersion() }`)}
                />
            </div>
        </div>
    );

    // #endregion
}

Footer.propTypes = {

};

Footer.defaultProps = {

};

export default Footer;
