import { useState } from 'react';

/**
 * Hook which tracks a value in state that starts off in a "not fetched" state, and offers a "fetched"
 * flag in its return to denote a difference between something not existing and something that just
 * hasn't had a fetch attempt made yet.
 */
export default function() {
    // Track the value in state
    const [state, setState] = useState(null);
    // Track a "fetched" flag in state
    const [fetched, setFetched] = useState(false);

    // Create an augmented `setState` function that also sets the `fetched` flag to `true` when called
    const augmentedSetState = (stateOrFn) => {
        setState(stateOrFn);
        setFetched(true);
    };

    // Return the hook API
    return [
        state,
        fetched,
        augmentedSetState,
    ];
}
