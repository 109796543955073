import map from 'lodash/map';
import { deleteUser } from 'firebase/auth';
import { where } from 'firebase/firestore';
import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';
import ProfileModel from '@models/profile';
import AssociationModel, { ROLES } from '@models/association';
import FirebaseFunctions from '@utils/constants/firebase-functions';

export default async function(user, profile) {
    try {
        const result = await FirebaseFunctions.deleteCustomerSubscriptions({
            stripeCustomerID: profile.stripeCustomerID,
        });

        if (!result?.success) {
            throw new Error('There was an error while attempting to delete all of the customer\'s subscriptions. Aborting the delete user operation.');
        }
        await disbandUserTeams(profile);
        await deleteUser(user);
        await deleteUserProfile(user);
    } catch (err) {
        switch (err?.code) {
            case 'auth/requires-recent-login':
                throw ERRORS.factories.REQUIRES_RECENT_LOGIN();
            default:
                throw ERRORS.factories.UNDEFINED_ERROR();
        }
    }
}

// #region Categorized Errors

const ERRORS = new CategorizedErrorFactory({
    UNDEFINED_ERROR: {
        message: 'We encountered an issue while attempting to delete your account!',
    },
    REQUIRES_RECENT_LOGIN: {
        message: 'This operation requires you to have a recent login.',
    },
});

// #endregion

// #region Helper Functions

/**
 * Attempt to delete the user's profile from Firestore.
 *
 * @param {Firebase.Auth.User} user The Firebase auth user to delete the associated profile for
 */
async function deleteUserProfile(user) {
    await ProfileModel.deleteByID(user.uid);
}

/**
 * Attempt to disband all of the user's teams.
 */
async function disbandUserTeams(profile) {
    const ownerAssociations = await AssociationModel.getByQuery([
        where('userID', '==', profile.id),
        where('role', '==', ROLES.OWNER),
    ]);
    await Promise.all(map(ownerAssociations, async(ownerAssociation) => {
        await FirebaseFunctions.disbandTeam({ teamID: ownerAssociation.teamID });
    }));
}

// #endregion
