import { useMemo } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {
    Checkbox,
} from '@mui/material';
import { StickyTooltip, IconButton } from '@psionic/ui';
import {
    Edit,
    Delete,
    Grade,
} from '@mui/icons-material';
import Linkify from 'react-linkify';
import { OPTION_TYPES } from '@utils/constants';
import {
    UserAvatar,
    GroupAvatar,
} from '@components/avatars';
import localStyles from './action-item-info.module.scss';

/**
 * Details about a given action item.
 */
function ActionItemInfo({
    actionItem,
    teammates,
    groups,
    onCompleteUpdate,
    onEdit,
    onDelete,
}) {
    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Users required render.
     */
    const usersRequired = useMemo(() => {
        const listItems = [];

        for (const requiredObj of actionItem.requires) {
            switch (requiredObj.type) {
                case OPTION_TYPES.TEAMMATE:
                    const userID = requiredObj.value;
                    const user = find(teammates, [
                        'id',
                        userID,
                    ]);
                    listItems.push(
                        <li key={userID}>
                            <UserAvatar
                                imageURL={user?.avatarURL}
                                size='extra-small'
                                userEmail={user?.email}
                                userName={user?.displayName}
                            />
                        </li>
                    );
                    break;
                case OPTION_TYPES.GROUP:
                    const groupID = requiredObj.value;
                    const group = find(groups, [
                        'id',
                        groupID,
                    ]);
                    listItems.push(
                        <li key={groupID}>
                            <GroupAvatar
                                groupName={group?.name}
                                imageURL={group?.avatarURL}
                                size='extra-small'
                            />
                        </li>
                    );
                    break;
                case OPTION_TYPES.ALL:
                    listItems.push(
                        <li key='all'>
                            <GroupAvatar
                                groupName='All'
                                Icon={Grade}
                                size='extra-small'
                            />
                        </li>
                    );
                    break;
                default:
                    break;
            }
        }

        return listItems;
    }, [
        actionItem,
        teammates,
        groups,
    ]);

    /**
     * Main render.
     */
    return (
        <div className={localStyles.actionItemInfo}>
            <section className={localStyles.topRow}>
                <section className={localStyles.completeInfo}>
                    <StickyTooltip
                        content={
                            actionItem.complete
                                ? 'Mark as Incomplete'
                                : 'Mark as Complete'
                        }
                    >
                        <Checkbox
                            checked={actionItem.complete}
                            onChange={(event) => { return onCompleteUpdate(event.target.checked); }}
                        />
                    </StickyTooltip>
                </section>
                <section className={localStyles.userInfo}>
                    <ul>
                        {usersRequired}
                    </ul>
                </section>
                <section className={localStyles.messageInfo}>
                    <p>
                        <Linkify>
                            {actionItem.message}
                        </Linkify>
                    </p>
                </section>
            </section>
            <section className={localStyles.actions}>
                {
                    onEdit
                        ? (
                            <StickyTooltip content='Edit'>
                                <IconButton
                                    SvgIcon={Edit}
                                    onClick={onEdit}
                                />
                            </StickyTooltip>
                        )
                        : null
                }
                {
                    onDelete
                        ? (
                            <StickyTooltip content='Delete'>
                                <IconButton
                                    color='reject'
                                    SvgIcon={Delete}
                                    onClick={onDelete}
                                />
                            </StickyTooltip>
                        )
                        : null
                }
            </section>
        </div>
    );

    // #endregion
}

ActionItemInfo.propTypes = {
    /**
     * The action item object to represent.
     */
    actionItem:       PropTypes.object.isRequired,
    /**
     * Array of groups that can be related to the action item.
     */
    groups:           PropTypes.arrayOf(PropTypes.object),
    /**
     * Callback for when the "complete" flag is updated.
     */
    onCompleteUpdate: PropTypes.func.isRequired,
    /**
     * Callback for when the "delete" button is clicked.
     */
    onDelete:         PropTypes.func,
    /**
     * Callback for when the "edit" button is clicked.
     */
    onEdit:           PropTypes.func,
    /**
     * Array of teammates that can be related to the action item.
     */
    teammates:        PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActionItemInfo.defaultProps = {
    groups: [],
};

export default ActionItemInfo;
