/* eslint-disable no-bitwise */
/* eslint-disable no-magic-numbers */
import { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Popover,
    IconButton,
} from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import {
    Group,
} from '@mui/icons-material';
import localStyles from './group-avatar.module.scss';

/**
 * A group avatar with the group's image.
 */
function GroupAvatar({
    groupName,
    imageURL,
    Icon,
    size,
}) {
    // #region Hooks

    /**
     * Track whether the popover is open in state.
     */
    const [
        popoverOpen,
        setPopoverOpen,
    ] = useState(false);

    /**
     * Track a ref of the popover's anchor el.
     */
    const popoverAnchorElRef = useRef();

    /**
     * Memoized color based off of the group's name.
     */
    const avatarColor = useMemo(() => {
        if (!groupName) {
            return null;
        }

        const trimmedGroupName = groupName.trim();

        let hash = 0;
        // eslint-disable-next-line id-length
        let i;

        for (i = 0; i < trimmedGroupName.length; i += 1) {
            hash = trimmedGroupName.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${ value.toString(16) }`.slice(-2);
        }

        return color;
    }, [ groupName ]);

    /**
     * Memoized props to pass to the Avatar.
     */
    const avatarProps = useMemo(() => {
        const noInitialsProps = {
            sx: {
                bgcolor: '#F3F6F9',
            },
            children: Icon
                ? <Icon className={localStyles.noGroupInfoIcon}/>
                : <Group className={localStyles.noGroupInfoIcon}/>,
        };

        const trimmedGroupName = groupName ? groupName.trim() : null;

        if (!trimmedGroupName || Icon) {
            return noInitialsProps;
        }

        const splitGroupName = trimmedGroupName.split(' ');
        const firstInitial = splitGroupName[ 0 ][ 0 ].toUpperCase();
        let secondInitial = splitGroupName.length > 1 ? splitGroupName[ 1 ][ 0 ] : '';
        secondInitial = secondInitial === undefined ? '' : secondInitial.toUpperCase();

        return {
            sx: {
                bgcolor: avatarColor,
            },
            children: `${ firstInitial }${ secondInitial }`,
        };
    }, [
        groupName,
        avatarColor,
    ]);

    // #endregion

    // #region Functions

    /**
     * Handler for when the avatar is clicked.
     */
    const handleOnClick = (event) => {
        event.stopPropagation();
        setPopoverOpen((prevState) => { return !prevState; });
    };

    // #endregion

    // #region Render Functions

    /**
     * Popover for when the group avatar is clicked.
     */
    const popover = () => { return (
        <Popover
            anchorEl={popoverAnchorElRef.current}
            open={popoverOpen}
            anchorOrigin={{
                vertical:   'center',
                horizontal: 'center',
            }}
            PaperProps={{
                className: localStyles.popoverPaper,
            }}
            transformOrigin={{
                vertical:   'center',
                horizontal: 'center',
            }}
            onClose={handleOnClick}
        >
            <div className={localStyles.popoverCard}>
                {
                    Icon
                        ? (
                            <div className={localStyles.defaultImage}>
                                <Icon/>
                            </div>
                        )
                        : imageURL
                            ? (
                                <div className={localStyles.imgContainer}>
                                    <img
                                        alt={`${ groupName } avatar`}
                                        src={imageURL}
                                    />
                                </div>
                            )
                            : (
                                <div className={localStyles.defaultImage}>
                                    <Group/>
                                </div>
                            )
                }
                <div className={localStyles.groupInfo}>
                    <h6>
                        {groupName}
                    </h6>
                </div>
            </div>
        </Popover>
    ); };

    /**
     * Main render.
     */
    return (
        <>
            {popover()}
            <StickyTooltip content={groupName}>
                <IconButton
                    ref={popoverAnchorElRef}
                    className={localStyles.groupAvatarWrapper}
                    onClick={handleOnClick}
                >
                    <Avatar
                        className={localStyles.groupAvatar}
                        data-size={size}
                        src={imageURL}
                        {...avatarProps}
                    />
                </IconButton>
            </StickyTooltip>
        </>
    );

    // #endregion
}

GroupAvatar.propTypes = {
    /**
     * The group's name.
     */
    groupName: PropTypes.string,
    /**
     * The icon to use for the group (overrides the image URL if present).
     */
    Icon:      PropTypes.any,
    /**
     * The groups's picture URL.
     */
    imageURL:  PropTypes.string,
    /**
     * The size of the avatar.
     */
    size:      PropTypes.oneOf([
        'extra-small',
        'small',
        'medium',
        'large',
    ]),
};

GroupAvatar.defaultProps = {
    Icon:      undefined,
    groupName: undefined,
    imageURL:  undefined,
    size:      'small',
};

export default GroupAvatar;
