import { useMemo } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { UserInfo } from '@components/details';
import { BiddingCard } from '@components/cards';
import localStyles from './user-votes-list.module.scss';

/**
 * List of user votes.
 */
const UserVotesList = ({
    deck,
    userVotes,
    teamMembers,
}) => {
    /*********
     * HOOKS *
     *********/

    /*************
     * FUNCTIONS *
     *************/

    /********************
     * RENDER FUNCTIONS *
     ********************/

    /**
     * Vote bucket list item.
     */
    const bucketListItem = (cardIndex) => {
        // Construct the array of user list items for the bucket
        const usersList = [];
        for (const userID of Object.keys(userVotes || {})) {
            const userVote = userVotes[userID];
            if (userVote === cardIndex) {
                const userProfile = find(teamMembers, ['id', userID]);
                usersList.push(
                    <li key={`user-info-${userID}`}>
                        <UserInfo profile={userProfile}/>
                    </li>
                );
            }
        }

        // If the users list is not empty, return the bucket render
        if (usersList.length > 0) {
            return (
                <div className={localStyles.bucket}>
                    <BiddingCard
                        card={deck[cardIndex]}
                        sizeRatio={0.45}
                        badgeNum={usersList.length}
                    />
                    <ul className={localStyles.usersList}>
                        {usersList}
                    </ul>
                </div>
            );
        }
        // Otherwise, return nothing
        else {
            return null;
        }
    };
    
    /**
     * Memoized vote buckets.
     */
    const voteBuckets = useMemo(() => {
        const listItems = [];
        for (let cardIndex = 0; cardIndex < deck.length; cardIndex++) {
            const bucket = bucketListItem(cardIndex);
            if (bucket) {
                listItems.push(
                    <li key={`vote-bucket-${cardIndex}`}>
                        {bucketListItem(cardIndex)}
                    </li>
                );
            }
        }
        return listItems;
    }, [deck, userVotes, teamMembers]);

    /**
     * Main render.
     */
    return (
        <ul className={localStyles.bucketsList}>
            {voteBuckets}
        </ul>
    );
};

UserVotesList.propTypes = {
    /**
     * The bidding room's deck for the current session.
     */
    deck: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Object mapping a user's vote (represented by card index) to the user's ID.
     */
    userVotes: PropTypes.object.isRequired,
    /**
     * Object mapping a team member's profile to their email address.
     */
    teamMembers: PropTypes.object.isRequired,
};

UserVotesList.defaultProps = {

};

export default UserVotesList;