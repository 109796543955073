import { useState, useRef, useMemo } from 'react';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import BulletinBoardContextLevel from '@contexts/bulletin-board';

// #region Hook

/**
 * Replace with your own documentation.
 */
export default function({
    readOnly,
    initialValue,
}) {

    // #region Refs

    /**
     * Track a reference to the editor content element.
     */
    const editorContentRef = useRef();

    // #endregion

    // #region Context

    /**
     * Use the bulletin board focusedElement context value.
     */
    const bulletinBoardFocusedElement = BulletinBoardContextLevel.use.focusedElement.value();

    /**
     * Use the bulletin board focusedElement context API.
     */
    const bulletinBoardFocusedElementAPI = BulletinBoardContextLevel.use.focusedElement.api();

    /**
     * Use the bulletin board currentLink context API.
     */
    const bulletinBoardCurrentLinkAPI = BulletinBoardContextLevel.use.currentLink.api();

    /**
     * Use the bulletin board hasEdits context API.
     */
    const bulletinBoardHasEditsAPI = BulletinBoardContextLevel.use.hasEdits.api();

    // #endregion

    // #region State

    /**
     * Track a local copy of the value to modify.
     */
    const [
        value,
        setValue,
    ] = useState(initialValue);

    // #endregion

    // #region Editor Functions

    /**
     * Handler for when the editor's text selection has changed, or when the editor has obtained focus.
     */
    const onSelectOrFocused = ({ editor }) => {
        // If the `readOnly` flag is set to `true`, do nothing
        if (readOnly) return;

        const { head } = editor.view.state.selection;
        const { anchor } = editor.view.state.selection;

        // If a link is selected, show the link submenu
        const currentLink = editor.getAttributes('link').href;

        if (currentLink) {
            bulletinBoardFocusedElementAPI?.set(editorContentRef.current);
            bulletinBoardCurrentLinkAPI?.set(currentLink);
        }

        // Otherwise, only show the text context menu if the selection is non-empty
        else {
            bulletinBoardCurrentLinkAPI?.set(null);

            if (head === anchor) {
                bulletinBoardFocusedElementAPI?.set(null);
            } else {
                bulletinBoardFocusedElementAPI?.set(editorContentRef.current);
            }
        }
    };

    // #endregion

    // #region Misc Hooks

    /**
     * Use the TipTap editor hook to get the editor instance.
     */
    const editor = useEditor({
        editable:   !readOnly,
        content:    value,
        extensions: [
            StarterKit,
            Link.configure({
                autolink:    true,
                openOnClick: readOnly,
                linkOnPaste: true,
            }),
        ],
        onUpdate: ({ editor }) => {
            setValue(editor.getJSON());
            bulletinBoardHasEditsAPI.set(true);
        },
        onSelectionUpdate: onSelectOrFocused,
        onFocus:           onSelectOrFocused,
    });

    // #endregion

    // #region Functions

    /**
     * Handler for setting a link in the editor.
     */
    const onAddLink = (linkValue) => {
        let command = editor.chain().focus()
            .extendMarkRange('link');

        if (linkValue) {
            command = command.setLink({ href: linkValue });
        } else {
            command = command.unsetLink();
        }
        command.run();
    };

    /**
     * Handler for removing a link in the editor.
     */
    const onRemoveLink = () => {
        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run();
    };

    /**
     * Handler for when the text context menu is closed.
     */
    const onClose = () => {
        bulletinBoardCurrentLinkAPI?.set(null);
        bulletinBoardFocusedElementAPI?.set(null);
    };

    // #endregion

    // #region Memoized Values

    /**
     * Memoized anchor element for the text context menu; if not provided, the text context menu
     * should not be shown.
     */
    const textContextMenuAnchorEl = useMemo(() => {
        return bulletinBoardFocusedElement === editorContentRef.current ? bulletinBoardFocusedElement : null;
    }, [ bulletinBoardFocusedElement ]);

    // #endregion

    // #region Return

    /**
     * The `useTextEditor` API.
     */
    return [
        value,
        editor,
        editorContentRef,
        {
            anchorEl: textContextMenuAnchorEl,
            onClose,
            onAddLink,
            onRemoveLink,
        },
    ];

    // #endregion

}

// #endregion

// #region Helper Functions

// #endregion
