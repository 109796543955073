import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
} from '@mui/material';
import localStyles from './upload-avatar-button.module.scss';

/**
 * Button for uploading an avatar picture.
 */
function UploadAvatarButton({
    imageURL,
    FallbackIcon,
    onImageChange,
    size,
    variant,
    // Pass-Thru Props
    ...passThruProps
}) {
    // #region Hooks

    /**
     * Track the local image in state.
     */
    const [localImageURL, setLocalImageURL] = useState(imageURL);

    /**
     * Keep a reference to the invisible input element.
     */
    const inputRef = useRef();

    /**
     * Whenever the image URL updates, set the local image URL in state.
     */
    useEffect(() => {
        setLocalImageURL(imageURL);
    }, [imageURL]);

    // #endregion

    // #region Functions

    /**
     * `onClick` handler for the button.
     */
    const onClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    /**
     * `onChange` handler for the input element.
     */
    const onChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];
            onImageChange(img);
            setLocalImageURL(URL.createObjectURL(img));
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            <Button
                onClick={onClick}
                data-variant={variant}
                {...passThruProps}
                className={`
                    ${localStyles.uploadAvatarButton}
                    ${passThruProps?.className}
                `}
            >
                <Avatar
                    src={localImageURL}
                    data-size={size}
                >
                    <FallbackIcon className={localStyles.noProfileInfoIcon} />
                </Avatar>
            </Button>
            <input
                ref={inputRef}
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={onChange}
                className="noDisplay"
            />
        </>
    );

    // #endregion
}

UploadAvatarButton.propTypes = {
    /**
     * The user's profile picture URL.
     */
    imageURL:     PropTypes.string,
    /**
     * The icon to display if no imageURL is given.
     */
    FallbackIcon: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]).isRequired,
    /**
     * The size of the avatar.
     */
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
    /**
     * The upload avatar button variant to use.
     */
    variant: PropTypes.oneOf([
        'circular',
        'rounded',
    ]),
    /**
     * Callback for when the selected image changes.
     */
    onImageChange:      PropTypes.func.isRequired,
    /**
     * Any props to spread to the underlying button element.
     */
    '...passThruProps': PropTypes.any,
};

UploadAvatarButton.defaultProps = {
    size:    'small',
    variant: 'circular',
};

export default UploadAvatarButton;
