import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import {
    ExpandMore,
    Science,
} from '@mui/icons-material';
import {
    Switch,
    StickyTooltip,
} from '@psionic/ui';
import localStyles from './feature-control.module.scss';

/**
 * Control for enabling / disabling and configuring a feature.
 */
function FeatureControl({
    featureName,
    featureDescription,
    initiallyEnabled,
    switchFieldKey,
    configContent,
    disabledByPlan,
    isBeta,
}) {

    // #region Constants

    // #endregion

    // #region State

    const [
        expanded,
        setExpanded,
    ] = useState(false);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    const onAccordionChange = (event) => {
        // Only update the expanded state if the switch was not clicked
        if (event.target.id !== switchFieldKey) {
            setExpanded((prev) => !prev);
        }
    };

    // #endregion

    // #region Render Functions

    const wrapInStickyTooltipIfDisabledByPlan = (content) => {
        if (disabledByPlan) {
            return (
                <StickyTooltip content='This feature requires a premium subscription.'>
                    {content}
                </StickyTooltip>
            );
        } else {
            return content;
        }
    };

    const renderBetaBadge = () => {
        return (
            <div className={localStyles.betaBadge}>
                <StickyTooltip
                    className={localStyles.betaBadgeContent}
                    content='This feature is experimental!'
                >
                    <Science/>
                </StickyTooltip>
            </div>
        );
    };

    /**
     * Main render.
     */
    return (
        <div
            className={localStyles.featureControl}
            data-is-blocked={disabledByPlan}
        >
            <Accordion
                className={localStyles.accordion}
                expanded={expanded}
                onChange={onAccordionChange}
            >
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    {
                        wrapInStickyTooltipIfDisabledByPlan((
                            <Switch
                                aria-label='toggle feature'
                                disabled={disabledByPlan}
                                fieldKey={switchFieldKey}
                                initialValue={(initiallyEnabled && !disabledByPlan) || false}
                                label={featureName}
                                LabelProps={{
                                    className: localStyles.switchLabel,
                                }}
                            />
                        ))
                    }

                </AccordionSummary>
                <AccordionDetails>
                    <p className={localStyles.description}>{featureDescription}</p>
                    {
                        configContent && !disabledByPlan
                            ? (
                                <section className={localStyles.configContent}>
                                    <h2 className={localStyles.configurationHeader}>
                                        Configuration
                                    </h2>
                                    {configContent}
                                </section>
                            )
                            : null
                    }
                </AccordionDetails>
            </Accordion>
            { isBeta ? renderBetaBadge() : null }
        </div>
    );

    // #endregion
}

FeatureControl.propTypes = {
    /**
     * The content to display in the configuration section of the accordion.
     */
    configContent:      PropTypes.node,
    /**
     * Whether the feature is disabled by the user's plan.
     */
    disabledByPlan:     PropTypes.bool,
    /**
     * A description of the feature, to be displayed in the accordion details section.
     */
    featureDescription: PropTypes.string.isRequired,
    /**
     * The name of the feature, to be displayed in the switch label.
     */
    featureName:        PropTypes.string.isRequired,
    /**
     * Whether the feature is initially enabled.
     */
    initiallyEnabled:   PropTypes.bool,
    /**
     * Flag indicating whether this feature is in beta.
     */
    isBeta:             PropTypes.bool,
    /**
     * The field key to use for the enable feature switch in the form.
     */
    switchFieldKey:     PropTypes.string.isRequired,
};

FeatureControl.defaultProps = {
    initiallyEnabled: false,
    configContent:    undefined,
    disabledByPlan:   false,
    isBeta:           false,
};

export default FeatureControl;
