import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { StickyTooltip } from '@psionic/ui';
import { Delete } from '@mui/icons-material';
import localStyles from './date-list-header.module.scss';

/**
 * A header for an item in the date list.
 */
function DateListHeader({
    date,
    onDelete,

    // Pass-thru props
    ...passThruProps
}) {
    // #region State

    // #endregion

    // #region Effects

    // #endregion Effects

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <header
            {...passThruProps}
            className={`
                ${ localStyles.dateListHeader }
                ${ passThruProps?.className }
            `}
        >
            <div className={localStyles.line}>
                <h6>
                    {date.toFormat('M/d/y, EEE')}
                </h6>
                {
                    onDelete
                        ? (
                            <StickyTooltip content='Delete All Agenda Items for Day'>
                                <IconButton
                                    color='rejectDull'
                                    onClick={onDelete}
                                >
                                    <Delete/>
                                </IconButton>
                            </StickyTooltip>
                        )
                        : null
                }
            </div>
        </header>
    );

    // #endregion
}

DateListHeader.propTypes = {
    /**
     * Any props to forward to the root element.
     */
    '...passThruProps': PropTypes.any,
    /**
     * The luxon date object to display in the header.
     */
    date:               PropTypes.object.isRequired,
    /**
     * Callback for when the "delete" button is clicked.
     */
    onDelete:           PropTypes.func,
};

DateListHeader.defaultProps = {
    '...passThruProps': undefined,
    onDelete:           undefined,
};

export default DateListHeader;
