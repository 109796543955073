import { useMemo, useState } from 'react';
import { Button } from '@psionic/ui';
import TeamGroupModel from '@models/team-group';
import {
    uploadGroupAvatar,
} from '@services/group';
import TeamContextLevel from '@contexts/team';
import { GroupInput } from '@components/inputs';
import GroupControl from './components/group-control/group-control';
import localStyles from './organize-groups.module.scss';

/**
 * View for organizing a team's groups.
 */
function OrganizeGroups() {

    // #region Context

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the team groups context value.
     */
    const teamGroups = TeamContextLevel.use.teamGroups.value();

    /**
     * Use the current team's team members context value.
     */
    const teamMembers = TeamContextLevel.use.teamMembers.value();

    // #endregion

    // #region State

    /**
     * Track the group in add mode in state.
     */
    const [
        groupInAddMode,
        setGroupInAddMode,
    ] = useState(null);

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    /**
     * Creates a new group in add mode.
     */
    const createNewGroup = () => {
        setGroupInAddMode({
            teamID:    currentTeam.id,
            name:      '',
            avatarURL: null,
            members:   [],
        });
    };

    /**
     * Saves the given new group to the database.
     */
    const saveNewGroup = async(group, newAvatar) => {
        setGroupInAddMode(null);
        const newGroupRef = await TeamGroupModel.writeToNewDoc(group);

        if (newAvatar) {
            await uploadGroupAvatar(currentTeam.id, newGroupRef.id, newAvatar);
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Existing group list items render.
     */
    const existingGroupItems = useMemo(() => {
        const listItems = [];

        for (const group of teamGroups) {
            listItems.push(
                <li key={group.id}>
                    <GroupControl
                        currentTeam={currentTeam}
                        group={group}
                        teammates={Object.values(teamMembers || {})}
                    />
                </li>
            );
        }

        return listItems;
    }, [ teamGroups ]);

    /**
     * Main render.
     */
    return (
        <div className='workspacePage'>
            <header>
                <h1>
                    Organize
                    {' '}
                    {currentTeam.name}
                    {' '}
                    Groups
                </h1>
            </header>
            <section className='pageContent'>
                <section className={localStyles.existingGroupsSection}>
                    <ul>
                        {existingGroupItems}
                    </ul>
                </section>
                <section className={localStyles.newGroupSection}>
                    {
                        groupInAddMode
                            ? (
                                <GroupInput
                                    group={groupInAddMode}
                                    teammates={Object.values(teamMembers || {})}
                                    onCancel={() => { return setGroupInAddMode(null); }}
                                    onSave={saveNewGroup}
                                />
                            )
                            : null
                    }
                    {
                        groupInAddMode
                            ? null
                            : (
                                <div className={localStyles.newGroupButtonArea}>
                                    <Button
                                        height={50}
                                        variant='outlined'
                                        width={200}
                                        onClick={createNewGroup}
                                    >
                                        Create New Group
                                    </Button>
                                </div>
                            )
                    }
                </section>
            </section>
        </div>
    );

    // #endregion
}

export default OrganizeGroups;
