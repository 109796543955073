import { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {
    EmojiEmotionsOutlined,
} from '@mui/icons-material';
import { Popover } from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
    IconButton,
    StickyTooltip,
    CircularSpinner,
} from '@psionic/ui';
import localStyles from './reactions.module.scss';

/**
 * A component for displaying user reactions.
 */
function Reactions({
    reactions,
    onReactionClicked,
    users,
    userID,
}) {

    // #region Constants

    // #endregion

    // #region State

    const [
        emojiPickerAnchorEl,
        setEmojiPickerAnchorEl,
    ] = useState();

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    const onEmojiSelect = (emoji) => {
        onReactionClicked({
            id:     emoji.id,
            native: emoji.native,
        });
        setEmojiPickerAnchorEl(null);
    };

    const userHasSelectedReaction = (reaction) => {
        return Boolean(
            find(
                reaction.users,
                (user) => user === userID
            )
        );
    };

    // #endregion

    // #region Render Functions

    const renderUserNames = (usersReacted) => {
        if (usersReacted.length === 1) {
            return `${ usersReacted[ 0 ] }`;
        }

        if (usersReacted.length === 2) {
            return `${ usersReacted[ 0 ] } and ${ usersReacted[ 1 ] }`;
        }

        return `${ usersReacted.slice(0, -1).join(', ') }, and ${ usersReacted[ usersReacted.length - 1 ] }`;
    };

    const renderTooltipContent = (reactionID, reaction) => {
        const numUsersToShow = 8;
        const numUsersToNotShow = Math.max(reaction.users.length - numUsersToShow, 0);
        const userHasSelected = userHasSelectedReaction(reaction);
        const numOtherUsersToShow = userHasSelected ? numUsersToShow - 1 : numUsersToShow;

        const usernames = [];

        for (const reactedUserID of reaction.users) {
            if (usernames.length >= numOtherUsersToShow) {
                break;
            }

            if (reactedUserID !== userID) {
                const reactedUser = find(
                    users,
                    (user) => user.id === reactedUserID
                );

                usernames.push(reactedUser.displayName || reactedUser.email);
            }
        }

        if (userHasSelected) {
            usernames.push('You');
        }

        if (numUsersToNotShow) {
            usernames.push(`${ numUsersToNotShow } others...`);
        }

        return (
            <>
                <p className={localStyles.emoji}>
                    {reaction.native}
                </p>
                <p className={localStyles.text}>
                    <span className={localStyles.users}>
                        {renderUserNames(usernames)}
                    </span>
                    {' '}
                    reacted with :
                    {reactionID}
                    :
                </p>
            </>
        );
    };

    const renderReactions = () => {
        const items = [];

        for (
            const [
                reactionID,
                reaction,
            ] of Object.entries(reactions)
        ) {
            if (reaction.users?.length > 0) {
                items.push(
                    <StickyTooltip
                        content={renderTooltipContent(reactionID, reaction)}
                        TooltipProps={{
                            className: localStyles.tooltip,
                        }}
                    >
                        <li key={reaction.id}>
                            <button
                                className={localStyles.reaction}
                                data-user-added={userHasSelectedReaction(reaction)}
                                type='button'
                                onClick={() => onReactionClicked({ id: reactionID, ...reaction })}
                            >
                                <span className={localStyles.emoji}>
                                    {reaction.native}
                                </span>
                                <span className={localStyles.count}>
                                    {reaction.users.length}
                                </span>
                            </button>
                        </li>
                    </StickyTooltip>
                );
            }
        }

        return items;
    };

    /**
     * If the reactions are not loaded yet, return a loading state.
     */
    if (!reactions) {
        return (
            <CircularSpinner
                color='lowEmphasis'
                size={22}
            />
        );
    }

    /**
     * Main render.
     */
    return (
        <>
            <ul className={localStyles.reactions}>
                {renderReactions()}
                <li>
                    <IconButton
                        color='lowEmphasis'
                        SvgIcon={EmojiEmotionsOutlined}
                        onClick={(event) => setEmojiPickerAnchorEl(event.currentTarget)}
                    />
                </li>
            </ul>
            <Popover
                anchorEl={emojiPickerAnchorEl}
                open={Boolean(emojiPickerAnchorEl)}
                anchorOrigin={{
                    vertical:   'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    className: localStyles.popover,
                }}
                onClose={() => setEmojiPickerAnchorEl(null)}
            >
                <div className='emojiPickerWrapper'>
                    <Picker
                        data={data}
                        onEmojiSelect={onEmojiSelect}
                    />
                </div>
            </Popover>
        </>
    );

    // #endregion
}

Reactions.propTypes = {
    /**
     * The callback for when a reaction is clicked. Should take the following object as a parameter:
     * {
     *   id:     string,
     *   native: string
     * }
     */
    onReactionClicked: PropTypes.func.isRequired,
    /**
     * The reactions data.
     */
    reactions:         PropTypes.shape({
        /**
         * The native emoji icon.
         */
        native: PropTypes.string.isRequired,
        /**
         * The IDs of users who had this reaction.
         */
        users:  PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    /**
     * The ID of the current user.
     */
    userID: PropTypes.string,
    /**
     * Array of all users that could potentially be associated with a reaction.
     */
    users:  PropTypes.object.isRequired,
};

Reactions.defaultProps = {
    userID:    undefined,
    reactions: undefined,
};

export default Reactions;
