import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import localStyles from './histogram.module.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

/**
 * A histogram graph using chart.js.
 */
function Histogram({
    title,
    // eslint-disable-next-line id-length
    x,
    // eslint-disable-next-line id-length
    y,

    // Pass-thru props
    ...passThruProps
}) {
    // #region Hooks

    /**
     * Memoized options for the bar chart.
     */
    const chartOptions = useMemo(() => {
        return {
            responsive: true,
            plugins:    {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
            maintainAspectRatio: false,
            scales:              {
                // eslint-disable-next-line id-length
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#ffffffaa',
                    },
                },
                // eslint-disable-next-line id-length
                y: {
                    suggestedMax: 5,
                    grid:         {
                        color:     '#ffffff55',
                        drawTicks: true,
                        tickColor: '#00000000',
                    },
                    border: {
                        color: '#ffffff33',
                    },
                    ticks: {
                        beginAtZero: true,
                        callback:    (value) => {
                            if (value % 1 === 0) {
                                return value;
                            }
                        },
                        color: '#ffffffaa',
                    },
                },
            },
        };
    }, []);

    /**
     * Memoized data object for the bar chart.
     */
    const chartData = useMemo(() => {
        return {
            labels:   x,
            datasets: [ {
                label:           'Votes',
                data:            y,
                backgroundColor: '#008fbadd',
            } ],
        };
    }, [
        x,
        y,
    ]);

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div
            {...passThruProps}
            className={`${ localStyles.chartWrapper } ${ passThruProps?.className }`}
        >
            <h3>
                {title}
            </h3>
            <div className={localStyles.chart}>
                <Bar
                    data={chartData}
                    options={chartOptions}
                />
            </div>
        </div>
    );

    // #endregion
}

Histogram.propTypes = {
    /**
     * Any props to pass to the highest-level element within this component.
     */
    passThruProps: PropTypes.object,
    /**
     * The title to write above the graph.
     */
    title:         PropTypes.string.isRequired,
    /**
     * The array of data to display on the x-axis.
     */
    // eslint-disable-next-line id-length
    x:             PropTypes.array.isRequired,
    /**
     * The array of data to display on the y-axis.
     */
    // eslint-disable-next-line id-length
    y:             PropTypes.array.isRequired,
};

Histogram.defaultProps = {
    passThruProps: {},
};

export default Histogram;
