import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    Check,
} from '@mui/icons-material';
import {
    Button,
    StickyTooltip,
} from '@psionic/ui';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import localStyles from './all-surveys.module.scss';

function AllSurveys({
    surveys,
    incrementNumSurveysToLoad,
    moreSurveysToLoad,
    onFillOutCurrentSurveyClicked,
    latestActivityLogsBySurveyID,
}) {

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const { profile } = useUserContext();

    const currentSurvey = TeamCheckInContextLevel.use.myCurrentCheckIn.value();

    // #endregion

    // #region Functions

    const formatSurveyDate = (date) => {
        let luxonObj;

        if (typeof date === 'string') {
            luxonObj = DateTime.fromISO(date);
        } else {
            luxonObj = DateTime.fromJSDate(date);
        }

        return luxonObj.toFormat('MMM d, yyyy');
    };

    const navigateToViewSurvey = (survey) => {
        navigate(survey.id);
    };

    const navigateToFillOutSurvey = (survey) => {
        navigate(`fill-out/${ survey.id }`);
    };

    // #endregion

    // #region Render Functions

    const renderActivityStatus = (survey) => {
        const activityLogForSurvey = latestActivityLogsBySurveyID[ survey.id ];

        if (!activityLogForSurvey) {
            return null;
        }

        if (
            activityLogForSurvey.userID !== profile.id
            && !(activityLogForSurvey.acknowledgedBy || []).includes(profile.id)
        ) {
            return (
                <StickyTooltip content='New Activity'>
                    <div className={localStyles.newActivity}/>
                </StickyTooltip>
            );
        }

        return (
            <StickyTooltip content='No New Activity'>
                <div className={localStyles.reviewed}>
                    <Check/>
                </div>
            </StickyTooltip>
        );
    };

    const renderAction = (survey, surveyIndex) => {
        if (survey.dateSubmitted) {
            return (
                <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => navigateToViewSurvey(survey)}
                >
                    View Survey
                </Button>
            );
        }

        if (currentSurvey?.id === survey.id) {
            return (
                <Button
                    color='primary'
                    variant='contained'
                    onClick={onFillOutCurrentSurveyClicked}
                >
                    Fill Out Current Survey
                </Button>
            );
        }

        if (surveyIndex === 1 && !survey.dateSubmitted) {
            return (
                <Button
                    color='warning'
                    variant='outlined'
                    onClick={() => navigateToFillOutSurvey(survey)}
                >
                    Fill Out Overdue Survey
                </Button>
            );
        }

        return (
            <>
                —
            </>
        );
    };

    const renderPastSurveys = () => {
        const renders = [];

        for (const [
            surveyIndex,
            survey,
        ] of surveys.entries()) {
            renders.push(
                <tr key={survey.id}>
                    <td>
                        {formatSurveyDate(survey.startDate)}
                        {' '}
                        –
                        {' '}
                        {formatSurveyDate(survey.endDate)}
                    </td>
                    <td>
                        {survey.dateSubmitted ? formatSurveyDate(survey.dateSubmitted) : '—'}
                    </td>
                    <td>
                        { renderAction(survey, surveyIndex) }
                    </td>
                    <td>
                        { renderActivityStatus(survey) }
                    </td>
                </tr>
            );
        }

        return renders;
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pastSurveys}>
            <table className={localStyles.table}>
                <thead>
                    <tr>
                        <th>
                            Check-in Dates
                        </th>
                        <th>
                            Submitted
                        </th>
                        <th>
                            Action
                        </th>
                        <th>
                            Activity Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        surveys?.length > 0
                            ? renderPastSurveys()
                            : null
                    }
                </tbody>
            </table>
            {
                !surveys || surveys?.length <= 0
                    ? (
                        <p className={localStyles.emptyState}>
                            You have no past surveys yet. Any previous check-in surveys you have submitted
                            for this team will appear here.
                        </p>
                    )
                    : moreSurveysToLoad
                        ? (
                            <Button
                                color='primary'
                                variant='text'
                                onClick={incrementNumSurveysToLoad}
                            >
                                Load More Surveys
                            </Button>
                        )
                        : (
                            <p className={localStyles.reachedEndText}>
                                No more surveys to load.
                            </p>
                        )
            }
        </div>
    );

    // #endregion
}

AllSurveys.propTypes = {
    /**
     * The surveys to render.
     */
    surveys:                       PropTypes.arrayOf(PropTypes.object),
    /**
     * The function to call to increment the number of surveys to load.
     */
    incrementNumSurveysToLoad:     PropTypes.func.isRequired,
    /**
     * Flag indicating whether or not there are any additional surveys to load.
     */
    moreSurveysToLoad:             PropTypes.bool,
    /**
     * The function to call when the user clicks the "Fill Out Current Survey" button.
     */
    onFillOutCurrentSurveyClicked: PropTypes.func.isRequired,
    /**
     * The latest activity logs by survey ID.
     */
    latestActivityLogsBySurveyID:  PropTypes.object.isRequired,
};

AllSurveys.defaultProps = {
    surveys:           [],
    moreSurveysToLoad: false,
};

export default AllSurveys;
