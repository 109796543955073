import PropTypes from 'prop-types';
import {
    Dialog,
    BarLoader,
} from '@psionic/ui';
import localStyles from './operation-running-dialog.module.scss';

/**
 * Dialog showing that an operation is running.
 */
function OperationRunningDialog({
    isOpen,
    setIsOpen,
    dialogTitle,
    dialogText,
}) {
    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Dialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className={[
                'dialog',
                localStyles.dialog,
            ].join(' ')}
        >
            <div
                className={localStyles.content}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
            >
                <header>
                    <h3>
                        {dialogTitle}
                    </h3>
                </header>
                <section>
                    <p>
                        {dialogText}
                    </p>
                    <BarLoader width={500}/>
                </section>
            </div>
        </Dialog>
    );

    // #endregion
}

OperationRunningDialog.propTypes = {
    /**
     * Text to display as the dialog body.
     */
    dialogText:  PropTypes.string.isRequired,
    /**
     * Text to display as the dialog title.
     */
    dialogTitle: PropTypes.string.isRequired,
    /**
     * Flag tracking whether the dialog should be open or not.
     */
    isOpen:      PropTypes.bool,
    /**
     * Function to call to set the open state of the dialog.
     */
    setIsOpen:   PropTypes.func.isRequired,
};

OperationRunningDialog.defaultProps = {
    isOpen: false,
};

export default OperationRunningDialog;
