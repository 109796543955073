import { useMemo } from 'react';
import PropTypes from 'prop-types';
import localStyles from './date-list-section.module.scss';

/**
 * Date list section component.
 */
const DateListSection = ({
    children,
}) => {

    //#region State

    //#endregion

    //#region Effects

    //#endregion Effects

    //#region Functions

    //#endregion

    //#region Render Functions

    /**
     * Memoized list-item-ified children.
     */
    const listItemChildren = useMemo(() => {
        const listItems = [];
        let i = 0;
        for (const child of children) {

            if (child?.length > 0) {
                for (const childChild of child) {
                    if (childChild) {
                        listItems.push(
                            <li key={`date-list-item-${i++}`}>
                                {childChild}
                            </li>
                        );
                    }
                }
            }

            else if (child && child?.length != 0) {
                listItems.push(
                    <li key={`date-list-item-${i++}`}>
                        {child}
                    </li>
                );
            }
            
        }
        return listItems;
    }, [children]);

    /**
     * Main render.
     */
    return (
        <section className={localStyles.dateListSection}>
            <ul>
                {listItemChildren}
            </ul>
        </section>
    );

    //#endregion

};

DateListSection.propTypes = {
    /**
     * Children to render within the date list section.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

DateListSection.defaultProps = {

};

export default DateListSection;