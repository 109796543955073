import { Model } from '@unifire-js/firebase/firestore';

const InviteModel = new Model({
    collectionName:  'invites',
    collectionProps: [
        'teamID',
        'email',
    ],
});

export default InviteModel;
