import { Autobatcher } from '@unifire-js/firebase/firestore';
import AgendaModel from '@models/agenda';
import DayModel from '@models/day';
import MinuteModel from '@models/minute';

/**
 * Replace with your own documentation.
 */
class AgendaService {

    // #region Public Functions

    /**
     * Creates a new agenda for the given team with a default name.
     *
     * @param {string} teamID The ID of the team to create the agenda for
     * @param {number} numExistingAgendas The number of agendas that already exist for the team
     * @returns {Promise<void>} Resolves once the agenda is created
     */
    createNewAgenda = async(teamID, numExistingAgendas) => {
        await AgendaModel.writeToNewDoc(
            {
                name: `Agenda ${ numExistingAgendas + 1 }`,
                teamID,
            },
            { mergeWithDefaultValues: true }
        );
    };

    /**
     * Updates the specified agenda by merging in the specified new data with the
     * existing data.
     *
     * @param {string} agendaID The ID of the agenda to update
     * @param {Object} newData The new data to write to the agenda's document
     * @returns {Promise<void>} Resolves once the agenda is updated
     */
    updateAgenda = async(agendaID, newData) => {
        await AgendaModel.writeToID(
            agendaID,
            newData,
            { mergeWithExistingValues: true }
        );
    };

    /**
     * Deletes the specified agenda
     *
     * @param {string} agendaID The ID of the agenda to delete
     * @returns {Promise<void>} Resolves once the agenda has been deleted
     */
    deleteAgenda = async(agendaID) => {
        // We don't care about orphaned data; it would take too long to delete
        await AgendaModel.deleteByID(agendaID);
    };

    // #endregion

    // #region Private Functions

    // #endregion

}

export default new AgendaService();
