import {
    ref,
    uploadBytes,
    getDownloadURL,
} from 'firebase/storage';
import { storage } from '@services/firebase';
import TeamGroupModel from '@models/team-group';

/**
 * Uploads the given file to Firebase storage, and links the download URL to the given team group.
 */
export default async function uploadGroupAvatar(teamID, groupID, file) {
    const storageRef = ref(storage, `group-avatars/${teamID}/${groupID}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    await TeamGroupModel.writeToID(
        groupID,
        { avatarURL: downloadURL },
        { mergeWithExistingValues: true }
    );
}
