import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { verifyEmail } from '@services/user';
import { LoadingPageWithContent } from '@components/loading';
import localStyles from './verify-email.module.scss';

/**
 * Page where the user's email is attempted to be verified.
 */
function VerifyEmail() {
    // #region Hooks

    /**
     * State for tracking whether the email was verified successfully.
     */
    const [
        emailVerified,
        setEmailVerified,
    ] = useState(false);

    /**
     * State for tracking any verification error that comes up.
     */
    const [
        verificationError,
        setVerificationError,
    ] = useState(null);

    /**
     * Hook for grabbing search params from the URL.
     */
    const [
        searchParams,
        setSearchParams,
    ] = useSearchParams();

    /**
     * Attempt to verify the email when the component mounts.
     */
    useEffect(() => {
        const fn = async() => {
            try {
                await verifyEmail(
                    searchParams.get('oobCode'),
                    searchParams.get('email')
                );
                setEmailVerified(true);
            } catch (err) {
                setVerificationError(err);
            }
        };

        fn();
    }, []);

    // #endregion

    // #region Render Functions

    /**
     * Render the content for the page based on the current state.
     */
    const renderContentBasedOnState = () => {
        // Render for when the user first enters the page
        if (!emailVerified && !verificationError) {
            return (
                <LoadingPageWithContent
                    loadingState='loading'
                >
                    We are attempting to verify your email.
                </LoadingPageWithContent>
            );
        }

        // Render for when the user's email is verified
        if (emailVerified) {
            return (
                <LoadingPageWithContent
                    loadingState='success'
                >
                    Your email has been verified! You may exit this page, and log in!
                </LoadingPageWithContent>
            );
        }

        // Render for when there was a verification error
        if (verificationError) {
            return (
                <LoadingPageWithContent
                    loadingState='failure'
                >
                    {verificationError.message}
                </LoadingPageWithContent>
            );
        }
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pageWrapper}>
            {renderContentBasedOnState()}
        </div>
    );

    // #endregion
}

export default VerifyEmail;
