import { useEffect, useState } from 'react';
import { map } from 'lodash';

//#region Typedefs

/**
 * A flag indicating whether the page is loading data or not.
 * @typedef {boolean} PageLoadingFlag
 */

/**
 * The error that occurred while loading the page. Set to `null` if no error has occurred yet.
 * @typedef {Error | null} PageLoadingError
 */

//#endregion

//#region Hook

/**
 * Hook the handles calling some loading functions when a component mounts, and setting a loading
 * flag to track whether that data has finished loading (or encountered an error).
 *
 * @param {function[]} loadingFns Array of functions to call and await the results of when the component first mounts
 * @param {function} [onSuccess] Optional callback to run once all of the loading functions have resolved successfully
 * @param {function} [onFailure] Optional callback if the loading process fails at any point
 * @returns {[PageLoadingFlag, PageLoadingError]} The hook's API
 */
export default function(loadingFns, { onSuccess, onFailure } = {}) {
    /**
     * Track a loading flag in state.
     */
    const [loading, setLoading] = useState(true);

    /**
     * Track a `loadingErr` flag in state.
     */
    const [loadingErr, setLoadingErr] = useState(null);

    /**
     * When the component first mounts, call the loading functions.
     */
    useEffect(() => {
        Promise.all(map(loadingFns, (fn) => fn()))
            .then((results) => {
                onSucces?.(results);
            })
            .catch((err) => {
                setLoadingErr(err);
                onFailure?.(err);
            })
            .finally(() => setLoading(false));
    }, []);

    /**
     * Return the hook API.
     */
    return [
        loading,
        loadingErr,
    ];
}

//#endregion

//#region Helper Functions

//#endregion
