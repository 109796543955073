import { useMemo } from 'react';
import PropTypes from 'prop-types';
import localStyles from './solution-info.module.scss';

/**
 * Section for displaying an Eager Lead solution's info.
 */
function SolutionInfo({
    title,
    subtitle,
    featuresList,
}) {
    // #region Render Functions

    /**
     * Memoized renders of the features list items.
     */
    const featuresListItems = useMemo(() => {
        const elements = [];
        let featureIndex = 0;

        for (const feature of featuresList) {
            elements.push(
                <li key={`feature-${ featureIndex++ }`}>
                    <p>
                        {feature}
                    </p>
                </li>
            );
        }

        return elements;
    }, [ featuresList ]);

    /**
     * Main render.
     */
    return (
        <div className={localStyles.solutionInfo}>
            <div>
                <h5 className='accentHeader'>
                    {title}
                </h5>
                <p className={localStyles.subtitle}>
                    {subtitle}
                </p>
                <ul>
                    {featuresListItems}
                </ul>
            </div>
        </div>
    );

    // #endregion
}

SolutionInfo.propTypes = {
    /**
     * List of features to mention in the info.
     */
    featuresList: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * The text to render in the subtitle.
     */
    subtitle:     PropTypes.string.isRequired,
    /**
     * The text to render in the header.
     */
    title:        PropTypes.string.isRequired,
};

SolutionInfo.defaultProps = {

};

export default SolutionInfo;
