import {
    useMemo, useState, useEffect,
} from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import {
    StickyTooltip,
    IconButton,
    ControlledTextField,
} from '@psionic/ui';
import {
    Add,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import { ROLES } from '@models/association';
import InviteModel from '@models/invite';
import { validateEmail } from '@utils/validation';
import MemberItem from './components/member-item';
import localStyles from './manage-team-members.module.scss';

/**
 * Page that provides owners / admins of a team a way to add / remove team members.
 */
function ManageTeamMembers() {
    // #region Context

    const teamAssociations = TeamContextLevel.use.teamAssociations.value();

    const teamInvites = TeamContextLevel.use.teamInvites.value();

    const currentTeam = TeamContextLevel.use.currentTeam.value();

    const teamMembers = TeamContextLevel.use.teamMembers.value();

    // #endregion

    // #region State

    const [
        inviteField,
        setInviteField,
    ] = useState('');

    const [
        invitingUser,
        setInvitingUser,
    ] = useState(false);

    const [
        inviteFieldError,
        setInviteFieldError,
    ] = useState(null);

    // #endregion

    // #region Memoized Values

    const owner = useMemo(() => {
        // If the necessary data hasn't loaded yet, return nothing
        if (!teamAssociations || !teamMembers) {
            return null;
        }

        // Otherwise, find the profile of the team's owner
        const ownerAssociation = find(teamAssociations, [
            'role',
            ROLES.OWNER,
        ]);

        if (ownerAssociation) {
            return {
                profile:     teamMembers[ ownerAssociation.userID ],
                association: ownerAssociation,
            };
        }
    }, [
        teamAssociations,
        teamMembers,
    ]);

    const adminUsers = useMemo(() => {
        // If the necessary data hasn't loaded yet, return nothing
        if (!teamAssociations || !teamMembers) {
            return null;
        }

        // Otherwise, filter the team associations to just admins
        const adminAssociations = filter(teamAssociations, [
            'role',
            ROLES.ADMIN,
        ]);

        // Then, map the admin associations to their corresponding user profiles
        return adminAssociations.map((association) => {
            return {
                profile: teamMembers[ association.userID ],
                association,
            };
        });
    }, [
        teamAssociations,
        teamMembers,
    ]);

    const memberUsers = useMemo(() => {
        // If the necessary data hasn't loaded yet, return nothing
        if (!teamAssociations || !teamMembers) {
            return null;
        }

        // Otherwise, filter the team associations to just members
        const memberAssociations = filter(teamAssociations, [
            'role',
            ROLES.MEMBER,
        ]);

        // Then, map the member associations to their corresponding user profiles
        return memberAssociations.map((association) => {
            return {
                profile: teamMembers[ association.userID ],
                association,
            };
        });
    }, [
        teamAssociations,
        teamMembers,
    ]);

    // #endregion

    // #region Effects

    /**
     * Reset the invite field whenever it changes.
     */
    useEffect(() => {
        setInviteFieldError(null);
    }, [ inviteField ]);

    // #endregion

    // #region Functions

    const onInviteClicked = async() => {
        const email = inviteField.toLowerCase().trim();

        if (!validateEmail(email)) {
            setInviteFieldError('Invalid email address');

            return;
        }

        if (find(teamInvites, [
            'email',
            email,
        ])) {
            setInviteFieldError('User has already been invited');

            return;
        }

        if (find(teamMembers, [
            'email',
            email,
        ])) {
            setInviteFieldError('User is already a member');

            return;
        }

        await inviteUser(email);
    };

    const inviteUser = async(email) => {
        setInvitingUser(true);

        await InviteModel.writeToNewDoc(
            {
                teamID: currentTeam.id,
                email,
            }
        );

        setInviteField('');
        setInvitingUser(false);
    };

    // #endregion

    // #region Render Functions

    return (
        <div className='workspacePage'>
            <header>
                <h1>
                    Manage
                    {' '}
                    {currentTeam.name}
                    {' '}
                    Members
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                <section className={localStyles.ownerSection}>
                    <h2>
                        Owner
                    </h2>
                    <ul>
                        {
                            owner
                                ? (
                                    <li>
                                        <MemberItem
                                            member={owner?.profile}
                                            memberAssociation={owner?.association}
                                        />
                                    </li>
                                )
                                : null
                        }
                    </ul>
                </section>
                <section className={localStyles.adminsList}>
                    <h2>
                        Admins
                    </h2>
                    <ul>
                        {
                            adminUsers?.length > 0
                                ? adminUsers.map((adminUser) => (
                                    <li key={adminUser.id}>
                                        <MemberItem
                                            member={adminUser?.profile}
                                            memberAssociation={adminUser?.association}
                                        />
                                    </li>
                                ))
                                : (
                                    <p className={localStyles.emptyState}>
                                        No admins yet!
                                    </p>
                                )
                        }
                    </ul>
                </section>
                <section className={localStyles.membersList}>
                    <h2>
                        Members
                    </h2>
                    <ul>
                        {
                            memberUsers?.length > 0
                                ? memberUsers.map((memberUser) => (
                                    <li key={memberUser.id}>
                                        <MemberItem
                                            member={memberUser?.profile}
                                            memberAssociation={memberUser?.association}
                                        />
                                    </li>
                                ))
                                : (
                                    <p className={localStyles.emptyState}>
                                        No members yet!
                                    </p>
                                )
                        }
                    </ul>
                </section>
                <section className={localStyles.invitesList}>
                    <h2>
                        Invites
                    </h2>
                    <ul>
                        {
                            teamInvites?.length > 0
                                ? teamInvites.map((invite) => (
                                    <li key={invite.id}>
                                        <MemberItem
                                            member={{ email: invite.email }}
                                            memberInvite={invite}
                                        />
                                    </li>
                                ))
                                : (
                                    <p className={localStyles.emptyState}>
                                        No invites yet!
                                    </p>
                                )
                        }
                    </ul>
                </section>
                <section className={localStyles.inviteSection}>
                    <p>
                        Invite Member:
                    </p>
                    <ControlledTextField
                        disabled={invitingUser}
                        hasError={!!inviteFieldError}
                        helperMessage={inviteFieldError}
                        label='Email Address'
                        value={inviteField}
                        darkMode
                        onChange={setInviteField}
                    />
                    <StickyTooltip content='Invite Member'>
                        <IconButton
                            color='primary'
                            disabled={!inviteField}
                            SvgIcon={Add}
                            onClick={onInviteClicked}
                        />
                    </StickyTooltip>
                </section>
            </section>
        </div>
    );

    // #endregion
}

export default ManageTeamMembers;
