import { useMemo, useState, useEffect } from 'react';
import { useUserContext } from '@unifire-js/firebase/auth';
import { useTrackFunction } from '@unifire-js/hooks';
import { CircularSpinner } from '@psionic/ui';
import { motion } from 'framer-motion';
import TeamContextLevel from '@contexts/team';
import FirebaseFunctions from '@utils/constants/firebase-functions';
import { SubscriptionOption } from '@components/cards';
import { SubscriptionInfo } from '@components/details';
import { OperationRunningDialog } from '@components/dialogs';
import localStyles from './manage-subscription.module.scss';

/**
 * Page view for managing a team's subscription.
 */
function ManageSubscription() {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the current team context.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the team subscription context.
     */
    const teamSubscription = TeamContextLevel.use.teamSubscription.value();

    /**
     * Memoized flag indicating whether the subscription was purchased by the signed-in user or not.
     */
    const subscriptionPurchasedByUser = useMemo(
        () => { return profile?.stripeCustomerID === teamSubscription?.purchasedBy; },
        [
            profile,
            teamSubscription,
        ]
    );

    /**
     * Track the subscription prices in state.
     */
    const [
        prices,
        setPrices,
    ] = useState(null);

    /**
     * Track the operation running dialog info in state.
     */
    const [
        operationRunningDialogInfo,
        setOperationRunningDialogInfo,
    ] = useState({
        dialogTitle: '',
        dialogText:  '',
        isOpen:      false,
    });

    /**
     * Tracked function for taking a user to the specified payment link in Stripe.
     */
    const [
        takingUserToPaymentLink,
        takeUserToPaymentLink,
    ] = useTrackFunction(async(priceInfo) => {
        try {
            // Determine whether to use a fake URL or real URL (based on testing locally)
            const baseURL = window.location.origin.toString().includes('localhost') ? 'https://www.eagerlead.com' : window.location.origin.toString();

            // Create the checkout session for the user
            const result = await FirebaseFunctions.createCheckoutSession({
                stripeCustomerID: profile?.stripeCustomerID,
                baseURL,
                priceInfo,
                teamID:           currentTeam.id,
            });

            // Take the user to the given URL
            window.location.href = result.checkoutURL;
        } catch (err) {
            // Do nothing...
        }
    });

    /**
     * When the component first mounts, fetch all of the subscription price information and store it in state.
     */
    useEffect(() => {
        const fn = async() => {
            // Fetch the pricing information from the Stripe API
            const result = await FirebaseFunctions.getAllAvailableSubscriptionPrices();

            // Store the prices in state
            setPrices(result.prices);
        };

        fn();
    }, []);

    // #endregion

    // #region Functions

    /**
     * Cancels the team's subscription.
     */
    const cancelSubscription = async() => {
        setOperationRunningDialogInfo({
            dialogTitle: 'Canceling Subscription...',
            dialogText:  'We are attempting to cancel your subscription.',
            isOpen:      true,
        });
        await FirebaseFunctions.cancelSubscription({ subscriptionID: teamSubscription.id });
        setOperationRunningDialogInfo((prevState) => { return {
            ...prevState,
            isOpen: false,
        }; });
    };

    /**
     * Undo the team's subscription cancellation.
     */
    const undoCancelSubscription = async() => {
        setOperationRunningDialogInfo({
            dialogTitle: 'Undoing Subscription Cancellation...',
            dialogText:  'We are attempting to undo your subscription cancellation.',
            isOpen:      true,
        });
        await FirebaseFunctions.undoSubscriptionCancellation({ subscriptionID: teamSubscription.id });
        setOperationRunningDialogInfo((prevState) => { return {
            ...prevState,
            isOpen: false,
        }; });
    };

    // #endregion

    // #region Render Functions

    /**
     * Subscription options.
     */
    const subscriptionOptions = useMemo(() => {
        // If the necessary data is not loaded yet, return a loading indicator
        if (!currentTeam || !prices) {
            return (
                <motion.div
                    animate={{ scale: 1 }}
                    className={localStyles.loading}
                    initial={{ scale: 0 }}
                    transition={{
                        duration: 0.25,
                    }}
                >
                    <CircularSpinner/>
                </motion.div>
            );
        }

        // Otherwise, return the subscription options
        const subscriptionOptionListItems = [];

        for (const price of prices) {
            subscriptionOptionListItems.push(
                <li key={price.planName}>
                    <SubscriptionOption
                        disabled={takingUserToPaymentLink}
                        priceInfo={price}
                        onClick={() => { return takeUserToPaymentLink(price); }}
                    />
                </li>
            );
        }

        return (
            <section className={localStyles.subscriptionOptionsSection}>
                <header>
                    <h2>
                        Select a Premium Subscription Plan!
                    </h2>
                </header>
                <ul>
                    {subscriptionOptionListItems}
                </ul>
                <p className={localStyles.perUserMeaning}>
                    * Per user charges based on peak number of users during that billing cycle.
                </p>
            </section>
        );
    }, [
        currentTeam,
        prices,
        takingUserToPaymentLink,
    ]);

    /**
     * Main render.
     */
    return (
        <>
            {/* DIALOGS */}
            <OperationRunningDialog
                dialogText={operationRunningDialogInfo.dialogText}
                dialogTitle={operationRunningDialogInfo.dialogTitle}
                isOpen={operationRunningDialogInfo.isOpen}
            />
            {/* MAIN PAGE */}
            <div className='workspacePage'>
                <header>
                    <h1>
                        Manage Team Premium Subscription
                    </h1>
                </header>
                <section className='pageContent'>
                    <section className={localStyles.currentPlanSection}>
                        <SubscriptionInfo
                            subscription={teamSubscription}
                            subscriptionPurchasedByUser={subscriptionPurchasedByUser}
                            team={currentTeam}
                            onCancelSubscription={cancelSubscription}
                            onUndoCancellation={undoCancelSubscription}
                        />
                    </section>
                    <section className={localStyles.infoSection}>
                        <header>
                            <h2>
                                With a premium subscription, your team is able to access:
                            </h2>
                        </header>
                        <ul>
                            <li>
                                <span>Agendas</span>
                                <span>
                                    Create agendas, tracking who needs to be present and who can leave each day
                                    to stay productive.
                                </span>
                            </li>
                            <li>
                                <span>Check In</span>
                                <span>
                                    Create check-in sessions for managers to keep track of their team members'
                                    wellbeing.
                                </span>
                            </li>
                            <li>
                                <span>Bulletins</span>
                                <span>
                                    Create Notion-like bulletins to keep your team informed of important
                                    information.
                                </span>
                            </li>
                            <li>
                                <span>Task Bidding</span>
                                <span>Quickly and easily organize task bidding sessions with your remote team!</span>
                            </li>
                        </ul>
                    </section>
                    {teamSubscription ? null : subscriptionOptions}
                </section>
            </div>
        </>
    );

    // #endregion
}

export default ManageSubscription;
