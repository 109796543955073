import { useNavigate, useSearchParams } from 'react-router-dom';
import VerifyEmail from './verify-email';
import ResetPassword from './reset-password';

/**
 * "Router" for any custom email action handlers.
 */
function EmailActionHandler() {
    // #region Hooks

    const navigate = useNavigate();

    const [
        searchParams,
        // eslint-disable-next-line no-unused-vars
        setSearchParams,
    ] = useSearchParams();

    // #endregion

    // #region Render Functions

    /**
     * Render the appropriate email action handler page depending on the mode from URL params.
     */
    switch (searchParams.get('mode')) {
        case 'verifyEmail':
            return <VerifyEmail/>;
        case 'resetPassword':
            return <ResetPassword/>;
        default:
            navigate('/home');

            return null;
    }

    // #endregion
}

export default EmailActionHandler;
