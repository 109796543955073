import PropTypes from 'prop-types';
import localStyles from './banner.module.scss';

/**
 * Banner displaying a information.
 */
const Banner = ({
    children
}) => {
    /*********
     * HOOKS *
     *********/

    /*************
     * FUNCTIONS *
     *************/

    /********************
     * RENDER FUNCTIONS *
     ********************/

    /**
     * Main render.
     */
    return (
        <div className={localStyles.banner}>
            <section className={localStyles.content}>
                {children}
            </section>
        </div>
    );
};

Banner.propTypes = {
    /**
     * React components to render in the banner content section.
     */
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

Banner.defaultProps = {
    
};

export default Banner;