import PropTypes from 'prop-types';
import {
    IconButton,
    StickyTooltip,
} from '@psionic/ui';
import {
    CheckCircle,
    CheckCircleOutlined,
    ChatBubbleOutlined,
} from '@mui/icons-material';
import StarterKit from '@tiptap/starter-kit';
import { EditorContent, useEditor } from '@tiptap/react';
import { Reactions } from '@components/core';
import localStyles from './past-priority.module.scss';

/**
 * Replace this with a comment describing the component.
 */
function PastPriority({
    value,
    checked,
    setChecked,
    users,
    reactions,
    onReactionClicked,
    userID,
    onCommentButtonClicked,
}) {

    // #region Constants

    // #endregion

    // #region Misc Hooks

    const editor = useEditor({
        content:    value,
        extensions: [ StarterKit ],
        editable:   false,
    });

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pastPriority}>
            <div className={localStyles.leftSide}>
                <div className={localStyles.upperSection}>
                    <div className={localStyles.customCheckbox}>
                        {
                            checked
                                ? <CheckCircle className={localStyles.doneIcon}/>
                                : <CheckCircleOutlined className={localStyles.notDoneIcon}/>
                        }
                        <input
                            aria-label='priority completed'
                            checked={checked}
                            className={localStyles.actualCheckbox}
                            disabled={Boolean(!setChecked)}
                            type='checkbox'
                            onChange={setChecked ? (event) => setChecked(event.target.checked) : () => {}}
                        />
                    </div>
                    <div className={localStyles.priorityText}>
                        <EditorContent editor={editor}/>
                    </div>
                </div>
                <div className={localStyles.lowerSection}>
                    <Reactions
                        reactions={reactions}
                        userID={userID}
                        users={users}
                        onReactionClicked={onReactionClicked}
                    />
                </div>
            </div>
            <div className={localStyles.rightSide}>
                {
                    onCommentButtonClicked
                        ? (
                            <StickyTooltip content='Comment'>
                                <IconButton
                                    color='lowEmphasis'
                                    SvgIcon={ChatBubbleOutlined}
                                    onClick={onCommentButtonClicked}
                                />
                            </StickyTooltip>
                        )
                        : null
                }
            </div>
        </div>
    );

    // #endregion
}

PastPriority.propTypes = {
    /**
     * Whether the priority is checked off as complete or not.
     */
    checked:                PropTypes.bool,
    /**
     * The callback for when the comment button is clicked.
     */
    onCommentButtonClicked: PropTypes.func,
    /**
     * The callback for when a reaction is clicked.
     */
    onReactionClicked:      PropTypes.func.isRequired,
    /**
     * The reactions data.
     */
    reactions:              PropTypes.shape({
        /**
         * The native emoji icon.
         */
        native: PropTypes.string.isRequired,
        /**
         * The IDs of users who had this reaction.
         */
        users:  PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    /**
     * Callback for when the checkbox to mark the priority as complete is clicked.
     */
    setChecked: PropTypes.func,
    /**
     * The ID of the current user.
     */
    userID:     PropTypes.string,
    /**
     * Array of all users that could potentially be associated with a reaction.
     */
    users:      PropTypes.object.isRequired,
    /**
     * The content of the priority.
     */
    value:      PropTypes.object.isRequired,
};

PastPriority.defaultProps = {
    checked:                false,
    onCommentButtonClicked: undefined,
    reactions:              undefined,
    userID:                 undefined,
    setChecked:             undefined,
};

export default PastPriority;
