import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Business, Save } from '@mui/icons-material';
import {
    ControlledTextField,
    Snackbar,
    Button,
} from '@psionic/ui';
import {
    Autocomplete,
    TextField,
} from '@mui/material';
import TeamContextLevel from '@contexts/team';
import TeamModel from '@models/team';
import {
    uploadTeamLogo,
} from '@services/team';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import {
    UploadAvatarButton,
} from '@components/buttons';
import localStyles from './edit-team-info.module.scss';

/**
 * Edit team info view.
 */
function EditTeamInfo() {
    // #region Hooks

    /**
     * Use the `navigate` function from react-router-dom.
     */
    const navigate = useNavigate();

    /**
     * Use the current team context slice.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Track a local copy of the current team in state.
     */
    const [
        localCurrentTeam,
        // eslint-disable-next-line no-unused-vars
        setLocalCurrentTeam,
    ] = useState({ ...currentTeam });

    /**
     * Track the image file for any new logo that is uploaded.
     */
    const [
        newLogo,
        setNewLogo,
    ] = useState(null);

    const [
        saving,
        setSaving,
    ] = useState(false);

    const [
        timezoneInputValue,
        setTimezoneInputValue,
    ] = useState(localCurrentTeam?.timezone);

    // #endregion

    // #region Functions

    /**
     * Handler for when the team logo changes.
     */
    const onImageChange = (newImage) => {
        setNewLogo(newImage);
    };

    /**
     * Handler for when the cancel button is pressed.
     */
    const onCancelPressed = () => {
        navigate(`/team/${ currentTeam.id }`);
    };

    /**
     * Handler for when the save button is pressed.
     */
    const onSavePressed = async() => {
        setSaving(true);

        // First, we need to save the changes to the profile
        await TeamModel.writeToID(
            currentTeam.id,
            {
                ...localCurrentTeam,
                name:     localCurrentTeam?.name,
                timezone: localCurrentTeam?.timezone?.value,
            },
            { mergeWithExistingValues: true }
        );

        // If a new logo was uploaded, we need to upload it for the team
        if (newLogo) {
            await uploadTeamLogo(currentTeam, newLogo);
        }

        // Navigate back to the home page on a success
        SnackbarService.addSnackbar(
            ({ removeSnackbar }) => { return (
                <Snackbar
                    color='approve'
                    removeSnackbar={removeSnackbar}
                    SvgIcon={Save}
                    text='Team Changes Saved!'
                />
            ); },
            CORE.SNACKBAR_DURATION
        );
        navigate(`/team/${ currentTeam.id }`);
    };

    // #endregion

    // #region Variables

    const nameIsValid = Boolean(localCurrentTeam?.name);

    const timezoneIsValid = Boolean(localCurrentTeam?.timezone);

    const formIsValid = nameIsValid && timezoneIsValid;

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div
            className={[
                'workspacePage',
                localStyles.page,
            ].join(' ')}
        >
            <header>
                <h1>
                    Edit Team Info
                </h1>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.form,
                ].join(' ')}
            >
                <section className={localStyles.topInfo}>
                    <UploadAvatarButton
                        FallbackIcon={Business}
                        imageURL={newLogo ? URL.createObjectURL(newLogo) : localCurrentTeam.avatarURL}
                        size='large'
                        variant='rounded'
                        onImageChange={onImageChange}
                    />
                    <ControlledTextField
                        className={localStyles.textField}
                        label='Team Name'
                        value={localCurrentTeam?.name || ''}
                        darkMode
                        required
                        onChange={(newValue) => setLocalCurrentTeam({ ...localCurrentTeam, name: newValue })}
                    />
                </section>
                <Autocomplete
                    id='timezone-select'
                    inputValue={timezoneInputValue || ''}
                    options={moment.tz.names().map((timeZoneName) => ({ label: timeZoneName, value: timeZoneName }))}
                    value={localCurrentTeam?.timezone?.value || localCurrentTeam?.timezone}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Team Timezone'
                        />
                    )}
                    disablePortal
                    onChange={(event, newValue) => setLocalCurrentTeam({ ...localCurrentTeam, timezone: newValue })}
                    onInputChange={(event, newValue) => setTimezoneInputValue(newValue)}
                />
            </section>
            <section className={localStyles.actions}>
                <Button
                    disabled={saving}
                    variant='text'
                    darkMode
                    onClick={onCancelPressed}
                >
                    Cancel
                </Button>
                <Button
                    disabled={saving || !formIsValid}
                    variant='contained'
                    darkMode
                    onClick={onSavePressed}
                >
                    Save Changes
                </Button>
            </section>
        </div>
    );

    // #endregion
}

export default EditTeamInfo;
