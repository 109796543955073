import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';
import { applyActionCode } from 'firebase/auth';
import { where } from 'firebase/firestore';
import { auth } from '@services/firebase';
import ProfileModel from '@models/profile';

// #region Main Handler

/**
 * Attempt to verify the user's email.
 *
 * @param {Firebase.Auth.OOBCode} actionCode The Firebase auth oobCode
 * @param {string} email The email the user is attempting to verify
 * @returns {Promise<void>} Resolves when the user's email has been verified
 */
export default async function(actionCode, email) {
    try {
        const sanitizedEmail = email.trim().toLowerCase();

        await applyActionCode(auth, actionCode);
        await setEmailVerifiedOnProfile(sanitizedEmail);
    } catch (err) {
        throw ERRORS.factories.COULD_NOT_VERIFY_EMAIL();
    }
}

// #endregion

// #region Categorized Errors

const ERRORS = new CategorizedErrorFactory({
    COULD_NOT_VERIFY_EMAIL: {
        message: 'We could not verify your email at this time.',
    },
});

// #endregion

// #region Helper Functions

/**
 * Attempt to set the user profile's `emailVerified` property to `true` in Firestore.
 *
 * @param {string} email The email the user is attempting to verify
 * @returns {Promise<void>} Resolves when the user profile's `emailVerified` property is set to `true`
 */
async function setEmailVerifiedOnProfile(email) {
    const sanitizedEmail = email.trim().toLowerCase();

    const profiles = await ProfileModel.getByQuery([ where('email', '==', sanitizedEmail) ]);

    if (profiles.length > 0) {
        await ProfileModel.writeToID(
            profiles[ 0 ].id,
            { emailVerified: true },
            { mergeWithExistingValues: true }
        );
    }
}

// #endregion
