import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '@unifire-js/firebase/auth';
import { Error, Check } from '@mui/icons-material';
import {
    Form,
    Button,
    TextField,
    Snackbar,
} from '@psionic/ui';
import { SnackbarService } from '@services/snackbar';
import { updatePassword } from '@services/user';
import { CORE } from '@utils/constants';
import { ReauthenticateDialog } from '@components/dialogs';
import localStyles from './change-password.module.scss';

function ChangePassword() {
    // #region Misc Hooks

    const navigate = useNavigate();

    const { user } = useUserContext();

    // #endregion

    // #region State

    const [
        reauthDialogOpen,
        setReauthDialogOpen,
    ] = useState(false);

    const [
        callbackAfterReauth,
        setCallbackAfterReauth,
    ] = useState(null);

    // #endregion

    // #region Functions

    const onCancel = () => {
        navigate('/user/account-settings');
    };

    const onSubmit = async(formData) => {
        try {
            await updatePassword(
                user,
                formData.newPassword.value
            );
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => (
                    <Snackbar
                        color='approve'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Check}
                        text='Password updated successfully.'
                    />
                ),
                CORE.SNACKBAR_DURATION
            );
            navigate('/user/account-settings');
        } catch (err) {
            if (err.id === 'REQUIRES_RECENT_LOGIN') {
                setCallbackAfterReauth(() => onSubmit(formData));
                setReauthDialogOpen(true);

                return;
            }

            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text={err.message}
                    />
                ),
                CORE.SNACKBAR_DURATION
            );
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            {/* Reauth Dialog */}
            <ReauthenticateDialog
                isOpen={reauthDialogOpen}
                setIsOpen={setReauthDialogOpen}
                onReauthenticationSuccess={callbackAfterReauth || (() => {})}
            />
            {/* Main Content */}
            <div
                className={[
                    'nonWorkspacePage',
                    localStyles.changePassword,
                ].join(' ')}
            >
                <header>
                    <h1>
                        Change Password
                    </h1>
                </header>
                <Form onSubmit={onSubmit}>
                    <div
                        className={[
                            'pageContent',
                            localStyles.newPasswordSection,
                        ].join(' ')}
                    >
                        <TextField
                            className={localStyles.newPasswordInput}
                            fieldKey='newPassword'
                            label='New Password'
                            type='password'
                            validator={(value) => {
                                if (value.length < CORE.MIN_PASSWORD_LENGTH) {
                                    return `Password must be at least ${ CORE.MIN_PASSWORD_LENGTH } characters long`;
                                }

                                return null;
                            }}
                            darkMode
                            required
                        />
                        <TextField
                            className={localStyles.newPasswordInput}
                            fieldKey='newPasswordRepeat'
                            label='New Password (Repeat)'
                            type='password'
                            validator={(value, formData) => {
                                if (value !== formData.newPassword.value) {
                                    return 'Passwords do not match';
                                }

                                return null;
                            }}
                            darkMode
                            required
                        />
                    </div>
                    <div className={localStyles.actions}>
                        <Button
                            variant='text'
                            darkMode
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            darkMode
                        >
                            Change Password
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );

    // #endregion
}

export default ChangePassword;
