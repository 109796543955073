import { Model } from '@unifire-js/firebase/firestore';

const TeamGroupModel = new Model({
    collectionName:  'teamGroups',
    collectionProps: [
        'teamID',
        'name',
        'avatarURL',
        'members',
    ],
    propDefaults: {
        members: [],
    },
});

export default TeamGroupModel;
