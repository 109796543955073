import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Done,
} from '@mui/icons-material';
import localStyles from './stepper-header.module.scss';

/**
 * A stepper's header.
 */
function StepperHeader({
    numSteps,
    currentStep,
    stepTitle,
}) {
    // #region Render Functions

    /**
     * Memoized steps.
     */
    const steps = useMemo(() => {
        const stepRenders = [];
        for (let stepNum = 1; stepNum <= numSteps; stepNum++) {
            let state;
            if (stepNum === currentStep) {
                state = 'active';
            } else if (stepNum < currentStep) {
                state = 'past';
            } else {
                state = 'future';
            }
            stepRenders.push((
                <div
                    key={`step-${stepNum}`}
                    className={localStyles.step}
                    data-state={state}
                >
                    <p>
                        {state !== 'past' ? stepNum : <Done />}
                    </p>
                </div>
            ));
            if (stepNum !== numSteps) {
                stepRenders.push(<div key={`divider-${stepNum}`} className={localStyles.divider} data-state={state} />);
            }
        }
        return stepRenders;
    }, [numSteps, currentStep]);

    /**
     * Main render.
     */
    return (
        <header className={localStyles.stepperHeader}>
            <section className={localStyles.stepIndicators}>
                {steps}
            </section>
            <section className={localStyles.stepTitle}>
                <h5>
                    {stepTitle}
                </h5>
            </section>
        </header>
    );

    // #endregion
}

StepperHeader.propTypes = {
    /**
     * The title of the current step.
     */
    stepTitle:   PropTypes.string.isRequired,
    /**
     * The number of steps in the stepper.
     */
    numSteps:    PropTypes.number.isRequired,
    /**
     * The current step number the user is on (1-indexed).
     */
    currentStep: PropTypes.number.isRequired,
};

StepperHeader.defaultProps = {

};

export default StepperHeader;
