import { useState } from 'react';
import { DateTime } from 'luxon';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    IconButton,
} from '@psionic/ui';
import {
    ArrowBack,
} from '@mui/icons-material';
import CheckInSurveyModel from '@models/check-in-survey';
import RevieweeContextLevel from '@contexts/reviewee';
import { LoadingPage } from '@components/loading';
import ReviewSurvey from '../components/review-survey';
import localStyles from './review-survey-page.module.scss';

function ReviewSurveyPage() {

    // #region Misc Hooks

    const { teamID, surveyID } = useParams();

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const reviewee = RevieweeContextLevel.use.reviewee.value();

    // #endregion

    // #region State

    const [
        survey,
        setSurvey,
    ] = useState(undefined);

    // #endregion

    // #region Effects

    CheckInSurveyModel.useListenerByPath(
        'review-survey-tracker',
        `teams/${ teamID }/checkInSurveys/${ surveyID }`,
        setSurvey
    );

    // #endregion

    // #region Render Functions

    const renderTitle = () => {
        const startDate = DateTime.fromJSDate(survey.startDate).toFormat('MMM d, yyyy');
        const endDate = DateTime.fromJSDate(survey.endDate).toFormat('MMM d, yyyy');

        return (
            <>
                <h1>
                    Survey for
                    {' '}
                    { startDate }
                    {' '}
                    -
                    {' '}
                    { endDate }
                </h1>
                <h2>
                    { reviewee?.displayName || reviewee?.email }
                </h2>
            </>
        );
    };

    const renderNoSurvey = () => {
        return (
            <p className={localStyles.emptyState}>
                No survey was found with the given ID. You may have navigated to an invalid link.
                <br/>
                Please click the back button and select a survey from the list of the reviewee's surveys.
            </p>
        );
    };

    if (survey === undefined) {
        return (
            <LoadingPage/>
        );
    }

    /**
     * Main render.
     */
    return (
        <div
            className={[
                'workspacePage',
                localStyles.workspacePage,
            ].join(' ')}
        >
            <header className={localStyles.header}>
                <IconButton
                    color='white'
                    SvgIcon={ArrowBack}
                    onClick={() => navigate('../../')}
                />
                <div className={localStyles.title}>
                    { survey ? renderTitle() : <h1>Invalid Survey</h1> }
                </div>
            </header>
            <section
                className={[
                    'pageContent',
                    localStyles.pageContent,
                ].join(' ')}
            >
                {
                    survey
                        ? (
                            <ReviewSurvey
                                showHeader={false}
                                survey={survey}
                            />
                        )
                        : renderNoSurvey()
                }
            </section>
        </div>
    );

    // #endregion
}

export default ReviewSurveyPage;
