import {
    EmailAuthProvider,
    reauthenticateWithCredential,
} from 'firebase/auth';
import PropTypes from 'prop-types';
import {
    Dialog,
    Button,
    Form,
    TextField,
    Snackbar,
} from '@psionic/ui';
import {
    Security,
    Error,
} from '@mui/icons-material';
import { useUserContext } from '@unifire-js/firebase/auth';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';

/**
 * Dialog for when an action requires reauthentication from a user before it can be run.
 *
 * Has an imperative handle with the following:
 *
 * - {function} open Open the dialog
 * - {function} close Close the dialog
 *
 * The dialog will automatically be closed when the cancel button is pressed or if the
 * reauthentication and post-reauthentication function succeed.
 */
function ReauthenticateDialog({
    onReauthenticationSuccess,
    isOpen,
    setIsOpen,
}) {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { user } = useUserContext();

    // #endregion

    // #region Functions

    /**
     * Attempt to reauthenticate the user.
     */
    const attemptReauthentication = async(formData) => {
        try {
            const credential = EmailAuthProvider.credential(
                formData?.email?.value,
                formData?.password?.value
            );
            await reauthenticateWithCredential(user, credential);
            await onReauthenticationSuccess();
            setIsOpen(false);
        } catch (err) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='reject'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Error}
                        text={err.message}
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
            setIsOpen(false);
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Dialog
            className='dialog withIcon'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className='leftSide'>
                <Security className='greenIcon'/>
            </div>
            <div className='rightSide'>
                <h1>
                    Requires Reauthentication
                </h1>
                <p>
                    This operation requires a recent login. Please log back in using the form below,
                    and we will attempt the operation again.
                </p>
                <Form
                    onSubmit={attemptReauthentication}
                >
                    <TextField
                        fieldKey='email'
                        label='Email'
                        type='email'
                        darkMode
                        required
                    />
                    <TextField
                        fieldKey='password'
                        label='Password'
                        type='password'
                        darkMode
                        required
                    />
                    <div className='actionSection'>
                        <Button
                            variant='text'
                            darkMode
                            onClick={() => { return setIsOpen(false); }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            darkMode
                        >
                            Reauthenticate
                        </Button>
                    </div>
                </Form>
            </div>
        </Dialog>
    );

    // #endregion
}

ReauthenticateDialog.propTypes = {
    /**
     * Whether the dialog is open.
     */
    isOpen:                    PropTypes.bool.isRequired,
    /**
     * The function to run after reauthentication has succeeded.
     */
    onReauthenticationSuccess: PropTypes.func.isRequired,
    /**
     * Function to set whether the dialog is open.
     */
    setIsOpen:                 PropTypes.func.isRequired,
};

export default ReauthenticateDialog;
