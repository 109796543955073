import { Submodel } from '@unifire-js/firebase/firestore';
import DayModel from '@models/day';

const MinuteModel = new Submodel({
    collectionName:  'minutes',
    parent:          DayModel,
    collectionProps: [
        'message',
        'owner',
        'requires',
        'reviewed',
        'priority',
        'agendaID',
        'dateToReview',
    ],
    propDefaults: {
        requires: [],
        reviewed: false,
    },
});

export default MinuteModel;
