import { useMemo, useState, useEffect } from 'react';
import { useUserContext } from '@unifire-js/firebase/auth';
import { IconButton } from '@mui/material';
import {
    StickyTooltip,
    Dialog,
    Button,
    CircularSpinner,
} from '@psionic/ui';
import {
    ArrowBack,
    Close,
    PersonRemove,
} from '@mui/icons-material';
import TeamContextLevel from '@contexts/team';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import {
    removeVote,
    getUserVotingInfo,
    kickUser,
} from '@services/bidding-room';
import { BiddingRoomLayout } from '@components/page-layouts';
import { BiddingCard } from '@components/cards';
import { UserInfo } from '@components/details';
import localStyles from './awaiting-results.module.scss';

/**
 * View for users awaiting the results of the bidding process.
 */
function AwaitingResults() {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the "has admin permissions" context value.
     */
    const hasAdminPermissions = TeamContextLevel.use.hasAdminPermissions.value();

    /**
     * Use the bidding room public info context value.
     */
    const biddingRoomPublicInfo = BiddingRoomContextLevel.use.biddingRoomPublicInfo.value();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the bid card context value.
     */
    const bidCard = BiddingRoomContextLevel.use.bidCard.value();

    /**
     * Use the bid card context API.
     */
    const bidCardContextAPI = BiddingRoomContextLevel.use.bidCard.api();

    /**
     * Memoized user voting info.
     */
    const userVotingInfo = useMemo(() => {
        return getUserVotingInfo(biddingRoomPublicInfo.users);
    }, [ biddingRoomPublicInfo ]);

    /**
     * Memoize the signed-in user's info from the room's public info.
     */
    const userPublicInfo = useMemo(() => {
        // If the data isn't loaded yet, return null
        if (!profile || !biddingRoomPublicInfo?.users) {
            return null;
        }

        // Otherwise, return the user's data
        return biddingRoomPublicInfo.users[ profile.id ];
    }, [
        profile,
        biddingRoomPublicInfo,
    ]);

    /**
     * Track whether the confirm kick user dialog is open.
     */
    const [
        confirmKickDialogOpen,
        setConfirmKickDialogOpen,
    ] = useState(false);

    /**
     * Track the user that was selected to be kicked.
     */
    const [
        kickingUser,
        setKickingUser,
    ] = useState(null);

    /**
     * When the component unmounts, reset the bid card context.
     */
    useEffect(() => { return bidCardContextAPI.reset; }, []);

    // #endregion

    // #region Functions

    /**
     * Handler for when the user clicks the back button.
     */
    const onBackButtonClicked = () => {
        if (profile?.id && currentTeam?.id) {
            removeVote(profile.id, currentTeam.id);
            bidCardContextAPI.set(null);
        }
    };

    /**
     * Handler for when the user clicks the kick button for a user.
     */
    const onKickButtonClicked = (profileToKick) => {
        setKickingUser(profileToKick);
        setConfirmKickDialogOpen(true);
    };

    /**
     * Handler for when the kick user action is confirmed.
     */
    const handleConfirmKickUser = async() => {
        if (kickingUser?.id && currentTeam?.id) {
            await kickUser(kickingUser.id, currentTeam.id);
        }
        setKickingUser(null);
        setConfirmKickDialogOpen(false);
    };

    /**
     * Handler for when the kick user action is cancelled.
     */
    const handleCancelKickingUser = () => {
        setKickingUser(null);
        setConfirmKickDialogOpen(false);
    };

    // #endregion

    // #region Render Functions

    /**
     * Memoized list of users in the lobby (not including the signed-in user, themself).
     */
    const userListItems = useMemo(() => {
        const listItems = [];

        for (const biddingUser of Object.values(biddingRoomPublicInfo?.users || {})) {
            // If the user is the currently signed-in user, don't render them here
            if (biddingUser?.profile?.id === profile?.id) {
                continue;
            }

            // If the user has been kicked, don't render them here
            if (biddingUser?.kicked) {
                continue;
            }

            // Get the user's status
            let status = null;

            if (biddingUser?.voted) {
                status = 'voted';
            }

            if (biddingUser?.observerMode) {
                status = 'afk';
            }

            // Otherwise, create the list item
            listItems.push(
                <li key={biddingUser?.profile?.id}>
                    <div>
                        <UserInfo
                            profile={biddingUser?.profile}
                            status={status}
                        />
                        {
                            hasAdminPermissions
                                ? (
                                    <StickyTooltip content='Kick User'>
                                        <IconButton
                                            color='reject'
                                            onClick={() => onKickButtonClicked(biddingUser?.profile)}
                                        >
                                            <Close/>
                                        </IconButton>
                                    </StickyTooltip>
                                )
                                : null
                        }
                    </div>
                </li>
            );
        }

        return listItems;
    }, [ biddingRoomPublicInfo ]);

    /**
     * If the user voting info states that all users have voted, and at least one user voted, then
     * display a loading page while we wait for FF to proceed to the next phase.
     */
    if (userVotingInfo.allUsersVoted && userVotingInfo.atLeastOneUserVoted) {
        return (
            <div className={localStyles.loading}>
                <CircularSpinner size={120}/>
            </div>
        );
    }

    /**
     * Main render.
     */
    return (
        <>
            {/* DIALOGS */}
            <Dialog
                className='dialog withIcon'
                isOpen={confirmKickDialogOpen}
                setIsOpen={setConfirmKickDialogOpen}
            >
                <div className='leftSide'>
                    <PersonRemove className='redIcon'/>
                </div>
                <div className='rightSide'>
                    <h1>
                        Are you sure?
                    </h1>
                    <p>
                        Are you sure you want to kick
                        {' '}
                        {kickingUser?.displayName || kickingUser?.email || 'this user'}
                        {' '}
                        from the room? They can join back whenever they'd like.
                    </p>
                    <div className='actionSection'>
                        <Button
                            variant='text'
                            darkMode
                            onClick={handleCancelKickingUser}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            darkMode
                            onClick={handleConfirmKickUser}
                        >
                            Kick User
                        </Button>
                    </div>
                </div>
            </Dialog>
            {/* MAIN PAGE */}
            <BiddingRoomLayout
                hasAdminPermissions={hasAdminPermissions}
                title='Waiting for Teammates'
            >
                {
                    bidCard
                        ? (
                            <section className={localStyles.yourVote}>
                                <header>
                                    <StickyTooltip content='Select Different Bid'>
                                        <IconButton
                                            color='primary'
                                            onClick={onBackButtonClicked}
                                        >
                                            <ArrowBack/>
                                        </IconButton>
                                    </StickyTooltip>
                                    <h5>
                                        Your Vote
                                    </h5>
                                </header>
                                <BiddingCard
                                    card={bidCard}
                                    sizeRatio={0.5}
                                />
                            </section>
                        )
                        : null
                }
                <section className={localStyles.usersList}>
                    <ul>
                        <li>
                            <UserInfo
                                profile={profile}
                                status={userPublicInfo?.voted ? 'voted' : 'afk'}
                            />
                        </li>
                        {userListItems}
                    </ul>
                </section>
            </BiddingRoomLayout>
        </>
    );

    // #endregion
}

export default AwaitingResults;
