import { Model } from '@unifire-js/firebase/firestore';

const TeamModel = new Model({
    collectionName:  'teams',
    collectionProps: [
        'name',
        'avatarURL',
        'trialUsed',
        'timezone',
        'aiRequestsThisMonth',
        'aiRequestLimitStartDate',
    ],
    propDefaults: {
        trialUsed:  false,
        subscribed: false,
    },
});

export default TeamModel;
