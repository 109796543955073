import { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularSpinner } from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import CheckInSurveyQuestionResponseModel from '@models/check-in-survey-question-response';
import { Question } from '@components/check-in';
import ConnectedQuestionResponse from './connected-question-response';
import localStyles from './connected-question.module.scss';

function ConnectedQuestion({
    question,
    profile,
    teamMembers,
    reviewers,
    surveyOwner,
}) {

    // #region Context

    const team = TeamContextLevel.use.currentTeam.value();

    // #endregion

    // #region State

    const [
        responses,
        setResponses,
    ] = useState(null);

    // #endregion

    // #region Effects

    /**
     * Track the responses for this question.
     */
    CheckInSurveyQuestionResponseModel.useListenerByQueryInInstance(
        `question-${ question.id }-responses`,
        `teams/${ team.id }/checkInSurveys/${ question.surveyID }/checkInSurveyQuestions/${ question.id }/checkInSurveyQuestionResponses`,
        [],
        setResponses
    );

    // #endregion

    // #region Render Functions

    const renderQuestionResponses = () => {
        const renders = [];

        for (const response of responses) {
            renders.push(
                <ConnectedQuestionResponse
                    profile={profile}
                    question={question}
                    response={response}
                    reviewers={reviewers}
                    surveyOwner={surveyOwner}
                    teamMembers={teamMembers}
                />
            );
        }

        return renders;
    };

    /**
     * If the responses haven't been fetched yet, return a loading state.
     */
    if (!responses) {
        return <CircularSpinner/>;
    }

    /**
     * Main render.
     */
    return (
        <div className={localStyles.connectedQuestion}>
            <Question
                isOptional={question.isOptional}
                listItems={renderQuestionResponses()}
                question={question.question}
            />
            {
                responses.length === 0
                    ? (
                        <p className={localStyles.emptyState}>
                            No responses.
                        </p>
                    )
                    : null
            }
        </div>
    );

    // #endregion
}

ConnectedQuestion.propTypes = {
    /**
     * The corresponding question the response was for.
     */
    question: PropTypes.object.isRequired,
    /**
     * The current user's profile.
     */
    profile:  PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * The team members profiles.
     */
    teamMembers: PropTypes.object.isRequired,
    /**
     * The reviewers profiles.
     */
    reviewers:   PropTypes.array.isRequired,
    /**
     * The survey owner's profile.
     */
    surveyOwner: PropTypes.object.isRequired,
};

ConnectedQuestion.defaultProps = {

};

export default ConnectedQuestion;
