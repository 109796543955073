import PropTypes from 'prop-types';
import { UserAvatar } from '@components/avatars';
import localStyles from './reviewers-info.module.scss';

/**
 * Displays a list of reviewers.
 */
function ReviewersInfo({
    reviewers,
}) {

    // #region Constants

    // #endregion

    // #region State

    // #endregion

    // #region Effects

    // #endregion

    // #region Functions

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.reviewersInfo}>
            <h3>
                Reviewers
            </h3>
            {
                reviewers.length === 0
                    ? (
                        <p className={localStyles.emptyState}>
                            No reviewers have been assigned to this check-in.
                        </p>
                    )
                    : (
                        <ul>
                            {reviewers.map((reviewer) => (
                                <li key={reviewer?.id}>
                                    <UserAvatar
                                        imageURL={reviewer?.avatarURL}
                                        size='small'
                                        userEmail={reviewer?.email}
                                        userName={reviewer?.displayName}
                                    />
                                </li>
                            ))}
                        </ul>
                    )
            }
        </div>
    );

    // #endregion
}

ReviewersInfo.propTypes = {
    /**
     * The reviewers to display.
     */
    reviewers: PropTypes.arrayOf(PropTypes.object),
};

ReviewersInfo.defaultProps = {
    reviewers: [],
};

export default ReviewersInfo;
