import PropTypes from 'prop-types';
import localStyles from './action-header.module.scss';

/**
 * Action header menu.
 */
const ActionHeader = ({
    actionButtons,
}) => {
    /*********
     * HOOKS *
     *********/

    /*************
     * FUNCTIONS *
     *************/

    /********************
     * RENDER FUNCTIONS *
     ********************/

    /**
     * Action button list items.
     */
    const actionButtonListItems = () => {
        const listItems = [];
        let i = 0;
        for (const actionButton of actionButtons) {
            listItems.push(
                <li key={`action-button-${i++}`}>
                    {actionButton}
                </li>
            );
        }
        return listItems;
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.actionHeader}>
            <ul>
                {actionButtonListItems()}
            </ul>
        </div>
    );
};

ActionHeader.propTypes = {
    /**
     * Array of action buttons to render in the menu.
     */
    actionButtons: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    ).isRequired,
};

ActionHeader.defaultProps = {

};

export default ActionHeader;