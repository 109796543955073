import { OPTION_TYPES } from '@utils/constants';
import TeamContextLevel from '@contexts/team';
import GroupQuestionsAccordion from './components/group-questions-accordion';
import localStyles from './questions-tab.module.scss';

/**
 * The questions tab for the manage check-in service page.
 */
function QuestionsTab() {

    // #region Context

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const team = TeamContextLevel.use.currentTeam.value();

    // #endregion

    // #region Render Functions

    const renderGroupAccordions = () => {
        const accordions = [];

        accordions.push(
            <GroupQuestionsAccordion
                key={OPTION_TYPES.ALL}
                group={{ id: OPTION_TYPES.ALL }}
                team={team}
            />
        );

        for (const group of teamGroups) {
            accordions.push(
                <GroupQuestionsAccordion
                    key={group.id}
                    group={group}
                    team={team}
                />
            );
        }

        return accordions;
    };

    return (
        <div className={localStyles.questionsTab}>
            <p className={localStyles.noteOnChanges}>
                Changes to questions will not affect any surveys that have already been sent out to your team.
            </p>
            <ul className={localStyles.groupsList}>
                {renderGroupAccordions()}
            </ul>
        </div>
    );

    // #endregion
}

export default QuestionsTab;
