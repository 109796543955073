// #region Public Functions

/**
 * Formats the given duration to the largest unit of time that is greater than 0.
 * The smallest unit it will go to is minutes.
 *
 * @param {Duration} duration The luxon duration object to format
 * @returns {string} The formatted duration
 */
export function formatToLargestUnit(duration) {
    const days = Math.abs(duration.days);
    const hours = Math.abs(duration.hours);
    const minutes = Math.abs(duration.minutes);

    if (days >= 1) {
        return `${ Math.floor(days) } day${ days > 1 ? 's' : '' }`;
    } else if (hours >= 1) {
        return `${ Math.floor(hours) } hour${ hours > 1 ? 's' : '' }`;
    } else {
        return `${ Math.floor(minutes) } minute${ minutes > 1 ? 's' : '' }`;
    }
}

// #endregion

// #region Private Functions

// #endregion
