import { useState, useRef } from 'react';
import { emit } from '@psionic/emit';
import { where } from 'firebase/firestore';
import { runTransaction } from '@unifire-js/firebase/firestore';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import {
    Button,
} from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import TeamCheckInContextLevel from '@contexts/team-check-in';
import CheckInReviewerAssociationModel from '@models/check-in-reviewer-association';
import GroupReviewerInput from './components/group-reviewer-input';
import localStyles from './reviewer-assignment.module.scss';

function ReviewerAssignmentTab() {

    // #region Context

    const team = TeamContextLevel.use.currentTeam.value();

    const teamGroups = TeamContextLevel.use.teamGroups.value();

    const reviewerAssociations = TeamCheckInContextLevel.use.checkInReviewerAssociations.value();

    // #endregion

    // #region Refs

    const editedAssociationsByGroupRef = useRef({});

    // #endregion

    // #region State

    const [
        changesMade,
        setChangesMade,
    ] = useState(false);

    const [
        savingChanges,
        setSavingChanges,
    ] = useState(false);

    // #endregion

    // #region Functions

    const saveChanges = async() => {
        setSavingChanges(true);

        await runTransaction(async(transaction) => {
            // Create any associations that don't exist yet
            await Promise.all(
                map(
                    editedAssociationsByGroupRef?.current || {},
                    async(reviewerIDs, groupID) => {
                        await Promise.all(
                            map(
                                reviewerIDs,
                                async(reviewerID) => {
                                    const existingAssociation = await CheckInReviewerAssociationModel
                                        .getByQueryInInstance(
                                            `teams/${ team?.id }/checkInReviewerAssociations`,
                                            [
                                                where('groupID', '==', groupID),
                                                where('reviewerID', '==', reviewerID.value),
                                            ],
                                            { transaction }
                                        );

                                    if (existingAssociation?.length <= 0) {
                                        await CheckInReviewerAssociationModel.writeToNewDoc(
                                            `teams/${ team?.id }/checkInReviewerAssociations`,
                                            { groupID, reviewerID: reviewerID.value },
                                            { transaction }
                                        );
                                    }
                                }
                            )
                        );
                    }
                )
            );

            // Delete any associations that shouldn't exist anymore
            const existingAssociationsToDelete = filter(
                reviewerAssociations,
                (reviewerAssociation) => {
                    const editedReviewerAssociationsForGroup = editedAssociationsByGroupRef
                        ?.current[ reviewerAssociation.groupID ];

                    return !find(
                        editedReviewerAssociationsForGroup,
                        (editedReviewerAssociation) => {
                            return editedReviewerAssociation?.value === reviewerAssociation.reviewerID;
                        }
                    );
                }
            );

            await Promise.all(
                map(
                    existingAssociationsToDelete,
                    async(existingAssociationToDelete) => {
                        await CheckInReviewerAssociationModel.deleteByPath(
                            `teams/${ team?.id }/checkInReviewerAssociations/${ existingAssociationToDelete?.id }`,
                            { transaction }
                        );
                    }
                )
            );
        });

        setSavingChanges(false);
        setChangesMade(false);
    };

    const discardChanges = () => {
        emit('reviewer-associations-changes-discarded');
        setChangesMade(false);
    };

    // #endregion

    // #region Render Functions

    const renderGroupInputs = () => {
        const renders = [];

        for (const group of teamGroups) {
            renders.push(
                <li key={`group-${ group.id }`}>
                    <GroupReviewerInput
                        editedAssociationsByGroupRef={editedAssociationsByGroupRef}
                        group={group}
                        markChangesMade={() => setChangesMade(true)}
                    />
                </li>
            );
        }

        return renders;
    };

    return (
        <div className={localStyles.reviewerAssignmentTab}>
            <div className={localStyles.notesSection}>
                <p className={localStyles.noteOnChanges}>
                    Changes to reviewer assignments WILL take effect immediately once saved.
                </p>
                <p className={localStyles.noteOnChanges}>
                    Reviewers will not be asked questions from the groups they are assigned to review, even if they
                    are also a member of that group.
                </p>
            </div>
            <ul className={localStyles.groupsList}>
                {renderGroupInputs()}
            </ul>
            <div className={localStyles.actions}>
                <Button
                    color='reject'
                    disabled={!changesMade || savingChanges}
                    variant='text'
                    darkMode
                    onClick={discardChanges}
                >
                    Discard
                </Button>
                <Button
                    color='primary'
                    disabled={!changesMade || savingChanges}
                    variant='contained'
                    darkMode
                    onClick={saveChanges}
                >
                    Save
                </Button>
            </div>
        </div>
    );

    // #endregion
}

export default ReviewerAssignmentTab;
