/* eslint-disable no-magic-numbers */
/* eslint-disable no-bitwise */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
} from '@mui/material';
import { Business } from '@mui/icons-material';
import localStyles from './team-avatar.module.scss';

/**
 * Avatar representing a team.
 */
function TeamAvatar({
    teamName,
    imageURL,
    size,
}) {
    // #region Hooks

    /**
     * Memoized color based off of the team's name.
     */
    const avatarColor = useMemo(() => {
        if (!teamName) {
            return null;
        }

        const trimmedTeamName = teamName.trim();

        let hash = 0;
        // eslint-disable-next-line id-length
        let i;

        for (i = 0; i < trimmedTeamName.length; i += 1) {
            hash = trimmedTeamName.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${ value.toString(16) }`.slice(-2);
        }

        return color;
    }, [ teamName ]);

    /**
     * Memoized props to pass to the avatar.
     */
    const avatarProps = useMemo(() => {
        const noInitialsProps = {
            sx: {
                bgcolor: '#aaaaaa',
            },
            children: <Business className={localStyles.noTeamInfoIcon}/>,
        };

        const trimmedTeamName = teamName ? teamName.trim() : null;

        if (!trimmedTeamName) {
            return noInitialsProps;
        }

        const splitTeamName = trimmedTeamName.split(' ');
        const firstInitial = splitTeamName[ 0 ][ 0 ].toUpperCase();
        let secondInitial = splitTeamName.length > 1 ? splitTeamName[ 1 ][ 0 ] : '';
        secondInitial = secondInitial === undefined ? '' : secondInitial.toUpperCase();

        return {
            sx: {
                bgcolor: avatarColor,
            },
            children: `${ firstInitial }${ secondInitial }`,
        };
    }, [
        teamName,
        avatarColor,
    ]);

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <Avatar
            className={localStyles.teamAvatar}
            data-size={size}
            src={imageURL}
            variant='rounded'
            {...avatarProps}
        />
    );

    // #endregion
}

TeamAvatar.propTypes = {
    /**
     * The team's picture URL.
     */
    imageURL: PropTypes.string,
    /**
     * The size of the avatar.
     */
    size:     PropTypes.oneOf([
        'extra-extra-small',
        'extra-small',
        'small',
        'medium',
        'large',
    ]),
    /**
     * The team's name.
     */
    teamName: PropTypes.string,
};

TeamAvatar.defaultProps = {
    size:     'small',
    imageURL: undefined,
    teamName: undefined,
};

export default TeamAvatar;
