import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import find from 'lodash/find';
import { Save } from '@mui/icons-material';
import { Autobatcher } from '@unifire-js/firebase/firestore';
import {
    Form,
    Button,
    Snackbar,
} from '@psionic/ui';
import TeamContextLevel from '@contexts/team';
import FeatureConfigModel from '@models/feature-config';
import { CORE } from '@utils/constants';
import { SnackbarService } from '@services/snackbar';
import {
    FeatureControl,
} from '@components/controls';
import localStyles from './configure-features.module.scss';

function ConfigureFeatures() {
    // #region Constants

    const FEATURE_KEYS = {
        BULLETIN_BOARD: 'bulletinBoard',
        AGENDA:         'agenda',
        TASK_BIDDING:   'taskBidding',
        CHECK_IN:       'checkIn',
    };

    // #endregion

    // #region Misc Hooks

    const navigate = useNavigate();

    // #endregion

    // #region Context

    const teamSubscription = TeamContextLevel.use.teamSubscription.value();

    const teamFeatureConfigs = TeamContextLevel.use.featureConfigs.value();

    const currentTeam = TeamContextLevel.use.currentTeam.value();

    // #endregion

    // #region State

    const [
        changesMade,
        setChangesMade,
    ] = useState(false);

    const [
        formInitialized,
        setFormInitialized,
    ] = useState(false);

    // #endregion

    // #region Functions

    const markChangesMade = () => {
        // The first time the form update comes in, it is only initializing itself
        if (formInitialized) {
            setChangesMade(true);
        } else {
            setFormInitialized(true);
        }
    };

    const isFeatureConfigEnabled = (key) => {
        return find(
            teamFeatureConfigs,
            [
                'id',
                key,
            ]
        )?.enabled;
    };

    const onCancel = () => {
        navigate('../../');
    };

    const onSave = async(formData) => {
        let nothingUpdated = true;
        const autobatcher = new Autobatcher();

        for (const key of Object.values(FEATURE_KEYS)) {
            const featureNowEnabled = formData[ key ]?.checked;

            // If the feature's enabled / disabled state has updated, save it to the database
            if (featureNowEnabled !== isFeatureConfigEnabled(key)) {
                nothingUpdated = false;
                FeatureConfigModel.writeToPath(
                    `teams/${ currentTeam.id }/featureConfigs/${ key }`,
                    { enabled: featureNowEnabled },
                    { autobatcher }
                );
            }
        }

        if (!nothingUpdated) {
            await autobatcher.allBatchesFinalized();
        }

        SnackbarService.addSnackbar(
            ({ removeSnackbar }) => {
                return (
                    <Snackbar
                        color="approve"
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Save}
                        text={`Successfully saved feature configurations!`}
                    />
                );
            },
            CORE.SNACKBAR_DURATION
        );

        setChangesMade(false);
    };

    // #endregion

    // #region Render Functions

    return (
        <div className='workspacePage'>
            <header>
                <h1>
                    Configure Features
                </h1>
            </header>
            <Form
                onChange={markChangesMade}
                onSubmit={onSave}
            >
                <div className='pageContent'>
                    <div className={localStyles.blurbs}>
                        <p className={localStyles.formBlurb}>
                            Configure the features made available to your team. Enabled features will show up under
                            the "Solutions" tab in the left navigation bar for your team.
                        </p>
                        {
                            teamSubscription
                                ? null
                                : (
                                    <p className={localStyles.noSubscriptionBlurb}>
                                        Features highlighted in red require a premium subscription.
                                    </p>
                                )
                        }
                    </div>
                    <ul className={localStyles.featureConfigurations}>
                        <li>
                            <FeatureControl
                                featureDescription='Create a bulletin board for your team where you can track important information that should be visible to everyone on your team.'
                                featureName='Bulletin Board'
                                initiallyEnabled={isFeatureConfigEnabled('bulletinBoard')}
                                switchFieldKey='bulletinBoard'
                            />
                        </li>
                        <li>
                            <FeatureControl
                                disabledByPlan={!teamSubscription}
                                featureDescription='Create agendas for your team. Track the discussion of agenda items and create action items based on the discussion.'
                                featureName='Agenda'
                                initiallyEnabled={isFeatureConfigEnabled('agenda')}
                                switchFieldKey='agenda'
                            />
                        </li>
                        <li>
                            <FeatureControl
                                disabledByPlan={!teamSubscription}
                                featureDescription='Create check-in questionnaires for your team. Check-in questionnaires allow you to ask your team members a series of questions on a regular basis to see how they are feeling at work.'
                                featureName='Check-In'
                                initiallyEnabled={isFeatureConfigEnabled('checkIn')}
                                switchFieldKey='checkIn'
                            />
                        </li>
                        <li>
                            <FeatureControl
                                disabledByPlan={!teamSubscription}
                                featureDescription='Host task bidding sessions with your team. Task bidding sessions allow your team to anonymously vote on the level of effort required for upcoming tasks, and are typically used in a SCRUM environment.'
                                featureName='Task Bidding'
                                initiallyEnabled={isFeatureConfigEnabled('taskBidding')}
                                switchFieldKey='taskBidding'
                            />
                        </li>
                    </ul>
                </div>
                <div className={localStyles.actionSection}>
                    <Button
                        color='white'
                        variant='text'
                        darkMode
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!changesMade}
                        type='submit'
                        variant='contained'
                        darkMode
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </div>
    );

    // #endregion
}

export default ConfigureFeatures;
