import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    where,
    orderBy,
    collection,
    doc,
} from 'firebase/firestore';
import {
    IconButton,
} from '@psionic/ui';
import StarterKit from '@tiptap/starter-kit';
import { EditorContent, useEditor } from '@tiptap/react';
import { ChatBubble } from '@mui/icons-material';
import { firestore } from '@services/firebase';
import { postComment } from '@services/check-in';
import CommentModel from '@models/comment';
import ReactionModel from '@models/reaction';
import TeamContextLevel from '@contexts/team';
import { useFormattedReactions } from '@hooks/reactions';
import { onReactionClickedFactory } from '@utils/reactions';
import {
    CommentInput,
    Reactions,
} from '@components/core';
import ExistingCommentControl from './existing-comment-control';
import localStyles from './connected-question-response.module.scss';

function ConnectedQuestionResponse({
    question,
    response,
    profile,
    teamMembers,
    reviewers,
    surveyOwner,
}) {

    // #region Misc Hooks

    const editor = useEditor({
        content:    response.content,
        extensions: [ StarterKit ],
        editable:   false,
    });

    // #endregion

    // #region Context

    const team = TeamContextLevel.use.currentTeam.value();

    // #endregion

    // #region State

    const [
        comments,
        setComments,
    ] = useState(null);

    const [
        reactions,
        setReactions,
    ] = useState(null);

    const [
        comment,
        setComment,
    ] = useState('');

    const [
        commentSectionOpen,
        setCommentSectionOpen,
    ] = useState(undefined);

    // #endregion

    // #region Effects

    /**
     * If there is at least 1 existing comment, open the comment section.
     */
    useEffect(() => {
        if (comments?.length > 0 && commentSectionOpen === undefined) {
            setCommentSectionOpen(true);
        }
    }, [ comments ]);

    /**
     * Track the comments for this question.
     */
    CommentModel.useListenerByQuery(
        `question-response-${ response.id }-comments`,
        [
            where(
                'associatedData',
                '==',
                doc(collection(firestore, `teams/${ team.id }/checkInSurveys/${ question.surveyID }/checkInSurveyQuestions/${ question.id }/checkInSurveyQuestionResponses`), response.id)
            ),
            orderBy('dateCreated'),
        ],
        setComments
    );

    /**
     * Track the reactions for this question.
     */
    ReactionModel.useListenerByQuery(
        `question-response-${ response.id }-reactions-listener`,
        [ where(
            'associatedData',
            '==',
            doc(collection(firestore, `teams/${ team.id }/checkInSurveys/${ question.surveyID }/checkInSurveyQuestions/${ question.id }/checkInSurveyQuestionResponses`), response.id)
        ) ],
        setReactions
    );

    // #endregion

    // #region Memoized Values

    const formattedReactions = useFormattedReactions(reactions);

    // #endregion

    // #region Functions

    const onPostCommentClicked = async() => {
        const content = comment;
        setComment('');

        await postComment(
            doc(collection(firestore, `teams/${ team.id }/checkInSurveys/${ question.surveyID }/checkInSurveyQuestions/${ question.id }/checkInSurveyQuestionResponses`), response.id),
            reviewers,
            profile,
            content,
            team.id,
            question.surveyID,
            surveyOwner
        );
    };

    const onReactionClicked = onReactionClickedFactory(
        doc(collection(firestore, `teams/${ team.id }/checkInSurveys/${ question.surveyID }/checkInSurveyQuestions/${ question.id }/checkInSurveyQuestionResponses`), response.id),
        profile
    );

    // #endregion

    // #region Render Functions

    const renderExistingComments = () => {
        const renders = [];

        for (const existingComment of comments) {
            renders.push(
                <ExistingCommentControl
                    key={`${ existingComment.id }-${ new Date() }`}
                    comment={existingComment}
                    profile={profile}
                    surveyID={question.surveyID}
                    teamID={team.id}
                    teamMembers={teamMembers}
                />
            );
        }

        return renders;
    };

    /**
     * Main render.
     */
    return (
        <div className={localStyles.connectedQuestion}>
            <div className={localStyles.mainContent}>
                <div className={localStyles.responseText}>
                    <EditorContent editor={editor}/>
                </div>
                <div className={localStyles.iconButton}>
                    <IconButton
                        color='lowEmphasis'
                        SvgIcon={ChatBubble}
                        onClick={() => setCommentSectionOpen((prev) => !prev)}
                    />
                </div>
            </div>
            <Reactions
                reactions={formattedReactions}
                userID={profile.id}
                users={teamMembers}
                onReactionClicked={onReactionClicked}
            />
            {
                commentSectionOpen
                    ? (
                        <div className={localStyles.commentSection}>
                            {renderExistingComments()}
                            <CommentInput
                                key={comments.length}
                                id={`question-response-${ response.id }-new-comment-input`}
                                setValue={setComment}
                                user={profile}
                                value={comment}
                                onSaveClicked={onPostCommentClicked}
                            />
                        </div>
                    )
                    : null
            }
        </div>
    );

    // #endregion
}

ConnectedQuestionResponse.propTypes = {
    /**
     * The corresponding question the response was for.
     */
    question: PropTypes.object.isRequired,
    /**
     * The response to display.
     */
    response: PropTypes.object.isRequired,
    /**
     * The current user's profile.
     */
    profile:  PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    /**
     * The team members profiles.
     */
    teamMembers: PropTypes.object.isRequired,
    /**
     * The reviewers profiles.
     */
    reviewers:   PropTypes.array.isRequired,
    /**
     * The survey owner's profile.
     */
    surveyOwner: PropTypes.object.isRequired,
};

ConnectedQuestionResponse.defaultProps = {

};

export default ConnectedQuestionResponse;
