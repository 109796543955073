import { Model } from '@unifire-js/firebase/firestore';

const CommentModel = new Model({
    collectionName:  'comments',
    collectionProps: [
        'associatedData',
        'authorID',
        'content',
        'dateCreated',
    ],
});

export default CommentModel;
