import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularSpinner } from '@psionic/ui';
import { motion } from 'framer-motion';
import localStyles from './loading-page.module.scss';

/**
 * A component for displaying a loading indicator while content is loading.
 */
function LoadingPage({
    displayRefreshTip,
}) {
    // #region Hooks

    /**
     * Track the help text class name in state.
     */
    const [helpTextClassName, setHelpTextClassName] = useState(localStyles.helpTextInvisible);

    /**
     * When the component mounts, start a timer that will change the help text class
     * name after 2 seconds.
     */
    useEffect(() => {
        const displayHelpTextTimeout = setTimeout(() => {
            if (displayRefreshTip) {
                setHelpTextClassName(localStyles.helpTextVisible);
            }
        }, 2000);
        // On the cleanup, clear the timeout function
        return () => clearTimeout(displayHelpTextTimeout);
    }, []);

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <div className={localStyles.pageWrapper}>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                    duration: 0.25,
                }}
            >
                <CircularSpinner size={100} />
                <h5 className={helpTextClassName}>
                    If this gets stuck, try refreshing the page!
                </h5>
            </motion.div>
        </div>
    );

    // #endregion
}

LoadingPage.propTypes = {
    /**
     * Flag indicating whether the "refresh if this page is stuck" tip will be shown after some time.
     */
    displayRefreshTip: PropTypes.bool,
};

LoadingPage.defaultProps = {
    displayRefreshTip: true,
};

export default LoadingPage;
