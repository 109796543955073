import {
    getAuth,
    sendPasswordResetEmail,
} from 'firebase/auth';
import { useUserContext } from '@unifire-js/firebase/auth';
import {
    Form,
    TextField,
    Button,
    Snackbar,
} from '@psionic/ui';
import {
    Check,
    Error,
} from '@mui/icons-material';
import { SnackbarService } from '@services/snackbar';
import { validateEmail } from '@utils/validation';
import { CORE } from '@utils/constants';
import {
    LandingPageHeader,
    Footer,
} from '@components/core';
import localStyles from './send-password-reset.module.scss';

/**
 * View for sending the password reset request.
 */
function SendPasswordReset() {
    // #region Hooks

    /**
     * Use the user context.
     */
    const { profile, user } = useUserContext();

    // #endregion

    // #region Functions

    const sendResetEmail = async(formData) => {
        // If the email value is empty, return early
        if (!formData?.email?.value) {
            return;
        }

        // Send the password reset email
        try {
            await sendPasswordResetEmail(
                getAuth(),
                formData.email.value
            );
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => { return (
                    <Snackbar
                        color='approve'
                        removeSnackbar={removeSnackbar}
                        SvgIcon={Check}
                        text={SNACKBAR_MESSAGES.SUCCESS}
                    />
                ); },
                CORE.SNACKBAR_DURATION
            );
        } catch (err) {
            switch (err?.code) {
                case 'auth/user-not-found':
                    SnackbarService.addSnackbar(
                        ({ removeSnackbar }) => { return (
                            <Snackbar
                                color='approve'
                                removeSnackbar={removeSnackbar}
                                SvgIcon={Check}
                                text={SNACKBAR_MESSAGES.SUCCESS}
                            />
                        ); },
                        CORE.SNACKBAR_DURATION
                    );
                    break;
                default:
                    SnackbarService.addSnackbar(
                        ({ removeSnackbar }) => { return (
                            <Snackbar
                                color='reject'
                                removeSnackbar={removeSnackbar}
                                SvgIcon={Error}
                                text={SNACKBAR_MESSAGES.FAILURE}
                            />
                        ); },
                        CORE.SNACKBAR_DURATION
                    );
            }
        }
    };

    // #endregion

    // #region Render Functions

    /**
     * Main render.
     */
    return (
        <>
            <LandingPageHeader
                profile={profile}
                user={user}
            />
            <main
                className={[
                    'nonWorkspacePage',
                    localStyles.container,
                ].join(' ')}
            >
                <div className='pageContent'>
                    <header>
                        <h1>
                            Send Password Reset Email
                        </h1>
                    </header>
                    <Form
                        className={localStyles.form}
                        onSubmit={sendResetEmail}
                    >
                        <TextField
                            fieldKey='email'
                            label='Email'
                            validator={(value) => { return validateEmail(value) ? null : 'Please enter a valid email address.'; }}
                            darkMode
                            required
                        />
                        <Button
                            type='submit'
                            variant='contained'
                        >
                            Send Password Reset Email
                        </Button>
                    </Form>
                </div>
            </main>
            <Footer/>
        </>
    );

    // #endregion
}

export default SendPasswordReset;

// #region Constants

// Reference enum of snackbar notification messages
const SNACKBAR_MESSAGES = {
    SUCCESS: 'Please check your email for the password reset link.',
    FAILURE: 'We encountered an error while attempting to send the reset link.',
};

// #endregion
