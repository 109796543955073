import filter from 'lodash/filter';

// #region Constants

const SAVED_SURVEY_KEY = 'savedSurvey';
const SAVED_RESPONSES_KEY = 'savedResponses';
const SAVED_PRIORITIES_KEY = 'savedPriorities';
const SAVED_COMPLETED_PRIORITIES_KEY = 'savedCompletedPriorities';

// #endregion

// #region Public Functions

/**
 * Retrieve the saved survey from local storage.
 *
 * @returns {Object | null} The saved survey; null if there is none
 */
export function getSavedSurvey() {
    const savedSurveyJSONString = localStorage.getItem(SAVED_SURVEY_KEY);

    return savedSurveyJSONString && savedSurveyJSONString !== 'undefined' ? JSON.parse(savedSurveyJSONString) : null;
}

/**
 * Sets the saved survey in local storage.
 *
 * @param {Object} survey The survey to save to local storage
 */
export function setSavedSurvey(survey) {
    localStorage.setItem(SAVED_SURVEY_KEY, JSON.stringify(survey));
}

/**
 * Retrieves the saved responses from local storage.
 *
 * @returns {Object[] | null} The saved responses; null if there are none
 */
export function getSavedResponses() {
    const savedResponsesJSONString = localStorage.getItem(SAVED_RESPONSES_KEY);

    return savedResponsesJSONString && savedResponsesJSONString !== 'undefined' ? JSON.parse(savedResponsesJSONString) : null;
}

/**
 * Sets the saved responses in local storage.
 *
 * @param {Object[]} responses The responses to save to local storage
 */
export function setSavedResponses(responses) {
    localStorage.setItem(
        SAVED_RESPONSES_KEY,
        JSON.stringify(
            filter(
                responses,
                (response) => response.content
            )
        )
    );
}

/**
 * Retrieves the saved priorities from local storage.
 *
 * @returns {Object[] | null} The saved priorities; null if there are none
 */
export function getSavedPriorities() {
    const savedPrioritiesJSONString = localStorage.getItem(SAVED_PRIORITIES_KEY);

    return savedPrioritiesJSONString && savedPrioritiesJSONString !== 'undefined' ? JSON.parse(savedPrioritiesJSONString) : null;
}

/**
 * Sets the saved priorities in local storage.
 *
 * @param {Object[]} priorities The priorities to save to local storage
 */
export function setSavedPriorities(priorities) {
    localStorage.setItem(
        SAVED_PRIORITIES_KEY,
        JSON.stringify(
            filter(
                priorities,
                (priority) => priority?.content
            )
        )
    );
}

/**
 * Retrieves the saved completed priorities from local storage.
 *
 * @returns {string[] | null} The saved completed priorities; null if there are none
 */
export function getSavedCompletedPriorities() {
    const savedCompletedPrioritiesJSONString = localStorage.getItem(SAVED_COMPLETED_PRIORITIES_KEY);

    return savedCompletedPrioritiesJSONString && savedCompletedPrioritiesJSONString !== 'undefined' ? JSON.parse(savedCompletedPrioritiesJSONString) : null;
}

/**
 * Sets the saved completed priorities in local storage.
 *
 * @param {string[]} priorities The completed priorities to save to local storage
 */
export function setSavedCompletedPriorities(priorities) {
    localStorage.setItem(
        SAVED_COMPLETED_PRIORITIES_KEY,
        JSON.stringify(priorities)
    );
}

// #endregion

// #region Private Functions

// #endregion
