import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import omit from 'lodash/omit';
import {
    Person,
    PersonOff,
    MeetingRoom,
    Warning,
} from '@mui/icons-material';
import { useUserContext } from '@unifire-js/firebase/auth';
import { RealtimeDatabaseInterface } from '@unifire-js/firebase/realtime-database';
import { useTrackFunction } from '@unifire-js/hooks';
import { Snackbar } from '@psionic/ui';
import BiddingRoomContextLevel from '@contexts/bidding-room';
import TeamContextLevel from '@contexts/team';
import {
    useFirstDependentEffect,
} from '@hooks/async';
import { PHASES } from '@services/bidding-room';
import { SnackbarService } from '@services/snackbar';
import { CORE } from '@utils/constants';
import { LoadingPage } from '@components/loading';
import { ActionHeader } from '@components/cards';
import { FancyIconButton } from '@components/buttons';
import localStyles from './wrapper.module.scss';
import Lobby from './lobby';
import BidSelection from './bid-selection';
import AwaitingResults from './awaiting-results';
import Results from './results';
import Settings from './settings';

/**
 * Wrapper for any bidding room views.
 */
function BiddingRoomWrapper() {
    // #region Hooks

    /**
     * Use react-router-dom's `navigate` function.
     */
    const navigate = useNavigate();

    /**
     * Use the user context.
     */
    const { profile } = useUserContext();

    /**
     * Use the bid card context API.
     */
    const bidCardContextAPI = BiddingRoomContextLevel.use.bidCard.api();

    /**
     * Use the bidding room public info context API.
     */
    const biddingRoomPublicInfoAPI = BiddingRoomContextLevel.use.biddingRoomPublicInfo.api();

    /**
     * Use the bidding room public info context value.
     */
    const biddingRoomPublicInfo = BiddingRoomContextLevel.use.biddingRoomPublicInfo.value();

    /**
     * Use the current team context value.
     */
    const currentTeam = TeamContextLevel.use.currentTeam.value();

    /**
     * Use the "Settings Open" context value.
     */
    const settingsOpen = BiddingRoomContextLevel.use.settingsOpen.value();

    /**
     * Track whether the user has been kicked from the room, in state.
     */
    const [
        kicked,
        setKicked,
    ] = useState(false);

    /**
     * Memoize whether the user is in the room or not.
     */
    const inRoom = useMemo(() => {
        // If the users array or the user's profile does not exist yet, the user is not in the room
        if (!biddingRoomPublicInfo?.users || !profile) {
            return false;
        }

        // If the user is found in the users prop of the bidding room public info, then the user is in the room
        if (biddingRoomPublicInfo?.users[ profile.id ]) {
            return true;
        }

        // Otherwise, the user is not in the room
        return false;
    }, [
        biddingRoomPublicInfo,
        profile,
    ]);

    /**
     * Memoize which page should be displayed.
     */
    const page = useMemo(() => {
        // If the settings page is open, return the settings page
        if (settingsOpen) {
            return <Settings/>;
        }

        // Otherwise, display the page depending on the bidding room's state
        switch (biddingRoomPublicInfo?.phase) {
            case PHASES.LOBBY:
                return <Lobby/>;
            case PHASES.VOTING:
                if (
                    biddingRoomPublicInfo?.users
                    && (
                        biddingRoomPublicInfo?.users[ profile?.id ]?.voted
                        || biddingRoomPublicInfo?.users[ profile?.id ]?.observerMode
                    )
                ) {
                    return <AwaitingResults/>;
                }

                return <BidSelection/>;

            case PHASES.RESULTS:
                return <Results/>;
            default:
                return null;
        }
    }, [
        settingsOpen,
        biddingRoomPublicInfo,
        profile,
    ]);

    /**
     * Memoize the signed-in user's info from the room's public info.
     */
    const userPublicInfo = useMemo(() => {
        // If the data isn't loaded yet, return null
        if (!profile || !biddingRoomPublicInfo?.users) {
            return null;
        }

        // Otherwise, return the user's data
        return biddingRoomPublicInfo.users[ profile.id ];
    }, [
        profile,
        biddingRoomPublicInfo,
    ]);

    /**
     * Create a tracked "join room" function.
     */
    const [
        joiningRoom,
        joinRoom,
    ] = useTrackFunction(async() => {
        // If the room has not been created yet, create the room by setting its phase to LOBBY
        const roomPhase = await RealtimeDatabaseInterface.getByPath(`${ currentTeam.id }/biddingRoom/publicInfo/phase`);

        if (!roomPhase) {
            await RealtimeDatabaseInterface.writeToPath(
                `${ currentTeam.id }/biddingRoom/publicInfo/phase`,
                PHASES.LOBBY
            );
        }

        // If the user is not already in the room, add them to the room
        const userInRoom = await RealtimeDatabaseInterface.getByPath(`${ currentTeam.id }/biddingRoom/publicInfo/users/${ profile.id }`);

        if (!userInRoom) {
            await RealtimeDatabaseInterface.writeToPath(
                `${ currentTeam.id }/biddingRoom/publicInfo/users/${ profile.id }`,
                {
                    profile: omit(profile, [
                        '_ref',
                        'subcollections',
                    ]),
                    voted:        false,
                    observerMode: false,
                    kicked:       false,
                }
            );
        }
    });

    /**
     * When the component first mounts, subscribe to the room's public info.
     */
    RealtimeDatabaseInterface.useListenerByPath(
        'BiddingRoomPublicInfoListener',
        `${ currentTeam.id }/biddingRoom/publicInfo`,
        (data) => {
            biddingRoomPublicInfoAPI.set(data);
        }
    );

    /**
     * Whenever the component first mounts, have the user join the room (and create the room, if needed).
     */
    const [
        // eslint-disable-next-line no-unused-vars
        joinCheckPassed,
        joined,
    ] = useFirstDependentEffect(

        // Effect
        joinRoom,

        // Checking function
        () => {
            return !!profile;
        },

        // Dependencies
        [ profile ]
    );

    /**
     * When the user disconnects, have the user leave the room and remove their vote if they had joined.
     */
    useEffect(() => {
        if (joined) {
            RealtimeDatabaseInterface.addOnDisconnectListenerByPath(
                'OnDisconnectRemoveUser',
                `${ currentTeam.id }/biddingRoom/publicInfo/users/${ profile.id }`,
                'remove'
            );
            RealtimeDatabaseInterface.addOnDisconnectListenerByPath(
                'OnDisconnectRemoveUserVote',
                `${ currentTeam.id }/biddingRoom/privateInfo/userVotes/${ profile.id }`,
                'remove'
            );

            // On component unmount, remove the `onDisconnect` listeners, and have the user leave the room
            return () => {
                RealtimeDatabaseInterface.deleteAtPath(`${ currentTeam.id }/biddingRoom/publicInfo/users/${ profile.id }`);
                RealtimeDatabaseInterface.deleteAtPath(`${ currentTeam.id }/biddingRoom/privateInfo/userVotes/${ profile.id }`);

                RealtimeDatabaseInterface.removeOnDisconnectListener('OnDisconnectRemoveUser');
                RealtimeDatabaseInterface.removeOnDisconnectListener('OnDisconnectRemoveUserVote');
            };
        }
    }, [ joined ]);

    /**
     * If the user is ever kicked from the room, set the kicked state to `true`.
     */
    useEffect(() => {
        // If the user has not joined yet, or if the bidding room public info has not yet loaded the user's data,
        // return early
        if (!joined || !biddingRoomPublicInfo?.users || !biddingRoomPublicInfo?.users[ profile.id ]) {
            return;
        }

        // Otherwise, if the user is ever kicked from the room, perform the kicked logic
        if (biddingRoomPublicInfo.users[ profile.id ].kicked) {
            setKicked(true);
        }
    }, [
        joined,
        biddingRoomPublicInfo,
    ]);

    /**
     * If the user is ever no longer in the room, but they already performed the initial join operation, then
     * attempt to have them join again.
     */
    useEffect(() => {
        if (
            profile
            && joined
            && !kicked
            && !inRoom
            && !joiningRoom
        ) {
            joinRoom();
        }
    }, [
        joined,
        inRoom,
        joiningRoom,
        profile,
    ]);

    /**
     * If the user is ever kicked from the room, attempt to navigate them away from the room.
     */
    useEffect(() => {
        if (kicked) {
            SnackbarService.addSnackbar(
                ({ removeSnackbar }) => {
                    return (
                        <Snackbar
                            color='warning'
                            removeSnackbar={removeSnackbar}
                            SvgIcon={Warning}
                            text='You were removed from the bidding room!'
                        />
                    );
                },
                CORE.SNACKBAR_DURATION
            );
            navigate(`/team/${ currentTeam.id }`);
        }
    }, [ kicked ]);

    // #endregion

    // #region Functions

    /**
     * Handler for when the "leave room" button is clicked.
     */
    const onLeaveRoomButtonClicked = () => {
        navigate(`/team/${ currentTeam?.id }`);
    };

    /**
     * Handler for when the "toggle AFK button" is clicked.
     */
    const onToggleAFK = async() => {
        // Reset the bid card context
        bidCardContextAPI.reset();

        // Write database data
        await Promise.all([
            RealtimeDatabaseInterface.writeToPath(
                `${ currentTeam.id }/biddingRoom/publicInfo/users/${ profile.id }`,
                {
                    kicked:       false,
                    observerMode: !userPublicInfo?.observerMode,
                    profile:      userPublicInfo?.profile,
                    voted:        false,
                }
            ),
            RealtimeDatabaseInterface.deleteAtPath(`${ currentTeam.id }/biddingRoom/privateInfo/userVotes/${ profile.id }`),
        ]);
    };

    // #endregion

    // #region Render Functions

    /**
     * If the user has not joined yet, render the loading page.
     */
    if (!joined || !inRoom || !userPublicInfo || !page) {
        return <LoadingPage/>;
    }

    /**
     * Main render.
     */
    return (
        <div
            className={[
                'workspacePage',
                localStyles.wrapper,
            ].join(' ')}
        >
            <header className={localStyles.actionHeader}>
                <ActionHeader
                    actionButtons={[
                        <FancyIconButton
                            key={0}
                            className={localStyles.leaveRoomButton}
                            Icon={MeetingRoom}
                            tooltip='Leave Room'
                            onClick={onLeaveRoomButtonClicked}
                        />,
                        <FancyIconButton
                            key={1}
                            Icon={userPublicInfo?.observerMode ? PersonOff : Person}
                            tooltip={userPublicInfo?.observerMode ? 'Set Active Status' : 'Set Away from Keyboard Status'}
                            className={
                                userPublicInfo?.observerMode
                                    ? localStyles.toggleAFKButtonInactive
                                    : localStyles.toggleAFKButtonActive
                            }
                            onClick={onToggleAFK}
                        />,
                    ]}
                />
            </header>
            {page}
        </div>
    );

    // #endregion
}

/**
 * Wrapper to provide the bidding room-level Provider.
 */
// eslint-disable-next-line react/no-multi-comp
function ProviderWrapper() {
    return (
        <BiddingRoomContextLevel.Provider>
            <BiddingRoomWrapper/>
        </BiddingRoomContextLevel.Provider>
    );
}

export default ProviderWrapper;
