/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */

// #region Model Imports

import ActionItemModel from '@models/action-item';
import AgendaModel from '@models/agenda';
import AssociationModel from '@models/association';
import BiddingRoomConfigModel from '@models/bidding-room-config';
import BulletinModel from '@models/bulletin';
import CheckInQuestionModel from '@models/check-in-question';
import CheckInReviewerAssociationModel from '@models/check-in-reviewer-association';
import CheckInServiceInfoModel from '@models/check-in-service-info';
import CheckInSurveyBatchModel from '@models/check-in-survey-batch';
import CheckInSurveyConfigurationModel from '@models/check-in-survey-configuration';
import CheckInSurveyQuestionResponseModel from '@models/check-in-survey-question-response';
import CheckInSurveyQuestionModel from '@models/check-in-survey-question';
import CheckInSurveyModel from '@models/check-in-survey';
import CommentModel from '@models/comment';
import DayModel from '@models/day';
import ElementModel from '@models/element';
import FeatureConfigModel from '@models/feature-config';
import InviteModel from '@models/invite';
import MinuteModel from '@models/minute';
import PrioritiesModel from '@models/priorities';
import ProfileModel from '@models/profile';
import ReactionModel from '@models/reaction';
import TeamGroupModel from '@models/team-group';
import TeamSubscriptionModel from '@models/team-subscription';
import TeamModel from '@models/team';
import WorkerModel from '@models/worker';

// #endregion

import { Settings as LuxonSettings } from 'luxon';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UserContextProvider } from '@unifire-js/firebase/auth';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import {
    StyleManager,
    SnackbarManager,
    useSnackbar,
} from '@psionic/ui';
import ReleaseNotesModule from '@module-components/release-notes/release-notes-module';
import '@stylesheets/main.scss';
import '@stylesheets/typography.scss';
import '@stylesheets/common-ui-components.scss';
import muiTheme from '@stylesheets/muiTheme';
import psionicTheme from '@stylesheets/psionic-theme';
import { SnackbarService } from '@services/snackbar';
import { initializeProfileListener } from '@services/auth-middleware';
import { ScrollToTop } from '@components/navigation';

function App() {
    useEffect(() => {
        const siteWidth = 1280;
        const scale = screen.width / siteWidth;

        document.querySelector('meta[name="viewport"]').setAttribute('content', `width=${ siteWidth }, initial-scale=${ scale }`);
    }, []);

    return (
        <UserContextProvider
            middleware={[ initializeProfileListener ]}
        >
            <StyleManager theme={psionicTheme}>
                <SnackbarManager>
                    <SnackbarManagerExporter/>
                    <ReleaseNotesModule/>
                    <DndProvider backend={HTML5Backend}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={muiTheme}>

                                    <ScrollToTop/>
                                    <Outlet/>

                                </ThemeProvider>
                            </StyledEngineProvider>
                        </LocalizationProvider>
                    </DndProvider>
                </SnackbarManager>
            </StyleManager>
        </UserContextProvider>
    );
}

// eslint-disable-next-line react/no-multi-comp
function SnackbarManagerExporter() {
    const addSnackbar = useSnackbar();
    SnackbarService.addSnackbar = addSnackbar;

    return null;
}

export default App;
