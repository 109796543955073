import { Submodel } from '@unifire-js/firebase/firestore';
import CheckInSurveyQuestionModel from '@models/check-in-survey-question';

const CheckInSurveyQuestionResponseModel = new Submodel({
    collectionName:  'checkInSurveyQuestionResponses',
    parent:          CheckInSurveyQuestionModel,
    collectionProps: [
        'content',
        'surveyID',
        'surveyQuestionID',
        'teamID',
    ],
});

export default CheckInSurveyQuestionResponseModel;
