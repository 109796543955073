import { DateTime } from 'luxon';

// #region Public Functions

/**
 * Gets today's Agenda UTC date.
 *
 * @param {string} teamTimezone The team's timezone
 * @returns {DateTime} Today's agenda UTC date
 */
export function getTodaysAgendaUTCDate(teamTimezone) {
    return DateTime.local()
        .setZone(teamTimezone)
        .startOf('day')
        .toUTC()
        .startOf('day');
}

/**
 * Converts the given agenda UTC date to the team's timezone (beginning of day).
 *
 * @param {DateTime} agendaUTCDate The Agenda UTC date to convert
 * @param {string} teamTimezone The team's timezone
 * @returns {DateTime} The given agenda UTC date in the team's timezone (beginning of day)
 */
export function convertAgendaUTCDateToTeamTimezone(agendaUTCDate, teamTimezone) {
    let date = agendaUTCDate.setZone(teamTimezone).startOf('day');

    date = date.plus({ days: 1 });

    return date;
}

/**
 * Gets the given day's "date value" for the team in UTC.
 *
 * @param {DateTime} date The date to convert
 * @returns {DateTime} The start of the given date in UTC
 */
export function getValueForDateInTeamTimezone(date, teamTimezone) {
    return DateTime.fromObject(
        {
            year:        date.year,
            month:       date.month,
            day:         date.day,
            hour:        0,
            minute:      0,
            second:      0,
            millisecond: 0,
        },
        {
            zone: teamTimezone,
        }
    )
        .toUTC()
        .startOf('day');
}

// #endregion

// #region Private Functions

// #endregion
