import { CategorizedErrorFactory } from '@unifire-js/categorized-errors';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@services/firebase';

/**
 * Attempt to log a user in to their Firebase auth account.
 *
 * @param {string} email The email supplied by the user for logging in
 * @param {string} password The email supplied by the user for logging in
 */
export default async function(email, password) {
    const sanitizedEmail = email.trim().toLowerCase();

    try {
        await signInWithEmailAndPassword(auth, sanitizedEmail, password);
    } catch (err) {
        switch (err.code) {
            case 'auth/user-not-found':
            case 'auth/wrong-password':
                throw ERRORS.factories.INCORRECT_CREDENTIALS();
            default:
                throw ERRORS.factories.OTHER_ERROR();
        }
    }
}

// #region Categorized Errors

const ERRORS = new CategorizedErrorFactory({
    INCORRECT_CREDENTIALS: {
        message: 'An account was not found with those credentials.',
    },
    OTHER_ERROR: {
        message: 'An error occurred while attempting to log you in!',
    },
});

// #endregion
